<template>
	<div>
		<HeaderMain
				title="Документы"
			/>

		<List>
			<CardDocument v-for="item in documents.data" :key="item.key" :item="item"/>
		</List>
	</div>
</template>


<script>
import HeaderMain from '@/components/headers/HeaderMain';
import request from "@/services/request";
import ModelDocumentPreview from '@/services/models/ModelDocumentPreview';
import List from '@/components/lists/List';
import CardDocument from '@/components/cards/custom/CardDocument/CardDocument';

export default {
	components: {
		CardDocument,
		List,
		HeaderMain
	},
	data() {
		return {
			documents: [],
		}
	},
	mounted() {
		this.getAllDocuments();
	},
	methods: {
		async getAllDocuments() {
			this.documents = await request({endpoint: '/api/mainPage/getAllDocuments', method: 'POST', body: {"pageNumber":0,"recordCount":100}, path: 'documents', model: ModelDocumentPreview})
		},
	}

}


</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"




</style>