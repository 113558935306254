<template>
  <div class="reference-main">

    <HeaderMain
        title="Список ЦО"
        subtitle=""
    />
    <div class="cms-panel">
      <div class="cms-panel-search">
        <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon">mdi-magnify</v-icon>
        <input type="search" class="cms-panel-search__input" v-model="searchOrg">
      </div>
      <BSelect :data="searchFields" v-model="selectedSearchField" class="cms-panel-search__sort" />
    </div>

    <div class="sort-panel">
      <div class="card__info card__info_sort card__info__id" @click="sort('orgFounderID')">ID &#8595;</div>
      <div class="card__info card__info_sort card__info__name" @click="sort('orgFounderName')">Наименование &#8595;</div>
      <div class="card__info card__info__action"></div>
    </div>

    <div v-if="resultSearch">
      <CardAdminFounder v-for="item in resultSearch.data" :key="item.key" :item="item" @auth-in-founder="onAuthInFounder($event)" @block-founder="blockFounder($event)"/>
    </div>

    <v-progress-linear
        indeterminate
        color="#00599b"
        v-if="loadingSpr"
    ></v-progress-linear>
    <p class="paginator-info"> Страница: {{ this.paginatorParams.selectedPage }}, Результатов на странице: {{ this.paginatorParams.itemsPerPage }}, Всего результатов: {{resultSearch.counter}} </p>
    <BPaginator
        v-if="resultSearch"
        :propDataCount="resultSearch.counter"
        v-model="paginatorParams"
    />

  </div>
</template>


<script>
import {mapActions} from 'vuex';
import CardAdminFounder from "@/components/cards/custom/CardAdminOrg/CardAdminFounder";
import BSelect from "@/components/controls/BSelect/BSelect";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";
import HeaderMain from "@/components/headers/HeaderMain";
import request from "@/services/request";
export default {
  components: {
    CardAdminFounder,
    HeaderMain,
    BSelect,
    BPaginator
  },
  name: 'viewFounders',
  data() {
    return {
      searchOrg: '',
      searchFields: ['ID', 'Наименование'],
      selectedSearchField: 'Наименование',
      results: 0,
      currentSort: 'orgFounderID',
      currentSortDir: 'asc',
      paginatorParams: {'selectedPage' : 1, 'itemsPerPage' : 10},
      loadingSpr: false,
      founderSpr : {data: [], counter: 0}
    }
  },
  created() {
    this.getServerData()
  },
  computed: {
    // ...mapGetters('spr', {founderSpr: 'founderSpr'}),
    // ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    selectedField() {
      if (this.selectedSearchField === 'ID') {
        return 'orgFounderID'
      } else if (this.selectedSearchField === 'Наименование') {
        return 'orgFounderName'
      } else {
        return 'orgFounderName'
      }
    },

    resultSearch() {
      let results;
      let array = [];
      array = this.founderSpr.data
      array = array.sort((a, b) => {
        let mod = 1
        if (this.currentSortDir === 'desc') mod = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * mod
        if (a[this.currentSort] > b[this.currentSort]) return 1 * mod
        return 0
      });
      let search = this.searchOrg;
      if (!search) {
        results = array.length;
        array = array.filter((row, index) => {
          let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
          let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
          if (index >= start && index < end) return true
        })
        return {'data': array, 'counter': results};
      }
      search = search.trim().toLowerCase()
      array = array.filter((item) => {
        if (String(item[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
          return item
        }
      })
      results = array.length;
      array = array.filter((row, index) => {
        let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
        let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
        if (index >= start && index < end) return true
      })
      return {'data': array, 'counter': results};
    },
  },
  methods: {
    ...mapActions('spr', {dataForSpr: 'dataForSpr'}),
    ...mapActions('auth', {replaceFounderIdAdmin: 'replaceFounderIdAdmin'}),
    ...mapActions('auth', {login: 'login'}),
    // Pagination
    prevPage () {
      if (this.paginatorParams.selectedPage > 1) this.paginatorParams.selectedPage-=1
    },
    nextPage () {
      if ((this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage) < this.resultSearch.counter) this.paginatorParams.selectedPage+=1
    },
    sort (e) {
      if (e === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = e
    },
    onAuthInFounder(event) {
      this.replaceFounderIdAdmin(event);
      this.$router.push('/private');
    },
    async blockFounder(item) {
      const res = await request({
        endpoint: `/api/stageData/${item.blocked3 ? 'unblock': 'block'}?founderId=${item.orgFounderID}&stage=3`,
        method: 'get',
      })
      if (res.status === 200) {
        this.loadingSpr = false;
        const index = this.founderSpr.data.findIndex(el => el.orgFounderID ===  item.orgFounderID);
        this.founderSpr.data.splice(index, 1, {...res.data, ...res.data.sprOrgFounder});
        // this.founderSpr = {data : res.data, counter: res.data.length}
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных',
          timeout: 3000
        })
        // this.errorData = res.error;
        // this.loadingSpr = false;
      }
    },
    async getServerData() {
      this.loadingSpr = true;
      const res = await request({
        endpoint: `/api/founderService/getAll`,
        method: 'post',
      })
      if (res.status === 200) {
        this.loadingSpr = false;
        this.founderSpr = {data : res.data.filter(el=>([6,8,9,11,12,13,14,15,16,17,18,31,46,47,54,108,111,
                  113,114,118,119,120,121,127,134,139].includes(el.orgFounderID)) ), counter: res.data.length}
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных',
          timeout: 3000
        })
        this.errorData = res.error;
        this.loadingSpr = false;
      }
    }
  }

}

</script>


<style lang="sass" scoped>
@import "@/assets/styles/utils/variables"

.cms-panel-search
  display: flex
  align-items: center
  background: #fff
  border-radius: 5px
  padding: 0px 10px
  border: 1px solid #cdd1d4
.cms-panel
  margin-bottom: 20px
  display: flex
.cms-panel-search__sort
  background-color: #fff
  display: flex
  align-items: center
  border-radius: 5px
  padding: 0px 10px
  border: 1px solid #cdd1d4
  margin-left: 10px
.sort-panel
  display: flex
  width: 100%
  justify-content: space-between
.reference-main
  width: 100%
.paginator-info
  text-align: center
  color: #00599b
.sort-panel
  font-weight: 600
  color: #00599b
  margin-bottom: 10px
.card__info_sort
  cursor: pointer
.card__info.card__info__action .button_border
  text-align: center
  max-height: 35px
.cms-panel-search__sort.select
  min-width: 170px
.cms-panel-search__input
  min-width: 500px
</style>