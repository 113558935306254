<template>
<!--ContainerIndicatorsAllList  -->
  <component :is="!isIndicatorsWithMaxValues ? 'ContainerIndicatorsAllList' : 'ContainerIndicatorsAllListWithFinallyCalculatedValues'"/>
<!--  <ContainerIndicatorsAllList/>-->
</template>

<script>
import ContainerIndicatorsAllList from '@/containers/private/indicators/ContainerIndicatorsAllList';
import ContainerIndicatorsAllListWithFinallyCalculatedValues
  from "@/containers/private/indicators/ContainerIndicatorsAllListWithFinallyCalculatedValues";
export default {
name: "viewKcpGroups",
  components: {
    ContainerIndicatorsAllList,
    ContainerIndicatorsAllListWithFinallyCalculatedValues
  },
  computed: {
    isIndicatorsWithMaxValues() {
      const flag = this.$route.query?.prior;
      return flag === true || flag === 'true'
    }
  },

}
</script>

<style scoped>

</style>