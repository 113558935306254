<template>
<router-view/>
</template>

<script>
export default {
name: "viewIndicatorsRouter"
}
</script>

<style scoped>

</style>