<template>
  <div>
    <!-- <HeaderMain
          v-if="list"
          :title="list[0].sprOrg.orgName"
      /> -->




    <div v-if="list">
      <div v-for="(org, index) of list" :key="index">
        <h2
            class="title app-title_main app__title"
            :class="org.isOwner ? 'main-header__title-list' : ''"
            :style="org.isOwner ? 'font-size: 32px!important;' : ''"
          >
          {{org.orgName}}
        </h2>
<!--        <div class="app-header" v-if="org.isOwner">-->
<!--          <div class="app-header__form"></div>-->
<!--          <div class="app-header__form"></div>-->
<!--          <div class="app-header__form">-->
<!--             <span class="app-header__about"> Очно </span>-->
<!--          </div>-->
<!--          <div class="app-header__form">-->
<!--            <span class="app-header__about">-->
<!--              Очно-заочно-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="app-header__form">-->
<!--            <span class="app-header__about">-->
<!--              Заочно-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
        <div v-for="(formUsl, index) of org.data" :key="index" class="app-list">
          <div class="app-header" >
            <div class="app-header__form">
              <h3 class="title app__title text-left" v-text="formUsl.uslName"></h3>
            </div>
            <div class="app-header__form"></div>
            <div class="app-header__form">
              <span class="app-header__about"> Очно </span>
            </div>
            <div class="app-header__form">
            <span class="app-header__about">
              Очно-заочно
            </span>
            </div>
            <div class="app-header__form">
            <span class="app-header__about">
              Заочно
            </span>
            </div>
          </div>

          <div v-for="(item, index) of formUsl.data" :key="index" class="app">
            <div v-text="item.sprKCPGroup.code" class="app__form" ></div>
            <div v-text="item.sprKCPGroup.name" class="app__form"></div>
            <div class="app__form app__form_link  "
                 @click="openScore(item.id, item.s0b, 1, item.o1, item.sprKCPGroup.sprUsl.uslID)"
                 style="justify-content: space-between;"
                 :style="calcBackground(item.o1, item.ow)"
                 v-if="item.za1">
<!--              <div class="app__calc" v-text="item.za1 ? item.za1 : 'н/д'"></div>-->
              <div class="app__score" v-text="item.o1 ? item.o1.toFixed(3) : 'н/д'"></div>
            </div>
            <div v-else class="app__form">
            </div>
            <div class="app__form app__form_link  "
                 @click="openScore(item.id, item.s0b, 2, item.o2, item.sprKCPGroup.sprUsl.uslID)"
                 style="justify-content: space-between;"
                 :style="calcBackground(item.o2, item.ow)"
                 v-if="item.za2">
<!--              <div class="app__calc" v-text="item.za2 ? item.za2 : 'н/д'"></div>-->
              <div class="app__score" v-text="item.o2 ? item.o2.toFixed(3) : 'н/д'"></div>
            </div>
            <div v-else class="app__form">
            </div>
            <div class="app__form app__form_link  "
                 @click="openScore(item.id, item.s0b, 3, item.o3, item.sprKCPGroup.sprUsl.uslID)"
                 style="justify-content: space-between;"
                 :style="calcBackground(item.o3, item.ow)"
                 v-if="item.za3">
<!--              <div class="app__calc" v-text="item.za3 ? item.za3 : 'н/д'"></div>-->
              <div class="app__score" v-text="item.o3 ? item.o3.toFixed(3) : 'н/д'"></div>
            </div>
            <div v-else class="app__form">
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import request from "@/services/request";
// import HeaderMain from "@/components/headers/HeaderMain";
import {mapGetters} from 'vuex'

export default {
  components: {
    // HeaderMain,
  },
  data() {
    return {
      list: null,
    }
  },
  created() {
    this.getAllCalcOrgAndKCPGroup(this.get_replace_id_admin)
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
  },
  methods: {
    async getAllCalcOrgAndKCPGroup(orgID) {
      let res = await request({
        endpoint: `/api/zayavka/getAllCalcOrgAndKCPGroup?sprOrgID=${orgID}`,
        method: 'get'
      })
      let list = {};
      if (res.status === 200) {

        res.data.zayavkaKCPCalcMains.forEach(el => {
          // Фильтрация по организации (организация и филиалы)
          if (!(el.sprOrg.orgName in list)) {
            list[el.sprOrg.orgName] = {};
            list[el.sprOrg.orgName].orgName = el.sprOrg.orgName;
            list[el.sprOrg.orgName].status = el.sprOrg.orgID === el.sprOrg.orgOwner ? 'Головная' : 'Филиал';
            list[el.sprOrg.orgName].isOwner = el.sprOrg.orgID === el.sprOrg.orgOwner;
            list[el.sprOrg.orgName].data = {};
          }
          const orgData = list[el.sprOrg.orgName].data;
          // Фильтрация по уровню
          if (!(el.sprKCPGroup.sprUsl.uslName in orgData)) {
            orgData[el.sprKCPGroup.sprUsl.uslName] = {};
            orgData[el.sprKCPGroup.sprUsl.uslName].uslName = el.sprKCPGroup.sprUsl.uslName;
            orgData[el.sprKCPGroup.sprUsl.uslName].data = [];
          }
          orgData[el.sprKCPGroup.sprUsl.uslName].data.push(el);

        });
        this.list = list;

      }
    },
    calcBackground(value, maxValue){
      let a=  Number.parseFloat(value)
      let b=  Number.parseFloat(maxValue)
      let per = a * 100 / b
      return  isNaN(per) ?`  background: #f3f3f4; border-color: #CDD1D4`:
          `background: -webkit-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:    -moz-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:     -ms-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:      -o-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:         linear-gradient(to right, #eb722e ${per}%,#f9d3be ${per}%);`
    },
    openScore(id,s0b, appForm, score, usl) {
     // console.log(id," - ",s0b," - ", appForm," - ", score," - ", usl)
      // сдлеать переход ессли s0b == 0
      if (s0b === 1 || s0b === '1')
        this.$router.push({path: '/private/indicators/list', query: {fo: appForm, id: id, value: score, usl: usl}})
    }

  }
}
</script>
<style lang="sass">
.app
  background: #fff
  margin-bottom: 15px
  padding: 5px 10px
  display: flex
  justify-content: space-between
  align-items: center
  border-left: 3px solid #00599b
  transition: 0.3s
.main-header__title-list
  font-size: 32px!important
  line-height: 35.2px!important
  color: #00599B!important
  font-family: 'Montserrat'!important
  font-weight: 700!important
  max-width: 100%!important
  margin-bottom: 22px
.app:hover
  transform: translateY(-5px)
  box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
  transition: 0.3s

.app__form
  display: flex
  max-width: 200px
  justify-content: space-between
  flex-basis: 200px
  margin-right: auto
  &:nth-child(1)
    margin-right: 0
    max-width: 100px
    flex-basis: 100px
  &:nth-child(2)
    max-width: 400px
    flex-basis: 400px

.app-header
  font-weight: 600
  color: #00599b
  margin-bottom: 10px
  display: flex
  width: 100%
  justify-content: right
  padding: 5px 0px
.app-header__form
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  margin-right: auto
  max-width: 200px
  flex-basis: 200px
  &:nth-child(1)
    margin-right: 0
    max-width: 100px
    flex-basis: 100px
  &:nth-child(2)
    max-width: 400px
    flex-basis: 400px




.app-header__about
  //font-size: 15px
  text-align: center
  width: 100%

.app-header__calc, .app-header__score
  font-size: 10px

.app__title
  margin-bottom: 15px
  color: #00599b

.app__form_link
  background: #eb8e1c
  color: #fff
  font-weight: 600
  border-radius: 7px
  padding: 4px 7px
  cursor: pointer


.app-title_main
  //text-transform: uppercase
  font-weight: 900
  color: #eb8e1c
  //margin-top: 50px

.app-list
  margin-bottom: 30px
</style>