<template>
    <ContainerReferenceDetail />
</template>

<script>
import ContainerReferenceDetail from "@/containers/admin/reference/ContainerReferenceDetail";
export default {
  components: {
    ContainerReferenceDetail,
  },
};
</script>
