<template>
  <component :is="item.dataFieldName" :item="item" :row-of-values="rowOfValues"/>
</template>

<script>
import b11 from "@/components/cards/custom/indicatiors/cardsTables/b11";
import b12 from "@/components/cards/custom/indicatiors/cardsTables/b12";
import b13 from "@/components/cards/custom/indicatiors/cardsTables/b13";
import b21 from "@/components/cards/custom/indicatiors/cardsTables/b21";
import b22 from "@/components/cards/custom/indicatiors/cardsTables/b22";
import b23 from "@/components/cards/custom/indicatiors/cardsTables/b23";
import b24 from "@/components/cards/custom/indicatiors/cardsTables/b24";
import b25 from "@/components/cards/custom/indicatiors/cardsTables/b25";
import b28 from "@/components/cards/custom/indicatiors/cardsTables/b28";
import b31 from "@/components/cards/custom/indicatiors/cardsTables/b31";
import b32 from "@/components/cards/custom/indicatiors/cardsTables/b32";
import b41 from "@/components/cards/custom/indicatiors/cardsTables/b41";
import b42 from "@/components/cards/custom/indicatiors/cardsTables/b42";
import b43 from "@/components/cards/custom/indicatiors/cardsTables/b43";
import b44 from "@/components/cards/custom/indicatiors/cardsTables/b44";

import m11 from "@/components/cards/custom/indicatiors/cardsTables/m11";
import m12 from "@/components/cards/custom/indicatiors/cardsTables/m12";
import m13 from "@/components/cards/custom/indicatiors/cardsTables/m13";
import m14 from "@/components/cards/custom/indicatiors/cardsTables/m14";
import m21 from "@/components/cards/custom/indicatiors/cardsTables/m21";
import m22 from "@/components/cards/custom/indicatiors/cardsTables/m22";
import m23 from "@/components/cards/custom/indicatiors/cardsTables/m23";
import m26 from "@/components/cards/custom/indicatiors/cardsTables/m26";
import m31 from "@/components/cards/custom/indicatiors/cardsTables/m31";
import m32 from "@/components/cards/custom/indicatiors/cardsTables/m32";
import m41 from "@/components/cards/custom/indicatiors/cardsTables/m41";
import m42 from "@/components/cards/custom/indicatiors/cardsTables/m42";
import m43 from "@/components/cards/custom/indicatiors/cardsTables/m43";
import m44 from "@/components/cards/custom/indicatiors/cardsTables/m44";


import o11 from "@/components/cards/custom/indicatiors/cardsTables/o11";
import o13 from "@/components/cards/custom/indicatiors/cardsTables/o13";
import o14 from "@/components/cards/custom/indicatiors/cardsTables/o14";
import o21 from "@/components/cards/custom/indicatiors/cardsTables/o21";
import o22 from "@/components/cards/custom/indicatiors/cardsTables/o22";
import o23 from "@/components/cards/custom/indicatiors/cardsTables/o23";
import o28 from "@/components/cards/custom/indicatiors/cardsTables/o28";
import o31 from "@/components/cards/custom/indicatiors/cardsTables/o31";
import o41 from "@/components/cards/custom/indicatiors/cardsTables/o41";
import o42 from "@/components/cards/custom/indicatiors/cardsTables/o42";
import o43 from "@/components/cards/custom/indicatiors/cardsTables/o43";
import o44 from "@/components/cards/custom/indicatiors/cardsTables/o44";
import o12 from "@/components/cards/custom/indicatiors/cardsTables/o12";

import k11 from "@/components/cards/custom/indicatiors/cardsTables/k11";
import k12 from "@/components/cards/custom/indicatiors/cardsTables/k12";
import k13 from "@/components/cards/custom/indicatiors/cardsTables/k13";
import k14 from "@/components/cards/custom/indicatiors/cardsTables/k14";
import k21 from "@/components/cards/custom/indicatiors/cardsTables/k21";
import k22 from "@/components/cards/custom/indicatiors/cardsTables/k22";
import k23 from "@/components/cards/custom/indicatiors/cardsTables/k23";
import k31 from "@/components/cards/custom/indicatiors/cardsTables/k31";
import k32 from "@/components/cards/custom/indicatiors/cardsTables/k32";
import k41 from "@/components/cards/custom/indicatiors/cardsTables/k41";
import k42 from "@/components/cards/custom/indicatiors/cardsTables/k42";

import a11 from "@/components/cards/custom/indicatiors/cardsTables/a11";
import a21 from "@/components/cards/custom/indicatiors/cardsTables/a21";
import a22 from "@/components/cards/custom/indicatiors/cardsTables/a22";
import a23 from "@/components/cards/custom/indicatiors/cardsTables/a23";
import a31 from "@/components/cards/custom/indicatiors/cardsTables/a31";
import a32 from "@/components/cards/custom/indicatiors/cardsTables/a32";
import a33 from "@/components/cards/custom/indicatiors/cardsTables/a33";
import a34 from "@/components/cards/custom/indicatiors/cardsTables/a34";
import a35 from "@/components/cards/custom/indicatiors/cardsTables/a35";

import generalValue from "@/components/cards/custom/indicatiors/cardsTables/generalValue";

export default {
  name: "cardTopTables",
  components:{
    b11, b12, b13,b21, b22, b23, b24, b25, b28, b31, b32, b41, b42, b43, b44,
    m11,m12,m13,m14,m21,m22,m23,m26,m31,m32,m41,m42,m43,m44,
    o11,o12,o13,o14,o21,o22,o23,o28,o31,o41,o42,o43,o44,
    k11, k12, k13, k14,k21, k22, k23, k31, k32, k41, k42,
    a11,a21,a22,a23,a31,a32,a33,a34,a35,
    generalValue
  },
  props: {
    nameComponent:{
      type:String,
      default: ''
    },
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
}
</script>

<style scoped>

</style>