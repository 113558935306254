<template>
<div>
  <div class="score-about">
    <p><b>п.4.1.2. Приложения 2 Порядка:</b></p>
    <p>Соотношение численности абитуриентов, подавших заявление на поступление, и численности абитуриентов, принятых на обучение</p>
  </div>
  <v-divider class="mt-4"/>
  <h4 class="score-subtitle">1. Значение показателя</h4>
  <h5 class="score-subsubtitle">
    Исходные данные:
  </h5>
  <div class="calculation__about">
    <p class="calculation__about-text">
      Форма 5.10.<br>
      Показатели приема на обучение по программам ординатуры, ассистентуры
    </p>
  </div>
  <table class="indicators__table">
    <thead class="b32-thead">
    <tr class="">
      <th class="" rowspan="3">№ п/г</th>
      <th class="" colspan="2">УГСН</th>
      <th class="" colspan="3">Всего подано заявлений на<br> поступление на I курс</th>
      <th colspan="3">Всего абитуриентов,<br>
        принятых на I курс</th>
    </tr>
    <tr class="">
      <th class="">Код</th>
      <th class="">Наименование</th>
      <th class="" colspan="3">2020 год</th>
      <th class="" colspan="3">2020 год</th>

    </tr>
    <tr class="">
      <th class="">1</th>
      <th class="">2</th>
      <th class="" colspan="3">3</th>
      <th class="" colspan="3">4</th>

    </tr>
    </thead>
    <tbody class="b32-tbody">
    <tr>
      <td class="">1.</td>
      <td class="">
        {{ rowOfValues.data.sprKCPGroup.code }}
      </td>
      <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
      <td class="" colspan="3" v-html="checkData(rowOfValues.data.zm)"></td>
      <td class="" colspan="3" v-html="checkData(rowOfValues.data.zpk)"></td>
    </tr>
    <tr>
      <td colspan="3">
      </td>
      <td colspan="3" style="">
        <div ><img style="height: 20px; margin: 0; width:210px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                     alt=""/>
        </div>
      </td>
      <td colspan="3" style="">
        <div ><img style="height: 20px; margin: 0; width:210px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                   alt=""/>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="3">
      </td>
      <td colspan="3" style="">
        <div >
          ЗМ
        </div>
      </td>
      <td colspan="3" style="">
        <div >
          ЗПК
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <!-- formula -->
  <section class="section-score">
    <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

    <div class="formula">
      <div class="formula-full"><span>K12<span class="font-size-little">0</span> =</span></div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline  px-3">ЗМ</div>
        <div class="formula-half" style="margin-left: 25%">ЗПК</div>
      </div>
      <div class="formula-full">
        <div class="font-weight-regular">×100</div>
      </div>
      <div class="formula-full">=</div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.zm)"></span></div>
        <div class="formula-half" style="margin-left: 25%"><span v-html="checkData(rowOfValues.data.zpk)"></span></div>
      </div>
      <div class="formula-full">
        <div class="font-weight-regular">×100</div>
      </div>
      <div class="formula-full">=</div>
      <div class="formula-full" v-html="checkData(rowOfValues.data.k12b0)"> </div>
    </div>

    <!-- formula -->

    <div class="score-about mb-4">
    </div>
    <div class="calculation__about">
      <p class="calculation__about-text">
ЗМ - всего заявлений на поступление на I курс, поданных в 2020 году (за счет всех источников финансирования)
      </p>
      <p class="calculation__about-text">
ЗПК - всего абитуриентов, принятых в 2020 году на обучение на I курс (за счет всех источников финансирования)
      </p>
    </div>
  </section>
</div>
</template>

<script>
export default {
name: "k12",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%
.b32-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px
.b32-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px
  tr
    &:nth-child(2)
      td
        border: 0
    &:nth-child(3)
      td
        border: 0
</style>