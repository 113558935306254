import { nanoid } from 'nanoid'
import moment from 'moment'

class ModelNewsDetail {
    constructor({articlesID, publicDAte, title, articleBody, important, titleTranslit}) {
        this.id = articlesID;
        this.key = nanoid();
        this.createDate = moment(publicDAte).format('LL');
        this.title = title;
        this.articleBody = articleBody;
        this.important = important;
        this.titleTranslit = titleTranslit;
    }
}
export default ModelNewsDetail;