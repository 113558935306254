<template>
	<main>
	<v-container>

		<HeaderMain
			title="Часто-задаваемые вопросы"
		/>
		<ContainerFAQ />
		


	</v-container>
    </main>
</template>


<script>
	import ContainerFAQ from '@/containers/front/faq/ContainerFAQCategory';
	import HeaderMain from '@/components/headers/HeaderMain';


	export default {
		components: {
			ContainerFAQ,
			HeaderMain,
			},
		data() {
			return {
			}
		}
	}
</script>

<style lang="sass">
</style>