<template>
    <WrapperForm>
		<form @submit.prevent="onSubmit" class='form'>
            <Field
                type="text"
                v-model="form.fio"
                name="fio"
                placeholder="ФИО"
                label="Фамилия, Имя, Отчество ответственного"
                @input="onChange"
            />
            <Field
                type="text"
                v-model="form.position"
                name="position"
                placeholder="Должность"
                label="Введите должность ответственного"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.position}"
            />
            <Field
                type="text"
                v-model="form.email"
                name="email"
                placeholder="E-mail"
                label="Контактный e-mail"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.email}"
            />
            <Field
                type="tel"
                v-model="form.workPhoneNumber"
                name="workPhoneNumber"
                placeholder="Введите номер рабочего телефона ответственного"
                label="Номер рабочего телефона"
                customClass="field_size_half"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.workPhoneNumber}"
            />
            <Field
                type="tel"
                v-model="form.personalPhoneNumber"
                name="personalPhoneNumber"
                placeholder="Введите номер телефона ответственного"
                label="Номер телефона"
                customClass="field_size_half"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.personalPhoneNumber}"
            />
                <button :disabled="!isOnButtonSave" type="submit" :class="['button', {'button_disabled': !isOnButtonSave}]">Сохранить</button>
                <button class="button_inline" @click.prevent="cancel">Отмена</button>
			
		</form>
     </WrapperForm>    
</template>    

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import Field from '@/components/fields/Field/Field';
import WrapperForm from '@/components/wrappers/WrapperForm';
export default {
    components: {
        Field,
        WrapperForm
    },
    data() {
        return {
           isOnButtonSave: false,
            validator: {
                email: true,
                workPhoneNumber: true,
                personalPhoneNumber: true,
                position: true,
            },
            form: {
                fio: this.$attrs.value.fio,
                position: this.$attrs.value.position,
                email: this.$attrs.value.email,
                workPhoneNumber: this.$attrs.value.workPhoneNumber,
                personalPhoneNumber: this.$attrs.value.personalPhoneNumber,
            }
        }
    },
        validations: {
            form: {
                email: {
                    required,
                    email,
                },
                workPhoneNumber: {
                    required,
                },
                personalPhoneNumber: {
                    required,
                },
                position: {
                    minLength: minLength(4),
                },

            }
        },

    methods: {
        onSubmit() {
            this.$v.form.$touch();

            const testTel = /^[+]*[0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.0-9]*$/.test(this.form.personalPhoneNumber);
             const testWorkTel = /^[+]*[0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.0-9]*$/.test(this.form.workPhoneNumber);

            this.validator.email = this.$v.form.email.$invalid ? false : true;
            this.validator.workPhoneNumber = !this.$v.form.workPhoneNumber.$invalid && testWorkTel ? true : false;
            this.validator.personalPhoneNumber = !this.$v.form.personalPhoneNumber.$invalid && testTel ? true : false;
            this.validator.position = this.$v.form.position.$invalid ? false : true;
            
            let check = Object.values(this.validator);

            if (check.every(el => el === true)) {
                this.$emit('input', this.form);
            }
        },
        onChange() {
            this.isOnButtonSave = true;
        },
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>

<style lang="sass">
</style>