<template>
  <div
      :class="cardClass"
      v-if="rowOfValues.data['s'+this.item.dataFieldName+''] !== 0 || item.dataFieldName === 'generalValue' "
      :style="item.dataFieldName === 'generalValue' ? 'margin-left: 20px;':''"
  >
    <div class="b-indicator__header" v-if="item.dataFieldName !== 'generalValue'">
      <div class="b-indicator__title "
           :class="[1,2].includes(rowOfValues.data['s'+this.item.dataFieldName+'']) || item.dataFieldName ==='ki' ? '' : 'half-opacity grey-color__important'"
      >{{ rowOfValues.data[item.dataFieldName + 'N'] }}
      </div>
      <div class="b-indicator__about"
           :class="[1,2].includes(rowOfValues.data['s'+this.item.dataFieldName+'']) || item.dataFieldName ==='ki' ? '' : 'half-opacity'">
        {{ rowOfValues.data[item.dataFieldName + 'Name'] }}
      </div>
    </div>
    <div class="b-indicator__header"
         v-else-if="item.dataFieldName === 'generalValue'"
         style="flex-basis: 250px;"
    >
      <div class="b-indicator__title">{{ item.title }}</div>
      <div class="b-indicator__about ">{{ item.about }}</div>
    </div>
    <div class="b-indicator__header darker-orange-color" v-if="!item.centerValuesHide">
      <div
          class="font-size-big"
          v-if="[1,2].includes(rowOfValues.data['s'+this.item.dataFieldName+'']) || item.dataFieldName ==='ki'"
          :class="item.dataFieldName ==='ki' ? 'black-color' : ''"
      >
        {{   item.dataFieldName !=='ki' ?  rowOfValues.data[item.dataFieldName] != null ? rowOfValues.data[item.dataFieldName].toFixed(3) : '' : item.count.toFixed(3) }}
      </div>
      <div
          class="font-size-small"
          v-if="[1,2].includes(rowOfValues.data['s'+this.item.dataFieldName+'']) && rowOfValues.data[item.dataFieldName] != null">
        из
      </div>
      <div
          class="font-size-small"
          v-if="[1,2].includes(rowOfValues.data['s'+this.item.dataFieldName+''])">
        {{ rowOfValues.data[item.dataFieldName + 'W'] != null ? rowOfValues.data[item.dataFieldName + 'W'].toFixed(0) : '' }}
      </div>
    </div>
    <!-- Для основных карточек -->
    <div class="b-indicator__score" v-if="item.dataFieldName !== 'generalValue'  ">
      <div class="b-indicator__min font-weight-regular justify-end ml-0"
           :class="disabledFlag ? 'b-indicator__max_disable' : ''"
           :style="item.dataFieldName !== 'generalValue' ? 'font-weight: 500;':''"
           v-if="item.dataFieldName !=='ki'"
      >{{ rowOfValues.data[item.dataFieldName + 'Min'] }}
      </div>
      <div style=" display: flex; flex-direction: column; justify-content: center"
           v-if="item.dataFieldName !=='ki'">
        <div
            class="wrapper-indicator-arrow" v-if="item.dataFieldName !== 'generalValue' && !disabledFlag">
          <div
              v-if="item.dataFieldName !=='ki'"
              style="display: flex;flex-direction: column; "
              :style="`padding-left: ` + positionTriangle(rowOfValues.data[item.dataFieldName+'Rf'] ,rowOfValues.data[item.dataFieldName+'Max'],rowOfValues.data[item.dataFieldName+'Min'])+'%;'"
          >
            <v-tooltip
                top
                color="primary"
                nudge-top="0"
            >
              <template v-slot:activator="{on,attrs}">
                <div
                    v-bind="attrs"
                    v-on="on"
                    class="arrow-down"
                />
              </template>
              <span> {{ rowOfValues.data[item.dataFieldName + 'Rf'] }} - среднее по РФ</span>
            </v-tooltip>
          </div>
        </div>
        <div
            v-if="item.dataFieldName !=='ki'"
            class="b-indicator__count" :class="disabledFlag ? 'b-indicator__count_disable' : ''"
             :style="calcBackground(item.count, rowOfValues.data[item.dataFieldName+'Max'],rowOfValues.data[item.dataFieldName+'Min'])">

        <span
            :style=" !disabledFlag   ? (rowOfValues.data['s' + item.dataFieldName + ''] === 2) ? 'text-align: center;width: 100%;' : '': 'font-size: 14px; text-align: center;width: 100%;'">
          {{ item.count }}
        </span>
        </div>
        <div
            class="wrapper-indicator-arrow"
             v-if="item.dataFieldName !== 'generalValue' && !disabledFlag && item.dataFieldName !=='ki'">
          <div
              style="display: flex;flex-direction: column; "
              :style="`padding-left: ` + positionTriangle(rowOfValues.data[item.dataFieldName+'Reg'] ,rowOfValues.data[item.dataFieldName+'Max'],rowOfValues.data[item.dataFieldName+'Min'])+'%;'"
          >
            <v-tooltip
                bottom
                color="primary"
                nudge-bottom="0"
            >
              <template v-slot:activator="{on,attrs}">
                <div
                    v-bind="attrs"
                    v-on="on"
                    class="arrow-up"
                />
                <!--                <div-->
                <!--                    class="indicator-arrow__count">-->
                <!--                  {{ rowOfValues.data[item.dataFieldName+'Reg'] }}-->
                <!--                </div>-->
              </template>
              <span> {{ rowOfValues.data[item.dataFieldName + 'Reg'] }} - среднее по региону</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div    v-if="item.dataFieldName !=='ki'"
          class="b-indicator__max font-weight-regular"
           :class="disabledFlag ? 'b-indicator__max_disable' : ''"
           :style="item.dataFieldName !== 'generalValue' ? 'font-weight: 500;':''"
      >{{ rowOfValues.data[item.dataFieldName + 'Max'] }}
      </div>
    </div>
    <!-- Для Главной карточек -->
    <div class="b-indicator__score-general " v-else-if="item.dataFieldName === 'generalValue'">
      <div class="b-indicator__max ml-0"
           :class="isNaN(item.count) ? 'b-indicator__max_disable' : ''"
           :style="item.dataFieldName !== 'generalValue' ? 'margin-top: 20px;':''"
      >
      </div>
      <div style=" display: flex; flex-direction: column; justify-content: center; position: relative">

        <div class="b-indicator__count-general"
             :class="isNaN(+item.count) ? 'b-indicator__count_disable' : ''"
             style="width: 820px;"
        >
          <div
              class="pl-3"
              style="z-index: 2; background-color: #eb722e; border: 0px solid #eb722e; border-radius: 4px 0 0 4px"
              :style=" 'width: ' + generalMainBlock(item.count, item.max) *820 + 'px'">
              <span
                  class="font-size-extra-big"
                  :style=" !isNaN(item.count) ? '' : 'font-size: 14px;'">
          {{ !isNaN(item.count) ? item.count.toFixed(3) : 'Показатель не рассчитывается для этой УГСН(НПС)' }}
        </span>
          </div>
          <div
              class="wrapper-indicator-arrow ml-0"
              style="z-index: 1; position: absolute; margin-bottom: 50px; width: 100%;"
              :style="`padding-left: ` + positionTriangleForGeneralValue(rowOfValues.data.olim,item.max)+'%;'">
            <div
                class="indicator-arrow__count text-center">
              <div class="darker-orange-color font-size-small font-weight-regular">конкурсный диапазон</div>
              <div class="font-size-medium black-color"> {{ rowOfValues.data.olim != null ? rowOfValues.data.olim.toFixed(1) : '-' }} -
                {{ rowOfValues.data.olim != null ? item.max.toFixed(1) : '-' }}
              </div>
            </div>
            <div
                style="display: flex;flex-direction: row; "
                class="contest-range"
            >
            </div>
          </div>
        </div>
        <div class="wrapper-indicator-arrow">
          <card-triangle-with-tooltip
              :item="{...item, count: rowOfValues.data.obest1!= null ?  rowOfValues.data.obest1.toFixed(3) : '-'}"

              description-tooltip="региональный лидерпо УГСН (НПС)"/>
          <card-triangle-with-tooltip class="pl-2"
                                      :item="{...item, count: rowOfValues.data.obest1!= null ?  rowOfValues.data.obest0.toFixed(3) : '-'}"
                                      :another-style="true"
                                      description-tooltip="лидерпо УГСН (НПС)"/>
        </div>
      </div>
      <div class="b-indicator__max"
           :class="isNaN(item.count) ? 'b-indicator__max_disable' : ''"
           :style="item.dataFieldName !== 'generalValue' ? 'margin-top: 20px;':''"
      >{{ item.max }}<br>max
      </div>
    </div>

  </div>
</template>

<script>
import CardTriangleWithTooltip from "@/components/cards/custom/indicatiors/cardTriangleWithTooltip";

export default {
  name: "cardIndicatorForFinalCompare",
  components: {CardTriangleWithTooltip},
  props: {
    showProp: {
      type: Boolean
    },
    item: {
      type: Object,
      default: () => {
        return {title: '', about: '', count: '', max: ''}
      }
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    available: {
      type: Boolean,
      default: false
    },
    typeStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    show: {
      get() {
        return this.$attrs.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    disabledFlag(){
      return this.rowOfValues.data['s' + this.item.dataFieldName + ''] === 3
    },
    cardClass() {
      let cssClass = 'b-indicator'
      if (this.typeStyle !== 'general' && this.rowOfValues.data['s' + this.item.dataFieldName + ''] === 3)
        return "b-indicator b-indicator-unavailable"
      else {
        switch (this.typeStyle) {
          case 'general':
            cssClass = "b-indicator b-indicator-general"
            break;
          case 'unavailable':
            cssClass = "b-indicator b-indicator-unavailable"
            break;
          default:
            cssClass = "b-indicator"
            break;
        }
        return cssClass
      }
    }
  },
  methods: {
    positionTriangle(value, maxValue, minValue) {
      let a = Number.parseFloat(value)
      let b = Number.parseFloat(maxValue)
      let c = Number.parseFloat(minValue)
      return (a - c) * 100 / (b - c)
    },
    positionTriangleForGeneralValue(value, maxValue) {
      let a = Number.parseFloat(value)
      let b = Number.parseFloat(maxValue)
      return a * 100 / b
    },
    generalMainBlock(value, maxValue) {
      let a = Number.parseFloat(value)
      let b = Number.parseFloat(maxValue)
      return a / b
    },
    calcBackground(value, maxValue, minValue) {
      let a = Number.parseFloat(value)
      if (this.rowOfValues.data['s' + this.item.dataFieldName + ''] === 2)
        a = 0
      let b = Number.parseFloat(maxValue)
      let c = Number.parseFloat(minValue)
      let per = (a - c) * 100 / (b - c)
      return isNaN(per) ? `  background: #f3f3f4; border-color: #CDD1D4;` :
          `background: -webkit-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:    -moz-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:     -ms-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:      -o-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:         linear-gradient(to right, #eb722e ${per}%,#f9d3be ${per}%);`
    }
  },
}
</script>

<style lang="scss" scoped>
$color: #eb722e;
$transparent: rgba(0, 0, 0, 0);
.half-opacity {
  opacity: 0.5;
}

.contest-range {
  background: linear-gradient(-45deg,
      $transparent 49.9%,
      $color 49.9%, $color 60%,
      $transparent 60%
  ),
  linear-gradient(-45deg,
          $color 10%,
          $transparent 10%
  );
  background-size: 0.3em 0.3em;
  background-color: #f9d3be;
  border-left: 1px solid $color;
  border-right: 1px solid $color;
  border-top: 1px solid $color;
  border-bottom: 1px solid $color;
  border-radius: 5px;
  height: 44px;
}

.wrapper-indicator-arrow {
  width: calc(100% - 2px);
  margin-left: -6px;
  position: relative;

  .indicator-arrow__count {
    font-size: 10px;
    color: #00599B;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #eb722e;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #eb722e;
}

.b-indicator__wrapper {
  border-bottom: 1px solid #CAD2D8;
  padding: 18px 0px;

  &:last-child {
    border: 0;
  }
}

.b-indicator__wrapper:first-of-type, .b-indicator__wrapper:last-of-type {
  padding-top: 0px;
}

.b-indicator {
  display: flex;
  //justify-content: space-between;
  flex-grow: 1;
  align-items: center;
}

.b-indicator__title {
  font-size: 17px;
  color: #00599B;
  font-weight: 600;
  line-height: 31px;
}

.b-indicator__about {
  color: #455058;
  font-size: 12px;
}

.b-indicator__header {
  justify-content: space-between;
  margin-right: auto;
  max-width: 500px;
  flex-basis: 150px;
  display: flex;
  flex-direction: row;

  &:nth-child(1) {
    flex-direction: column;
    flex-basis: 250px;
  }
  &:nth-child(2) {
    align-items: flex-end;
    flex-basis: 100px;
    //justify-content: start;
  }

}

.b-indicator__score {
  justify-content: flex-start;
  margin-right: auto;
  max-width: 634px;
  flex-basis: 634px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  &-general {
    justify-content: flex-end;
    margin-right: auto;
    max-width: 850px;
    flex-basis: 850px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
  }
}

.b-indicator__count {
  display: flex;
  width: 550px;
  align-items: center;
  padding-left: 14px;
  border: 1px solid #EB722E;
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  max-height: 35px;

  &-general {
    display: flex;
    width: 550px;
    align-items: center;
    flex-direction: row;
    position: relative;
    //padding-left: 14px;
    border-top: 1px solid #EB722E;
    border-bottom: 1px solid #EB722E;
    border-radius: 5px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    max-height: 37px;
    background-color: #f9d3be;
  }
}

.b-indicator__max {
  display: flex;
  max-width: 45px;
  color: #EB722E;
  flex-basis: 45px;
  font-weight: 700;
  font-size: 15px;
  padding-top: 5px;
  align-items: center;
  margin-left: 6px;
  line-height: 16px;
  justify-content: flex-start;
}
.b-indicator__min {
  display: flex;
  max-width: 45px;
  color: #EB722E;
  flex-basis: 45px;
  font-weight: 700;
  font-size: 15px;
  padding-top: 5px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 6px;
  line-height: 16px;
}

.b-indicator__max span {
  font-size: 12px;
  font-weight: 400;
}

.b-indicator__max_disable {
  color: #89A0B0;
  opacity: 0;
}

.b-indicator__count_disable {
  background: #f3f3f4;
  font-family: 'Roboto';
  font-size: 17px;
  line-height: 25px;
  border-color: #e2e4e6;
  font-weight: 400;
  color: #89A0B0;
}
</style>