<template>
	<div :class="['field', customClass]">
	<label class="label" for="name">{{label}}</label>
	<input
		v-model="$attrs.value"
		@change="onValueChanged"
		:type="type"
		:class="['input', 'input_' + type]"
		:placeholder="placeholder"
		:name="name"
		v-on="$attrs"
	>
	</div>
</template>

<script>
	// Важно указывать type, т.к. на клавиатуре смартфона меняет клавиатуру(буквы/цифры)
	export default {
		props: {
			type: {
				type: String,
				default: 'text',
			},
			name: {
				type: String,
				default: '',
			},
			label: {
				type: String,
				default: '',
			},
			placeholder: {
				type: String,
				default: '',
			},
			customClass: {
				type: String,
				default: '',
			},
		},
	methods: {
		onValueChanged() {
			this.$emit('input', this.$attrs.value);
		}
	}
}
	
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.input
    color: $greyColor
    font-size: $size-1
    border-bottom: 1px solid $color-g
    height: 45px
    width: 99%
    transition: $speed-0
.input:focus
    border-bottom: 1px solid $color-2
    transition: $speed-0

.input:invalid
    color: red
.input:valid
    color: $color-g
</style>