<template>
  <div>

    <div class="score-about">
      <p><b>п.1.4.3. Приложения 2 Порядка:</b></p>
      <p>Процент иностранных граждан в приведенном контингенте обучающихся</p>
    </div>
    <v-divider class="mt-4"/>
    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about">
      <p class="calculation__about-text">Форма 5.1.</p>
      <p class="calculation__about-text">
        Общая численность обучающихся по данным 2020 года, человек (за счет всех источников финансирования)
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b43-thead">
      <tr class="">
        <th class="" rowspan="3">№ п/г</th>
        <th class="" rowspan="2">Уровень</th>
        <th class="" rowspan="2">Форма обучения</th>
        <th class="" colspan="4">Обучающихся, всего

        </th>
        <th>из них - иностранцев</th>
        <th rowspan="3"></th>
        <th class="" colspan="5" style="vertical-align: middle; text-align: center">Расчет исходных значений</th>
      </tr>
      <tr class="">
<!--        <th class="">2017 год</th>-->
<!--        <th class="">2018 год</th>-->
<!--        <th class="">2019 год</th>-->
        <th class="" colspan="4">2020 год</th>
        <th class="">2020 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="" colspan="4">3</th>
        <th class="">4</th>
<!--        <th class="">5</th>-->
<!--        <th class="">6</th>-->
<!--        <th class="">7</th>-->
        <th class="">переменная</th>
        <th class="">значение</th>
        <th></th>
        <th class="">переменная</th>
        <th class="">значение</th>
      </tr>
      </thead>
      <tbody class="b43-tbody">
      <tr>
        <td class="">1.</td>
        <td class="">Бакалавриат</td>
        <td class="">очная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nbo2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nbo2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nbo2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nbo2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ibo2020)"></td>
        <td rowspan="3" style="vertical-align: middle">
          <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
               alt=""/>
          <!--                  =>-->
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NO</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.no2020)"></td>
        <td rowspan="3"></td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">IO</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.io2020)"></td>
      </tr>
      <tr>
        <td class="">2.</td>
        <td class="">Специалитет</td>
        <td class="">очная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nso2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nso2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nso2019)"></td>-->
        <td class=""  colspan="4" v-html="checkData(rowOfValues.data.nso2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.iso2020)"></td>
      </tr>
      <tr>
        <td class="">3.</td>
        <td class="">Магистратура</td>
        <td class="">очная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nmo2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nmo2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nmo2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nmo2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.imo2020)"></td>
      </tr>
      <tr>
        <td class="">4.</td>
        <td class="">Бакалавриат</td>
        <td class="">очно-заочная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nbv2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nbv2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nbv2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nbv2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ibv2020)"></td>
        <td rowspan="3" style="vertical-align: middle">
          <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
               alt=""/>
          <!--                  =>-->
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NV</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nv2020)"></td>
        <td rowspan="3"></td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">IV</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.iv2020)"></td>
      </tr>
      <tr>
        <td class="">5.</td>
        <td class="">Специалитет</td>
        <td class="">очно-заочная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nsv2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nsv2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nsv2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nsv2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.isv2020)"></td>
      </tr>
      <tr>
        <td class="">6.</td>
        <td class="">Магистратура</td>
        <td class="">очно-заочная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nmv2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nmv2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nmv2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nmv2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.imv2020)"></td>
      </tr>
      <tr>
        <td class="">7.</td>
        <td class="">Бакалавриат</td>
        <td class="">заочная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nbz2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nbz2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nbz2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nbz2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ibz2020)"></td>
        <td rowspan="3" style="vertical-align: middle">
          <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
               alt=""/>
          <!--                  =>-->
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NZ</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nz2020)"></td>
        <td rowspan="3"></td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">IZ</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.iz2020)"></td>
      </tr>
      <tr>
        <td class="">8.</td>
        <td class="">Специалитет</td>

        <td class="">заочная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nsz2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nsz2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nsz2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nsz2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.isz2020)"></td>

      </tr>
      <tr>
        <td class="">9.</td>
        <td class="">Магистратура</td>
        <td class="">заочная</td>
<!--        <td class="" v-html="checkData(rowOfValues.data.nmz2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nmz2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nmz2019)"></td>-->
        <td class="" colspan="4" v-html="checkData(rowOfValues.data.nmz2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.imz2020)"></td>
      </tr>
<!--      <tr>-->
<!--        <td class="">4.</td>-->
<!--        <td class="">Ординатура</td>-->
<!--        <td class="">очная</td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.noo2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.noo2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.noo2019)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.noo2020)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.ioo2020)"></td>-->
<!--        <td rowspan="2"></td>-->
<!--        <td colspan="5" rowspan="2"></td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td class="">5.</td>-->
<!--        <td class="">Аспирантура</td>-->
<!--        <td class="">очная</td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nko2017)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nko2018)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nko2019)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.nko2020)"></td>-->
<!--        <td class="" v-html="checkData(rowOfValues.data.iko2020)"></td>-->
<!--      </tr>-->
      </tbody>
    </table>
<!--    <p class="score-about">-->
<!--      В таблице отражены агрегированные данные по корреспондирующим УГСН-->
<!--      (НПС). Исходно, в Форме 5.3, эти данные представлены раздельно по каждой-->
<!--      НПС.-->
<!--    </p>-->

    <section class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>Б43<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
          <span class="font-weight-regular">1.0 × </span> IO <span class="font-weight-regular"> + 0.25 × </span> IV
            <span class="font-weight-regular"> + 0.1 × </span> IZ
          </div>
          <div class="formula-half" >
            <span class="font-weight-regular">1.0 × </span> NO <span class="font-weight-regular"> + 0.25 × </span> NV
            <span class="font-weight-regular"> + 0.1 × </span> NZ
          </div>
        </div>
        <div class="formula-full font-weight-regular"><div>× 100 =</div></div>

        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span class="font-weight-regular">1.0 × </span>  <span v-html="checkData(rowOfValues.data.io2020)"></span>
            <span class="font-weight-regular"> + 0.25 × </span> <span v-html="checkData(rowOfValues.data.iv2020)"></span>
            <span class="font-weight-regular"> + 0.1 × </span> <span v-html="checkData(rowOfValues.data.iz2020)"></span>
          </div>
          <div class="formula-half" >
            <span class="font-weight-regular">1.0 × </span> <span v-html="checkData(rowOfValues.data.no2020)"></span>
            <span class="font-weight-regular"> + 0.25 × </span> <span v-html="checkData(rowOfValues.data.nv2020)"></span>
            <span class="font-weight-regular"> + 0.1 × </span> <span v-html="checkData(rowOfValues.data.nz2020)"></span>
          </div>
        </div>
        <div class="formula-full font-weight-regular"><div>× 100 = <span v-html="checkData(rowOfValues.data.b43b0)"></span></div></div>

      </div>

      <div class="calculation__about">
        <p class="calculation__about-text">
          IO	-	студентов-иностранных граждан очной формы обучения 
        </p>
        <p class="calculation__about-text">
          IV	-	студентов-иностранных граждан очно-заочной формы обучения 
        </p>
        <p class="calculation__about-text">
          IZ	-	студентов-иностранных граждан заочной формы обучения 
        </p>
        <p class="calculation__about-text">
          NO	-	всего студентов очной формы обучения
        </p>
        <p class="calculation__about-text">
          NV	-	всего студентов очно-заочной формы обучения
        </p>
        <p class="calculation__about-text">
          NZ	-	всего студентов заочной формы обучения
        </p>
      </div>
    </section>

    <!-- calculation -->

    <!-- formula -->

      <!-- formula -->



  </div>
</template>

<script>
export default {
  name: "b43",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float', 3, false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  //position: relative
  //display: block
  //border-radius: 5px
  width: 100%

.b43-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px
      //&:nth-child(6)
      //  border: 0
      //  min-width: 20px

    //&:nth-child(8)
    //  border: 0
    //
    //&:nth-child(9)
    //  border: 0

  tr
    &:first-child
      th
        &:nth-child(n+6)
          border: 0
          min-width: 20px

    &:last-child
      th
        &:nth-child(7)
          border: 0
        &:nth-child(8)
          border: 0
          padding-left: 10px
          border-bottom: 1px solid #546e7a
        &:nth-child(9)
          border: 0
          text-align: right
          padding-right: 10px
          border-bottom: 1px solid #546e7a
        &:nth-child(5)
          border: 0
          padding-left: 10px
          border-bottom: 1px solid #546e7a
        &:nth-child(6)
          border: 0
          text-align: right
          padding-right: 10px
          border-bottom: 1px solid #546e7a
.b43-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px

    &:nth-child(9)
      border: 0
      min-width: 20px
    &:nth-child(8)
      padding-right: 5px
      text-align: right
    &:nth-child(10)
      border: 0
      padding-left: 2px
      text-align: right
    &:nth-child(11)
      border: 0
      padding-left: 2px
      text-align: left
    &:nth-child(6)
      border: 0
    &:nth-child(7)
      border: 0
      padding-left: 2px
      text-align: right
    &:nth-child(8)
      border: 0
      padding-left: 2px
      text-align: left

.grid-container__header
  display: grid
  //grid-template-rows: 1fr 1fr
  div
    border: 1px solid black
    text-align: center
  //grid-auto-rows: 50px
  grid-gap: 3px
  grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

  .grid-row-span-2
    grid-column: 1
    grid-row: 1/3

  &_inner
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 2px
</style>