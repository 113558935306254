<template>
    <div style="width: 100%;">
        <header-main title="Выгрузки"></header-main>
        <section class="section-preview section-preview_lk mb-5">
            <h1>Специальные выгрузки</h1>
            <v-row class=" ">
              <v-col cols="3">
                <v-row class="card__info">
                  <v-col>
                    <button class="button_border" @click="getDocumentList(1)">БСМ Аккредитованные
                      <v-progress-circular indeterminate size="14" v-if="loadingListFiles[0]=== true"/>
                    </button>
                  </v-col>
                </v-row>
                <v-row class="card__info">
                  <v-col>
                    <button class="button_border" @click="getDocumentList(3)">БСМ Не аккредитованные
                      <v-progress-circular indeterminate size="14" v-if="loadingListFiles[2]"/>
                    </button>
                  </v-col>
                </v-row>
                <v-row class="card__info">
                  <v-col>
                    <button class="button_border" @click="getDocumentList(2)">АОА Аккредитованные
                      <v-progress-circular indeterminate size="14" v-if="loadingListFiles[1]"/>
                    </button>
                  </v-col>
                </v-row>
                <v-row class="card__info">
                  <v-col>
                    <button class="button_border" @click="getDocumentList(4)">АОА Не аккредитованные
                      <v-progress-circular indeterminate size="14" v-if="loadingListFiles[3]"/>
                    </button>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <card-d-ownload-zip-document-admin document-type="1" text="БСМ Аккредитованные"/>
                <card-d-ownload-zip-document-admin document-type="3" text="БСМ Не аккредитованные"/>
                <card-d-ownload-zip-document-admin document-type="2" text="АОА Аккредитованные"/>
                <card-d-ownload-zip-document-admin document-type="4" text="АОА Не аккредитованные"/>
                <card-d-ownload-zip-document-admin document-type="5" text="Загрузить ДОП КЦП"/>
              </v-col>

            </v-row>
        </section>
        <template v-if="fileListLoading">
            <in-progress/>
        </template>
        <template v-else-if="!fileListErrorLoading">
            <div v-for="file in fileList" :key="file.id" class="cms-card-news">
                <div>{{ file.reportDescription }}</div>
                <a @click="getDocument(file)" class="application__file_link">{{ file.fileName }}</a>
            </div>
        </template>
        <template v-else>
            Ошибка загрузки.
        </template>
    </div>
</template>

<script>

    import HeaderMain from "@/components/headers/HeaderMain";
    // eslint-disable-next-line no-unused-vars
    import saveFile from '@/utils/saveFile';
    import request from "@/services/request";
    import InProgress from "@/components/InProgress";
    import CardDOwnloadZipDocumentAdmin from "@/components/cards/custom/CardDocument/CardDOwnloadZipDocumentAdmin";

    export default {
        components: {
          CardDOwnloadZipDocumentAdmin,
            InProgress,
            HeaderMain
        },
        name: 'ViewExportsFilesAdmin',
        data() {
            return {
                fileList: [],
                fileListLoading: false,
                fileListErrorLoading: null,
                loadingListFiles:[false,false,false,false]
            }
        },
        created() {
            this.getListFiles()
        },
        methods: {
            async getListFiles() {
                this.fileListLoading = true;
                let res = await request({
                    endpoint: '/api/report/getReports/admin',
                    method: 'get'
                })
                // console.log("1", res);
                if (res.status === 200) {
                    // console.log("2", res.data)
                    this.fileList = res.data?.reports;
                    this.fileListLoading = false;
                    this.fileListErrorLoading = null;
                } else {
                    this.fileListLoading = false;
                    this.fileListErrorLoading = res.error;
                }
            },
            async getDocument(item) {
                console.log("item", item)
                let response = await request({
                    endpoint: '/api/fileServer/downloadReport',
                    method: 'post',
                    body: {
                        reportID: item.reportId,
                        fileName: item.fileName
                    }
                });
                console.log("res", response)
                if (response.status === 200)
                    saveFile(response.data);
            },
            async getDocumentList(item) {
              this.$set(this.loadingListFiles,item-1,true)
                // this.loadingListFiles[item-1] = true
                let response = await request({
                    endpoint: '/api/admin/doFinalRaspredPrint?documentType=' + item,
                    method: 'get'
                });
                if (response.status === 200)
                {
                  this.$set(this.loadingListFiles,item-1,false)
                  saveFile(response.data);
                }
            },
        },
    }

</script>


<style lang="sass" scoped>

    .cms-card-news
        background: #fff
        margin-bottom: 15px
        padding: 5px 10px
        display: flex
        justify-content: space-between
        align-items: center
        border-left: 3px solid #00599b
        transition: 0.3s

        &:hover
            transform: translateY(-5px)
            box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
            transition: 0.3s

        a
            &:hover
                color: #eb8e1c

        .application__file_link
            border: 1px solid #00599b
            border-radius: 5px
            padding: 5px 10px

            &:hover
                border: 1px solid #eb8e1c

        .spec
            display: flex
            width: 100%
            align-items: center

            &__info
                flex-basis: 0
                flex-grow: 1
                margin-right: 10px
                min-height: 50px
                display: flex
                align-items: center

            &__status, &__id, &__action
                flex-basis: 150px
                flex-grow: 0

        .spec-section
            background: #fff
            border-radius: 10px
            border: 1px solid #00599b
            margin-bottom: 20px

</style>