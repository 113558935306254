<template>
  <div>
    <div v-if="!componentName">
      <h5 class="score-subsubtitle">Расчет нормированной оценки по средним:</h5>
      <p class="calculation__about-text">
        При невозможности расчета значения показателя в качестве его
        нормированной оценки принимается минимальное из средних нормированных
        значений по субъекту РФ, федеральному округу или Российской Федерации в
        целом.
      </p>
      <div class="calculation">
        <div class="calculation__row">
          <div class="calculation__cell calculation__cell_no-grow"> {{ item.title }}<span
              class="font-size-little__bottom">{{ foName ? foName.name : '' }}</span></div>
          <div class="calculation__cell calculation__cell_no-grow">×</div>
          <div class="calculation__cell calculation__cell_no-grow">W</div>
          <div class="calculation__cell calculation__cell_no-grow">=</div>
          <div class="calculation__cell"><span class="font-weight-regular">Min</span> {  {{ item.title }}<span class="font-size-little__bottom">РФ{{
              foName ? foName.name : ''
            }}</span>
             {{ item.title }}<span class="font-size-little__bottom">ФО{{ foName ? foName.name : '' }}</span>
             {{ item.title }}<span class="font-size-little__bottom">Р{{ foName ? foName.name : '' }}</span> }
          </div>
          <div class="calculation__cell calculation__cell_no-grow">=</div>
          <div class="calculation__cell ">
            <span class="font-weight-regular">Min</span> {
            <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'rf' + rowOfValues.fo])"/>,
            <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'fo' + rowOfValues.fo])"/>,
            <span v-html="checkData(rowOfValues.data[item.dataFieldName + 're' + rowOfValues.fo])"/>
            }
          </div>
          <div class="calculation__cell calculation__cell_no-grow"> = </div>
          <div class="calculation__cell "><span v-html="checkData(rowOfValues.data[item.dataFieldName + 'n' + rowOfValues.fo])"/>{{  }}</div>
        </div>
      </div>
      <p class="calculation__about-text">
        Для УГСН (НПС), контрольные цифры приема по которым распределяются без
        учета места нахождения образовательных организаций, нормированная оценка
        принимается равной среднему значению по Российской Федерации в целом (в этом случае для средних значений по региону
        и округу в формуле указаны значения "н/д").
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardS2",
  props: {
    componentName: {
      type: String,
      default: ''
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      foData: [
        {id: 1, name: 'оч'},
        {id: 2, name: 'вч'},
        {id: 3, name: 'зч'},
      ]
    }
  },
  computed: {
    foName() {
      return this.foData.find(el => el.id === this.rowOfValues.fo);
    }
  },
  methods: {
    checkData(value) {
      return value//this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
    }
  },


}
</script>

<style scoped>

</style>