import { render, staticRenderFns } from "./CardDocument.vue?vue&type=template&id=dcccf9ea&"
import script from "./CardDocument.vue?vue&type=script&lang=js&"
export * from "./CardDocument.vue?vue&type=script&lang=js&"
import style0 from "./CardDocument.vue?vue&type=style&index=0&id=dcccf9ea&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports