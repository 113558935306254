<template>
<div>

    
    <Alert alertType="warning" v-if="applicationActive">
        <template v-slot:icon>
		<v-icon :size="30" color="#856404">mdi-alert</v-icon>
	</template>
	<template v-slot:default>
		Есть активная заявка на изменение данных
        <button class="button_mini" @click="modalOpen = !modalOpen">подробно о заявке</button>
        <button class="button_mini" @click="deleteApplication">отменить или создать новую</button>
	</template>
    </Alert>



    <Modal v-on:input="modalOpen = !modalOpen" v-if="modalOpen" title="Подробно о заявке">
        <div class="info-card" v-if="organization && !editMode">
            <div class="info-card__field" v-for="(value, name, index) in applicationToShow" :key="index">
                <div class="info-card__name">
                   {{name}}:
                </div>
                <div class="info-card__cell" :class="{'info-card__cell_changed': value.changed}">
                    {{value.value}}
                </div>
            </div>
            <div class="info-card__file" @click="getFile">Основание для изменения</div>
    </div>
    </Modal>

    <!-- <Перенести в компонент> -->
    <div class="info-card" v-if="organization && !editMode">
            <div class="info-card__field" v-for="(value, name, index) in infoOrganization" :key="index">
                <div class="info-card__name">
                   {{name}}:
                </div>
                <div class="info-card__cell">
                    {{value}}
                </div>
            </div>
    </div>
    <!-- </Перенести в компонент> -->

    <button v-if="authData.role.id === 1 && !applicationActive && organization && !editMode && !get_blockedAll" class="button info-card__button" @click="editMode = !editMode">Редактировать</button>
    <button v-if="authData.role.id !== 1 && !applicationActive && organization && !editMode && !get_blockedAll" class="button info-card__button">Редактировать</button>
    
    <FormKcpContestInfoOrganization  v-model="organization" v-if="editMode" v-on:input="requestUpdateOrg" @cancel="onCancel" />
</div>    
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormKcpContestInfoOrganization from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoOrganization';
import Alert from '@/components/alerts/Alert';
import Modal from '@/components/modals/Modal';
import request from "@/services/request";
import saveFile from '@/utils/saveFile';

export default {
    components: {
        FormKcpContestInfoOrganization,
        Alert,
        Modal
    },
    data() {
        return {
            organization: null,
            organizationInitial: null,
            editMode: false,
            application: null,
            applicationActive: false,
            modalOpen: false,
        }
    },
    computed: {
        ...mapGetters('auth', {authData: 'authData'}),
        ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
        ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
        infoOrganization() {
            const {inn, kpp, orgAddress, orgFullName, orgName, orgShortName, orgFounderName, sprOrgType} = this.organizationInitial;
            return {
                'Полное название': orgFullName,
                'Название': orgName,
                'Сокращенное название': orgShortName,
                'Тип организации': sprOrgType.orgTypeName,
                'Наименование учредителя': orgFounderName,
                'Адрес': orgAddress,
                'ИНН': inn,
                'КПП': kpp,    
            }
        },
        applicationToChange() {
            return {
                applicationID:"cf1360c7-4339-4965-821e-0643edc2af84",
                    helpfulNote: this.organization.helpfulNote,
                    inn: this.organization.inn,
                    kcpUser: this.authData.userID,
                    kpp: this.organization.kpp,
                    orgAddress: this.organization.orgAddress,
                    sprOrgType: this.organization.sprOrgType,
                    orgFounderName: this.organization.orgFounderName,
                    orgFullName: this.organization.orgFullName,
                    orgID: this.organization.orgID,
                    orgName: this.organization.orgName,
                    orgOpCode: this.organization.orgOpCode,
                    orgShortName: this.organization.orgShortName,
            }
        },
        applicationToShow() {
            return {
                'ID заявки': {value: "cf1360c7-4339-4965-821e-0643edc2af84", changed: false},
                'Полное название': {value: this.application.orgFullName, changed: this.application.orgFullName !== this.organization.orgFullName},
                'Название': {value: this.application.orgName, changed: this.application.orgName !== this.organization.orgName},
                'Сокращенное название': {value: this.application.orgShortName, changed: this.application.orgShortName !== this.organization.orgShortName},
                'Учредитель': {value: this.application.orgFounderName, changed: this.application.orgFounderName !== this.organization.orgFounderName},
                'Адрес': {value: this.application.orgAddress, changed: this.application.orgAddress !== this.organization.orgAddress},
                'ИНН': {value: this.application.inn, changed: this.application.inn !== this.organization.inn},
                'КПП': {value: this.application.kpp, changed: this.application.kpp !== this.organization.kpp},    
            }
        },
    },
    mounted() {
        this.getInfoOrganization();
        this.getAllApplicationsByUser();
    },
    methods: {
        ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
        // Получаем данные с сервера
        async getInfoOrganization() {
            let organization = await request({
            endpoint: `/api/kcpCollectionReturn/getOrgByID?sprOrgID=${this.get_replace_id_admin}&userProfile=${this.authData.userProfile}`,
            method: 'GET',
            });
            organization = organization.data;
            for (let key in organization) {
                if (organization[key] === null) {
                    organization[key] = '';
                }
            }
            this.organization = {...organization};
            this.organizationInitial = {...organization};
        },
        // Сработает при отмене редактирования
        onCancel() {
            this.organization = {...this.organizationInitial};
            this.editMode = false;
       
        },

        async requestUpdateOrg() {

            let organization = await request({
            endpoint: `/api/userProfile/checkApplicationByOrgID?orgID=${this.authData.sprOrgID}`,
            method: 'GET',
            });
            if (!organization.data) {
                let objToSend = {
                        helpfulNote: '',
                        orgFullCode: this.organization.orgFullCode,
                        orgOpCode: this.organizationInitial.orgOpCode,
                        orgFullName: this.organization.orgFullName,
                        orgName: this.organization.orgName,
                        orgShortName: this.organization.orgShortName,
                        orgAddress: this.organization.orgAddress,
                        orgFounderName: this.organization.orgFounderName,
                        inn: this.organization.inn,
                        kpp: this.organization.kpp,
                        orgID: this.organizationInitial.orgID,
                        applicationTypeMethod: 'UPDATE',
                        toChangeCase: 'MAIN_ORG',
                        userID: this.authData.userID,
                };
            let fd = new FormData();
            fd.append('applicationData', JSON.stringify(objToSend));
            fd.append('file', this.organization.file);
            await request({
            endpoint: '/api/userProfile/createApplicationRequest',
            method: 'POST',
            body: fd,
            });
            this.getInfoOrganization();
            this.getAllApplicationsByUser();
            this.editMode = false;
            }


            
        },

        async getAllApplicationsByUser() {
            let requestapp = await request({
            endpoint: `/api/userProfile/getApplicationsByUserID?userID=${this.authData.userID}`,
            method: 'GET',

            });
            let applicationActive = requestapp.data.find(el => el.applicationStatus === 'ACTIVE' && el.toChangeCase === 'MAIN_ORG');
            if (applicationActive) {
                this.applicationActive = true;
                this.application = applicationActive;
            }
             
        },

        async deleteApplication() {
            await request({
            endpoint: `/api/userProfile/deleteApplication?applicationID=${this.application.applicationID}`,
            method: 'GET',

            });
            this.getInfoOrganization();
            this.getAllApplicationsByUser();
            this.applicationActive = false;
        },

        async getFile() {
            let response = await request({
            endpoint: '/api/fileServer/downloadFileByID',
            method: 'POST',
            body: {
                fileServerID: this.application.applicationsFiles.fileServerID,
                fileName: this.application.applicationsFiles.fileName,
                fileFormat: this.application.applicationsFiles.fileFormat,
                }
        });
        saveFile(response.data);
    },


        

	},
}
</script>

<style lang="sass">
.info-card__button
    margin-top: 10px
    margin-left: 10px
.info-card__button:hover
    background: #0292e1
.info-card__cell_changed
    background: #fff3cd
    border-radius: 3px
    color: #856404
    padding: 0px 3px
.info-card__file
    color: #EB722E
    text-decoration: underline
    cursor: pointer           
</style>