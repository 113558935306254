<template>
  <div>
    <div v-if="get_replace_role_id_admin === 4">
      <div v-if="loadedFiles">
        <CardFileFounder v-for="item in neededFiles" :key="item.id" :item="item" @uploadFile="uploadFile($event)"
                         @replaceFile="replaceFile($event)"/>
      </div>
    </div>
    <div v-else>
      <div v-if="loadedFiles">
        <CardFileFounder v-for="item in neededFiles" :key="item.id" :item="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import request from "@/services/request";
import CardFileFounder from "@/components/cards/custom/CardFileFounder";

export default {
  components: {
    CardFileFounder,
  },
  props: {
    allFiles: {
      type: Boolean,
      default: false
    },
    stage:{
      type: Number,
      default: 1,
      validator: (v)=>{
        return (typeof v === 'string') ? Number.parseInt(v) : v;
      }
    }
  },
  data() {
    return {
      files: [
        {name: 'Файл подтверждения введенных данных', fileType: `STAGE_${this.stage}_CO_FILE`, types: ['application/pdf']},
        {name: 'Расчетный файл ', fileType: `STAGE_${this.stage}_CO_FILE_CALC`, types: ['.xlsx'], dependence: 'allFiles'},
        {
          name: 'Пояснительная записка',
          fileType: `STAGE_${this.stage}_CO_FILE_DESCR`,
          types: ['application/pdf', '.doc', '.docx', 'application/msword'],
          dependence: 'allFiles'
        },
        {
          name: 'Письмо для минобрнауки',
          fileType: `STAGE_${this.stage}_CO_FILE_MIN_LETTER`,
          types: ['application/pdf', '.doc', '.docx', 'application/msword'],
          dependence: 'allFiles'
        },
      ],
      loadedFiles: false,
    }
  },

  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
    ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
    neededFiles() {
      return this.files.filter(el => {
        if (el.dependence) {
          if (this[el.dependence])
            return el;
        } else {
          return el;
        }
      })
    }
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    async getDocuments() {
      this.loadedFiles = false;
      const res = await request({
        endpoint: `/api/fileServer/getAllFounderMainFilesName?founderId=${this.get_replace_id_admin}`,
        method: 'GET',
      });
      res.data.forEach(el => {
        let overlapIndex = this.files.findIndex(file => file.fileType === el.fileType);
        if (overlapIndex !== -1) {
          this.files[overlapIndex] = Object.assign(this.files[overlapIndex], el);
        }
      });
      this.loadedFiles = true;
    },
    async uploadFile(event) {
      let fileInfo = {
        founderID: this.get_replace_id_admin,
        fileType: event.fileType,
        userID: this.authData.userID,
      };
      let fd = new FormData();
      fd.append('fileUploadDTO', JSON.stringify(fileInfo));
      fd.append('file', event.file);
      await request({
        endpoint: '/api/fileServer/uploadOrgMainFiles',
        method: 'POST',
        body: fd,
      });
      await this.getDocuments();
      },
    async replaceFile(event) {
      let fileInfo = {
        founderID: this.get_replace_id_admin,
        fileType: event.fileType,
        userID: this.authData.userID,
        id: event.id,
      };
      let fd = new FormData();
      fd.append('fileUploadDTO', JSON.stringify(fileInfo));
      fd.append('file', event.file);
      await request({
        endpoint: '/api/fileServer/uploadOrgMainFiles',
        method: 'POST',
        body: fd,
      });
      await this.getDocuments();
    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px

.file-edit
  display: flex
  align-items: center

.file-edit .v-file-input
  max-width: 300px
  margin-right: 10px
  min-width: 200px

.file-edit
  display: inline-flex
  align-items: center
  background: #FFEDE2
  padding: 0px 15px
  border-radius: 10px
  margin: 20px

.border_margin_right
  margin-right: 10px
</style>