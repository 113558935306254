<template>
  <div>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title">Все заявки на рассмотрении</h2>
    </header>
<!--    <in-progress/>-->
<!--     <DTable
        :data-headers="dataHeaders"
        :allow-dop-dialogs="false"
        :data-rows="dataRows"
        :custom-action-props="customActions"
        :error-rows="get_data_table_error"
        :flag-loading-rows="get_data_table_loading"
        :id-page.sync="idPageDefault"
        :num-page.sync="numPageDefault"
        :color="color"
        :request="null"
        :settings="null"
        :action-access="true"
        v-on:send-request="sendRequest($event)"
        v-on:action-row="actionRow($event)"
    /> -->

        <DTable
             v-if="get_replace_id_admin === 9"
            :data-headers="dataHeaders1"
            name-dialog="universalDialog"
            :data-dialog-headers="headersfordialog.massive"
            :errors-for-dialog="get_data_dialog_error"
            :flag-loading-for-dialog="get_data_dialog_loading"
            :allow-dop-dialogs="true"
            :data-for-dialog.sync="dataForDialog"
            :data-rows="dataRows"
            :custom-action-props="customActions"
            :error-rows="get_data_table_error"
            :flag-loading-rows="get_data_table_loading"
            :id-page.sync="idPageDefault"
            :num-page.sync="numPageDefault"
            :color="color"
            :request="null"
            :settings="null"
            :action-access="true"
            v-on:action-row="actionRow($event)"
             v-on:send-request="sendRequest($event)"
        />

         <DTable v-else
        :data-headers="dataHeaders"
        :allow-dop-dialogs="false"
        :data-rows="dataRows"
        :custom-action-props="customActions"
        :error-rows="get_data_table_error"
        :flag-loading-rows="get_data_table_loading"
        :id-page.sync="idPageDefault"
        :num-page.sync="numPageDefault"
        :color="color"
        :request="null"
        :settings="null"
        :action-access="true"
        
        v-on:action-row="actionRow($event)"
    />

  </div>
</template>
<script>
// import inProgress from '@/components/InProgress.vue'
import request from "@/services/request";
import DTable from '@/components/DTable_new/Dtable'
import {mapGetters, mapActions} from 'vuex'
export default {
  components:{
    // inProgress,
    DTable
  },
  data() {
    return {
      idPageDefault: 0,
      numPageDefault: 50,
      dataForDialog: null,
      get_data_dialog_error: null,
      get_data_dialog_loading:null,
       dataHeaders: [
        [{
          "text": "Согласовать (отображается текущее состояние)",
          "dataField": "action",
          "typeVar": "string",
          "cols": "1",
          "rows": "2",
          "tooltip": "Согласовать (отображается текущее состояние)",
          "active": true,
          "accessAdd": false,
          "accessEdit": false,
          "accessDelete": true,
          "deleteText": "Согласовать",
          "deleteIcon": "mdi-check-underline",
          "customAction": true,
          "order": 0,
          "width": "30px",
          "sortable": false
        }, {
          "text": "Наименование организации/филиала",
          "dataField": "sprOrg",
          "typeVar": "selector",
          "cols": "1",
          "rows": "2",
          "tooltip": "Наименование организации/филиала",
          "active": true,
          "order": 1,
          "width": null,
          "sortable": false
        },
          {
            "text": "УГСН (НПС)",
            "dataField": "sprKCPGroup",
            "typeVar": "selector",
            "cols": "1",
            "rows": "2",
            "tooltip": "УГСН (НПС)",
            "active": true,
            "order": 3,
            "width": null,
            "sortable": false
          }, {
          "text": "Лицензия на осуществление образовательной деятельности",
          "dataField": "",
          "typeVar": "",
          "cols": "7",
          "rows": "1",
          "tooltip": "Лицензия на осуществление образовательной деятельности",
          "active": false,
          "order": 4,
          "width": null,
          "sortable": false
        }, {
          "text": "Год первичного обязательства в получении государственной аккредитации",
          "dataField": "year",
          "typeVar": "string",
          "cols": "1",
          "rows": "2",
          "tooltip": "Год первичного обязательства в получении государственной аккредитации",
          "order": 22,
          "active": true,
          "width": null,
          "sortable": false
        },
          {
            "text": "Наличие КЦП 2017",
            "dataField": "kcp2017",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2017",
            "active": true,
            "order": 23,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2018",
            "dataField": "kcp2018",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2018",
            "active": true,
            "order": 24,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2019",
            "dataField": "kcp2019",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2019",
            "active": true,
            "order": 25,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2020",
            "dataField": "kcp2020",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2020",
            "active": true,
            "order": 26,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2021",
            "dataField": "kcp2021",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2021",
            "active": true,
            "order": 27,
            "width": null,
            "sortable": false
          }
        ],
        [{
          "text": "Рег. номер лицензии",
          "dataField": "licRNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Рег. номер лицензии",
          "active": true,
          "order": 6,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Номер бланка лицензии",
          "dataField": "licNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Номер бланка лицензии",
          "active": true,
          "order": 7,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Серия бланка лицензии",
          "dataField": "licSer",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Серия бланка лицензии",
          "active": true,
          "order": 8,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Номер приложения к лицензии",
          "dataField": "licPRNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Номер приложения к лицензии",
          "active": true,
          "order": 9,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Дата выдачи лицензии",
          "dataField": "licDate",
          "typeVar": "date",
          "cols": "1",
          "rows": "1",
          "tooltip": "Дата выдачи лицензии",
          "active": true,
          "order": 10,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Серия бланка приложения к лицензии",
          "dataField": "licBLSer",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Серия бланка приложения к лицензии",
          "active": true,
          "order": 11,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Номер бланка приложения к лицензии",
          "dataField": "licBLNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Номер бланка приложения к лицензии",
          "active": true,
          "order": 12,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }
        ]
      ],
      dataHeaders1: [
        [{
          "text": "Согласовать (отображается текущее состояние)",
          "dataField": "action",
          "typeVar": "string",
          "cols": "1",
          "rows": "2",
          "tooltip": "",
          "active": true,
          "accessAdd": false,
          "accessEdit": true,
          "accessDelete": true,
          "deleteText": "Согласовать",
          "deleteIcon": "mdi-check-underline",
          "customAction": true,
          "order": 0,
          "width": "30px",
          "sortable": false
        }, {
          "text": "Наименование организации/филиала",
          "dataField": "sprOrg",
          "typeVar": "selector",
          "cols": "1",
          "rows": "2",
          "tooltip": "Наименование организации/филиала",
          "active": true,
          "order": 1,
          "width": null,
          "sortable": false
        },
          {
            "text": "УГСН (НПС)",
            "dataField": "sprKCPGroup",
            "typeVar": "selector",
            "cols": "1",
            "rows": "2",
            "tooltip": "УГСН (НПС)",
            "active": true,
            "order": 2,
            "width": null,
            "sortable": false
          }, {
          "text": "Год первичного обязательства в получении государственной аккредитации",
          "dataField": "year",
          "typeVar": "string",
          "cols": "1",
          "rows": "2",
          "tooltip": "Год первичного обязательства в получении государственной аккредитации",
          "order": 3,
          "active": true,
          "width": null,
          "sortable": false
        },
          {
            "text": "Наличие КЦП 2017",
            "dataField": "kcp2017",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2017",
            "active": true,
            "order": 4,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2018",
            "dataField": "kcp2018",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2018",
            "active": true,
            "order": 5,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2019",
            "dataField": "kcp2019",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2019",
            "active": true,
            "order": 6,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2020",
            "dataField": "kcp2020",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2020",
            "active": true,
            "order": 7,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие КЦП 2021",
            "dataField": "kcp2021",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2021",
            "active": true,
            "order": 8,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие аккредитации в 2018",
            "dataField": "hasGA2018",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2021",
            "active": true,
            "order": 9,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие аккредитации в 2019",
            "dataField": "hasGA2019",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2021",
            "active": true,
            "order": 9,
            "width": null,
            "sortable": false
          },
          {
            "text": "Наличие аккредитации в 2020",
            "dataField": "hasGA2020",
            "typeVar": "customSelector",
            "data": [{"value": false, "text": "нет"}, {"value": true, "text": "да"}],
            "cols": "1",
            "rows": "2",
            "tooltip": "Наличие КЦП 2021",
            "active": true,
            "order": 9,
            "width": null,
            "sortable": false
          },
          {
          "text": "Лицензия на осуществление образовательной деятельности",
          "dataField": "",
          "typeVar": "",
          "cols": "7",
          "rows": "1",
          "tooltip": "Лицензия на осуществление образовательной деятельности",
          "active": false,
          "order": 20,
          "width": null,
          "sortable": false
        }, 
        ],
        [
            {
          "text": "Рег. номер лицензии",
          "dataField": "licRNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Рег. номер лицензии",
          "active": true,
          "order": 14,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Номер бланка лицензии",
          "dataField": "licNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Номер бланка лицензии",
          "active": true,
          "order": 15,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Серия бланка лицензии",
          "dataField": "licSer",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Серия бланка лицензии",
          "active": true,
          "order": 16,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Номер приложения к лицензии",
          "dataField": "licPRNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Номер приложения к лицензии",
          "active": true,
          "order": 17,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Дата выдачи лицензии",
          "dataField": "licDate",
          "typeVar": "date",
          "cols": "1",
          "rows": "1",
          "tooltip": "Дата выдачи лицензии",
          "active": true,
          "order": 18,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Серия бланка приложения к лицензии",
          "dataField": "licBLSer",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Серия бланка приложения к лицензии",
          "active": true,
          "order": 19,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }, {
          "text": "Номер бланка приложения к лицензии",
          "dataField": "licBLNum",
          "typeVar": "string",
          "cols": "1",
          "rows": "1",
          "tooltip": "Номер бланка приложения к лицензии",
          "active": true,
          "order": 20,
          "width": null,
          "sortable": false,
          "alternativeSortable": false
        }
        ]
      ],
      headersfordialog: 
 {
   "massive": [
   // {
   //   "text": "Наличие КЦП 2017",
   //   "dataField": "kcp2017",
   //   "typeVar": "customSelector",
   //   "data": [
   //   {
   //     "value": false,
   //     "text": "нет"
   //   },
   //   {
   //     "value": true,
   //     "text": "да"
   //   }],
   //   "cols": "1",
   //   "rows": "2",
   //   "tooltip": "Наличие КЦП 2017",
   //   "active": true,
   //   "rules": 1,
   //   "order": 23,
   //   "width": null,
   //    "hidden": true,
   //   "sortable": false
   // },
   // {
   //   "text": "Наличие КЦП 2018",
   //   "dataField": "kcp2018",
   //   "typeVar": "customSelector",
   //   "data": [
   //   {
   //     "value": false,
   //     "text": "нет"
   //   },
   //   {
   //     "value": true,
   //     "text": "да"
   //   }],
   //   "cols": "1",
   //   "rules": 1,
   //   "rows": "2",
   //   "tooltip": "Наличие КЦП 2018",
   //   "active": true,
   //   "order": 24,
   //   "width": null,
   //    "hidden": true,
   //   "sortable": false
   // },
   // {
   //   "text": "Наличие КЦП 2019",
   //   "dataField": "kcp2019",
   //   "typeVar": "customSelector",
   //   "data": [
   //   {
   //     "value": false,
   //     "text": "нет"
   //   },
   //   {
   //     "value": true,
   //     "text": "да"
   //   }],
   //   "cols": "1",
   //   "rows": "2",
   //   "rules": 1,
   //   "tooltip": "Наличие КЦП 2019",
   //   "active": true,
   //   "order": 25,
   //   "width": null,
   //    "hidden": true,
   //   "sortable": false
   // },
   // {
   //   "text": "Наличие КЦП 2020",
   //   "dataField": "kcp2020",
   //   "typeVar": "customSelector",
   //   "data": [
   //   {
   //     "value": false,
   //     "text": "нет"
   //   },
   //   {
   //     "value": true,
   //     "text": "да"
   //   }],
   //   "cols": "1",
   //   "rows": "2",
   //   "rules": 1,
   //   "tooltip": "Наличие КЦП 2020",
   //   "active": true,
   //   "order": 26,
   //   "width": null,
   //    "hidden": true,
   //   "sortable": false
   // },
   // {
   //   "text": "Наличие КЦП 2021",
   //   "dataField": "kcp2021",
   //   "typeVar": "customSelector",
   //   "data": [
   //   {
   //     "value": false,
   //     "text": "нет"
   //   },
   //   {
   //     "value": true,
   //     "text": "да"
   //   }],
   //   "cols": "1",
   //   "rows": "2",
   //   "rules": 1,
   //   "tooltip": "Наличие КЦП 2021",
   //   "active": true,
   //   "order": 27,
   //   "width": null,
   //   "hidden": true,
   //   "sortable": false
   // },

   {
     "text": "5",
     "dataField": "year",
     "typeVar": "string",
     "cols": "1",
     "rows": "1",
     "tooltip": "Год первичного обязательства в получении государственной аккредитации",
     "active": true,
     "order": 15,
     "width": null,
     "sortable": false,
     "alternativeSortable": false
   }]
 },
      dataRows: {},
      get_data_table_error: null,
      get_data_table_loading: false,
      color: {
        dataField: 'flApproved',
        colors: [
          {value: null, backGroundColor: '#ffffff', color: '#00599b', class: 'default-row-class'},
          {value: 'APPROVED_BUT_NO_ACCRED',                  backGroundColor: '#EBF9EB', color: '#3AB939',},
          {value: 'IN_PROCESS',              backGroundColor: '#CFE0EC', color: '#00599b',},
          // {value: 'HAVE_NO_GOS_ACCRED_BUT_IN_ACCEPTED_PROCESS',    backGroundColor: '#EB722E', color: '#FFFFFF',},
          {value: 'REJECTED',                           backGroundColor: '#FDEBEB', color: '#F03738',},
          // {value: 'ACCEPTED_BUT_HAVE_NOT_GOS_ACCRED_INCLUDED',     backGroundColor: '#426075', color: '#FFFFFF',},
          // {value: 'ACCEPTED_BUT_HAVE_NOT_GOS_ACCRED_NOT_INCLUDED', backGroundColor: '#CFE0EC', color: '#00599b',},
          {value: 'NOT_SET',                                       backGroundColor: '#CFE0EC', color: '#00599b',},
        ]
      },
      customActions:
          {
            active: 0,
            dataField: 'flApproved',
            data: [
              {value: null, icon: 'mdi-close', action: 'customAction', text: 'Ошибка', blocked: true},
              {value: 'APPROVED_WITH_ACCRED', icon: 'mdi-close', action: 'customAction', text: 'Отказать', blocked: false},
              {value: 'REJECTED', icon: 'mdi-close', action: 'customAction', text: 'Отказать', blocked: false},
              {value: 'IN_PROCESS', icon: 'mdi-close', action: 'customAction', text: 'Отказать', blocked: false},
              {value: 'NOT_SET', icon: 'mdi-close', action: 'customAction', text: 'Отказать', blocked: false},
              {value: 'APPROVED_BUT_NO_ACCRED', icon: 'mdi-close', action: 'customAction', text: 'Отказать', blocked: false},
            ]
          },
    }
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
  },
  created() {
    if (this.authData && (this.authData.role.id === 4 || this.authData.role.id === 6))
      this.getLicenses()
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    async getLicenses() {
      this.get_data_table_loading = true
      let res = await request({
        endpoint: `/api/founderService/getAllInProcessLicense`,
        method: 'POST',
        body: {
          id: this.get_replace_id_admin,
          pageNumber: -1,
          recordCount: 0,
        }
      })
      if (Object.hasOwnProperty.call(res, 'error'))
        this.get_data_table_error = res.error
      else {
        // console.log(res)
        this.get_data_table_error = null
        this.dataRows = new Object({
          content: res.data.tempReestrClasses,
          totalElements: res.data.counter
        })
      }
      this.get_data_table_loading = false
    },
    async actionRow(obj) {
      if (obj.typeAction === 'delete' && obj.id != null) {
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        let dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
        // console.log("deit", obj)
        this.get_data_table_loading = true
        let resRow =await request({
          endpoint: `/api/founderService/acceptLicense`,
          method: 'POST',
          body: {
            orgID: dataForDialog.sprOrg.orgID,
            sprKCPGroupID:  dataForDialog.sprKCPGroup.sprKCPGroupID
          }
        })
        if (resRow.status === 200)
          await this.addNotification({
            notificationStatus: 'success',
            notificationMessage: 'Данные успешно изменены',
            timeout: 3000
          })
        else
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка в изменении',
            timeout: 3000
          })
        this.get_data_table_loading = false
        await this.getLicenses()
      }
      else if((obj.typeAction === 'edit' && obj.id != null) ) {
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        this.dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
      }
      else  if ( obj.typeAction === 'customAction' && obj.id != null){
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        let dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
        this.get_data_table_loading = true
        let resRow = await request({
          endpoint: `/api/founderService/rejectLicense`,
          method: 'POST',
          body: {
            orgID: dataForDialog.sprOrg.orgID,
            sprKCPGroupID:  dataForDialog.sprKCPGroup.sprKCPGroupID
          }
        })
        if (resRow.status === 200)
          await this.addNotification({
            notificationStatus: 'success',
            notificationMessage: 'Данные успешно изменены',
            timeout: 3000
          })
        else
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка в изменении',
            timeout: 3000
          })
        this.get_data_table_loading = false
        await this.getLicenses()
      }
    },
    async sendRequest(obj) {
      
      let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id');
      let buf = ind > -1 ? this.dataRows.content[ind] : null
      let resRow = await request({
          endpoint: `/api/founderService/changeGAYear`,
          method: 'POST',
          body: {
            orgID: buf.sprOrg.orgID,
            year: obj.year,
            ugsID: buf.sprKCPGroup.sprUgs.ugsID,
            uslID: buf.sprUsl.uslID,
            id: obj.id

          }
        })
      if (resRow.status === 200)
          await this.addNotification({
            notificationStatus: 'success',
            notificationMessage: 'Данные успешно изменены',
            timeout: 3000
          })
        else
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка в изменении',
            timeout: 3000
          })
        this.get_data_table_loading = false
        await this.getLicenses(this.orgID)
    }
  }
}
</script>