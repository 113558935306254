<template>
  <div>
    <component :is="layout"/>
  </div>
</template>
<script>
import InProgress from "@/components/InProgress";
import zayavkaKcpStructureOrg from "@/pages/zayvkaKcp/zayavkaKcpStructureOrg.vue"
import zayavkaKcpStructureFounder from "@/pages/zayvkaKcp/zayavkaKcpStructureFounder"
import {mapGetters} from "vuex"
export default {
  name: 'zayavkaKcpStructure',
  components: {
    InProgress,
    zayavkaKcpStructureOrg,
    zayavkaKcpStructureFounder
  },
  computed: {
    ...mapGetters('auth', {get_replace_role_id_admin:'get_replace_role_id_admin'}),
    layout() {
      let nameComponent = 'zayavkaKcpStructureOrg'
      switch (this.get_replace_role_id_admin){
        case 1:
          nameComponent = 'zayavkaKcpStructureOrg'
          break
        case 4:
          nameComponent = 'zayavkaKcpStructureFounder'
          break
        default:
          nameComponent = 'InProgress'
          break
      }
      return nameComponent;
    }
  },
}

</script>