<template>
  <div>
    <component :is="dialogComponent"
               v-if="allowDopDialogs"
               :dop-props="dopPropsForDialog"
               :show-dialog.sync="showDialog"
               :access-apply-edit-function.sync="accessApplyEditFunction"
               :data-for-dialog="dataForDialog"
               :variables="dataDialogHeaders"
               v-on:fetch-new-data="sendNewData($event)"
               v-on:clear-data-for-dialog="clearDtaForDialog($event)"
    />
    <Table
        :table-headers="dataHeaders"
        :table-data="dataRows"
        :error-headers="errorHeaders"
        :error-rows="errorRows"
        :action-access="actionAccess"
        :loading-flag="flagLoadingHeaders"
        :loading-flag-rows="flagLoadingRows"
        :pages-paginate="pagesPaginate"
        :sort-by-prop="sortMass"
        :show-dialog.sync="showDialog"
        :rolled-cols="rolledCols"
        :color="color"
        :custom-action-props="customActionProps"
        :name-row-component="nameRowComponent"
        :array-for-menu-cells="arrayForMenuCells"
        v-on:sendNumOfPaginatePage="changeNumOfPaginatePage($event)"
        v-on:action-row="actionRow($event)"
        v-on:sendNumPage="changeNumPage($event)"
        v-on:send:sorts="changeSort($event)"
        v-on:send:hiddenColumn="rollOnColumns($event)"
    /><!-- :hidden-cols="request.parameters"    is he unused????? when?????-->
  </div>
</template>

<script>
import Table from "@/components/DTable_new/Table";
import universalDialog from "@/components/Dialogs/universalDialog";
import accreditationDialog from "@/components/Dialogs/accreditationDialog";

export default {
  name: "DTable",
  components: {
    Table,
    universalDialog,accreditationDialog
  },
  props: {
    dataRows: {
      type: Object,
      default: () => {
      }
    },
    arrayForMenuCells: {
      type: Array
    },
    nameRowComponent:{
      type: String,
    },
    color:{
      type: Object
    },
    dataHeaders: {
      type: Array,
      default: () => []
    },
    dataDialogHeaders: {
      type: Array,
      default: () => []
    },
    dataForDialog: {},
    flagLoadingForDialog:{
    },
    errorsForDialog:{
      default: null
    },
    actionAccess: {
      type: Boolean,
      default: false
    },
    dopPropsForDialog: {
      default: null
    },
    nameDialog: {
      type: String,
      default: null
    },
    flagLoadingHeaders: {
      type: Boolean,
      default: false
    },
    flagLoadingRows: {
      type: Boolean,
      default: false
    },
    errorHeaders: {
      default: null
    },
    errorRows: {
      default: null
    },
    settings: {
      type: Object
    },
    request: {
      type: Object
    },
    sortMass: {
      type: Array,
      default: () => []//{dataField: "organization", typeSort: 1, typeVar: "string"}
    },
    idPage: {
      type: Number,
      default: 0
    },
    numPage: {
      type: Number,
      default: 0
    },
    allowDopDialogs: {
      type: Boolean,
      default: false
    },
    customActionProps: {},
  },
  data: () => ({
    showDialog: {show: false, typeDialog: 1},
    rolledCols: [],
    accessApplyEditFunction: false,
    pagesPaginate: [
      {val: 5, name: 5},
      {val: 10, name: 10},
      {val: 25, name: 25},
      {val: 50, name: 50},
      {val: 100, name: 100},
      {val: 250, name: 250},
      {val: 500, name: 500},
      {val: 1000, name: 1000}
    ],
  }),
  computed: {
    dialogComponent() {
      return this.nameDialog != null ? this.nameDialog : universalDialog
    }
  },
  methods: {
    actionRow(obj) {
      if (obj.typeAction === 'edit')
      {
        this.showDialog = {show: true, typeDialog: 2}
        this.accessApplyEditFunction = false
      }
      this.$emit('action-row', obj)
    },
    changeNumOfPaginatePage(val) {
      this.$emit("update:num-page", val.val)
      // this.$emit('send-request')
    },
    changeNumPage(val) {
      this.$emit("update:id-page", val)
      // this.$emit('send-request')
    },
    clearDtaForDialog(Obj){
      if (Obj.typeDialog === 2)
        this.$emit("update:data-for-dialog", null)
    },
    sendNewData(data) {
      this.$emit('send-request', data)
      if (!this.flagLoadingForDialog && this.errorsForDialog == null)
      {

        // console.log("check flag for dialog errror " ,this.flagLoadingForDialog , this.errorsForDialog)
        this.showDialog.show = false
        this.accessApplyEditFunction = false
        // console.log("1212121",this.accessApplyEditFunction)
      }
    },
    // Проверка на наличие сортируемого в столбца в актульной выборке данных,если такого нет обнуление иначе добавляем сортировку
    checkSortArrayOnGroups(val) {
      if (this.request.groups.length > 0)
        this.$emit("update:sort-mass", val.filter(el => (this.$scripts.TableJs.arraySearch(this.dataHeaders, el.dataField, 'dataField') > -1)))
      else
        this.$emit("update:sort-mass", val)
    },
    changeSort(val) {
      this.checkSortArrayOnGroups(val);
      this.$emit('send-request')
    },
    //Сворачивание столбцов
    rollOnColumns(arr) {
      this.rolledCols = arr;
    },
  }

}
</script>

<style scoped>

</style>