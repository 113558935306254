<template>
  <div>
    <div class="score-about">
      <p><b>п.5.2.3. Приложения 2 Порядка:</b></p>
      <p>Процент обучавшихся по программам подготовки научно-педагогических кадров в аспирантуре по договорам о целевом
        обучении, закончивших обучение с защитой диссертации на соискание ученой степени кандидата наук</p>
    </div>
    <v-divider class="mt-4"/>
    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about">
      <p class="calculation__about-text">
        Форма 5.5.<br>
        Целевое обучение - численность закончивших обучение в аспирантуре, человек
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b31-thead">
      <tr class="">
        <th class="" rowspan="3">№ п/г</th>
        <th class="" colspan="2">УГСН</th>
        <th class="" rowspan="2">Форма обучения</th>
        <th class="" >Всего закончивших<br> аспирантуру, обучавшихся по<br> договорам о целевом<br> обучении
        </th>
        <th >из них - с защитой диссертации</th>
      </tr>
      <tr class="">
        <th class="">Код</th>
        <th class="">Наименование</th>
        <th class="" >2019 год</th>
        <th class="" >2019 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="" >3</th>
        <th class="" >4</th>
        <th class="" >5</th>
      </tr>
      </thead>
      <tbody class="b31-tbody">
      <tr>
        <td colspan="6"> {{ rowOfValues.data.sprOrg.orgName }}</td>
      </tr>
      <tr>
        <td class="">1.</td>
        <td class="">
          {{ rowOfValues.data.sprKCPGroup.code }}
        </td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
        <td class="" >очная</td>
        <td class="" >{{ rowOfValues.data.cvo }}</td>
        <td class="" >{{ rowOfValues.data.zo }}</td>
      </tr>
      <tr>
        <td class="">2.</td>
        <td class="">
          {{ rowOfValues.data.sprKCPGroup.code }}
        </td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
        <td class="" >очно-заочная</td>
        <td class="" >{{ rowOfValues.data.cvv }}</td>
        <td class="" >{{ rowOfValues.data.zv }}</td>
      </tr>
      <tr>
        <td class="">3.</td>
        <td class="">
          {{ rowOfValues.data.sprKCPGroup.code }}
        </td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
        <td class="" >заочная</td>
        <td class="" >{{ rowOfValues.data.cvz }}</td>
        <td class="" >{{ rowOfValues.data.zz }}</td>
      </tr>
      <tr>
        <td colspan="4">
        </td>
        <td colspan="1" style="">
          <div><img style="height: 20px; margin: 0; width:140px; "
                    src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                    alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div><img style="height: 20px; margin: 0; width:140px; "
                    src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                    alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="4">
        </td>
        <td colspan="1" style="">
          <div>
            ЦВ = <span v-html="checkData(rowOfValues.data.cv)"></span>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            ЦЗ = <span v-html="checkData(rowOfValues.data.z)"></span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <!-- formula -->
    <section

        class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>A23<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">ЦЗ</div>
          <div class="formula-half" style="margin-left: 25%">ЦВ</div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.z)"></span>
          </div>
          <div class="formula-half" style="margin-left: 25%"><span v-html="checkData(rowOfValues.data.cv)"></span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full" >
          {{  rowOfValues.data != null && rowOfValues.data.sasp3 != null && rowOfValues.data.sasp3 == 1 ? 'н/д' : checkData(rowOfValues.data.a23b) }}
        </div>
      </div>

      <!-- formula -->

      <div class="score-about mb-4">
        <p class="ml-4">
          Если значение показателя ЦВ менее 5, значения показателей ЦЗ и ЦВ рассчитываются по области образования, к
          которой относится УГСН.
        </p>
      </div>
      <div class="calculation__about">
        <p class="calculation__about-text">
          ЦЗ - численность обучавшихся по договорам о целевом обучении, закончивших обучение в 2019 году с защитой
          диссертации
        </p>
        <p class="calculation__about-text">
          ЦВ - численность обучавшихся по договорам о целевом обучении, закончивших обучение в 2019 году
        </p>
      </div>
    </section>
    <section v-if="rowOfValues.data != null && rowOfValues.data.sasp3 != null && rowOfValues.data.sasp3 == 1" class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя по области образования:</h5>

      <div class="formula">
        <div class="formula-full"><span>A23<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">ЦЗ+</div>
          <div class="formula-half" style="margin-left: 25%">ЦВ+</div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.zn)"></span>
          </div>
          <div class="formula-half" style="margin-left: 25%"><span v-html="checkData(rowOfValues.data.cvn)"></span>
          </div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full" v-html="checkData(rowOfValues.data.a23b0)"></div>
      </div>

      <!-- formula -->

      <div class="score-about mb-4">
        <p class="ml-4">
          Если значение показателя ЦВ, рассчитанное по области образования, менее 5:
          - если частное показателей ЦЗ и ЦВ, рассчитанных по области образования, равно или более 0,6, то показатель
          рассчитывается по указанной выше формуле;
          - в иных случаях в целях оценки заявки значение показателя не рассчитывается, а его нормированная оценка
          определяется в соответствии с пунктом 7 Методики.</p>
      </div>
      <div class="calculation__about">
        <p class="calculation__about-text">
          ЦЗ+ - численность обучавшихся по договорам о целевом обучении, закончивших обучение в 2019 году с защитой
          диссертации в целом по области образования
        </p>
        <p class="calculation__about-text">
          ЦВ+ - численность обучавшихся по договорам о целевом обучении, закончивших обучение в 2019 году в целом по
          области образования
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "a23",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%

.b31-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px
.b31-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px
  tr
    &:nth-child(n+5)
      td
        border: 0
    //&:nth-child(3)
    //  td
    //    border: 0
</style>