<template>
  <div>
    <HeaderMain
        title="Пояснение"
    />
    <ul class="pl-0">
      <li class=" ">Увеличение предложений по установлению КЦП (заявок) осуществляется в соответствии с п.19 Порядка
        проведения
        конкурса на распределение контрольных цифр приема по специальностям и направлениям подготовки и (или)
        укрупненным группам специальностей и направлений подготовки для обучения по образовательным программам высшего
        образования за счет бюджетных ассигнований федерального бюджета, в целях распределения нераспределенных остатков
        общих объемов КЦП, сложившихся по итогам предварительного распределения КЦП.
      </li>
      <li class="mt-2">В первом столбце формы указаны предельные объемы увеличения заявок, определенные Минобрнауки
        России в
        соответствии с Порядком. Во втором столбце образовательная организация указывает согласованные предельные объемы
        увеличения заявок, но не более значений, указанных в первом столбце.
      </li>
      <li class="mt-2">Увеличение заявок образовательных организаций будет осуществляться в порядке убывания рейтинга
        (сводной
        оценки) заявок, до тех пор, пока общий объем увеличения заявок не будет равен нераспределенному остатку КЦП. В
        связи с этим, итоговый объем увеличения заявки и соответствующий ему объем увеличения КЦП в результате может
        оказаться меньше предельного объема увеличения заявки, согласованного организацией в рамках настоящей формы
        (вплоть до нулевого значения).
      </li>
    </ul>
    <br/>
    <p class="error--text">Возможность ввода данных и согласования будут доступны до 23:59
      05.04.2021 по Московскому времени</p>
    <div>
      <button
          v-if="authData.role.id !==4 && authData.role.id !==6 && !loading"
          @click="blockEnter"
          :disabled="blocked"
          class="text-none mb-5 btn-corrected-value-block"
      ><!--class="button_border"  :class="blocked? 'btn-corrected-value-block__disabled':'btn-corrected-value-block'"-->
        <span>
        {{ blocked ? 'Заблокировано' : 'Заблокировать ввод' }}
      </span>
      </button>

      <button
          v-if="authData.role.id !==4 && authData.role.id !==6 && !loading && blocked"
          @click="printTemplate"
          class="text-none mb-5 ml-4 button_border"
          :disabled="templateLoading"
      >
      <span v-show="!templateLoading" ref="btnTemplateFile">
          Печать согласованных предельных объемов увеличения предложений по установлению КЦП
      </span>
        <span v-show="templateLoading">
        <v-progress-circular size="19" indeterminate></v-progress-circular>
      </span>
      </button>

    </div>
    <div>
      <div v-if="blocked" class="file mb-4">
        <div class="file-view">
          <div class="file-view__name">
            <button v-if="file && file.fileServerID"
                    class="download-file-indicators"
                    @click="getFileFromServer">просмотр файла
              <v-progress-circular
                  v-if="uploadNow"
                  indeterminate
                  color="#eb722e"
                  size="10"
                  width="2"
              ></v-progress-circular>
            </button>
          </div>
          <div class="file-view__date my-1">
            <span v-if="file && file.fileServerID">Дата загрузки: {{ uploadFileDate }}</span>
            <span v-else>Необходимо загрузить</span>

          </div>
          <div class="file-view__actions">
            <button v-if="!modeEdit" class="button_border file-view__button_replace"
                    :class="{'button_border_important' : !(file && file.fileServerID)}" @click="modeEdit = !modeEdit">
              {{ file && file.fileServerID ? 'Заменить' : 'Загрузить' }}
            </button>
          </div>
        </div>
        <div class="file-edit" v-if="modeEdit">
          <v-file-input label="Выбрать файл" v-model="fileToUpload" :rules="rules"
                        accept="application/pdf"/>
          <!-- <v-file-input label="Выбрать файл..." v-model="fileToUpload" accept="pdf" :rules="rules"/> -->
          <p v-if="uploadToServNowError" class="error--text"> Ошибка загрузки файла</p>
          <button
              class="button_border button_border border_margin_right"
              @click="uploadFile"
              v-if="validate"
              :disabled="!canUpload || uploadToServNow"
          >Отправить документ
            <v-progress-circular
                v-if="uploadToServNow"
                indeterminate
                color="#eb722e"
                size="10"
                width="2"
            ></v-progress-circular>
          </button>
          <button class="button_border ml-2" @click="modeEdit = !modeEdit">Отмена</button>
        </div>

      </div>
      <!--      <AlertSuccess-->
      <!--          class="mt-2" v-if="blocked">-->
      <!--        <p>Отправленный документ:-->
      <!--          <button @click="getFileFromServer" class="button_border button_border_application">Просмотр загруженного-->
      <!--            файла-->
      <!--          </button>-->
      <!--        </p>-->
      <!--      </AlertSuccess>-->
      <!--       <AlertSuccess v-if="convertAccepted">-->
      <!--         <p>Конверт принят {{convertAcceptInfo.data}} под номером {{convertAcceptInfo.id}} <button @click="getConvert" class="button_border button_border_application">Просмотр загруженного файла</button></p>-->
      <!--       </AlertSuccess>-->
    </div>

    <template v-if="!loading">
      <div v-if="list">
        <div v-for="(org, index) of list" :key="index">
          <h2
              class="title app-title_main app__title"
              :class="org.isOwner ? 'main-header__title-list' : ''"
              :style="org.isOwner ? 'font-size: 32px!important;' : ''"
          >
            {{ org.orgName }}
          </h2>
          <div class="app-header-corrected mr-0">
            <div class="app-header__form3-corrected">
            </div>
            <div class="app-header__form3-corrected">
            </div>
            <div class="app-header__form3-corrected">
              <span
                  class="app-header__about">Предельные объемы увеличения предложений по установлению КЦП (заявок)</span>
            </div>
            <div class="app-header__form3-corrected">
              <span class="app-header__about">Согласованные предельные объемы увеличения предложений по установлению КЦП (заявок)</span>
          </div>
          </div>
          <div v-for="(formUsl, index) of org.data" :key="index" class="app-list">

            <div class="app-header-corrected">
              <div class="app-header__form-corrected">
                <h3 class="title app__title text-left" v-text="formUsl.uslName"></h3>
              </div>
              <div class="app-header__form-corrected"></div>
              <div class="app-header__form-corrected">
                <span class="app-header__about"> Очно </span>
              </div>
              <div class="app-header__form-corrected">
              <span class="app-header__about">
                Очно-заочно
              </span>
              </div>
              <div class="app-header__form-corrected">
            <span class="app-header__about">
              Заочно
            </span>
              </div>
              <div class="app-header__form-corrected">
                <span class="app-header__about"> Очно </span>
              </div>
              <div class="app-header__form-corrected">
            <span class="app-header__about">
              Очно-заочно
            </span>
              </div>
              <div class="app-header__form-corrected">
            <span class="app-header__about">
              Заочно
            </span>
              </div>

            </div>

            <div v-for="(item, index) of formUsl.data" :key="index" class="app">
              <div v-text="item.sprKCPGroup.code" class="app__form-corrected"></div>
              <div v-text="item.sprKCPGroup.name" class="app__form-corrected"></div>
              <!--              <div class="app__form-corrected app__form_link__disabled  "-->
              <!--                   style="justify-content: space-between;"-->
              <!--                   v-if="item.soglAdvKcpOchnoe && item.soglAdvKcpOchnoZaochnoe && item.soglAdvKcpZaochnoe">-->
              <!--                &lt;!&ndash;              <div class="app__calc" v-text="item.za3 ? item.za3 : 'н/д'"></div>&ndash;&gt;-->
              <!--                <div class="app__score" v-text="item.soglAdvKcpOchnoe && item.soglAdvKcpOchnoZaochnoe && item.soglAdvKcpZaochnoe-->
              <!--               ? (item.soglAdvKcpOchnoe + item.soglAdvKcpOchnoZaochnoe + item.soglAdvKcpZaochnoe).toFixed(3) : 'н/д'"></div>-->
              <!--              </div>-->
              <!--              <div v-else class="app__form-corrected">-->
              <!--              </div>-->
              <div class="app__form-corrected app__form_link__disabled  "
                   style="justify-content: space-between;"
                   v-if="item.advKcpOchnoe || item.advKcpOchnoe == null">
                <!--              <div class="app__calc" v-text="item.za1 ? item.za1 : 'н/д'"></div>-->

                <div class="app__score"
                     v-text="item.advKcpOchnoe || item.advKcpOchnoe == 0 ? item.advKcpOchnoe.toFixed(0) : '0'"></div>
              </div>
              <div v-else class="app__form-corrected">
              </div>
              <div class="app__form-corrected app__form_link__disabled  "
                   style="justify-content: space-between;"
                   v-if="item.advKcpOchnoZaochnoe || item.advKcpOchnoZaochnoe == null">
                <!--              <div class="app__calc" v-text="item.za1 ? item.za1 : 'н/д'"></div>-->
                <div class="app__score"
                     v-text="item.advKcpOchnoZaochnoe || item.advKcpOchnoZaochnoe == 0 ? item.advKcpOchnoZaochnoe.toFixed(0) : '0'"></div>
              </div>
              <div v-else class="app__form-corrected">
              </div>
              <div class="app__form-corrected app__form_link__disabled  "
                   style="justify-content: space-between;"
                   v-if="item.advKcpZaochnoe || item.advKcpZaochnoe == null">
                <!--              <div class="app__calc" v-text="item.za2 ? item.za2 : 'н/д'"></div>-->
                <div class="app__score"
                     v-text="item.advKcpZaochnoe || item.advKcpZaochnoe == 0 ? item.advKcpZaochnoe.toFixed(0) : '0'"></div>
              </div>
              <div v-else class="app__form-corrected">
              </div>

              <!--              <div class="app__form-corrected app__form_link__disabled  "-->
              <!--                   style="justify-content: space-between;"-->
              <!--                   v-if="item.advKcpOchnoe && item.advKcpOchnoZaochnoe && item.advKcpZaochnoe">-->
              <!--                &lt;!&ndash;              <div class="app__calc" v-text="item.za3 ? item.za3 : 'н/д'"></div>&ndash;&gt;-->
              <!--                <div class="app__score" v-text="item.advKcpOchnoe && item.advKcpOchnoZaochnoe && item.advKcpZaochnoe-->
              <!--               ? (item.advKcpOchnoe + item.advKcpOchnoZaochnoe + item.advKcpZaochnoe).toFixed(3) : 'н/д'"></div>-->
              <!--              </div>-->
              <!--              <div v-else class="app__form-corrected">-->
              <!--              </div>-->

              <!--              <vuetify-menu-modal-->
              <!--                  v-if="(item.advKcpOchnoe || item.advKcpOchnoe == 0) && (!item.advKcpOchnoeLoading) && !blocked"-->
              <!--                  :item="item"-->
              <!--                  data-field="advKcpOchnoe"-->
              <!--                  :max-value="item.soglAdvKcpOchnoe"-->
              <!--                  :loading="item.advKcpOchnoeLoading"-->
              <!--                  v-on:update-value="updateValue($event,formUsl,org)"-->
              <!--              />-->
              <!--              class="app__form-corrected app__form_link  "-->
              <div class="app__form-corrected app__form_link"
                   :class="item.advKcpOchnoe !==  item.soglAdvKcpOchnoe ? 'app__form_link-disabled' : ''"
                   v-if="(item.soglAdvKcpOchnoe || item.soglAdvKcpOchnoe == 0) && item.advKcpOchnoe && (!item.soglAdvKcpOchnoeLoading) && !blocked"
              >
                <div class="app__score"
                     v-text="item.soglAdvKcpOchnoe || item.soglAdvKcpOchnoe == 0 ? item.soglAdvKcpOchnoe : '0'"></div>
                <v-tooltip
                    top
                    :color="item.advKcpOchnoe !==  item.soglAdvKcpOchnoe ? 'success' : 'error'"
                    nudge-top="10"
                >
                  <template v-slot:activator="{on,attrs}">
                    <v-btn
                        @click="takePartInTheCompetition(item,formUsl,org,'soglAdvKcpOchnoe','advKcpOchnoe')"
                        v-bind="attrs"
                        v-on="on"

                        max-height="24"
                        icon>
                      <v-icon
                          :color="item.advKcpOchnoe !==  item.soglAdvKcpOchnoe ? '#FFFFFF' : '#FFFFFF'"
                      >
                        {{ item.advKcpOchnoe !== item.soglAdvKcpOchnoe ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.advKcpOchnoe !== item.soglAdvKcpOchnoe ? 'Принять' : 'Отказаться ' }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.soglAdvKcpOchnoeLoading && !blocked" class="app__form-corrected justify-center">
                <v-progress-circular indeterminate/>
              </div>
              <div class="app__form-corrected app__form_link__disabled  "
                   style="justify-content: space-between;"
                   v-else-if="blocked || item.advKcpOchnoe == 0 || item.advKcpOchnoe == null">
                <div class="app__score"
                     v-text="item.soglAdvKcpOchnoe || item.soglAdvKcpOchnoe == 0 ? item.soglAdvKcpOchnoe : '0'"></div>
              </div>
              <div v-else class="app__form-corrected">
              </div>
              <div class="app__form-corrected app__form_link"
                   :class="item.advKcpOchnoZaochnoe !==  item.soglAdvKcpOchnoZaochnoe ? 'app__form_link-disabled' : ''"
                   v-if="(item.soglAdvKcpOchnoZaochnoe || item.soglAdvKcpOchnoZaochnoe == 0)  && item.advKcpOchnoZaochnoe && (!item.soglAdvKcpOchnoZaochnoeLoading) && !blocked"
              >
                <div class="app__score"
                     v-text="item.soglAdvKcpOchnoZaochnoe || item.soglAdvKcpOchnoZaochnoe == 0 ? item.soglAdvKcpOchnoZaochnoe : '0'"></div>
                <v-tooltip
                    top
                    :color="item.advKcpOchnoZaochnoe !==  item.soglAdvKcpOchnoZaochnoe ? 'success' : 'error'"
                    nudge-top="10"
                >
                  <template v-slot:activator="{on,attrs}">
                    <v-btn
                        @click="takePartInTheCompetition(item,formUsl,org,'soglAdvKcpOchnoZaochnoe','advKcpOchnoZaochnoe')"
                        v-bind="attrs"
                        v-on="on"

                        max-height="24"
                        icon>
                      <v-icon
                          :color="item.advKcpOchnoZaochnoe !==  item.soglAdvKcpOchnoZaochnoe ? '#FFFFFF' : '#FFFFFF'"
                      >
                        {{ item.advKcpOchnoZaochnoe !== item.soglAdvKcpOchnoZaochnoe ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.advKcpOchnoZaochnoe !== item.soglAdvKcpOchnoZaochnoe ? 'Принять' : 'Отказаться ' }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.soglAdvKcpOchnoZaochnoeLoading && !blocked"
                   class="app__form-corrected justify-center">
                <v-progress-circular indeterminate/>
              </div>
              <div class="app__form-corrected app__form_link__disabled  "
                   style="justify-content: space-between;"
                   v-else-if="blocked || item.advKcpOchnoZaochnoe == 0 || item.advKcpOchnoZaochnoe == null">
                <div class="app__score"
                     v-text="item.soglAdvKcpOchnoZaochnoe || item.soglAdvKcpOchnoZaochnoe == 0 ? item.soglAdvKcpOchnoZaochnoe : '0'"></div>
              </div>
              <div v-else class="app__form-corrected">
              </div>
              <div class="app__form-corrected app__form_link"
                   :class="item.advKcpZaochnoe !==  item.soglAdvKcpZaochnoe ? 'app__form_link-disabled' : ''"
                   v-if="(item.soglAdvKcpZaochnoe || item.soglAdvKcpZaochnoe == 0)   && item.advKcpZaochnoe && (!item.soglAdvKcpZaochnoeLoading) && !blocked"
              >
                <div class="app__score"
                     v-text="item.soglAdvKcpZaochnoe || item.soglAdvKcpZaochnoe == 0 ? item.soglAdvKcpZaochnoe : '0'"></div>
                <v-tooltip
                    top
                    :color="item.advKcpZaochnoe !==  item.soglAdvKcpZaochnoe ? 'success' : 'error'"
                    nudge-top="10"
                >
                  <template v-slot:activator="{on,attrs}">
                    <v-btn
                        @click="takePartInTheCompetition(item,formUsl,org,'soglAdvKcpZaochnoe','advKcpZaochnoe')"
                        v-bind="attrs"
                        v-on="on"
                        max-height="24"
                        icon>
                      <v-icon
                          :color="item.advKcpZaochnoe !==  item.soglAdvKcpZaochnoe ? '#FFFFFF' : '#FFFFFF'"
                      >
                        {{ item.advKcpZaochnoe !== item.soglAdvKcpZaochnoe ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.advKcpZaochnoe !== item.soglAdvKcpZaochnoe ? 'Принять' : 'Отказаться ' }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.soglAdvKcpZaochnoeLoading && !blocked" class="app__form-corrected justify-center">
                <v-progress-circular indeterminate/>
              </div>
              <div class="app__form-corrected app__form_link__disabled  "
                   style="justify-content: space-between;"
                   v-else-if="blocked || item.advKcpZaochnoe == 0 || item.advKcpZaochnoe == null">
                <div class="app__score"
                     v-text="item.soglAdvKcpZaochnoe || item.soglAdvKcpZaochnoe == 0 ? item.soglAdvKcpZaochnoe : '0'"></div>
              </div>
              <div v-else class="app__form-corrected">
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <in-progress/>
    </template>

  </div>
</template>
<script>
import request from "@/services/request";
import HeaderMain from "@/components/headers/HeaderMain";
import {mapGetters} from 'vuex'
// import VuetifyMenuModal from "@/components/modals/VuetifyMenuModal";
import InProgress from "@/components/InProgress";

// import AlertSuccess from "@/components/alerts/AlertSuccess";
import saveFile from "@/utils/saveFile";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    InProgress,
    // VuetifyMenuModal,
    HeaderMain,
    // AlertSuccess,
  },
  data() {
    return {
      list: null,
      blocked: false,
      loading: false,
      file: null,
      fileToUpload: null,
      modeEdit: false,
      uploadNow: false,
      templateLoading: false,
      validate: null,
      uploadToServNow: false,
      uploadToServNowError: null,
      rules: [
        value => !value || value.size < 20000000 || 'Размер файла не должен превышать 20 МБ!',
        value => !value || value.type === 'application/pdf' || 'Допустимы файлы только в pdf формате',
        value => {
          if (!value) {
            this.validate = null;
            return false;
          } else if (value.type === 'application/pdf') {
            this.validate = true;
            return true;
          } else {
            this.validate = false;
            return false;
          }
        }
      ],
      canUpload: true,
    }
  },
  created() {
    this.getAllCalcOrgAndKCPGroup(this.get_replace_id_admin)
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {authData: 'authData'}),
    uploadFileDate() {
      return moment(this.file?.uploadDate).format('LLL');
    },
  },
  methods: {
    uploadFile: function () {
      if (this.fileToUpload.size < 20000000) {
        this.uploadToServNow = true;
        if (this.fileToUpload && this.validate) {

          this.uploadFileApplication()
        }
      } else
        this.canUpload = false
    },
    async takePartInTheCompetition(item, formUsl, org, prop, valueProp) {
      // if (item[prop] === false)
      //   this.agree = confirm('Отказаться от КЦП?')
      // else
      //   this.agree = true
      // if (this.agree) {
      this.id = item.id

      const ff = this.list?.[org['orgName']]?.data[formUsl.uslName].data.find(el => el.id === item.id)
      ff[prop + 'Loading'] = true
      let obj = new Object({})
      obj[prop] = item[valueProp] === item[prop]  ? 0 : Number(item[valueProp])
      let res = await request({
        endpoint: '/api/zayavka/updateZayavkaKCPADV',
        method: 'post',
        body: {
          ...ff,
          ...obj,
          sprOrgID: this.get_replace_id_admin,
        }
      })
      if (res.status === 200) {
        ff[prop] = res.data[prop]
        ff[prop + 'Loading'] = false
        ff[prop + 'Error'] = null
      } else {
        ff[prop + 'Loading'] = false
        ff[prop + 'Error'] = res.error
      }
    },
    async getAllCalcOrgAndKCPGroup(orgID) {
      this.loading = true
      let res = await request({
        endpoint: `/api/zayavka/getAllZayavkaKCPADV?sprOrgID=${orgID}`,
        method: 'get'
      })
      let list = {};
      if (res.status === 200) {

        res.data.zayavkaKcpAdvZays.forEach(el => {
          // Фильтрация по организации (организация и филиалы)
          if (!(el.sprOrg.orgName in list)) {
            list[el.sprOrg.orgName] = {};
            list[el.sprOrg.orgName].orgName = el.sprOrg.orgName;
            list[el.sprOrg.orgName].status = el.sprOrg.orgID === el.sprOrg.orgOwner ? 'Головная' : 'Филиал';
            list[el.sprOrg.orgName].isOwner = el.sprOrg.orgID === el.sprOrg.orgOwner;
            list[el.sprOrg.orgName].data = {};
          }
          const orgData = list[el.sprOrg.orgName].data;
          // Фильтрация по уровню
          if (!(el.sprKCPGroup.sprUsl.uslName in orgData)) {
            orgData[el.sprKCPGroup.sprUsl.uslName] = {};
            orgData[el.sprKCPGroup.sprUsl.uslName].uslName = el.sprKCPGroup.sprUsl.uslName;
            orgData[el.sprKCPGroup.sprUsl.uslName].data = [];
          }
          orgData[el.sprKCPGroup.sprUsl.uslName].data.push({
            ...el,
            soglAdvKcpOchnoeLoading: false, soglAdvKcpOchnoeError: null,
            soglAdvKcpOchnoZaochnoeLoading: false, soglAdvKcpOchnoZaochnoeError: null,
            soglAdvKcpZaochnoeLoading: false, soglAdvKcpZaochnoeError: null
          });

        });
        this.list = list;
        this.blocked = res.data.blockAdvStatus === 'BLOCKED'
        this.file = res.data.zayavkaKCPAdvFiles
        this.loading = false
      }
    },
    async updateValue(item, formUsl, org) {
      const prop = item.dataField
      const ff = this.list?.[org['orgName']]?.data[formUsl.uslName].data.find(el => el.id === item.id)
      ff[prop + 'Loading'] = true
      let obj = new Object({})
      obj[prop] = Number(item.value)
      let res = await request({
        endpoint: '/api/zayavka/updateZayavkaKCPADV',
        method: 'post',
        body: {
          ...ff,
          ...obj,
          sprOrgID: this.get_replace_id_admin
        }
      })
      if (res.status === 200) {
        ff[prop] = res.data[prop]
        ff[prop + 'Loading'] = false
        ff[prop + 'Error'] = null
      } else {
        ff[prop + 'Loading'] = false
        ff[prop + 'Error'] = res.error
      }
    },
    async blockEnter() {
      let res = await request({
        endpoint: `/api/zayavka/blockInput?sprOrgID=${this.get_replace_id_admin}`,
        method: 'get'
      })
      if (res.status === 200)
        this.blocked = true
    },
    async getFileFromServer() {
      let response = await request({
        endpoint: '/api/fileServer/downloadFileByID',
        method: 'POST',
        body: {
          fileServerID: this.file.fileServerID,
          fileName: this.file.fileName,
          fileFormat: this.file.fileFormat,
        }
      });
      saveFile(response.data);
    },
    // async getFileTemplateFromServer() {
    //   let response = await request({
    //     endpoint: `/api/report/increase/${this.get_replace_id_admin}`,
    //     method: 'get'
    //   });
    //   saveFile(response.data);
    // },
    async printTemplate() {
      // console.log("print id", this.get_replace_id_admin)
      this.templateLoading = true;
      await this.getFileTemplateFromServer()
    },
    async getFileTemplateFromServer() {
      let responseAxios = await axios.get(`/api/report/increase/${this.get_replace_id_admin}`, {
        headers: this.$scripts.Utils.authHeader(),
        responseType: 'blob'
      })
      this.templateLoading = false;
      this.saveByteArray("ReportFunc.pdf", responseAxios.data)
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/pdf"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
    async uploadFileApplication() {
      let fd = new FormData();
      fd.append('sprOrgID', this.get_replace_id_admin);
      fd.append('userID', this.authData.userID);
      fd.append('file', this.fileToUpload);
      const sendingFileApplication = await request({
        endpoint: '/api/zayavka/uploadAdvFile',
        method: 'post',
        body: fd,
      });
      //rework in progress
      if (sendingFileApplication.status === 200) {
        this.file = sendingFileApplication.data
        this.uploadToServNow = false
        this.modeEdit = false
      } else {
        this.uploadToServNow = false
        this.uploadToServNowError = sendingFileApplication.error
      }

    }
  }
}
</script>
<style lang="sass">
@import "../../../assets/styles/utils/variables"
@import "../../../assets/styles/card"
.btn-corrected-value-block
  border-radius: 7px
  color: #FFFFFF
  background-color: $danger-color
  padding: 5px 20px
  font-weight: bold

  &:disabled
    color: #546e7a
    border-color: #e2e8f0
    background-color: #e2e8f0

.download-file-indicators
  @extend .file-view__download-file
  font-size: 14px
  color: #eb722e
  border: 1px solid #eb722e
  padding: 5px 20px
  margin-left: 0
  border-radius: 7px

.app
  background: #fff
  margin-bottom: 15px
  padding: 5px 10px
  display: flex
  justify-content: space-between
  align-items: center
  border-left: 3px solid #00599b
  transition: 0.3s

.main-header__title-list
  font-size: 32px !important
  line-height: 35.2px !important
  color: #00599B !important
  font-family: 'Montserrat' !important
  font-weight: 700 !important
  max-width: 100% !important
  margin-bottom: 22px

.app:hover
  transform: translateY(-5px)
  box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
  transition: 0.3s

.app__form-corrected
  display: flex
  max-width: 100px
  justify-content: space-between
  flex-basis: 70px
  margin-right: auto

  &:nth-child(1)
    margin-right: 0
    max-width: 100px
    flex-basis: 100px

  &:nth-child(2)
    max-width: 400px
    flex-basis: 400px

.app-header-corrected
  font-weight: 600
  color: #00599b
  margin-bottom: 10px
  display: flex
  width: 100%
  justify-content: right
  padding: 5px 0px

.app-header__form-corrected
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  margin-right: auto
  max-width: 200px
  flex-basis: 70px

  &:nth-child(1)
    margin-right: 0
    max-width: 100px
    flex-basis: 100px

  &:nth-child(2)
    max-width: 400px
    flex-basis: 400px

.app-header__form2-corrected
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  margin-right: auto
  max-width: 400px
  flex-basis: 70px

  &:nth-child(1)
    margin-right: 0
    max-width: 100px
    flex-basis: 100px

  &:nth-child(2)
    max-width: 400px
    flex-basis: 350px

  &:nth-child(4)
    max-width: 300px
    flex-basis: 210px

  &:nth-child(6)
    max-width: 300px
    flex-basis: 210px

.app-header__form3-corrected
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  margin-right: auto
  max-width: 400px
  flex-basis: 70px

  &:nth-child(1)
    margin-right: 0
    max-width: 100px
    flex-basis: 100px

  &:nth-child(2)
    max-width: 400px
    flex-basis: 400px

  &:nth-child(3)
    max-width: 400px
    flex-basis: 300px
    border-bottom: 1px solid #00599b

  &:nth-child(4)
    max-width: 400px
    flex-basis: 300px
    border-bottom: 1px solid #00599b

.app-header__about
  //font-size: 15px
  text-align: center
  width: 100%

.app-header__calc, .app-header__score
  font-size: 10px

.app__title
  margin-bottom: 15px
  color: #00599b

.app__form_link
  background: #eb722e
  color: #fff
  font-weight: 600
  border-radius: 7px
  padding: 4px 7px
  cursor: pointer
  &-disabled
    color: #546e7a
    border-color: #e2e8f0
    background-color: #e2e8f0

.app__form_link__disabled
  border-radius: 7px
  padding: 4px 7px

.app-title_main
  //text-transform: uppercase
  font-weight: 900
  color: #eb8e1c
//margin-top: 50px

.app-list
  margin-bottom: 30px
</style>