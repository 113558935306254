import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'

Vue.use(Router)

import LK from "@/pages/LK";
import inProgress from './components/InProgress'
/*
* zayvka KCP
* */
// import zayavkaKcp from "@/pages/zayvkaKcp/ZayavkaKcp";
import zayavkaKcpContent from "@/pages/zayvkaKcp/zayvkaKcpContent";
import zayavkaKcpLicense from "@/pages/zayvkaKcp/zayavkaKcpLicense";
import zayavkaKcpAccreditation from "@/pages/zayvkaKcp/zayavkaKcpAccreditation";

import zayavkaKcpStructure from "@/pages/zayvkaKcp/zayavkaKcpStructure";
import zayavkaKcpFounderOrgs from "@/pages/zayvkaKcp/zayavkaKcpFounderOrgs";
import zayavkaKcpKcp from "@/pages/zayvkaKcp/zayavkaKcpKcp";
import zayavkaKcpFiles from "@/pages/zayvkaKcp/zayavkaKcpFiles";
/*
* формы 5.1 - 5.20
* */
import f5Router from "@/pages/zayvkaKcp/f5/f5_router";
import f5RouterContent from "@/pages/zayvkaKcp/f5/f5RouterContent";
import zayvkaKcpForm5 from "@/pages/zayvkaKcp/f5/f5";
/*__________________________________________________________________*/
let userRole;

const routes = new Router({
    mode: 'history',
    routes: [
        {
            path: '/kcp_contest',
            // name: 'ZayavkaKcpContent',
            component: () => import('@/layout/LayoutLk/LayoutLk'),
            meta: {
                requiresAuth: true,
                checkFilled: true,
                roleAccess: [1, 4, 6, 14, 15],
                getActualOrgStatus: true,
            },
            children: [
                {
                    path: 'inProgress',
                    components: {
                        default: inProgress,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: false,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: 'license',
                    components: {
                        default: zayavkaKcpLicense,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 14, 15]
                    }
                },
                {
                    path: 'accreditation',
                    components: {
                        default: zayavkaKcpAccreditation,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 14, 15]
                    }
                },
                {
                    path: 'structure',
                    components: {
                        default: zayavkaKcpStructure,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: 'stages',
                    components: {
                        default: () => import('@/pages/zayvkaKcp/infoStages'),
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: 'stage1',
                    components: {
                        default: () => import('@/pages/zayvkaKcp/raspredStages'),
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: 'stage1-details',
                    components: {
                        default: () => import('@/views/private/stages/founderStageOneDetails'),
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: 'stage3',
                    components: {
                        default: () => import('@/pages/zayvkaKcp/raspredStages3'),
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: 'stage3-details',
                    components: {
                        default: () => import('@/views/private/stages/founderStageThreeDetails'),
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: 'structure-organisations',
                    components: {
                        default: zayavkaKcpFounderOrgs,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [4, 6, 14]
                    }
                },
                {
                    path: 'kcp',
                    components: {
                        default: zayavkaKcpKcp,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 14, 15]
                    }
                },
                {
                    path: 'print',
                    components: {
                        default: zayavkaKcpFiles,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 14, 15]
                    }
                },
                {
                    path: 'indicators',
                    components: {
                        default: f5Router,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 14, 15]
                    },
                    children: [
                        {
                            path: ':id_form',
                            component: zayvkaKcpForm5,
                            components: {
                                default: zayvkaKcpForm5,
                                nav: () => import('@/views/private/sidebars/ViewSidebar'),
                            },
                            meta: {
                                requiresAuth: true,
                                roleAccess: [1, 14, 15]
                            }
                        },
                        {
                            path: '',
                            component: f5RouterContent,
                            meta: {
                                requiresAuth: true,
                                roleAccess: [1, 14, 15]
                            }
                        },
                        {
                            path: '*',
                            redirect: 'indicators',
                            component: f5RouterContent,
                            meta: {
                                requiresAuth: true,
                                roleAccess: [1, 14, 15]
                            }
                        },
                    ]
                },
                {
                    path: '',
                    components: {
                        default: zayavkaKcpContent,
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
                {
                    path: '*',
                    redirect: '/kcp_contest',
                    component: zayavkaKcpContent,
                    meta: {
                        requiresAuth: true,
                        roleAccess: [1, 4, 6, 14, 15]
                    }
                },
            ]
        },
        {
            path: '/lk',
            component: () => import('@/layout/LayoutAuth/LayoutAuth'),
            meta: {requiresAuth: false},
            children: [
                {
                    path: '',
                    name: 'LK',
                    component: LK,
                    meta: {}
                },
            ],
        },
        {
            path: '/kcp-contest-admin',
            component: () => import('@/layout/LayoutAdmin/LayoutAdmin'),
            meta: {
                requiresAuth: true,
                roleAdminKCP: true,
                roleAccess: [10,14]
            },
            children: [
                {
                    path: '',
                    name: 'AdminMain',
                    component: () => import('@/views/admin/ViewMain'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [10, 14]
                    }
                },
                {
                    path: '/kcp-contest-admin/viewing',
                    name: 'AdminViewing',
                    component: () => import('@/views/admin/viewing/ViewViewing'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/letters',
                    name: 'AdminLetters',
                    component: () => import('@/views/admin/letters/ViewLetters'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/export',
                    name: 'AdminExport',
                    component: () => import('@/views/admin/export/ViewExport'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/apellation',
                    name: 'AdminApellation',
                    component: () => import('@/views/admin/apellation/ViewApellation'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/appeals',
                    name: 'AdminAppeals',
                    component: () => import('@/views/admin/appeals/ViewAdminAppeals'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/request',
                    name: 'AdminRequests',
                    component: () => import('@/views/admin/requests/ViewRequests'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/reference',
                    name: 'AdminReference',
                    component: () => import('@/views/admin/reference/ViewReference'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/reference/:id',
                    name: 'AdminReferenceDetail',
                    component: () => import('@/views/admin/reference/ViewReferenceDetail'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [14]
                    }
                },
                {
                    path: '/kcp-contest-admin/regions',
                    name: 'AdminRegions',
                    component: () => import('@/views/admin/regions/ViewRegions'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [10,14]
                    }
                },
                {
                    path: '/kcp-contest-admin/founders',
                    name: 'AdminFounders',
                    component: () => import('@/views/admin/founders/ViewFounders'),
                    meta: {
                        requiresAuth: true,
                        roleAdminKCP: true,
                        roleAccess: [10,14]
                    }
                }
            ],


        },
        {
            path: '/cms',
            component: () => import('@/layout/LayoutCMS/LayoutCMS'),
            meta: {
                requiresAuth: true,
                roleAdmin: true,
                roleAccess: [14, 15]
            },
            children: [
                {
                    path: '',
                    name: 'MainCMS',
                    component: () => import('@/views/cms/ViewMain'),
                    meta: {
                        requiresAuth: true,
                        roleAdmin: true,
                        roleAccess: [14, 15]
                    }
                },
                {
                    path: '/cms/news',
                    name: 'NewsCMS',
                    component: () => import('@/views/cms/news/ViewNewsCategory'),
                    meta: {
                        requiresAuth: true,
                        roleAdmin: true,
                        roleAccess: [14, 15]
                    }
                },
                {
                    path: '/cms/news/:id',
                    name: 'NewsDetailCMS',
                    component: () => import('@/views/cms/news/ViewNewsDetail'),
                    meta: {
                        requiresAuth: false,
                        roleAdmin: true,
                        roleAccess: [14, 15]
                    }
                },
                {
                    path: '/cms/documents',
                    name: 'DocumentsCMS',
                    component: () => import('@/views/cms/documents/ViewDocumentsCategory'),
                    meta: {
                        requiresAuth: true,
                        roleAdmin: true,
                        roleAccess: [14, 15]
                    }
                },
                {
                    path: '/cms/faq',
                    name: 'FaqCMS',
                    component: () => import('@/views/cms/faq/ViewFaqCategory'),
                    meta: {
                        requiresAuth: true,
                        roleAdmin: true,
                        roleAccess: [14, 15]
                    }
                },
                {
                    path: '/cms/contacts',
                    name: 'ContactsCMS',
                    component: () => import('@/views/cms/contacts/ViewContactsCategory'),
                    meta: {
                        requiresAuth: true,
                        roleAdmin: true,
                        roleAccess: [14, 15]
                    }
                },
            ],
        },
        // Пути	ЛК и не ЛК оборачиваются шаблонами
        {
            path: '/private',
            component: () => import('@/layout/LayoutLk/LayoutLk'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'LkMain',
                    components: {
                        default: () => import('@/views/private/ViewMain'),
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    props: {
                        default: () => ({'userRole': userRole}),
                    },
                    meta: {
                        requiresAuth: true,
                        getActualOrgStatus: true,
                    }
                },
                {
                    path: 'score',
                    name: 'Lk',
                    component: () => import('@/views/private/ViewScore'),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'indicators',
                    components: {
                        default: () => import('@/views/private/indicators/viewIndicatorsRouter'),
                        nav: () => import('@/views/private/sidebars/ViewSidebar'),
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'IndicatorsList',
                            components: {
                                default: () => import('@/views/private/indicators/ViewIndicators'),
                                nav: () => import('@/views/private/sidebars/ViewSidebar'),
                            },
                            meta: {
                                requiresAuth: true,
                                getActualOrgStatus: true,
                            }
                        },
                        {
                            path: 'final-list',
                            name: 'IndicatorsFinalList',
                            components: {
                                default: () => import('@/views/private/indicators/ViewFinalIndicators'),
                                nav: () => import('@/views/private/sidebars/ViewSidebar'),
                            },
                            meta: {
                                requiresAuth: true,
                                getActualOrgStatus: true,
                            }
                        },
                        // {
                        //     path: 'appeals',
                        //     name: 'IndicatorsAppeals',
                        //     components: {
                        //         default: () => import('@/views/private/indicators/ViewAppeals'),
                        //         nav: () => import('@/views/private/sidebars/ViewSidebar'),
                        //     },
                        //     meta: {
                        //         requiresAuth: true,
                        //         getActualOrgStatus: true,
                        //     }
                        // },
                        // {
                        //     path: 'appeals/source-data-error',
                        //     name: 'SourceDataError',
                        //     components: {
                        //         default: () => import('@/views/private/indicators/ViewSourceDataError'),
                        //         nav: () => import('@/views/private/sidebars/ViewSidebar'),
                        //     },
                        //     meta: {
                        //         requiresAuth: true,
                        //         getActualOrgStatus: true,
                        //     }
                        // },
                        // {
                        //     path: 'appeals/error-calculating-indicator-value',
                        //     name: 'ErrorCalculatingIndicatorValue',
                        //     components: {
                        //         default: () => import('@/views/private/indicators/ViewErrorCalculatingIndicatorValue'),
                        //         nav: () => import('@/views/private/sidebars/ViewSidebar'),
                        //     },
                        //     meta: {
                        //         requiresAuth: true,
                        //         getActualOrgStatus: true,
                        //     }
                        // },
                        // {
                        //     path: 'appeals/error-calculating-normalized-estimate',
                        //     name: 'ErrorCalculatingNormalizedEstimate',
                        //     components: {
                        //         default: () => import('@/views/private/indicators/ViewErrorCalculatingNormalizedEstimate'),
                        //         nav: () => import('@/views/private/sidebars/ViewSidebar'),
                        //     },
                        //     meta: {
                        //         requiresAuth: true,
                        //         getActualOrgStatus: true,
                        //     }
                        // },
                        // {
                        //     path: 'appeals/error-calculation-consolidated-rating',
                        //     name: 'ErrorCalculationConsolidatedRating',
                        //     components: {
                        //         default: () => import('@/views/private/indicators/ViewErrorCalculationConsolidatedRating'),
                        //         nav: () => import('@/views/private/sidebars/ViewSidebar'),
                        //     },
                        //     meta: {
                        //         requiresAuth: true,
                        //         getActualOrgStatus: true,
                        //     }
                        // },
                        {
                            path: 'kcp',
                            name: 'IndicatorsGroups',
                            components: {
                                default: () => import('@/views/private/indicators/viewKcpGroups'),
                                nav: () => import('@/views/private/sidebars/ViewSidebar'),
                            },
                            meta: {
                                requiresAuth: true,
                                getActualOrgStatus: true,
                            }
                        },
                        {
                            path: 'kcp/correct',
                            name: 'KcpCorrect',
                            components: {
                                default: () => import('@/views/private/indicators/viewKcpCorrect'),
                                nav: () => import('@/views/private/sidebars/ViewSidebar'),
                            },
                            meta: {
                                requiresAuth: true,
                                getActualOrgStatus: true,
                            }
                        },
                        {
                            path: '',
                            components: {
                                default: () => import('@/views/private/indicators/viewKcpGroups'),
                                nav: () => import('@/views/private/sidebars/ViewSidebar'),
                            },
                            meta: {
                                requiresAuth: true,
                                getActualOrgStatus: true,
                            }
                        },
                        {
                            path: '*',
                            components: {
                                default: () => import('@/views/private/indicators/viewKcpGroups'),
                                nav: () => import('@/views/private/sidebars/ViewSidebar'),
                            },
                            meta: {
                                requiresAuth: true,
                                getActualOrgStatus: true,
                            }
                        },
                    ],
                    meta: {
                        requiresAuth: true,
                        getActualOrgStatus: true,
                    }
                },
            ]
        },
        {
            path: '/region',
            component: () => import('@/layout/LayoutLk/LayoutLk'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'okcp',
                    name: 'RegionOCKP',
                    components: {
                        default: () => import('@/views/region/stages/regionStage'),
                        nav: () => import('@/views/region/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        getRegions: true,
                    },
                },
                {
                    path: 'stage',
                    name: 'RegionStageTwo',
                    components: {
                        default: () => import('@/views/region/stages/regionStageTwo'),
                        nav: () => import('@/views/region/sidebars/ViewSidebar'),
                    },
                    meta: {
                        needContact: true,
                        requiresAuth: true,
                        getRegions: true,
                    },
                },
                {
                    path: 'contacts_co',
                    name: 'RegionContactCO',
                    components: {
                        default: () => import('@/views/region/ViewContactsCO'),
                        nav: () => import('@/views/region/sidebars/ViewSidebar'),
                    },
                    meta: {
                        requiresAuth: true,
                        getRegions: true,
                    },
                },
                // {
                //     path: 'kcp',
                //     name: 'RegionKcp',
                //     components: {
                //         default: () => import('@/views/region/ViewKcpGroups'),
                //         nav: () => import('@/views/region/sidebars/ViewSidebar'),
                //     },
                //     meta: {
                //         requiresAuth: true,
                //         getRegions: true,
                //     },
                // },
                // {
                //     path: 'kcp/orglist',
                //     name: 'RegionOrgList',
                //     components: {
                //         default: () => import('@/views/region/ViewAllOrgsList'),
                //         nav: () => import('@/views/region/sidebars/ViewSidebar'),
                //     },
                //     meta: {
                //         requiresAuth: true,
                //         getRegions: true,
                //     },
                // },
                // {
                //     path: 'kcp/orgusllist',
                //     name: 'RegionOrgUslList',
                //     components: {
                //         default: () => import('@/views/region/ViewAllOrgsUslList'),
                //         nav: () => import('@/views/region/sidebars/ViewSidebar'),
                //     },
                //     meta: {
                //         requiresAuth: true,
                //         getRegions: true,
                //     },
                // },
                // {
                //     path: 'kcp/:kcpGroupID',
                //     name: 'RegionKcpOrgList',
                //     components: {
                //         default: () => import('@/views/region/ViewRegionKcpGroupOrgList'),
                //         nav: () => import('@/views/region/sidebars/ViewSidebar'),
                //     },
                //     meta: {
                //         requiresAuth: true,
                //         getRegions: true,
                //     },
                // },
                // {
                //     path: 'kcp/:kcpGroupID/:orgID/:uslID/:foID',
                //     name: 'RegionKcpOrgIndicators',
                //     components: {
                //         default: () => import('@/views/region/ViewFinalIndicators'),
                //         nav: () => import('@/views/region/sidebars/ViewSidebar'),
                //     },
                //     meta: {
                //         requiresAuth: true,
                //         getRegions: true,
                //     },
                // },
                {
                    path: '',
                    name: 'RegionMain',
                    components: {
                        default: () => import('@/views/region/ViewMain'),
                        nav: () => import('@/views/region/sidebars/ViewSidebar'),
                    },
                    props: {
                        default: () => ({'userRole': userRole}),
                    },
                    meta: {
                        requiresAuth: true,
                        getRegions: true,
                    }
                },
            ]
        },
        // Внешняя часть сайта
        {
            path: '/',
            component: () => import('@/layout/LayoutFront/LayoutFront'),
            children: [
                {
                    path: '',
                    redirect: { name: 'LkMain' }
                    // name: 'Main',
                    // component: () => import('@/views/front/ViewMain'),
                    // meta: {
                    //     requiresAuth: false,
                    //     layout: 'LayoutFrontContentMain',
                    // }
                },
                {
                    path: '/news',
                    name: 'NewsCategory',
                    component: () => import('@/views/front/news/ViewNewsCategory'),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: '/news/:id',
                    name: 'NewsDetail',
                    components: {
                        default: () => import('@/views/front/news/ViewNewsDetail'),
                        sidebar: () => import('@/views/front/sidebars/ViewSidebarDetail'),
                    },
                    meta: {
                        requiresAuth: false,
                        layout: 'LayoutFrontContentDetail',
                    }
                },
                {
                    path: '/documents',
                    name: 'DocumentsCategory',
                    component: () => import('@/views/front/documents/ViewDocumentsCategory'),
                    meta: {
                        requiresAuth: false,
                    }
                },
                {
                    path: '/question',
                    name: 'Question',
                    component: () => import('@/views/front/ViewQuestion'),
                    meta: {
                        requiresAuth: false,
                    }
                },
                {
                    path: '/documents/:id',
                    name: 'DocumentsDetail',
                    components: {
                        default: () => import('@/views/front/documents/ViewDocumentsDetail'),
                        sidebar: () => import('@/views/front/sidebars/ViewSidebarDetail'),
                    },
                    meta: {
                        requiresAuth: false,
                        layout: 'LayoutFrontContentDetail',
                    }
                },
                {
                    path: '/faq',
                    name: 'FaqCategory',
                    component: () => import('@/views/front/faqs/ViewFaqsCategory'),
                    meta: {
                        requiresAuth: false,
                    }
                },
                {
                    path: '/faq/:id',
                    name: 'FaqDetail',
                    components: {
                        default: () => import('@/views/front/faqs/ViewFaqsDetail'),
                        sidebar: () => import('@/views/front/sidebars/ViewSidebarDetail'),
                    },
                    meta: {
                        requiresAuth: false,
                        layout: 'LayoutFrontContentDetail',
                    }
                },
                {
                    path: '/contacts',
                    name: 'Contacts',
                    component: () => import('@/views/front/contacts/ViewContacts'),
                    meta: {
                        requiresAuth: false,
                    }
                },
                {
                    path: '*',
                    name: 'notFound',
                    component: () => import('@/views/common/View404'),
                    meta: {
                        requiresAuth: false,
                    }
                },
            ]
        },
    ]
})
routes.beforeEach((to, from, next) => {
    let authStatus = store.getters['auth/authStatus'];
    if (store.getters['auth/authData']) userRole = store.getters['auth/authData'].role.id;

    // console.log(authStatus, userRole);

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // этот путь требует авторизации, проверяем залогинен ли
        // пользователь, и если нет, перенаправляем на страницу логина
        if (!authStatus) {
            next({
                path: '/lk',
                query: {redirect: to.fullPath}
            });
        } else {
            next()
        }
    } else {
        next()
    }

    //check route with include
    // console.log(userRole)
    if (to.matched.some(record => record.meta.roleAccess)) {
        // console.log(to)
        if (!to.meta.roleAccess.includes(userRole)) {
            console.log('нет дотсупа', userRole, to.meta.roleAccess);
            next({
                path: '/private',
                query: {redirect: to.fullPath}
            });
        } else {
            // console.log('есть доступ',to );
            next()
        }
    } else {
        next()
    }

    if (to.matched.some(record => record.meta.roleAdmin)) {
        // этот путь требует прав админа,
        // если нет, перенаправляем на страницу личного кабинета
        if (userRole !== 10 && userRole !== 14 && userRole !== 15) {
            console.log('не админ', userRole, to.meta.roleAccess);
            next({
                path: '/private',
                query: {redirect: to.fullPath}
            });
        } else {
            // console.log('админ');
            next()
        }
    } else {
        next()
    }
    if (to.matched.some(record => record.meta.roleAdminKCP)) {
        // этот путь требует прав админа KCP,
        // если нет, перенаправляем на страницу личного кабинета
        if (userRole !== 14) {
            // console.log('не админ');
            next({
                path: '/',
                query: {redirect: to.fullPath}
            });
        } else {
            // console.log('админ');
            next()
        }
    } else {
        next()
    }
    if (to.matched.some(record => record.meta.checkFilled) && ![4, 6, 14].includes(store.getters['auth/authData'].role.id)) {
        let filledStatus = store.getters['checkFilled/get_check_filled_status'];
        if (filledStatus === null) {
            store.dispatch('checkFilled/fetchCheckFilled', store.getters['auth/authData'])
                .then(() => {
                    if (!filledStatus) {
                        next({
                            path: '/private',
                            query: {redirect: to.fullPath}
                        });
                    } else {
                        next()
                    }
                })
        } else {
            if (!filledStatus) {
                next({
                    path: '/private',
                    query: {redirect: to.fullPath}
                });
            } else {
                next()
            }
        }
    }

    if (to.matched.some(record => record.meta.needContact)) {
        if(store.getters('checkFilled/get_check_filled') !== null){
            next({
                name: 'RegionMain',
            });
        }
        else next();
    }

    if (to.matched.some(record => record.meta.getActualOrgStatus)) {
        store.dispatch('auth/getActualOrgStatus');
        next();
    }

    if (to.matched.some(record => record.meta.getRegions)) {
        store.dispatch('region/dataForSprRegion');
        next();
    }

})
export default routes;