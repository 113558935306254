import request from "@/services/request";

export default {
    namespaced: true,
    state: {
        checkFilled: null,
    },
    mutations: {
        ['ADD_STATUS_CHECK_FILLED'](state, payload) {
            state.checkFilled = payload;
        }, 
    },
    actions: {
        setCheckFilled({commit}, payload){
            commit('ADD_STATUS_CHECK_FILLED', payload);
        },
        async fetchCheckFilled({commit}, payload) {
                let getCheckFilledOrg = await request({
                endpoint: `/api/userProfile/checkProfile?sprOrgID=${payload.sprOrgID}&userProfile=${payload.userProfile}`,
                method: 'GET',
                });
                const checkFilledOrg = getCheckFilledOrg.data;
                let getCheckFilledFiles = await request({
                endpoint: `/api/fileServer/checkMainFilesStatus?sprOrgID=${payload.sprOrgID}&userProfile=${payload.userProfile}`,
                method: 'GET',
                });
                const checkFilledFiles = getCheckFilledFiles.data;
                commit('ADD_STATUS_CHECK_FILLED', {...checkFilledOrg, 'filesCheck': checkFilledFiles});
        },
    },
    getters: {
        get_check_filled(state) {
            return state.checkFilled;
        },
        get_check_filled_status(state) {
            if (state.checkFilled) {
                // return Object.values(state.checkFilled).every(el => el === true);
                if (state.checkFilled.contactCheck && state.checkFilled.directorCheck && state.checkFilled.filesCheck) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return null
            }
            
        },
    }
}