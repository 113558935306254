<template>
  <section class="section-score">

    <div class="score-about">
      <p><b>п.1.2.1. Приложения 2 Порядка:</b></p>
      <p>Наличие профессионально-общественной аккредитации образовательных программ по соответствующим УГСН (НПС)</p>
    </div>
    <h4 class="score-subtitle">1. Значение показателя</h4>
    <div class="formula">
      <div class="formula-full"><span>M21<span class="font-size-little">0</span> =</span></div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline font-weight-regular px-3">
          количество НПС, имеющих<br>
          профессионально-общественную аккредитацию
        </div>
        <div class="formula-half font-weight-regular " style="margin-left: 15%">количество лицензированных НПС</div>
      </div>
      <div class="formula-full">=</div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.poa)"/></div>
        <div class="formula-half font-weight-regular" style="margin-left:20%"><span
            v-html="checkData(rowOfValues.data.lic)"/></div>
      </div>

      <div class="formula-full"><span><span class="font-weight-regular"> = </span> <span
          v-html="checkData(rowOfValues.data.m21b0)"/> </span></div>
    </div>
    <!-- formula -->
    <div class="score-about">
      <p>
        При оценке заявок по УГСН значение показателя определяется как доля НПС, входящих в состав соответствующей УГСН,
        по которым имеются лицензии на осуществление образовательной деятельности и профессионально-общественная
        аккредитация образовательных программ, срок действия которой истекает не ранее завершения года, в котором
        проводится конкурс, в общем числе НПС, входящих в состав соответствующей УГСН, по которым имеются лицензии на
        осуществление образовательной деятельности.
      </p>
      <p>Для филиалов образовательных организаций указанный показатель принимается равным показателю, рассчитанному для
        головной организации.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "m21",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float', null, false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style scoped>

</style>