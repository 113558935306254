<template>
	<v-container>
<div>
  <Login/>
</div>
</v-container>
</template>

<script>
import Login from "@/components/Login";
export default {
name: "LK",
  components:{
  Login
  }
}
</script>

<style scoped>

</style>