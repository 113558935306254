<template>
    <div>

        <div class="score-about">
            <p><b>п.2.1.3. Приложения 2 Порядка:</b></p>
            <p>Доля абитуриентов, поступивших по договорам об образовании, заключаемым при приеме на обучение за счет средств физического и (или) юридического лица</p>
        </div>

        <v-divider class="mt-4"/>

        <h4 class="score-subtitle">1. Значение показателя</h4>
        <h5 class="score-subsubtitle">
            Исходные данные:
        </h5>
        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.9.</p>
            <p class="calculation__about-text">
              Доля абитуриентов, поступивших по договорам о платном образовании, человек (очная форма обучения)
            </p>
        </div>
        <table class="indicators__table">
            <thead class="b24-thead">
            <tr class="">
                <th class="" rowspan="2">№ п/п</th>
                <th class="">УГСН Код</th>
                <th class="">УГСН Наименование</th>
                <th class="">Всего абитуриентов,<br> принятых на обучение на I курс</th>
                <th class="">из них абитуриентов,<br> поступивших по договорам о платном обучении</th>
            </tr>
            <tr class="">
                <th class="">1</th>
                <th class="">2</th>
                <th class="">3</th>
                <th class="">4</th>
            </tr>
            </thead>
            <tbody class="b24-tbody">
            <tr>
                <td class="">1.</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="" v-html="checkData(rowOfValues.data.zpk)"></td>
                <td class="" v-html="checkData(rowOfValues.data.mdp)"></td>
            </tr>
            <tr>
              <td colspan="3">
              </td>
              <td colspan="1" style="">
                <div ><img style="height: 20px; margin: 0; width:300px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                           alt=""/>
                </div>
              </td>
              <td colspan="1" style="">
                <div ><img style="height: 20px; margin: 0; width:300px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                           alt=""/>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
              </td>
              <td colspan="1" style="">
                <div >
                  ЗПК
                </div>
              </td>
              <td colspan="1" style="">
                <div >
                  МДП
                </div>
              </td>
            </tr>
            </tbody>
        </table>


        <!-- formula -->
        <section class="section-score">
            <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

            <div class="formula">
                <div class="formula-full"><span>M13<span class="font-size-little">0</span> =</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline">
                        МДП
                    </div>
                    <div class="formula-half">ЗПК</div>
                </div>
                <div class="formula-full">=</div>
                <div class="formula-full">
                  <div class="formula-half formula-half_underline"> <span v-html="checkData(rowOfValues.data.mdp)"></span>
                  </div>
                  <div class="formula-half"><span v-html="checkData(rowOfValues.data.zpk)"></span></div>
                </div>
                <div class="formula-full"> <div>
                  <span
                      class="font-weight-regular"> = </span><span v-html="checkData(rowOfValues.data.m13b0)"></span>
                </div>
                </div>
            </div>

            <!-- formula -->
        </section>
         <div class="calculation__about">
                <p class="calculation__about-text">
                  МДП - число абитуриентов, поступивших в 2020 году по договорам с оплатой обучения (очная форма обучения)
                </p>
                <p class="calculation__about-text">
                  ЗПК - всего абитуриентов, принятых в 2020 году на обучение на I курс (за счет всех источников финансирования, очная форма обучения)
                </p>
            </div>


    </div>
</template>

<script>
    export default {
        name: "b24",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
        methods: {
            checkData(value) {
                return value;
                // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
            }
        },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

        .b24-thead
            th
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px

                &:nth-child(7)
                    border: 0
                    min-width: 20px

                &:nth-child(8)
                    border: 0

                &:nth-child(9)
                    border: 0

            tr
                &:last-child
                    th
                        &:nth-child(6)
                            border: 0
                            text-align: right
                            padding-right: 10px
                            border-bottom: 1px solid #546e7a

                        &:nth-child(7)
                            border: 0
                            padding-left: 10px
                            border-bottom: 1px solid #546e7a

        .b24-tbody
            tr
              &:nth-child(2)
                td
                  border: 0
              &:nth-child(3)
                td
                  border: 0
            td
                text-align: center
                border: 1px solid #546e7a
                padding: 3px

                &:nth-child(7)
                    border: 0
                    min-width: 20px

                &:nth-child(8)
                    border: 0
                    padding-right: 5px
                    text-align: right

                &:nth-child(9)
                    border: 0
                    padding-left: 2px
                    text-align: left


</style>