<template>
  <div>
    <card-indicator-head
        v-model="show"
        :item="item"
        :available="availableInfo[0]"
        :typeStyle="item.typeStyle"
    />
    <transition name="fade">
      <div
          class="b-indicator__collapse"
          v-if="rowOfValues && show"
      >
        <containerIndicatorBody :item="item" :row-of-values="rowOfValues" :available="availableInfo"/>
      </div>
    </transition>
  </div>
</template>

<script>
import CardIndicatorHead from "@/components/cards/custom/indicatiors/cardIndicatorHead";
import containerIndicatorBody from "@/containers/private/indicators/containerIndicatorBody";

export default {
  name: "cardIndicator",
  components: {containerIndicatorBody, CardIndicatorHead},
  props: {
    item: {
      type: Object,
      default: () => {
        return {title: '', about: '', count: '', max: ''}
      }
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    typeStyle:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    // show(newValue, oldValue) {
    //   console.log("watcher", newValue, oldValue)
    // }
  },
  computed: {
    availableInfo(){
      let arr = new Array([false, false, false])
      // console.log("this.dataFieldName",this.dataFieldName, this.dataFieldName === 'generalValue')
      if (this.item.dataFieldName === 'generalValue')
      {
        if([1,2,3].includes(this.item.idUsl))
          return arr = [true,false,false]
        else
          return [false,false,false]
      }
      arr[0] = this.rowOfValues.data
          && Object.prototype.hasOwnProperty.call(this.rowOfValues.data, this.item.dataFieldName + `s${0}`)
          && (this.rowOfValues.data[this.item.dataFieldName + `s${0}`] === '1' || this.rowOfValues.data[this.item.dataFieldName + `s${0}`] === 1)
          && (this.rowOfValues.data[this.item.dataFieldName + `s${0}b`] === '1' || this.rowOfValues.data[this.item.dataFieldName + `s${0}b`] === 1)
      for( let i = 1; i< 3; i++){
        arr[i] = this.rowOfValues.data
            && Object.prototype.hasOwnProperty.call(this.rowOfValues.data, this.item.dataFieldName + `s${i}`)
            && (this.rowOfValues.data[this.item.dataFieldName + `s${i}`] === '1' || this.rowOfValues.data[this.item.dataFieldName + `s${i}`] === 1)
      }
      return arr
    }
  },
}
</script>

<style lang="sass" scoped>
.fade-enter-active, .fade-leave-active
  transition: 0.5s
.fade-enter, .fade-leave-to
  opacity: 0

</style>