import Vue from 'vue';
import App from "./App";
import router from './router'
import Vuetify from 'vuetify'
import VueMeta from 'vue-meta'
import Scripts from './utils';
import UUID from "vue-uuid";
import store from './store/index'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)
Vue.use(Vuetify);
Vue.use(VueMeta);
Vue.use(UUID);
Vue.prototype.$scripts = Scripts;
Vue.config.productionTip = false;

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '../src/assets/styles/index.sass'

new Vue({
  router,
  store,
  vuetify: new Vuetify(
      {
        icons: {
          iconfont: 'mdi', // default - only for display purposes
        },
          theme:{
            themes:{
                light:{
                    primary: '#00599B',
                    secondary: '#0292e1',
                    accent: '#82B1FF',
                    error: '#ED393E',
                    info: '#618AA7',
                    success: '#29A128',
                    warning: '#D4990A',
                }
            }
          }
      }
  ),
  render: h => h(App),
}).$mount('#app')
