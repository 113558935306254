<template>
	<div class="list" :class="[
        direction === 'column' ? 'list_column' : '',
        {'list_wrap' : wrap}, customClass
    ]">
		<slot></slot>
	</div>
</template>


<script>
	export default {
        props: {
            direction: {
                type: String,
                default: 'row'
            },
            wrap: {
                type: Boolean,
                default: true
            },
            customClass: {
                type: String,
            }
        }
	}
</script>

<style lang="sass">
.list 
    display: flex
    justify-content: space-around
.list_column
    flex-direction: column
.list_wrap
    flex-wrap: wrap
</style>