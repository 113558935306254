<template>
	<div class="navigation-list">
		<NavigationItemViewColumn v-for="(item, index) in data" :key="index" :item="item"/>
	</div>
</template>

<script>
import NavigationItemViewColumn from '@/components/navigation/viewColumn/NavigationItemViewColumn'; 
export default {
	components: {
		NavigationItemViewColumn
	},
	props: {
		data: {
			type: Array,
		}
	}
}
	
</script>


