<template>
<div>
	<HeaderMain
		title="Новости"
	/>


	<div class="preview-news">
		<div class="preview-news" v-if="news">
			<List direction="column">
				<CardNewsRectangle v-for="item in news.data" :key="item.key" :item="item"/>
			</List>
			<BPaginator
				:propDataCount = "news.counter"
				v-model="paginatorParams"
				@input="getNewsList"
			/>
		</div>
		<div v-else>Загрузка...</div>
	</div>
</div>	
</template>


<script>
import request from "@/services/request";
import List from '@/components/lists/List';
import HeaderMain from '@/components/headers/HeaderMain';
import ModelNewsPreview from "@/services/models/ModelNewsPreview";
import CardNewsRectangle from "@/components/cards/custom/CardNews/CardNewsRectangle";
import BPaginator from '@/components/paginator/BPaginator/BPaginator';
export default {
	components: {
		BPaginator,
		CardNewsRectangle,
		List,
		HeaderMain
		},
	data() {
		return {
		paginatorParams: {'selectedPage' : 1, 'itemsPerPage' : 10},
		news: null,
		}
	},
    mounted() {
      this.getNewsList();
    },
	methods: {
		async getNewsList() {
			this.news = await request({
			endpoint: '/api/mainPage/getAllArticles',
			method: 'POST',
			body: {'pageNumber': this.paginatorParams.selectedPage,'recordCount' : this.paginatorParams.itemsPerPage-1},
			path: 'articles',
			model: ModelNewsPreview,
			});
		}
	},
}	
</script>
<style lang="sass">


</style>