<template>
  <div>

    <div class="score-about">
      <p><b>п.1.2.5. Приложения 2 Порядка:</b></p>
      <p>Соотношение приведенной численности лиц, прошедших обучение в образовательной организации по дополнительным профессиональным программам, и приведенного контингента студентов образовательной организации</p>
    </div>

    <v-divider class="mt-4"/>

    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about">
      <p class="calculation__about-text">Форма 5.20.</p>
      <p class="calculation__about-text">
        Программы повышения квалификации – численность прошедших обучение (за счет всех источников финансирования)
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b24-thead">
      <tr class="">
        <th class="" rowspan="2">Виды экономической деятельности,<br> соответствующие рассматриваемой<br> УГСН(НПС)</th>
        <th class="" rowspan="2">Форма обучения</th>
        <th class="">Повышение<br> квалификации</th>
        <th class="">Профессиональная<br> переподготовка</th>
        <th rowspan="3"></th>
        <th rowspan="2" class="text-center" colspan="2" style="vertical-align: bottom">Расчет исходных значений</th>
      </tr>
      <tr>
        <th> 2019 год</th>
        <th> 2019 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">переменная=значение</th>
        <th class="">переменная=значение</th>
      </tr>
      </thead>
      <tbody class="b24-tbody">
      <tr>
        <td colspan="7" style="border: 0">
          <div style="white-space: break-spaces; text-align: left; width:950px;">{{ rowOfValues.data.sprOrg.orgName }}</div>
        </td>
      </tr>
      <tr>
        <td class="" style="white-space: break-spaces; text-align: left; width:450px;vertical-align: middle"  rowspan="3">{{ rowOfValues.data.okved }}</td>
        <td class="">очная</td>
        <td class="">{{ rowOfValues.data.kpo }}</td>
        <td class="">{{ rowOfValues.data.ppo }}</td>
        <td>=></td>
        <td class=""><span class="font-weight-bold">КПо</span> = {{ rowOfValues.data.kpo }}</td>
        <td class=""><span class="font-weight-bold">ППо</span> = {{ rowOfValues.data.ppo }}</td>
      </tr>
      <tr>
        <!--                <td class="">{{rowOfValues.data.okved}}</td>-->
        <td class="">очно-заочная</td>
        <td class="">{{ rowOfValues.data.kpv }}</td>
        <td class="">{{ rowOfValues.data.ppv }}</td>
        <td>=></td>
        <td class=""><span class="font-weight-bold">КПоз</span> = {{ rowOfValues.data.kpv }}</td>
        <td class=""><span class="font-weight-bold">ППоз</span> = {{ rowOfValues.data.ppv }}</td>
      </tr>
      <tr>
        <!--                <td class="">{{rowOfValues.data.okved}}</td>-->
        <td class="">заочная</td>
        <td class="">{{ rowOfValues.data.kpz }}</td>
        <td class="">{{ rowOfValues.data.ppz }}</td>
        <td>=></td>
        <td class=""><span class="font-weight-bold">КПз</span> = {{ rowOfValues.data.kpz }}</td>
        <td class=""><span class="font-weight-bold">ППз</span> = {{ rowOfValues.data.ppz }}</td>
      </tr>
      </tbody>
    </table>
    <p class="score-about">Соответствие видов экономической деятельности и УГСН (НПС) установлено в п.1.2.5 Приложения 2
      Порядка проведения конкурса на распределение контрольных цифр приема.
    </p>

    <div class="calculation__about">
      <p class="calculation__about-text">Форма 5.3.</p>
      <p class="calculation__about-text">
        Численность обучающихся за счет всех источников финансирования, человек
      </p>


      <table class="indicators__table">
        <thead class="b53-thead">
        <tr class="">
          <th class="">Коды УГСН, соответствующие<br> видам экономической деятельности</th>
          <th class="">Уровень</th>
          <th class="">Форма обучения</th>
          <th class="">Численность обучающихся<br> 2020 год</th>
          <th rowspan="2" style="min-width: 20px;"></th>
          <th class="" colspan="2" style="vertical-align: middle">Расчет исходных значений</th>
        </tr>
        <tr class="">
          <th class="">1</th>
          <th class="">2</th>
          <th class="">3</th>
          <th class="">4</th>
          <th class="">переменная</th>
          <th class="">значение</th>
        </tr>
        </thead>
        <tbody class="b53-tbody">
        <tr>
          <td colspan="7">
            <div style="white-space: break-spaces; width:950px;">{{ rowOfValues.data.sprOrg.orgName }}</div>
          </td>
        </tr>
        <tr>
          <td class="" rowspan="11" style="vertical-align: middle">{{ rowOfValues.data.gug }}</td>
          <td class="">Бакалавриат</td>
          <td class="">Очная</td>
          <td class="">{{ rowOfValues.data.gnbo }}</td>
          <td rowspan="3" style="vertical-align: middle">
            <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
                 alt=""/>
          </td>
          <td class="" rowspan="3" style="vertical-align: middle"><span class="font-weight-bold" >NO</span> =</td>
          <td class="" rowspan="3" style="vertical-align: middle" v-html="checkData(rowOfValues.data.gno)"> {{ }}</td>
        </tr>
        <tr>
          <td class="">Специалитет</td>
          <td class="">Очная</td>
          <td class="">{{ rowOfValues.data.gnso }}</td>

        </tr>
        <tr>

          <td class="">Магистратура</td>
          <td class="">Очная</td>
          <td class="">{{ rowOfValues.data.gnmo }}</td>
        </tr>
        <tr>
          <td class="">Бакалавриат</td>
          <td class="">Очно-заочная</td>
          <td class="">{{ rowOfValues.data.gnbv }}</td>
          <td rowspan="3" style="vertical-align: middle">
            <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
                 alt=""/>
          </td>
          <td class="" rowspan="3" style="vertical-align: middle"><span class="font-weight-bold">NV</span> =</td>
          <td class="" rowspan="3" style="vertical-align: middle" v-html="checkData(rowOfValues.data.gnv)"> {{ }}</td>
        </tr>
        <tr>
          <!--                <td class="">{{rowOfValues.data.gug}}</td>-->
          <td class="">Специалитет</td>
          <td class="">Очно-заочная</td>
          <td class="">{{ rowOfValues.data.gnsv }}</td>

        </tr>
        <tr>
          <!--                <td class="">{{rowOfValues.data.gug}}</td>-->
          <td class="">Магистратура</td>
          <td class="">Очно-заочная</td>
          <td class="">{{ rowOfValues.data.gnmv }}</td>

        </tr>
        <tr>
          <!--                <td class="">{{rowOfValues.data.gug}}</td>-->
          <td class="">Бакалавриат</td>
          <td class="">Заочная</td>
          <td class="">{{ rowOfValues.data.gnbz }}</td>
          <td rowspan="3" style="vertical-align: middle">
            <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
                 alt=""/>
          </td>
          <td class="" rowspan="3" style="vertical-align: middle" ><span class="font-weight-bold">NZ</span> =</td>
          <td class="" rowspan="3" style="vertical-align: middle" v-html="checkData(rowOfValues.data.gnz)"> {{ }}</td>
        </tr>
        <tr>
          <!--                <td class="">{{rowOfValues.data.gug}}</td>-->
          <td class="">Специалитет</td>
          <td class="">Заочная</td>
          <td class="">{{ rowOfValues.data.gnsz }}</td>

        </tr>
        <tr>
          <!--                <td class="">{{rowOfValues.data.gug}}</td>-->
          <td class="">Магистратура</td>
          <td class="">Заочная</td>
          <td class="">{{ rowOfValues.data.gnmz }}</td>

        </tr>
        <tr>
          <!--                <td class="">{{rowOfValues.data.gug}}</td>-->
          <td class="">Ординатура</td>
          <td class="">Очная</td>
          <td class="">{{ rowOfValues.data.gopc }}</td>
          <td rowspan="2" style="vertical-align: middle">
            <img style="height: 51px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
                 alt=""/>
          </td>
          <td class="" rowspan="2" style="vertical-align: middle"><span class="font-weight-bold">NOA</span> =</td>
          <td class="" rowspan="2" style="vertical-align: middle" v-html="checkData(rowOfValues.data.gnoa)"> {{ }}</td>
        </tr>
        <tr>
          <td class="">Ассистентура</td>
          <td class="">Очная</td>
          <td class="">{{ rowOfValues.data.gacc }}</td>
        </tr>
        </tbody>
      </table>


    </div>

    <section class="section-score">
      <h5 class="score-subsubtitle">
        Расчет приведенных контингентов по корреспондирующим УГСН по данным 2020
        года:
      </h5>

      <div class="calculation">
        <div class="calculation__row">
          <div class="calculation__cell">ПКП</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span class="font-weight-regular"> 1.0 ×</span> КПо</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> КПо</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> КПз</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> {{
              rowOfValues.data.kpo
            }}
          </div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> {{
              rowOfValues.data.kpv
            }}
          </div>
          <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> {{
              rowOfValues.data.kpz
            }}
          </div>
          <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell">{{ rowOfValues.data.pkp }}</div>
        </div>

        <div class="calculation__row">
          <div class="calculation__cell">ППП</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span class="font-weight-regular">1.0 × </span>ППо</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> ППоз</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> ППз</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> {{
              rowOfValues.data.ppo
            }}
          </div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.25 × </span>{{
              rowOfValues.data.ppv
            }}
          </div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> {{
              rowOfValues.data.ppz
            }}
          </div>
          <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell">{{ rowOfValues.data.ppp }}</div>
        </div>

        <div class="calculation__row">
          <div class="calculation__cell">NP</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> NO</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> NV</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> NZ</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> {{
              rowOfValues.data.gno
            }}
          </div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.25 × </span>{{
              rowOfValues.data.gnv
            }}
          </div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> {{
              rowOfValues.data.gnz
            }}
          </div>
          <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell">{{ rowOfValues.data.gnp }}</div>
        </div>
      </div>

      <div class="calculation__about">
        <p class="calculation__about-text">
          ПКП - приведенный контингент обучившихся по программам повышения  квалификации
        </p>
        <p class="calculation__about-text">
          ППП - приведенный контингент обучившихся по программам профессиональной переподготовки
        </p>
        <p class="calculation__about-text">
          NP - приведенный контингент обучающихся по программам бакалавриата, специалитета и магистратуры
        </p>
        <p class="calculation__about-text">
          NOA - численность обучающихся по программам ординатуры и ассистентуры-стажировки
        </p>
      </div>
    </section>

    <!-- calculation -->

    <!-- formula -->
    <section class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>Б25<span class="font-size-little__bottom">0</span> =</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3"><span class="font-weight-regular"> 0,25 </span>
            <span class="font-weight-regular"> × </span>
            ПКП
            <span class="font-weight-regular"> + </span>
            ППП
          </div>
          <div class="formula-half" style="margin-left: 35%">NP + NOA</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3"><span class="font-weight-regular"> 0,25 </span><span
              class="font-weight-regular"> × </span><span v-html="checkData(rowOfValues.data.pkp)"></span> <span
              class="font-weight-regular"> + </span><span v-html="checkData(rowOfValues.data.ppp)"></span>
          </div>
          <div class="formula-half" style="margin-left: 35%"><span v-html="checkData(rowOfValues.data.gnp)"></span> +
            <span v-html="checkData(rowOfValues.data.gnoa)"></span></div>
        </div>
        <div class="formula-full">
          <div>
                  <span
                      class="font-weight-regular"> = </span><span v-html="checkData(rowOfValues.data.b25b0)"></span>
          </div>
        </div>
      </div>
      <div class="calculation__about">
        <p class="calculation__about-text">
          Если сумма значений показателей ПКП и ППП больше нуля, а значение показателя NP равно нулю, значение показателя принимается равным 1.
        </p>
      </div>

    </section>

  </div>
</template>

<script>
export default {
  name: "b24",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  //position: relative
  //display: block
  //border-radius: 5px
  width: 100%

  .b24-thead
    th
      background-color: inherit
      border: 1px solid #546e7a
      padding: 5px

      &:nth-child(5)
        border: 0
        min-width: 20px

      &:nth-child(6)
        border: 0

      &:nth-child(7)
        border: 0

    tr
      &:last-child
        th
          &:nth-child(5)
            border: 0
            text-align: right
            padding-right: 10px
            border-bottom: 1px solid #546e7a

          &:nth-child(6)
            border: 0
            padding-left: 10px
            border-bottom: 1px solid #546e7a
  .b53-thead
    th
      background-color: inherit
      border: 1px solid #546e7a
      padding: 5px

      &:nth-child(5)
        border: 0
        min-width: 20px

      &:nth-child(6)
        border: 0

      &:nth-child(7)
        border: 0
    tr
      &:last-child
        th
          &:nth-child(5)
            border: 0
            text-align: right
            padding-right: 10px
            border-bottom: 1px solid #546e7a

          &:nth-child(6)
            border: 0
            padding-left: 10px
            border-bottom: 1px solid #546e7a
  .b24-tbody
    tr
      &:nth-child(2)
        td
          &:nth-child(4)
            border: 1px solid #546e7a
            min-width: 20px
    td
      text-align: center
      border: 1px solid #546e7a
      padding: 3px
      &:nth-child(4)
        border: 0
        min-width: 20px
      &:nth-child(5)
        border: 0
        min-width: 20px

      &:nth-child(6)
        border: 0
        padding-right: 5px
        //text-align: right

      &:nth-child(7)
        border: 0
        padding-left: 2px
        //text-align: left
  .b53-tbody
    tr
      &:nth-child(2)
        td
          text-align: center
          border: 1px solid #546e7a
          padding: 3px
          &:nth-child(5)
            border: 0
            min-width: 20px
          &:nth-child(6)
            border: 0
            padding-right: 5px
            text-align: right

          &:nth-child(7)
            border: 0
            padding-left: 2px
            text-align: left
      &:nth-child(n+3)
        td
          text-align: center
          border: 1px solid #546e7a
          padding: 3px

          &:nth-child(4)
            border: 0
            min-width: 20px

          &:nth-child(5)
            border: 0
            padding-right: 5px
            text-align: right

          &:nth-child(6)
            border: 0
            padding-left: 2px
            text-align: left

  .grid-container__header
    display: grid
    //grid-template-rows: 1fr 1fr
    div
      border: 1px solid black
      text-align: center
    //grid-auto-rows: 50px
    grid-gap: 3px
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

    .grid-row-span-2
      grid-column: 1
      grid-row: 1/3

    &_inner
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 2px
</style>