<template>
<div>
<ViewMainRoleOrganization v-if="$attrs.userRole === 1 || get_replace_role_id_admin===1" />
<ViewMainRoleFounder v-else-if="$attrs.userRole === 4 || $attrs.userRole ===6 || get_replace_role_id_admin===4 || get_replace_role_id_admin===6" />
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import ViewMainRoleOrganization from '@/views/private/main/ViewMainRoleOrganization';
// import ViewMainRoleAdmin from '@/views/private/main/ViewMainRoleAdmin';
import ViewMainRoleFounder from '@/views/private/main/ViewMainRoleFounder';
export default {
	components: {
		ViewMainRoleOrganization,
		// ViewMainRoleAdmin,
    ViewMainRoleFounder
	},
	computed: {
		...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
	},
	data() {
			return {
				sectionMenu: [
				{url: '/license', name: 'Лицензированные НПС/УГНС', id: 1},
				{url: '/accreditation', name: 'Государственная аккредитация НПС/УГНС', id: 2},
				{url: '/poa', name: 'Профессионально-общественная аккредитация', id: 3},
				{url: '/structure', name: 'Реестр НПС/УГНС, состав заявки', id: 4},
				{url: '/kcp', name: 'Предложения по КЦП', id: 5},
				{url: '/indicators', name: 'Показатели деятельности организации', id: 6},
				{url: '/print', name: 'Печать конкурсной заявки', id: 7},				
			],
		}
	},
}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"
.section-preview_lk
	background: #fff
	border-radius: 10px
	border: 1px solid #cdd1d4
	margin-bottom: 20px
.lk-list_flex_start
	justify-content: flex-start
</style>