import Api from "@/utils/Api";
import router from '@/router'
import request from "@/services/request";
import jwt_decode from "jwt-decode"; //https://github.com/auth0/jwt-decode

export default {
    namespaced: true,
    state: {
        status: false,
        user: null,
        loading: false,
        accessToken: null,
        refreshToken: null,
        error: null,
        blockedEnterData: false,
        blockedLK: false,
        blockedAll: false,
        blockedAdmin: false,
        blockLoading: false,
        replaceIdAdmin: null,
        replaceIdTypeOrg: null,
        replaceRoleIdAdmin: null,
        actualOrgStatus: null,
    },
    actions: {
        login({commit, dispatch}, payload) {
            commit('LOADING', true)
            Api.login(payload)
                .then(data => {
                    let decodeToken = jwt_decode(data.data.accessToken)
                    console.log(decodeToken)
                    commit('ERROR', null);
                    commit('AUTHENTICATION_SUCCESS', {
                        'user': decodeToken,
                        'token': data.data.accessToken,
                        'status': true
                    });
                    console.log(decodeToken, decodeToken.role.id, decodeToken.userProfile)
                    if (![10, 14, 15].includes(decodeToken.role.id)) // 9
                    {
                        commit('REPLACE_ROLE_ID_ADMIN', decodeToken.role.id)
                        if ([4, 6].includes(decodeToken.role.id)) {
                            commit('REPLACE_ID_ADMIN', decodeToken.sprOrgFounderID)
                            dispatch('tables/getDataForHeaders', {}, {root: true})
                        } else if (decodeToken.role.id === 1) {
                            commit('REPLACE_ID_ADMIN', decodeToken.sprOrgID)
                            dispatch('tables/getDataForHeaders', {}, {root: true})
                        } else if (decodeToken.role.id === 9) {
                            commit('REPLACE_ID_ADMIN', decodeToken.userProfile)
                        }
                        if (![4, 6, 9].includes(decodeToken.role.id)) {
                            commit('REPLACE_ID_TYPE_ORG_ADMIN', decodeToken.sprOrgTypeID.orgTypeID)
                            dispatch('tables/getDataForHeaders', {}, {root: true})
                        }
                    }
                    dispatch('spr/dataForSpr', {}, {root: true})
                    dispatch('notifications/addNotification', {
                        notificationStatus: 'success',
                        notificationMessage: 'Вы вошли в систему',
                        timeout: 5000
                    }, {root: true})
                })
                .catch(error => {
                    commit('ERROR', error);
                    dispatch('notifications/addNotification', {
                        notificationStatus: 'error',
                        notificationMessage: 'Проверьте свои учетные данные, логин или пароль ',
                        timeout: 5000
                    }, {root: true})
                })
            commit('LOADING', false)
        },
        replaceIdAdmin({commit}, payload) {
            commit('REPLACE_ID_ADMIN', payload.orgID);
            commit('REPLACE_ID_TYPE_ORG_ADMIN', payload.sprOrgType);
            commit('REPLACE_ROLE_ID_ADMIN', payload.role)
        },
        replaceFounderIdAdmin({commit}, payload) {
            commit('REPLACE_ID_ADMIN', payload.orgFounderID);
            commit('REPLACE_ROLE_ID_ADMIN', payload.role)
        },
        replaceRegionIdAdmin({commit}, payload) {
            commit('REPLACE_ID_ADMIN', payload.regionID);
            commit('REPLACE_ROLE_ID_ADMIN', payload.role)
        },
        logout({commit}) {
            commit('LOG_OUT');
            localStorage.removeItem('auth');
            router.push('/lk');
            commit('LOADING', false)
        },
        async blockEnter({dispatch}, payload) {
            if (!payload.status) {
                const blockEnter = await Api.blockEnter(payload.sprOrgId);
                if (blockEnter.status === 200) {
                    dispatch('getActualOrgStatus');
                }
            } else {
                const unblockEnter = await Api.unblockEnter(payload.sprOrgId);
                if (unblockEnter.status === 200) {
                    dispatch('getActualOrgStatus');
                }
            }
        },
        async getActualOrgStatus({commit, getters, state}) {
            if (state.replaceRoleIdAdmin === 4) {
                const res = await request({
                    endpoint: `/api/stageData/blockstatus?founderId=${state.replaceIdAdmin}&stage=3`,
                    method: 'get'
                })
                if (res.status === 200) {
                    commit('BLOCK_USER_DATA', res.data.block ?? false);
                    commit('BLOCK_ALL_USER', res.data.block ?? false);
                }
            } else if (state.replaceRoleIdAdmin === 9) {
                const res = await request({
                    endpoint: `/api/stageData/blockstatus?founderId=${state.replaceIdAdmin}&stage=2`,
                    method: 'get'
                })
                if (res.status === 200) {
                    commit('BLOCK_USER_DATA', res.data.block ?? false);
                    commit('BLOCK_ALL_USER', res.data.block ?? false);
                }
            } else {
                let status = await Api.requestActualOrgStatus(getters.authData);
                status = status.data.orgBlockStatus;

                if (status === 'ACTIVATED') {
                    commit('BLOCK_USER_DATA', false);
                    commit('BLOCK_ALL_USER', false);
                } else if (status === 'BLOCKED_PRINT_READY') {
                    commit('BLOCK_USER_DATA', true);
                    commit('BLOCK_ALL_USER', false);
                } else if (status === 'BLOCKED_AFTER_SEND') {
                    commit('BLOCK_USER_DATA', true);
                    commit('BLOCK_ALL_USER', true);
                }
            }
        },
        async getActualRegionStatus({commit, getters, state}) {
            if (state.replaceRoleIdAdmin === 4) {
                const res = await request({
                    endpoint: `/api/stageData/blockstatus?founderId=${state.replaceIdAdmin}&stage=1`,
                    method: 'get'
                });
                if (res.status === 200) {
                    commit('BLOCK_USER_DATA', res.data.block ?? false);
                    commit('BLOCK_ALL_USER', res.data.block ?? false);
                }
            } else if (state.replaceRoleIdAdmin === 9) {
                const res = await request({
                    endpoint: `/api/stageData/blockstatus?founderId=${state.replaceIdAdmin}&stage=2`,
                    method: 'get'
                });
                if (res.status === 200) {
                    commit('BLOCK_USER_DATA', res.data.block ?? false);
                    commit('BLOCK_ALL_USER', res.data.block ?? false);
                }
            } else {
                let status = await Api.requestActualOrgStatus(getters.authData);
                status = status.data.orgBlockStatus;

                if (status === 'ACTIVATED') {
                    commit('BLOCK_USER_DATA', false);
                    commit('BLOCK_ALL_USER', false);
                } else if (status === 'BLOCKED_PRINT_READY') {
                    commit('BLOCK_USER_DATA', true);
                    commit('BLOCK_ALL_USER', false);
                } else if (status === 'BLOCKED_AFTER_SEND') {
                    commit('BLOCK_USER_DATA', true);
                    commit('BLOCK_ALL_USER', true);
                }
            }
        }
    },
    mutations: {
        ['AUTHENTICATION_SUCCESS'](state, payload) {
            state.status = payload.status;
            state.accessToken = payload.token;
            state.user = payload.user;
        },
        ['LOG_OUT'](state) {
            state.status = false;
            state.user = null;
            state.error = null;
            state.accessToken = null;
        },
        ['ERROR'](state, payload) {
            state.error = payload;
            state.status = false;
        },
        ['LOADING'](state, payload) {
            state.loading = payload
        },
        ['BLOCK_USER_DATA'](state, payload) {
            state.blockedEnterData = payload
        },
        ['BLOCK_LK_USER'](state, payload) {
            state.blockedLK = payload
        },
        ['BLOCK_ALL_USER'](state, payload) {
            state.blockedAll = payload
        },
        ['BLOCK_ADMIN'](state, payload) {
            state.blockedAdmin = payload
        },
        ['BLOCK_LOADING'](state, payload) {
            state.blockLoading = payload
        },
        ['REPLACE_ID_ADMIN'](state, payload) {
            state.replaceIdAdmin = payload
        },
        ['REPLACE_ID_TYPE_ORG_ADMIN'](state, payload) {
            state.replaceIdTypeOrg = payload
        },
        ['REPLACE_ROLE_ID_ADMIN'](state, payload) {
            state.replaceRoleIdAdmin = payload
        },
        ['ACTUAL_ORG_STATUS'](state, payload) {
            state.actualOrgStatus = payload
        }
    },
    getters: {
        authData(state) {
            return state.user
        },
        authToken(state) {
            return state.accessToken
        },
        authStatus(state) {
            return state.status;
        },
        get_actual_org_status(state) {
            return state.actualOrgStatus;
        },
        get_blockedEnterData(state) {
            if (state.user.userProfile===0) return true
            return state.blockedEnterData
        },
        get_blockedLK(state) {
            if (state.user.userProfile===0) return true
            return state.blockedLK
        },
        get_blockedAll(state) {
            if (state.user.userProfile===0) return true
            return state.blockedAll
        },
        get_blockedAdmin(state) {
            if (state.user.userProfile===0) return true
            return state.blockedAdmin
        },
        get_blockedLoading(state) {
            return state.blockedLoading
        },
        get_replace_id_admin(state) {
            return state.replaceIdAdmin
        },
        get_replace_role_id_admin(state) {
            return state.replaceRoleIdAdmin
        },
        get_replace_id_type_org_admin(state) {
            return state.replaceIdTypeOrg
        },
    },
}
