<template>
  <div class="nav_lk-sidebar"
       v-if="get_check_filled_status !== null || ( get_replace_role_id_admin===4 ||  get_replace_role_id_admin===6)">


    <div class="nav-lk__section"
         v-if="authData && authData.role!= null && get_replace_role_id_admin !== 6">
      <div class="nav-lk__item">
        <img src="@/assets/img/icon-nav-lk.svg" class="nav-lk__icon icon_nav-lk">
        <router-link :to="{name: 'LkMain'}" class="nav-lk__main-link">Личный кабинет</router-link>
      </div>
    </div>
    <div class="nav-lk__section" v-if="this.get_replace_id_admin === 39">
<!--         v-if="authData && authData.role!= null && get_replace_role_id_admin !== 4 && get_replace_role_id_admin !== 6">-->
      <div class="nav-lk__item">
        <img src="@/assets/img/icon-nav-indicators.svg" class="nav-lk__icon icon_nav-lk">
        <router-link :to="{name: 'IndicatorsGroups', query: {prior: false}}" class="nav-lk__main-link">Расчёт
          оценок
        </router-link>
      </div>
    </div>
    <div class="nav-lk__section" v-if="this.get_replace_id_admin === 39">
<!--         v-if="authData && authData.role!= null && get_replace_role_id_admin !== 4 && get_replace_role_id_admin !== 6">-->
      <div class="nav-lk__item">
        <img src="@/assets/img/icon-nav-other.svg" class="nav-lk__icon icon_nav-lk">
        <router-link :to="{name: 'IndicatorsGroups', query: {prior: true}}" class="nav-lk__main-link">Итоговое
          распределение КЦП
        </router-link>
      </div>
      <!--            <div class="nav-lk__under-items"-->
      <!--                 v-if="authData && authData.role!= null && (authData.role.id ===14 || (authData.kcpADV && authData.kcpADV === true )) && ( get_replace_role_id_admin!==4 || get_replace_role_id_admin!==6)">-->
      <!--                <router-link-->
      <!--                        :to="{name: 'KcpCorrect'}"-->
      <!--                        class="nav-lk__under-link"-->
      <!--                        :style="!get_check_filled_status ? 'color: #6B7C85' : '' ">-->
      <!--                    <img src="@/assets/img/icon-nav-link.svg" class="nav-lk__under-icon icon_under-items">-->
      <!--                    Согласование предельных объемов увеличения предложений по установлению КЦП (заявок)-->
      <!--                </router-link>-->
      <!--            </div>-->
    </div>
    <!--        <div class="nav-lk__section">-->
    <!--            <div class="nav-lk__item">-->
    <!--                <img src="@/assets/img/icon-nav-other.svg" class="nav-lk__icon icon_nav-lk">-->
    <!--                <router-link :to="{name: 'IndicatorsAppeals'}" class="nav-lk__main-link">Формы апелляций</router-link>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="nav-lk__section" v-if="false">
      <div class="nav-lk__item"   v-if="authData && authData.role!= null && get_replace_role_id_admin !== 4 && get_replace_role_id_admin !== 6">
        <img src="@/assets/img/icon-nav-other.svg" class="nav-lk__icon icon_nav-lk">
        <router-link

            to="/kcp_contest"
            class="nav-lk__main-link"
            :style="!get_check_filled_status ? 'color: #6B7C85' : '' ">
          <v-icon v-if="!get_check_filled_status" :size="15" color="#6B7C85" class="fr-topbar__icon">
            mdi-lock
          </v-icon>
          Конкурс КЦП
        </router-link>
      </div>
      <div class="nav-lk__item" v-else>
        <img src="@/assets/img/icon-nav-other.svg" class="nav-lk__icon icon_nav-lk">
        <router-link
            v-if="authData && authData.role!= null &&  ( get_replace_role_id_admin===4 || get_replace_role_id_admin===6)"
            to="/kcp_contest"
            class="nav-lk__under-link"
            :style="!get_check_filled_status ? 'color: #6B7C85' : '' ">
          Неакредитованные НПС/УГНС
        </router-link>
      </div>
      <div class="nav-lk__under-items">

        <div v-for="(navItem) in navListAfterCheckOnDeploy"
             :key="navItem.link"
             class="nav-lk__under-item"
        >
          <v-icon v-if="!get_check_filled_status  && get_replace_role_id_admin !== 4 && get_replace_role_id_admin !== 6"
                  :size="15" color="#6B7C85" class="fr-topbar__icon">mdi-lock
          </v-icon>
          <img src="@/assets/img/icon-nav-link.svg" class="nav-lk__under-icon icon_under-items">
          <router-link
              class="nav-lk__under-link"
              :to="navItem.link"
              :style="currentPath === navItem.link ? 'color: #6B7C85;' : '' "
          >
            {{ navItem.text }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="nav-lk__section">
      <div class="nav-lk__item" v-if="authData && authData.role!= null &&  ( get_replace_role_id_admin===4 || get_replace_role_id_admin===6)">
        <img src="@/assets/img/icon-nav-other.svg" class="nav-lk__icon icon_nav-lk">
        <router-link
            v-if="authData && authData.role!= null &&  ( get_replace_role_id_admin===4 || get_replace_role_id_admin===6)"
            to="/kcp_contest/stages"
            class="nav-lk__main-link">
          ИАС «Установление объемов КЦП-ВО-2023»
        </router-link>
      </div>
      <div class="nav-lk__under-items">

        <div v-for="(navItem) in navListAfterCheckOnDeploy"
             :key="navItem.link"
             class="nav-lk__under-item"
        >
          <v-icon v-if="!get_check_filled_status  && get_replace_role_id_admin !== 4 && get_replace_role_id_admin !== 6"
                  :size="15" color="#6B7C85" class="fr-topbar__icon">mdi-lock
          </v-icon>
          <img src="@/assets/img/icon-nav-link.svg" class="nav-lk__under-icon icon_under-items">
          <router-link
              class="nav-lk__under-link"
              :to="navItem.link"
              :style="currentPath === navItem.link ? 'color: #6B7C85;' : '' "
          >
            {{ navItem.text }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="nav-lk__section d-flex justify-end ">
      <div class="nav-lk__item" v-if="authData && authData.role!= null &&  ( get_replace_role_id_admin===4 || get_replace_role_id_admin===6)">
        <div  class="nav-lk__under-icon icon_under-items"/>
        <a
            v-if="authData && authData.role!= null &&  ( get_replace_role_id_admin===4 || get_replace_role_id_admin===6)"
            href="	http://files.ined.ru/adminpanel/cms_article_editor_1_66666_225aa741de930bba203d459ba19d39fc.pdf"
            target="_blank"
            class="nav-lk__main-link">
          Инструкция
        </a>
      </div>
      </div>

    <!-- <div v-for="(navItem, indexJ) in navListIndicators"
         :key="indexJ"
         class="nav-item_lk-sidebar"
    >
      <v-icon v-if="!get_check_filled_status  && get_replace_role_id_admin !== 4 && get_replace_role_id_admin !== 6" :size="15" color="#6B7C85" class="fr-topbar__icon">mdi-lock</v-icon>
      <router-link
          class="nav-link_lk-sidebar nav-link_lk-sidebar_prime"
          :to="{name: navItem.linkName}"
          :style="currentPath === navItem.link ? 'color: #6B7C85;' : '' "
      >
        {{ navItem.text }}
      </router-link>
    </div> -->
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "sideBar",

  data: () => ({
    navList: [
      {link: '/kcp_contest/license', text: 'Лицензированные НПС', dependency: false, userGroup: [1, 14, 15]},
      {
        link: '/kcp_contest/accreditation',
        text: 'Государственная аккредитация',
        dependency: false,
        userGroup: [1, 14, 15]
      },
      // {link: '/kcp_contest/poa', text: 'Профессионально-общественная аккредитация', dependency: false},
      {
        link: '/kcp_contest/structure',
        text: 'Реестр НПС/УГНС, состав заявки',
        dependency: false,
        userGroup: [1, 14]
      },
      {
        link: '/kcp_contest/structure-organisations',
        text: 'Организации подавшие заявки',
        dependency: false,
        userGroup: [14]//4, 6,
      },
      {
        link: '/kcp_contest/structure',
        text: 'Все заявки на рассмотрении',
        dependency: false,
        userGroup: [ 14]//4, 6,
      },
      {
        link: '/kcp_contest/stage1',
        text: 'Этап 1 распределения объемов КЦП на 2023/24 учебный год (центры ответственности)',
        dependency: false,
        userGroup: [4, 6, 14]
      },
      {
        link: '/kcp_contest/stage3',
        text: 'Этап 3 распределения объемов КЦП на 2023/24 учебный год (центры ответственности)',
        dependency: false,
        userGroup: [4, 6, 14]
      },
      {link: '/kcp_contest/kcp', text: 'Предложения по КЦП', dependency: false, userGroup: [1, 14, 15]},
      {
        link: '/kcp_contest/indicators',
        text: 'Показатели деятельности образовательной организации',
        dependency: false,
        userGroup: [1, 14, 15]
      },
    ],
    // navListIndicators: [
    //   {link: '/indicators/kcp',linkName: 'IndicatorsGroups', text: 'Список КЦП', dependency: false, userGroup: [1, 14, 15]},
    //   {
    //     link: '/indicators/list',
    //     linkName: 'IndicatorsList',
    //     text: 'Б1-Б60',
    //     dependency: false,
    //     userGroup: [1, 14, 15]
    //   },
    // ]
  }),
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
    currentPath() {
      return this.$route.path
    },
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('checkFilled', {get_check_filled_status: 'get_check_filled_status'}),
    navListAfterCheckOnDeploy() {
      return this.navList.filter(el => {
        return ((!el.dependency || (this[el.dependencyDataField])) && this.authData.role != null && el.userGroup.includes(this.get_replace_role_id_admin))
      })

    }
  },
  methods: {
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
  },
  created() {
    if (this.authData.role != null && this.get_replace_role_id_admin !== 4 && this.get_replace_role_id_admin !== 6) this.fetchCheckFilled({
      'sprOrgID': this.get_replace_id_admin,
      'userProfile': this.authData.userProfile
    });
  }
}
</script>
<style lang="sass">
.nav_lk-sidebar
  display: flex
  flex-direction: column
  margin-top: 28px
  height: 100%

  .nav-link_lk-sidebar:hover
    color: #eb8e1c

  .nav-link_lk-sidebar.nav-link_lk-sidebar_prime
    color: #646262
    font-size: 14px
    margin-left: 5px

  .nav-item_lk-sidebar
    display: flex
    align-items: flex-start
    margin-left: 20px


  .nav-lk__section
    margin-bottom: 25px
    display: flex
    flex-direction: column

  .nav-lk__item
    display: flex

  .icon_nav-lk
    width: 40px
    height: 40px

  .nav-lk__icon
    margin-right: 16px

  .nav-lk__main-link
    color: #00599B
    font-size: 18px

  .nav-lk__under-link
    color: #426075 !important
    line-height: 17, 6px
    font-size: 16px

  .icon_under-items
    height: 12px
    width: 6px

  .nav-lk__under-icon
    margin-right: 10px

  .nav-lk__main-link
    display: flex
    align-items: center

  .nav-lk__under-items
    padding-left: 56px
</style>