<template>
  <div>
    <div class="score-about">
      <p><b>п.5.3.4. Приложения 2 Порядка:</b></p>
      <p>Процент иностранных граждан в контингенте обучающихся</p>
    </div>
    <v-divider class="mt-4"/>
    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about">
      <p class="calculation__about-text">Форма 5.2.<br>
        Общая численность обучающихся по программам аспирантуры, человек (за счет всех источников финансирования, по всем формам обучения)
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="3">Всего обучающихся</th>
        <th colspan="3">из них - иностранных граждан</th>
      </tr>
      <tr class="">
        <th class="">на 1.01.2018</th>
        <th class="">на 1.01.2019</th>
        <th class="">на 1.01.2020</th>
        <th class="">на 1.01.2018</th>
        <th class="">на 1.01.2019</th>
        <th class="">на 1.01.2020</th>

      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" v-html="checkData(rowOfValues.data.asp2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.asp2019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.asp2020)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ia2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ia2019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ia2020)"></td>
      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td>
          <b>АСП</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>АСП</b><span class="font-size-little">2019</span>
        </td>
        <td>
          <b>АСП</b><span class="font-size-little">2020</span>
        </td>
        <td>
          <b>ИА</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>ИА</b><span class="font-size-little">2019</span>
        </td>
        <td>
          <b>ИА</b><span class="font-size-little">2020</span>
        </td>
      </tr>
      </tbody>
    </table>
    
    <!-- formula -->
    <section class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>A34<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full"><span style="font-size: 28px">(</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
             ИА<span class="font-size-little">2018</span>
          </div>
          <div class="formula-half" style="margin-left: 10%">АСП<span class="font-size-little">2018</span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
          ИА<span class="font-size-little">2019</span>
          </div>
          <div class="formula-half" style="margin-left: 10%">АСП<span class="font-size-little">2019</span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
         ИА<span class="font-size-little">2020</span>
          </div>
          <div class="formula-half" style="margin-left: 10%">АСП<span class="font-size-little">2020</span></div>
        </div>
        <div class="formula-full"><span style="font-size: 28px">)</span></div>
        <div class="formula-full font-weight-regular"><div>÷ <span class="font-weight-bold"> n </span> =</div></div>
      </div>
      <div class="formula">
         <div class="formula-full"> =</div>
        <div class="formula-full"><span style="font-size: 28px">(</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.ia2018)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.asp2018)"></span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.ia2019)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.asp2019)"></span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.ia2020)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.asp2020)"></span></div>
        </div>
        <div class="formula-full"><span style="font-size: 28px">)</span></div>
        <div class="formula-full font-weight-regular"><div>÷ <span class="font-weight-bold" v-html="checkData(rowOfValues.data.x3)"> </span> =</div></div>
        <div class="formula-full"><span v-html="checkData(rowOfValues.data.a34b0)"></span></div>
      </div>
      <!-- formula -->
      <div class="score-about mb-4">
        <p class="ml-4">Если значение АСП за один или два года отсутствует или менее 10, показатель определяется как среднее арифметическое значений ИА/АСП за те годы, за которые имеются отчетные значения показателя АСП, равные 10 или более.</p>
      </div>
      <div class="calculation__about">
        <p class="calculation__about-text">
          АСПk - общая численность обучающихся аспирантуры, человек (за счет всех источников финансирования, по всем формам обучения) в k-ом году;
        </p>
        <p class="calculation__about-text">
          ИАk - численность иностраных граждан, обучающихся в аспирантуре, человек (за счет всех источников финансирования, по всем формам обучения) в k-ом году;
        </p>
        <p class="calculation__about-text">
          n	-	число лет, за которые имеются отчетные данные НПРk.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "a34",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float', 3, false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%

.b42-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px

.b42-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px

  tr
    &:nth-child(2)
      td
        border: 0

    &:nth-child(3)
      td
        border: 0
</style>