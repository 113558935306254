<template>
  <tbody class="table-body" ref="tablebody">
  <tr class="table-body__tr"
      :class="paintRow(row)"
      :style="{width: tableBodyScrollWidth + 'px'}"
      v-for="(row,i) in rowsProps"
      :key="i"
  >
    <td class="table-body__td"
        :class="{'table-header__td_hidden' : isHidden(rolledCols,cell)}"
        v-for="(cell) in headersActiveArrayByOrder"
        :key="cell.dataField"

    >
      <div class="table-body__span" v-show="!isHidden(rolledCols,cell)">
        <span v-if="!isRouteCell(cell)">
          {{
            returnValueAfterTransformTypeCell(cell, row[cell.dataField],row)
          }}
        </span>
<!--        <v-menu-->
<!--            v-else-if="isRouteCell(cell) && computedMenuArr.length > 0"-->
<!--            v-model="computedMenuArr.find(el => {return el.dataField === cell.dataField && el.id === row.id}).showMenu"-->
<!--            :close-on-content-click="false"-->
<!--            :nudge-width="250"-->
<!--            offset-y-->
<!--        >&lt;!&ndash;menu.find(el => {return el.dataField === cell.dataField})&ndash;&gt;-->

            <v-btn
                v-else
                color=""
                width="100%"
                @click="sendRoute(row.id,cell.dataField)"
            >
              {{
                returnValueAfterTransformTypeCell(cell, row[cell.dataField])
              }}
            </v-btn>

      </div>
      <div v-if="cell.dataField === 'action' && actionAccess && actionColumn !== undefined"
           class="table-body__td_actions">
        <v-btn v-if="actionColumn.accessEdit"
               :disabled="authData && authData.role.id === 14"
               @click="actionRow(row.id, 'edit')">
          <v-icon>mdi-pencil</v-icon>
          Изменить
        </v-btn>
        <v-btn v-if="actionColumn.accessDelete"
               :disabled="authData && authData.role.id === 14"
               @click="actionRow(row.id, 'delete')">
          <v-icon>mdi-delete</v-icon>
          Удалить
        </v-btn>
        <v-btn v-if="actionColumn.customAction"
               :disabled="customActionCheck(row[customActionProps.dataField]).blocked ||  (authData && authData.role.id === 14)"
               @click="actionRow(row.id, 'customAction')">
          <v-icon>{{ customActionCheck(row[customActionProps.dataField]).icon}}</v-icon>
          {{ customActionCheck(row[customActionProps.dataField]).text }}
        </v-btn>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "TableRowWithRoute",
  props: {
    rowsProps: {
      type: Array,
      default: undefined
    },
    actionAccess: {
      type: Boolean,
      default: false
    },
    color: {
      type: Object,
      default: () => {
        return {
          dataField: '',
          colors: [
            {value: 0, backGroundColor: '#ffffff', color: '#000000', class: 'default-row-class'}
          ]
        }
      }
    },
    customActionProps: {
      type: Object,
      default: () => {
        return {
          active: 0,
          dataField: '',
          data: [
            {value: 0, icon: 'mdi-pencil', action: 'customAction'}
          ]
        }
      }
    },
    arrayForMenuCells: {
      type: Array
    },
    settingsForMenu: {},
    hiddenColumns: {
      type: Array,
      default: undefined
    },
    orderColumns: {
      type: Array,
      default: () => []
    },
    rolledCols: {
      type: Array,
      default: undefined
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    tableBodyScrollWidth: 0,
    menuArr: [],//{dataField: '', showMenu: false,idRow: 1}
    menuValue: null,
    menu: false,
    valid: false
  }),
  computed: {
    ...mapGetters('auth', {authData:'authData'}),
    headersActiveArrayByOrder() {
      let arr = []
      arr = [].concat(...this.orderColumns).filter(el => el.active).sort((a, b) => a.order - b.order)
      return arr
    },
    actionColumn() {
      return this.headersActiveArrayByOrder.find(el => el.dataField === 'action')
    },
  },
  mounted() {
    // this.assignMenuArr()
    // this.$emit('scrollBodyTableWidth', this.$refs.tablebody.scrollWidth);
    // this.tableBodyScrollWidth = this.$refs.tablebody.scrollWidth;
  },
  methods: {
    sendRoute(id, dataField) {
        this.$emit('action-row', {id: id, typeAction: 'routeAction', value: parseInt(this.menuValue), dataField: dataField});
        // this.computedMenuArr.find(el => {return el.dataField === dataField &&  el.id === id}).showMenu = false;
    },
    customActionCheck(value){
      // if (dataField === this.customActionProps.dataField) {
      let ind = this.$scripts.TableJs.arraySearch(this.customActionProps.data, value, 'value')
      return ind === -1 ? {
        icon: 'mdi-pencil',
        action: 'customAction',
        text: 'кастом действие'
      } : {
        icon: this.customActionProps.data[ind].icon,
        action: this.customActionProps.data[ind].action,
        text: this.customActionProps.data[ind].text
        // }
      }
    },
    isRouteCell(Obj) {
      return this.arrayForMenuCells.includes(Obj.dataField)
    },
    paintRow(Obj) {
      return this.$scripts.TableJs.arraySearch(this.color.colors, Obj[this.color.dataField], 'value') === -1 ? 'default-row-class' : this.color.colors[this.$scripts.TableJs.arraySearch(this.color.colors, Obj[this.color.dataField], 'value')].class
    },
    returnValueAfterTransformTypeCell(header, value,row){
      let result = ''
      switch (header.typeVar){
        case 'selector':
          result =  this.nameDataObj(value, header.dataField,row);
          break;
        case 'customSelector':
          result = header.data.find(el => el.value === value) != null ? header.data.find(el => el.value === value).text : value
          break;
        case 'date':
          result = this.$scripts.Utils.empty(value) ? value : value.slice(0,10)
          break;
        case 'integer':
          result  = value != null ? this.isInteger(+value) : 'н/д'
          break;
        case 'string':
          result = value
          break;
        case 'float':
          result = value != null ? (/*header.roundValue != null ? value.toFixed(header.roundValue) :*/ value ) : 'н/д'
          break;
        default:
          result = value
          break;
      }
      return result
    },
    isObject(obj,dataField) {
      if (Object.prototype.toString.call(obj) === '[object Object]' && obj !== null) {
        return this.nameDataObj(obj, dataField);
      } else
        return obj;
    },
    isInteger(num) {
      if ((num ^ 0) === num) {
        return num;
      } else {
        return num.toFixed(1);
      }
    },
    isHidden(arr, val) {
      if (arr !== undefined && arr.length > 0) {
        let indOfHiddenBy = this.$scripts.TableJs.arraySearch(arr, val.dataField, 'dataField');
        return indOfHiddenBy >= 0 ? arr[indOfHiddenBy].hidden : false;
      } else
        return false;
    },
    nameDataObj(obj, nameField,row) {
      let full_name = '';
      if (nameField==='sprUgsCollspan')
      {
        if (row.sprSpec != null && !this.$scripts.Utils.empty(row.sprSpec.specID)){
          full_name =row.sprSpec[`specCode`] + ' - ' + row.sprSpec[`specName`]
        }
        else if (row.sprUgs != null)
        {
          full_name =row.sprUgs[`ugsCode`] + ' - ' + row.sprUgs[`ugsName`]
        }
      }
      else {
        if (!this.$scripts.Utils.empty(obj))
        {
          full_name = ['usl', 'org', 'ff', 'fo'].includes(nameField.slice(3).toLowerCase()) ? obj[`${nameField.slice(3).toLowerCase()}Name`] : obj[`${nameField.slice(3).toLowerCase()}Code`] + ' ' + obj[`${nameField.slice(3).toLowerCase()}Name`];
          if (['kcpgroup'].includes(nameField.slice(3).toLowerCase()))
            full_name = obj[`code`] + ' - ' + obj[`name`]
        }
      }
      return full_name;
    },
    openDialog() {

    },
    actionRow(id, typeAction) {
      this.$emit('action-row', {id: id, typeAction: typeAction})
    }
  }

}
</script>

<style lang="sass" scoped>
.rows-enter-active, .rows-leave-active
  transition: all 1s

.rows-enter, .rows-leave-to
  opacity: 0
  transform: translateY(30px)

.table-body__td
  border: 1px solid black

.table-body__td_actions
  display: flex
  flex-direction: column

.default-row-class
  background-color: inherit
  color: black
</style>