<template>
<ContainerApellation />
</template>


<script>
import ContainerApellation from "@/containers/admin/apellations/ContainerApellation";
export default {
	components: {
		ContainerApellation
	},
	data() {
			return {

		}
	},
}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"

</style>