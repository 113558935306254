<template>
  <div>
    <FormKcpContestInfoPersonContact
        v-if="editMode"
        v-model="personToChange"
        v-on:input="requestPersonUpdate"
        @cancel="onCancel"/>
    <div v-else>
        

    <CardInfo v-if="personInitial && !editMode" :data="personToShow"/>

    <button  v-if="authData.role.id === 1 && !get_blockedAll" class="button info-card__button" @click="editMode = !editMode">Редактировать</button>
    <button v-if="authData.role.id !== 1 && !get_blockedAll" class="button info-card__button">Редактировать</button>
    
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormKcpContestInfoPersonContact from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoPersonContact';
import CardInfo from '@/components/cards/custom/CardInfo/CardInfo';
import request from "@/services/request";

export default {
  components: {
    FormKcpContestInfoPersonContact,
    CardInfo
  },
  data() {
    return {
      editMode: false,
      personToChange: null,
      personInitial: null,
    }
  },

    computed: {
        ...mapGetters('auth', {authData: 'authData'}),
        ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
      ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
      ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
        personToShow() {
            const {fio, position, email, workPhoneNumber, personalPhoneNumber} = this.personInitial;
            return {
                'Фамилия, Имя, Отчество ответственного': fio,
                'Введите должность ответственного': position,
                'Контактный e-mail': email,
                'Номер рабочего телефона': workPhoneNumber,
                'Номер телефона': personalPhoneNumber,
            }
        },
    },
    mounted() {
      this.getPersonContact();
    },


    methods: {
        ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
		async getPersonContact() {
			let person =  (this.get_replace_role_id_admin!= null && (this.get_replace_role_id_admin !==4 && this.get_replace_role_id_admin !==6 ) ) ? await request({
            endpoint: `/api/orgContacts/getContacts?sprOrgID=${this.get_replace_id_admin}&userProfile=${this.authData.userProfile}`,
			method: 'GET',
            }) : null;
            person = person != null ? person.data[0] : null;

      if (!person) {
        person = {
          fio: '',
          fioRodPad: '',
          email: '',
          position: '',
          positionRodPad: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
        }
      }

      for (let key in person) {
        if (person[key] === null) {
          person[key] = '';
        }
      }
      this.personToChange = {...person};
      this.personInitial = {...person};
    },
    async requestPersonUpdate() {
      if (this.get_replace_role_id_admin !==4 && this.get_replace_role_id_admin !==6){
        if (this.personInitial.sprOrgContactPersonID) {
          await request({
            endpoint: '/api/orgContacts/updateContact',
            method: 'POST',
            body: {
              ...this.personToChange,
              sprOrgID: this.authData.sprOrgID,
              userProfile: this.authData.userProfile,
              updateID: this.personInitial.sprOrgContactPersonID,

            },
          });
        } else {
          await request({
            endpoint: '/api/orgContacts/createNewContact',
            method: 'POST',
            body: {
              ...this.personToChange,
              sprOrgID: this.authData.sprOrgID,
              userProfile: this.authData.userProfile,

            },
          });
        }
        await this.getPersonContact();
        this.editMode = false;
        this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
      }
      else
        this.editMode = false;
    },
    onCancel() {
      this.personToChange = {...this.personInitial};
      this.editMode = false;

    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px
</style>