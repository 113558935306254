<template>
	<div class="contact-item">
		<div class="contact-item_name">{{item.name}}</div>
		<div class="contact-item_phone">{{item.phone}}</div>
		<div class="contact-item_email">{{item.email}}</div>
	</div>
</template>


<script>
	export default {
		props: {
			item: {
				type: Object,
			}
		}

	}
</script>

<style lang="sass">
.contact-item_name
	font-family: Roboto
	font-size: 19px
	font-weight: 500
	line-height: 17px
	color: #5B636B
	margin-bottom: 19px
.contact-item_phone
	font-family: Roboto
	font-size: 18px
	font-weight: 400
	line-height: 20px
	color: #5B636B
	margin-bottom: 5px
.contact-item_email
	font-family: Roboto
	font-size: 16px
	font-weight: 400
	line-height: 18px
	color: #5B636B
.contact-item
	background: #fff
	border-radius: 10px
	border: 1px solid #CAD2D8
	padding: 27px 23px
	min-width: 376px
	margin-right: 18px
	margin-bottom: 18px

</style>