<template>
<div class="applications">

<HeaderMain
		title="Заявки"
		subtitle="Изменение информации о филиалах"
	/>

<BSelect :data="selectedOptions" v-model="selectedOption" class="applications__select-type" />

<Alert alertType="primary" v-if="!selectedTypeApplication.length">
	<template v-slot:icon>
		<v-icon :size="30" color="#084298">mdi-information-outline</v-icon>
	</template>
	<template v-slot:default>
		Активных заявок нет
	</template>
</Alert>

<CardApplicationSub v-for="item in selectedTypeApplication" :key="item.key" :item="item" @reject="onReject($event)" @resolve="onResolve($event)" @aboutApplication="onClickAboutApplication($event)" />


    <Modal v-on:input="modalOpen = !modalOpen" v-if="modalOpen" title="Подробно о заявке">


        <div class="info-cards">
        <div class="compare-application">
            <h4 class="compare-application__title compare-application__title_application">Заявка на изменение</h4>
            <div class="compare-application__field" v-for="(value, name, index) in selectedApplication.applicationData" :key="index">
                <div class="compare-application__name" :class="{'compare-application__name_changed': value.changed}">
                   {{name}}:
                </div>
                <div class="compare-application__cell" :class="{'compare-application__cell_changed': value.changed}">
                    {{value.value}}
                </div>
            </div>
    </div>

    <div class="compare-application">
            <h4 class="compare-application__title compare-application__title_actual">Данные на сервере</h4>
            <div class="compare-application__field" v-for="(value, name, index) in selectedApplication.actualData" :key="index">
                <div class="compare-application__name">
                   {{name}}:
                </div>
                <div class="compare-application__cell" :class="{'info-card__cell_changed': value.changed}">
                    {{value}}
                </div>
            </div>
    </div>

    </div>
    </Modal>

</div>
</template>

<script>
import Alert from '@/components/alerts/Alert';
import HeaderMain from '@/components/headers/HeaderMain';
import Modal from '@/components/modals/Modal';
import {mapGetters} from 'vuex'
import request from "@/services/request";
import CardApplicationSub from "@/components/cards/custom/CardApplication/CardApplicationSub";
import BSelect from "@/components/controls/BSelect/BSelect";

export default {
    components: {
        CardApplicationSub,
        Modal,
        BSelect,
        Alert,
        HeaderMain
    },
    data() {
        return {
            applications: [],
            applicationsNew: [],
            modalOpen: false,
            selectedApplicationId: '',
            selectedOptions: ['Активные заявки', 'Неактивные заявки', 'Все заявки'],
            selectedOption: 'Активные заявки',
        }  
    },
    mounted() {
        this.getActualApplications();
    },

    computed: {
        ...mapGetters('auth', {authData: 'authData'}),
        ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
        selectedApplication() {
            return this.applicationsNew.find(el => el.applicationID === this.selectedApplicationId); 
        },
        selectedTypeApplication() {
            if (this.selectedOption === 'Активные заявки') {
                return this.applicationsNew.filter(el => el.applicationStatus === 'ACTIVE')
            } else  if (this.selectedOption === 'Неактивные заявки') {
                return this.applicationsNew.filter(el => el.applicationStatus === 'DONE' || el.applicationStatus === 'REJECT')
            } else {
                return this.applicationsNew;
            }
        }
    },
    
    methods: {
        onClickAboutApplication(e) {
            this.selectedApplicationId = e.id;
            this.modalOpen = !this.modalOpen;

        },
        async getActualApplications() {
            let applications = await request({
            endpoint: `/api/admin/getAllApplicationsChangeInfo`,
            method: 'POST',
            body: {"recordCount":6},
            });
            applications = applications.data;
            this.applications = applications;

            // Потом будет выборка на сервере
            let applicationsOrg = applications.filter(el => el.toChangeCase === 'SUB_ORG');

            let applicationsNew = [];
           for (let application of applicationsOrg) {
            await (async () => {
               let app = {};
                app.applicationID = application.applicationID;
                app.sprOrgOwnerName = this.get_spr_data_org.data.find(el => el.orgID === application.sprOrgOwnerID).orgName;
                app.orgID = application.orgID;
                app.orgName = application.orgName;
                app.applicationsFiles = application.applicationsFiles
                if (application.applicationTypeMethod === 'DELETE') app.applicationType = {type: 'DELETE', name: 'Удаление филиала'}
                if (application.applicationTypeMethod === 'CREATE') app.applicationType = {type: 'CREATE', name: 'Добавление филиала'}
                if (application.applicationTypeMethod === 'UPDATE') app.applicationType = {type: 'UPDATE', name: 'Изменение данных'}
                app.applicationStatus = application.applicationStatus;
                app.createDate = new Date(application.createDate);
                if (application.editDate) app.editDate = new Date(application.editDate);
                if (app.type === 'CREATE') {
                    app.applicationData = {
                    'Название': application.orgName,
                    'Адрес': application.orgAddress,
                    'КПП': application.kpp,
                    }
                } else if (app.type === 'UPDATE') {

                    let actual = await request({
                    endpoint: `/api/kcpCollectionReturn/getOrgByID?sprOrgID=${application.orgID}&userProfile=${this.authData.userProfile}`,
                    method: 'GET',
                    });
                    actual = actual.data;

                    app.actualData = {
                    'Полное название': actual.orgFullName,
                    'Название': actual.orgName,
                    'Сокращенное название': actual.orgShortName,
                    'Адрес': actual.orgAddress,
                    'ИНН': actual.inn,
                    'КПП': actual.kpp,
                }
                app.applicationData = {
                    'Полное название': {value: application.orgFullName, changed: application.orgFullName !== actual.orgFullName},
                    'Название': {value: application.orgName, changed: application.orgName !== actual.orgName},
                    'Сокращенное название': {value: application.orgShortName, changed: application.orgShortName !== actual.orgShortName},
                    'Адрес': {value: application.orgAddress, changed: application.orgAddress !== actual.orgAddress},
                    'ИНН': {value: application.inn, changed: application.inn !== actual.inn},
                    'КПП': {value: application.kpp, changed: application.kpp !== actual.kpp}, 
                }
                }
                

                
            applicationsNew.push(app);
           })()
           }
           applicationsNew = applicationsNew.sort((a, b) => b.createDate - a.createDate);
           this.applicationsNew = applicationsNew;

	
        },   
        async onReject(e) {
            await request({
            endpoint: `/api/admin/rejectApp?applicationID=${e.id}`,
            method: 'GET',
            });
            this.getActualApplications();
        },
        async onResolve(e) {
            await request({
            endpoint: `/api/admin/acceptApp?applicationID=${e.id}`,
            method: 'GET',
            });
            this.getActualApplications();
        },  
    
    }
}
</script>

<style lang="sass">
.applications
    width: 100%  
.info-card__field
    display: flex
    padding: 5px 10px
    align-items: self-start
.info-card__button
    margin-top: 10px
    margin-left: 10px
.info-card__button
    margin-top: 10px
    margin-left: 10px
.info-card__button:hover
    background: #0292e1
.info-card__cell_changed
    background: #fff3cd
    border-radius: 3px
    color: #856404
    padding: 0px 3px
.modal-content
    max-width: 900px
.info-cards
    display: flex
.compare-application
    text-align: left
    flex: 1
.compare-application:first-of-type
    border-right: 1px solid #6e8da2
.compare-application__name
    background: #e3effb
    color: #00599b
    padding: 1px 7px
    border-radius: 3px
    margin-right: 10px
    display: inline-block
    white-space: nowrap
.compare-application__field
    padding: 5px 10px
    display: flex
    flex-direction: column
    align-items: flex-start
.compare-application__cell
    margin-left: 10px
.compare-application__cell_changed
    background: #fff3cd
    color: #856404
    border-radius: 3px
.compare-application__name_changed
    background: #fff3cd
    color: #856404
.compare-application__title
    font-size: 18px
    text-align: center
    padding: 5px
.compare-application__title_actual
    background: #d4edda
    color: #155724
.compare-application__title_application
    background: #fff3cd
    color: #856404
.applications__select-type
    margin-bottom: 20px
    margin-top: 10px
    background-color: #fff
    padding: 5px 10px
    border: 1px solid #00599b
    cursor: pointer
    min-width: 200px
</style>