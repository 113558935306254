<template>
  <div>
    <button class="button_border" @click="$router.go(-1)">Назад</button>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title" v-if="!headersLoading & headers!= null">{{headers.tableName}}</h2>
    </header>
    <DTable
        v-if="dataHeaders.length > 0"
        :data-headers="dataHeaders"
        name-dialog="universalDialog"
        :data-dialog-headers="dataHeadersForDialog"
        :dop-props-for-dialog="dopPropsForDialog"
        :errors-for-dialog="get_data_dialog_error"
        :flag-loading-for-dialog="get_data_dialog_loading"
        :allow-dop-dialogs="false"
        :data-rows="dataRows"
        :custom-action-props="customActions"
        :color="color"
        :data-for-dialog.sync="dataForDialog"
        :error-headers="headersErrors"
        :error-rows="get_data_table_error"
        :flag-loading-headers="headersLoading"
        :flag-loading-rows="get_data_table_loading"
        :id-page.sync="idPageDefault"
        :num-page.sync="numPageDefault"
        :request="null"
        :settings="null"
        :sort-mass.sync="sortBy"
        :action-access="!get_blockedEnterData && headers.accessDialog"
        v-on:send-request="sendRequest($event)"
        v-on:action-row="actionRow($event)"
    />
    <in-progress v-else/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import DTable from "@/components/DTable_new/Dtable";
import InProgress from "@/components/InProgress";
import axios from 'axios'

export default {
  name: "zayavkaKcpStructureOrg",
  components: {
    InProgress,
    DTable
  },
  data: () => ({
    idPageDefault: 0,
    numPageDefault: 10,
    dataForDialog: null,
    sortBy: [],
    showAppealDialog: false,
    color: {
      dataField: 'state',
      colors: [
        {value: null, backGroundColor: '#ffffff', color: '#00599b', class: 'default-row-class'},
        {value: 'HAVE_GOS_ACCRED_AND_INCLUDED',                  backGroundColor: '#EBF9EB', color: '#3AB939',},
        {value: 'HAVE_GOS_ACCRED_AND_NOT_INCLUDED',              backGroundColor: '#CFE0EC', color: '#00599b',},
        {value: 'IN_PROCESS_AND_NOT_INCLUDE',    backGroundColor: '#FEF8E8', color: '#D4990A',},
        // {value: 'IN_PROCESS_AND_NOT_INCLUDE',    backGroundColor: '#FEF8E8', color: '#D4990A',},
        {value: 'REJECTED_AND_NOT_INCLUDED',                           backGroundColor: '#FDEBEB', color: '#F03738',},
        {value: 'ACCEPTED_BUT_HAVE_NOT_GOS_ACCRED_INCLUDED',     backGroundColor: '#3AB939', color: '#FFFFFF',},
        {value: 'ACCEPTED_BUT_HAVE_NOT_GOS_ACCRED_NOT_INCLUDED', backGroundColor: '#CFE0EC', color: '#00599b',},
        {value: 'NOT_SET',                                       backGroundColor: '#ffffff', color: '#00599b',},
      ]
    },
    customActions:
        {
          active: 0,
          dataField: 'state',
          data: [
            {value: null, icon: 'mdi-close', action: 'customAction', text: 'Ошибка', blocked: true},
            {value: 'HAVE_GOS_ACCRED_AND_INCLUDED', icon: 'mdi-close', action: 'customAction', text: 'Исключить из заявки', blocked: false},
            {value: 'HAVE_GOS_ACCRED_AND_NOT_INCLUDED', icon: 'mdi-check-underline', action: 'customAction', text: 'Добавить в заявку', blocked: false},
            {value: 'IN_PROCESS_AND_NOT_INCLUDE', icon: 'mdi-close', action: 'customAction', text: 'На согласовании', blocked: true},
            {value: 'REJECTED_AND_NOT_INCLUDED', icon: '', action: 'customAction', text: 'Отказано министерством', blocked: true},//нельзя нажать на кнопку
            {value: 'ACCEPTED_BUT_HAVE_NOT_GOS_ACCRED_INCLUDED', icon: 'mdi-close', action: 'customAction', text: 'Исключить из заявки', blocked: false},
            {value: 'ACCEPTED_BUT_HAVE_NOT_GOS_ACCRED_NOT_INCLUDED', icon: 'mdi-check-underline', action: 'customAction', text: 'Добавить в заявку', blocked: false},
            {value: 'NOT_SET', icon: 'mdi-check-underline', action: 'customAction', text: 'Добавить в заявку', blocked: false},
          ]
        },
  }),
  computed: {
    ...mapGetters('tables', {get_data_headers: 'get_data_headers'}),
    ...mapGetters('tables', {get_data_headers_error: 'get_data_headers_error'}),
    ...mapGetters('tables', {get_data_headers_loading: 'get_data_headers_loading'}),
    ...mapGetters('tables', {get_data_table: 'get_data_table'}),
    ...mapGetters('tables', {get_data_table_error: 'get_data_table_error'}),
    ...mapGetters('tables', {get_data_table_loading: 'get_data_table_loading'}),
    ...mapGetters('tables', {get_data_dialog: 'get_data_dialog'}),
    ...mapGetters('tables', {get_data_dialog_error: 'get_data_dialog_error'}),
    ...mapGetters('tables', {get_data_dialog_loading: 'get_data_dialog_loading'}),
    ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
    ...mapGetters('spr', {get_spr_data_org_error: 'get_spr_data_org_error'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    dopPropsForDialog() {
      return {
        org: {
          orgOwner: [this.get_replace_id_admin]
        },
        usl: {
          uslID: [1, 2, 3, 6, 9, 12]
        },
        spec: {
          specFlKlasif: [5]
        }
      }

    },

    headers() {
      let bufArr = this.get_data_headers.filter(el => el.routerLink === '/kcp_contest/structure')
      return (this.get_data_headers.length === 0 && this.get_data_headers_error === null) ? null : bufArr[this.$scripts.TableJs.arraySearch(bufArr, 1, 'formID')]
    },
    dataHeaders() {
      return (this.headers != null) ? ((Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headers, 'massive')) ? this.headers.headers.massive : []) : []
    },
    dataHeadersForDialog() {
      return (this.headers != null) ? ((Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headersForDialog, 'massive')) ? this.headers.headersForDialog.massive : []) : []
    },
    dataAccessDialog() {
      return (this.headers != null) ? (Object.hasOwnProperty.call(this.headers, 'headers') ? this.headers.accessDialog : false) : false
    },
    headersErrors() {
      return this.get_data_headers.length === 0 ? this.get_data_one_header_error : this.get_data_headers_error
    },
    headersLoading() {
      return this.get_data_headers.length === 0 ? this.get_data_one_header_loading : this.get_data_headers_loading
    },
    dataRows() {
      // console.log("this.headers", this.headers, this.headers.tableObjectName, this.get_data_table)
      return {
        content: ((this.headers != null) ? this.get_data_table[this.headers.tableObjectName] : []),
        totalElements: this.get_data_table.counter
      }
    },

  },
  created() {
    if (this.get_spr_data_org.data === undefined || this.get_spr_data_org.data.length === 0
        || this.get_spr_data_org_error !== null || this.get_spr_data_org_loading)
      this.dataForSpr()

    this.updateStructure()
        .then(
        () => {
          if (this.get_data_headers.length === 0  && !this.get_data_headers_loading) {
            this.getDataForHeaders()
                .then(
                    (() => {
                      this.getDataForTable({
                        link: this.headers.endPointForSelect,
                        allValues: true,
                        pageNumber: this.idPageDefault,
                        recordCount: this.numPageDefault
                      })
                    })
                ).catch(err => console.log("faiilllll", err))
          } else {
            this.getDataForTable({
              link: this.headers.endPointForSelect,
              allValues: true,
              pageNumber: this.idPageDefault,
              recordCount: this.numPageDefault
            })
          }
        }
    )
  },
  methods: {

    ...mapActions('spr', {dataForSpr: 'dataForSpr'}),
    ...mapActions('tables', {getDataForHeaders: 'getDataForHeaders'}),
    ...mapActions('tables', {getDataForTable: 'getDataForTable'}),
    ...mapActions('tables', {fetchDataForTable: 'fetchDataForTable'}),
    ...mapActions('tables', {updateDateForTable: 'updateDateForTable'}),
    ...mapActions('tables', {deleteDataFromTable: 'deleteDataFromTable'}),
    async updateStructure() {
      return axios.get('/api/zayavka/updateReestr' ,
          {headers: this.$scripts.Utils.authHeader(), params: { sprOrgID: this.get_replace_id_admin}})
    },
    actionRow(obj) {
      if (obj.typeAction === 'delete' && obj.id != null) {
        this.deleteDataFromTable({id: obj.id, link: this.headers.endPointForDelete})
            .then(() => {
              this.getDataForTable({
                link: this.headers.endPointForSelect,
                allValues: true,
                pageNumber: this.idPageDefault,
                recordCount: this.numPageDefault
              })
            })
      } else if (obj.typeAction === 'edit' && obj.id != null) {
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        this.dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
      }
      else  if ( obj.typeAction === 'customAction' && obj.id != null){
        // let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        this.updateDateForTable(new Object({
          data: new Object({id: obj.id}),
          link: this.headers.endPointForUpdate === undefined ? null : this.headers.endPointForUpdate,
          id: obj.id
        }))
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                }
            )
      }
    },
    sendRequest(data) {
      if (data.id == null) {
        // console.log("data.id =- null,", {data: {...data}, link: this.headers.endPointForInsert})
        this.fetchDataForTable(new Object({
          data: {...data},
          link: this.headers.endPointForInsert === undefined ? null : this.headers.endPointForInsert
        }))
            .then(
                () => {
                  if (this.headers.endPointForSelect !== undefined) this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                }
            )
        this.dataForDialog = null
      } else {
        // console.log("data.id != null,", data)
        this.updateDateForTable(new Object({
          data: {...data},
          link: this.headers.endPointForUpdate === undefined ? null : this.headers.endPointForUpdate,
          id: data.id
        }))
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                }
            )
        this.dataForDialog = null
      }
    }
  }
}
</script>

<style scoped>

</style>