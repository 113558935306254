<template>
	<main>
	<v-container>
		<HeaderMain
		title="Задать вопрос"
	/>
		<ContainerFormQuestion/>
	</v-container>
    </main>
</template>


<script>
import HeaderMain from '@/components/headers/HeaderMain';
import ContainerFormQuestion from '@/containers/common/containersFormQuestion/ContainerFormQuestion';

export default {
	components: {
		ContainerFormQuestion,
		HeaderMain,
	},
}
</script>