<template>
<div class="appeals-list" v-if="appeals">

    <HeaderMain
		title="Формы апелляций"
	/>

  <div class="cms-panel">
    <div class="cms-panel-search">
      <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon">mdi-magnify</v-icon>
      <input type="search" class="cms-panel-search__input" v-model="searchOrg">
    </div>
    <BSelect :data="searchFields" v-model="selectedSearchField" class="cms-panel-search__sort" />
  </div>
    <div v-if="resultSearch.data.length">
    <CardAppeal v-for="(appeal, index) of resultSearch.data" :key="index" :item="appeal" @openAboutAppeal="onOpenAboutAppeal($event)" />
    <Modal v-on:input="modalOpen = !modalOpen" v-if="modalOpen && activeAppeal" title="Подробно об апелляции" class="modal_left">
        <div class="appeal__header">
                <div class="appeal__property">ID: <span class="appeal__span" v-text="activeAppeal.appealID"></span></div>
          <div class="appeal__property">Организации: <span class="appeal__span" v-text="activeAppeal.sprOrg.orgName +' (ID: '+ activeAppeal.sprOrg.orgID + ' )'"></span></div>
          <div class="appeal__property">Название организации: <span class="appeal__span" v-text="activeAppeal.sprOrg.orgName"></span></div>
                <div class="appeal__property">Тип: <span class="appeal__span" v-text="appealType"></span></div>
                <div class="appeal__property">Дата создания апелляции: <span class="appeal__span" v-text="dateCreate"></span></div>
                <div class="appeal__property">Статус: <span class="appeal__span" v-text="status.text"></span></div>
                <div class="appeal__property">Дата изменения статуса: <span class="appeal__span" v-text="dateEdit"></span></div>

                <div v-if="activeAppeal.zayavkaForm" class="appeal__property">Форма заявки: <span class="appeal__span" v-text="referenceZayavkaForm"></span></div>

                <div v-if="activeAppeal.sprUsl" class="appeal__property">Уровень: <span class="appeal__span" v-text="activeAppeal.sprUsl.uslName"></span></div>
                <div v-if="activeAppeal.sprFO" class="appeal__property">Форма обучения: <span class="appeal__span" v-text="activeAppeal.sprFO.foName"></span></div>
          <div v-if="activeAppeal.sprKCPGroup" class="appeal__property"> ID УГСН(НПС): <span class="appeal__span" v-text="activeAppeal.sprKCPGroup.sprKCPGroupID"></span></div>
                <div v-if="activeAppeal.sprKCPGroup" class="appeal__property">УГСН(НПС): <span class="appeal__span" v-text="activeAppeal.sprKCPGroup.code + ' ' + activeAppeal.sprKCPGroup.name"></span></div>
                <div v-if="activeAppeal.indicator" class="appeal__property">Показатель: <span class="appeal__span" v-text="activeAppeal.indicator"></span></div>
                
                <div class="appeal__property">Описание: <p class="appeal__text" v-text="activeAppeal.description"></p></div>


                <div class="appeal__property">Файлы: </div>
                <div v-for="(file, index) in activeAppeal.appealFiles" :key="index" class="appeal-files ">
                    <div class="appeal-files__item" v-text="file.fileName" @click="getFile(file.fileName, file.fileServerID, file.fileFormat)"></div>
                </div>

                <div class="appeal__property appeal__actions">
                    <button class="button" @click="accept(activeAppeal.appealID)">Принять</button>
                    <button class="button" @click="reject(activeAppeal.appealID)">Отклонить</button>
                </div>


        </div>
    </Modal>
    </div>
  <p class="paginator-info"> Страница: {{ this.paginatorParams.selectedPage }}, Результатов на странице: {{ this.paginatorParams.itemsPerPage }}, Всего результатов: {{resultSearch.counter}} </p>
  <BPaginator
      v-if="resultSearch"
      :propDataCount="resultSearch.counter"
      v-model="paginatorParams"
  />


</div>    
</template>
<script>
import moment from 'moment'
moment.locale('ru');
import Modal from '@/components/modals/Modal';
import request from "@/services/request";
import CardAppeal from "@/components/cards/custom/CardAppeal";
import {mapGetters} from 'vuex';
import saveFile from '@/utils/saveFile';
import HeaderMain from '@/components/headers/HeaderMain';
import BSelect from "@/components/controls/BSelect/BSelect";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";

export default {
    components: {
        CardAppeal,
        Modal,
        HeaderMain,
      BSelect,
      BPaginator
    },
    data() {
        return {
            appeals: null,
            modalOpen: false,
            activeAppeal: null,
          searchOrg: '',
          searchFields: ['ID', 'Наименование'],
          selectedSearchField: 'ID',
          results: 0,
          currentSort: 'orgID',
          currentSortDir: 'asc',
          paginatorParams: {'selectedPage' : 1, 'itemsPerPage' : 10},
        }
    },
    computed: {
        ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
        dateCreate() {
                return moment(this.activeAppeal.createDate).format('LLL'); 
        },
        dateEdit() {
                return moment(this.activeAppeal.changeDate).format('LLL'); 
        },
      selectedField() {
        if (this.selectedSearchField === 'ID') {
          return 'orgID'
        } else if (this.selectedSearchField === 'Наименование') {
          return 'orgName'
        } else {
          return 'orgName'
        }
      },
      resultSearch () {
        let results;
        let array = this.appeals;

        array = array.sort((a, b) => {
          let mod = 1
          if (this.currentSortDir === 'desc') mod = -1
          if (a[this.currentSort] < b[this.currentSort]) return -1 * mod
          if (a[this.currentSort] > b[this.currentSort]) return 1 * mod
          return 0
        });
        let search = this.searchOrg;
        if (!search) {
          results = array.length;
          array = array.filter((row, index) => {
            let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
            let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
            if (index >= start && index < end) return true
          })

          return {'data': array, 'counter': results};
        }


        search = search.trim().toLowerCase()
        array = array.filter((item) => {
          if (String(item.sprOrg[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
            return item
          }
        })
        results = array.length;
        array = array.filter((row, index) => {
          let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
          let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
          if (index >= start && index < end) return true
        })
        return {'data': array, 'counter': results};
      },
        appealType() {
            let appealType;
            switch(this.activeAppeal.appealType) {
                case 'SOURCE_DATA_ERROR':
                appealType = 'Апелляция на ошибку в исходных данных';
                break;
                case 'INDICATORS_VALUE_ERROR':
                appealType = 'Апелляция на ошибку в расчете значения показателя';
                break;
                case 'SUMMARY_RATING_ERROR':
                appealType = 'Апелляция на ошибку в расчете сводной оценки';
                break;
                case 'NORMALIZED_RATING_ERROR':
                appealType = 'Апелляция на ошибку в расчете нормированной оценки';
                break;
            }
            return appealType;

        },
        status() {
            if (this.activeAppeal?.appealStatus === 'NOT_SET') {
                return {
                    text: 'На рассмотрении',
                    style: 'appeal_not-set'
                }
            } else if (this.activeAppeal?.appealStatus === 'ACCEPTED') {
                return {
                    text: 'Удовлетворена',
                    style: 'appeal_accepted'
                }
            } else if (this.activeAppeal?.appealStatus === 'REJECTED') {
                return {
                    text: 'Отказано',
                    style: 'appeal_rejected'
                }
            } else {
                return 'Ошибка'
            }
        },
        referenceZayavkaForm() {
            let referenceZayavkaForm;
            switch(this.activeAppeal ?? this.activeAppeal.zayavkaForm) {
                case '1':
                referenceZayavkaForm = 'Форма 5.1. Общая численность обучающихся по программам бакалавриата, специалитета, магистратуры, ординатуры, ассистентуры-стажировки'
                break;
                case '2':
                referenceZayavkaForm = 'Форма 5.2. Общая численность обучающихся по программам подготовки научно-педагогических кадров в аспирантуре'
                break;
                case '3':
                referenceZayavkaForm = 'Форма 5.3. Численность обучающихся'
                break;
                case '4':
                referenceZayavkaForm = 'Форма 5.4. Целевое обучение – численность обучающихся'
                break;
                case '5':
                referenceZayavkaForm = 'Форма 5.5. Целевое обучение – численность закончивших обучение по программам подготовки научно-педагогических кадров в аспирантуре'
                break;
                case '6':
                referenceZayavkaForm = 'Форма 5.6. Прием на обучение за счет средств федерального бюджета'
                break;
                case '7':
                referenceZayavkaForm = 'Форма 5.7. Прием на обучение по очной форме по программам бакалавриата и специалитета, за счет всех источников финансирования'
                break;
                case '8':
                referenceZayavkaForm = 'Форма 5.8. Средний балл ЕГЭ студентов, принятых на обучение по очной форме по программам бакалавриата и специалитета в 2020 году'
                break;
                case '9':
                referenceZayavkaForm = 'Форма 5.9. Показатели приема на обучение по программам магистратуры'
                break;
                case '10':
                referenceZayavkaForm = 'Форма 5.10. Показатели приема на обучение по программам ординатуры, ассистентуры-стажировки'
                break;
                case '11':
                referenceZayavkaForm = 'Форма 5.11. Показатели приема и выпуска аспирантуры'
                break;
                case '12':
                referenceZayavkaForm = 'Форма 5.12. Сохранность контингента обучающихся по программам бакалавриата и специалитета'
                break;
                case '13':
                referenceZayavkaForm = 'Форма 5.13. Сохранность контингента обучающихся по программам магистратуры и ассистентуры-стажировки'
                break;
                case '14':
                referenceZayavkaForm = 'Форма 5.14. Среднесписочная численность профессорско-преподавательского состава'
                break;
                case '15':
                referenceZayavkaForm = 'Форма 5.15. Численность научных работников образовательной организации'
                break;
                case '16':
                referenceZayavkaForm = 'Форма 5.16. Количество опубликованных научными работниками и работниками, относящимися к профессорско-преподавательскому составу, статей в рецензируемых научных журналах, которые учитываются при расчете индексов цитируемости в реферативно-библиографических базах научного цитирования'
                break;
                case '17':
                referenceZayavkaForm = 'Форма 5.17. Объем доходов образовательной организации'
                break;
                case '18':
                referenceZayavkaForm = 'Форма 5.18. Выполненный объем работ и услуг научной организацией, организацией дополнительного профессионального образования'
                break;
                case '19':
                referenceZayavkaForm = 'Форма 5.19. Внутренние затраты на научные исследования и разработки научной организации, организации дополнительного профессионального образования'
                break;
                case '20':
                referenceZayavkaForm = 'Форма 5.20. Программы повышения квалификации'
                break;
                case '21':
                referenceZayavkaForm = 'Форма 5.21. Наличие профессионально-общественной аккредитации'
                break;
                case '22':
                referenceZayavkaForm = 'Форма 5.22. Данные для расчета процента трудоустройства выпускников (бакалавриат, специалитет)'
                break;
                case '23':
                referenceZayavkaForm = 'Форма 5.23. Данные для расчета соотношения доходов выпускников и прожиточного минимума (магистратура, ординатура, ассистентура)'
                break;
            }
            return referenceZayavkaForm;
        },
        
    },
    mounted() {
        this.getAllAppeals();
    },
    methods: {
      prevPage () {
        if (this.paginatorParams.selectedPage > 1) this.paginatorParams.selectedPage-=1
      },
      nextPage () {
        if ((this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage) < this.resultSearch.counter) this.paginatorParams.selectedPage+=1
      },
        async getAllAppeals() {
            const res = await request({
                endpoint: `/api/admin/getAllAppeals`,
                method: 'GET',
            });
            if (res.status === 200) {
                this.appeals = res.data.appeals.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
            }
        },
        onOpenAboutAppeal(event) {
            this.modalOpen = true;
            this.activeAppeal = event;
        },
        async getFile(fileName, fileServerID, fileFormat) {
            let response = await request({
                endpoint: '/api/fileServer/downloadFileByID',
                method: 'POST',
                body: {
                    fileName: fileName,
                    fileServerID: fileServerID,
                    fileFormat: fileFormat,
                    }
            });
        saveFile(response.data);
    },
    async accept(id) {
        let response = await request({
                endpoint: `/api/admin/acceptAppeal?appealID=${id}`,
                method: 'GET',
        });
        if (response.status === 200) {
            this.getAllAppeals();
            this.modalOpen = false;
        }
    },
    async reject(id) {
        let response = await request({
                endpoint: `/api/admin/rejectAppeal?appealID=${id}`,
                method: 'GET',
        });
        if (response.status === 200) {
            this.getAllAppeals();
            this.modalOpen = false;
        }
    }

    }
    
}
</script>