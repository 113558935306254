<template>
<ContainerIndicatorsList />
</template>

<script>
import ContainerIndicatorsList from '@/containers/private/indicators/ContainerIndicatorsList';
export default {
    components: {
        ContainerIndicatorsList,
    }
}

</script>


<style lang="sass">
</style>