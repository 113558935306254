<template>
	<div class="document-item" @click="$emit('downloadFile', {'fileServerID': item.fileServerID, fileFormat: item.fileFormat, fileName: item.fileName})">
        <img class="document-item__img" src="@/assets/img/file-document.svg">
		<div class="document-item__content">
			<div class="document-item__name">{{item.fileType}}</div>
            <div class="document-item__date">{{date}}</div>
            
		</div>
	</div>
</template>


<script>
import moment from 'moment';
moment.locale('ru');
export default {
		props: {
			item: {
				type: Object,
			}
		},
        computed: {
            date() {
                return moment(this.item.uploadDate).format('LLL');
            }
        }

	}
</script>

<style lang="sass">
.document-list 
    display: flex
    justify-content: space-between
    flex-wrap: wrap
.document-item
    position: relative
.document-item:hover
    background: #FFEDE2     
.document-item__img
    position: absolute
    left: 0
.document-item 
    flex-basis: 33%
    background: #cfe0ec
    display: flex
    border-radius: 10px
    height: 99px
    max-width: 401px
    min-width: 401px
    overflow: hidden
    padding: 21px 26px 21px 68px
    margin-bottom: 30px
.document-item__content
    max-height: 70px
    overflow: hidden
    display: flex
    align-items: center
.document-item__name 
    font-family: Roboto
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: 19px
    letter-spacing: 0em
    text-align: left
    max-height: 57px
    overflow: hidden
    text-overflow: ellipsis
.documents__footer
    display: flex
    justify-content: flex-end
    margin: 30px 0 20px 0
.document-item__content
    flex-direction: column
    align-items: flex-start
    justify-content: center    
</style>