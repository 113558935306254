<template>
	<DetailArticle :item="news.data" schemaType="NewsArticle" />
</template>


<script>
import request from "@/services/request";
import ModelNewsDetail from '@/services/models/ModelNewsDetail';
import DetailArticle from '@/components/article/DetailArticle';
export default {
components: {
	DetailArticle,
},
data() {
	return {
		news: null
	}
},

	created() {
		this.getNews();
	},
	methods: {
		async getNews() {
			this.news = await request({
				endpoint: `/api/mainPage/getOneArticle/?id=${this.$route.params.id}`,
				method: 'GET',
				model: ModelNewsDetail
			})
		},
	} 
}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"

.article__text, .article__text strong, .article__text span, .article__text p, .article__text li, .article__text a
	font-family: $font-m
	color: #000 !important
	font-size: $size-1

.article__text span
	text-decoration: underline
.article__text a
	color: $color-1 !important
	text-decoration: underline
</style>