<template>
<v-container>

<ContainerFAQDetail />

</v-container>
</template>

<script>
import ContainerFAQDetail from "@/containers/front/faq/ContainerFAQDetail";

export default {
components: {
	ContainerFAQDetail,
}, 
}
</script>
