<template>
<div class="cms-news">
	<HeaderMain
		title="Админка новости"
		subtitle="Редактирование, публикация новостей"
	/>


<div class="cms-panel">
    <div class="cms-panel-search">
        <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon">mdi-magnify</v-icon>
        <input type="search" class="cms-panel-search__input">
    </div>

    <button class="button button_add">
        <v-icon :size="25" color="#ffffff" class="fr-topbar__icon">mdi-note-plus</v-icon>
        Добавить новость
    </button>

</div>



			<List direction="column" class="cms-news">
                <div class="cms-sort">
                    <div class="cms-sort__cell cms-sort__name">Сортировать по названию</div>
                    <div class="cms-sort__cell cms-sort__date">Сортировать по дате</div>
                    <div class="cms-sort__cell cms-sort__action"></div>
                </div>
				<CardNewsCms v-for="item in news.data" :key="item.key" :item="item"/>
			</List>
			<BPaginator
				:propDataCount = "news.counter"
				v-model="paginatorParams"
				@input="getNewsList"
			/>

</div>	
</template>


<script>
import request from "@/services/request";
import List from '@/components/lists/List';
import HeaderMain from '@/components/headers/HeaderMain';
import ModelNewsPreview from "@/services/models/ModelNewsPreview";
import CardNewsCms from "@/components/cards/custom/CardNews/CardNewsCms";
import BPaginator from '@/components/paginator/BPaginator/BPaginator';
export default {
	components: {
		BPaginator,
		CardNewsCms,
		List,
		HeaderMain
		},
	data() {
		return {
		paginatorParams: {'selectedPage' : 1, 'itemsPerPage' : 10},
		news: null,
		}
	},
    mounted() {
      this.getNewsList();
    },
	methods: {
		async getNewsList() {
			this.news = await request({
			endpoint: '/api/mainPage/getAllArticles',
			method: 'POST',
			body: {'pageNumber': this.paginatorParams.selectedPage,'recordCount' : this.paginatorParams.itemsPerPage-1},
			path: 'articles',
			model: ModelNewsPreview,
			});
		}
	},
}	
</script>
<style lang="sass">
.cms-news
    width: 100%
.cms-panel-search
    display: flex
    align-items: center
    background: #fff
    border-radius: 5px
    padding: 0px 10px
    border: 1px solid #cdd1d4
.cms-card-news__publish
    display: flex
    flex-direction: column
    flex-basis: 130px
    flex-shrink: 0
.cms-panel
    margin-bottom: 20px
    display: flex
    justify-content: space-between
.cms-sort
    display: flex
    justify-content: space-between
    padding: 5px 10px
.cms-sort__name
    flex-grow: 0
    flex-basis: 700px
.cms-card-news__actions, .cms-sort__action
    min-width: 86px
</style>