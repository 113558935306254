<template>
      <div class="file file-border_bottom">
        <div class="file-view">
            <div class="file-view__name text-justify">
            {{item.name}}
            <button v-if="item.fileServerID" class="file-view__download-file" @click="getFile">просмотр файла
                    <v-progress-circular
                    v-if="uploadNow"
                      indeterminate
                      color="#eb722e"
                      size="10"
                      width="2"
                    ></v-progress-circular>
            </button>
              <span v-else class="darker-orange-color">Не распределено</span>
            </div>

        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import moment from 'moment'
moment.locale('ru');
import saveFile from '@/utils/saveFile';
import request from "@/services/request";
export default {
    props: {
        item: {
            type: Object,
        }
    },
    data() {
        return {
            fileToUpload: null,
            modeEdit: false,
            uploadNow: false,
            uploadToServNow: false,
            rules: [
            value => !value || value.size < 20000000 || 'Размер файла не должен превышать 20 МБ!',
            ],
            canUpload: true,
        }
    },
    computed: {
        ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
        uploadFileDate() {
                return moment(this.item.uploadDate).format('LLL');  
        }
    },
    methods: {
        uploadFile: function() {
          if (this.fileToUpload.size < 20000000)
          {
            this.uploadToServNow = true;
            if (this.fileToUpload) {
              if (this.item.fileServerID) {
                this.$emit('replaceFile', {'fileType': this.item.fileType, 'file': this.fileToUpload, 'id': this.item.id});
              } else {
                this.$emit('uploadFile', {'fileType': this.item.fileType, 'file': this.fileToUpload});
              }
            
            }
          }
          else
            this.canUpload =false
        },
        async getFile() {
            this.uploadNow = true;
            let response = await request({
                endpoint: '/api/fileServer/downloadFileByID',
                method: 'POST',
                body: {
                    fileServerID: this.item.fileServerID,
                    fileName: this.item.fileName,
                    fileFormat: this.item.fileFormat,
                    }
            });
            this.uploadNow = false;
        saveFile(response.data);
    },
    },
}
</script>

<style lang="sass">
.file-border_bottom
  border-bottom: 1px solid rgba(0,0,0,.12)
  padding-bottom: 5px
  margin-bottom: 5px
  &:last-child
    border: 0
</style>