<template>
  <v-app-bar
      app
      color="white"
      elevate-on-scroll
      flat
  ><!--scroll-target="#scrolling-techniques-7"-->
    <v-app-bar-nav-icon
        @click.stop="show = !show"
    >
    </v-app-bar-nav-icon>


    <v-toolbar-title>
      <router-link to="/">
        <v-img class="logo"
            src="@/assets/img/logoIned.svg"
            alt="Интеробразование"
            contain
            color="#001520"
            min-width="100"
            min-height="30"
            max-height="64"
        >
        </v-img>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tabs
        color="#001520"
        right
        v-model="tab"
        class=""
        @change="changePage"
    ><!-- v-model="tab" -->
      <v-tab v-for="tabFor in tabs" :key="tabFor.id">
        {{tabFor.text}}
      </v-tab>
    </v-tabs>



    <div  v-if="authStatus" class="text-user">
      {{nameOrg === false? '': nameOrg}}
    </div>
    <v-btn v-if="authStatus" @click="logout">
        Выйти
    </v-btn>
  </v-app-bar>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: "TopBar",
  data: ()=> ({
    tab: 0,
    tabs: [
      {id: 0, text: 'Главная', router: '/'},
      // {id: 1, text: 'Распределение КЦП', router: '/'},
      {id: 2, text: 'Заявка КЦП', router: '/kcp_contest'},
      {id: 3, text: 'Личный кабинет', router: '/lk'},
      // {id: 4, text: 'Login', router: '/login'},
    ],
  }),
  props:{
    drawerLeftBar:{
      type: Boolean,
      default: false
    }
  },
  created(){
    if (this.$route.path !== '/'){
      let ind =this.$scripts.TableJs.arraySearch(this.tabs,this.$route.path,'router')
      this.tab = ind === -1 ? 0 : ind
    }
    if(this.get_spr_data_org.data === undefined || this.get_spr_data_org.data.length=== 0
        || this.get_spr_data_org_error!== null || this.get_spr_data_org_loading)
      this.dataForSpr()
  },
  computed: {
    ...mapGetters('auth', {authStatus: 'authStatus'}),
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
    ...mapGetters('spr', {get_spr_data_org_error: 'get_spr_data_org_error'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    nameOrg(){
      if (this.get_spr_data_org.data !== undefined && this.get_spr_data_org.data.length>= 0
          && this.get_spr_data_org_error=== null && !this.get_spr_data_org_loading)
      {
        if (this.authData.role.id === 1)
        return this.get_spr_data_org.data.filter(el => el.orgID === this.authData.userProfile)[0].orgFullName
      else if(this.authData.role.id === 6)
        return this.authData.fio
      else if (this.authData.role.id === 4)
        return this.authData.sprOrgFounderName
      else
        return ''
      }
      else
        return false
    },
    show: {
      get() {
        return this.drawerLeftBar
      },
      set(value) {
        this.$emit('update:drawer-left-bar', value)
      }
    },
  },
  methods:{
    changePage(event){
      if (this.tabs[event].router !== this.$route.path) {
        if (this.authStatus) this.$router.push(this.tabs[event].router);
        else if (this.tabs[event].router === '/' || this.tabs[event].router === '/lk')
          this.$router.push(this.tabs[event].router);
        else if (this.$route.path!== '/lk')
          this.$router.push('/lk');
      }
    },
    ...mapActions('spr', {dataForSpr:'dataForSpr'}),
    ...mapActions('auth', {storeLogout: 'logout'}),
    logout(){
      this.storeLogout()
      this.$router.push('/lk')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "/src/assets/styles/utils/variables"
.text-user
  color: $danger-color
.topbar
  max-height: 90px!important
</style>