<template>
    <div>
        <div class="score-about">
            <p><b>п.1.1.1. Приложения 2 Порядка:</b></p>
            <p>Средний балл единого государственного экзамена студентов, принятых на обучение по очной форме</p>
        </div>
        <h4 class="score-subtitle">1. Значение показателя</h4>
        <h5 class="score-subsubtitle">
            Исходные данные:
        </h5>
        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.8.</p>
            <p class="calculation__about-text">
                Средний балл ЕГЭ студентов, принятых на обучение по очной форме на бакалавриат и специалитет
            </p>
        </div>
        <table class="indicators__table">
            <thead class="indicator-thead">
                <tr>
                    <th rowspan="3">№ п/п</th>
                    <th rowspan="2">Уровень</th>
                    <th colspan="2">УГСН </th>
                    <th>Средний бал ЕГЭ</th>
                </tr>
                <tr>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>2020 год</th>
                </tr>
                <tr class="">
                    <th class="">1</th>
                    <th class="">2</th>
                    <th class="">3</th>
                    <th class="">4</th>
                </tr>
            </thead>
            <tbody class="indicator-tbody">
                <tr>
                    <td class="">1.</td>
                    <td class="">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
                    <td class="">
                        {{ rowOfValues.data.sprKCPGroup.code }}
                    </td>
                    <td class=""> {{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="">{{ rowOfValues.data.ege }}</td>
                </tr>
            </tbody>
        </table>
        <!-- formula -->
        <section class="section-score">
            <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

            <div class="formula">
                <div class="formula-full"><span>Б11<span class="font-size-little__bottom">0</span> =</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline"><div style="display: flex;flex-direction: row;align-items: center;"><span style="font-size: 28px; font-weight: lighter">Σ</span> <div>ЕГЭ<span class="font-size-little__bottom">k</span></div></div></div>
                    <div class="formula-half" style="margin-left: 35%">EN</div>
                </div>
                <div class="formula-full">=</div>
                <div class="formula-full">{{ rowOfValues.data.b11b0 }}</div>
            </div>

            <div class="score-about">
                <p>ЕГЭk - средний балл ЕГЭ k-го студента, принятого на обучение по очной форме;</p>
                <p>ЕN - численность студентов, принятых на обучение по очной форме, по которым был учтен средний балл ЕГЭ.</p>
            </div>
        </section>

    </div>
</template>

<script>
    export default {
        name: "b11",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

    .indicator-thead
        th
            background-color: inherit
            border: 1px solid #546e7a
            padding: 5px

    .indicator-tbody
        td
            text-align: center
            border: 1px solid #546e7a
            padding: 3px

    .grid-container__header
        display: grid
        //grid-template-rows: 1fr 1fr
        div
            border: 1px solid black
            text-align: center
        //grid-auto-rows: 50px
        grid-gap: 3px
        grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

        .grid-row-span-2
            grid-column: 1
            grid-row: 1/3

        &_inner
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 2px
</style>