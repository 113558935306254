<template>
    <div>

        <div class="score-about">
            <p><b>п.3.2.3. Приложения 2 Порядка:</b></p>
            <p>Доля обучающихся по договорам о целевом обучении в приведенном контингенте обучающихся</p>
        </div>

        <v-divider class="mt-4"/>

        <h4 class="score-subtitle">1. Значение показателя</h4>
        <h5 class="score-subsubtitle">
            Исходные данные:
        </h5>
        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.4.</p>
            <p class="calculation__about-text">
                Целевое обучение – численность обучающихся по договорам о целевом обучении за счет всех источников финансирования, человек
            </p>
        </div>
        <table class="indicators__table">
            <thead class="b24-thead">
            <tr class="">
                <th class="" rowspan="2">№ п/г</th>
                <th class="">Уровень</th>
                <th class="">УГСН Код</th>
                <th class="">УГСН Наименование</th>
                <th class="">Форма обучения</th>
                <th class="">Численность<br> обучающихся<br> 2020 год</th>
                <th rowspan="2"></th>
                <th class="" colspan="2" style="vertical-align: middle">Расчет исходных значений</th>
            </tr>
            <tr class="">
                <th class="">1</th>
                <th class="">2</th>
                <th class="">3</th>
                <th class="">4</th>
                <th class="">5</th>
                <th class="">переменная</th>
                <th class="">значение</th>
            </tr>
            </thead>
            <tbody class="b24-tbody">
            <tr>
                <td class="">1.</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.nao)">{{ }}</td>
                <td style="vertical-align: middle"> => </td>
                <td class="" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NAO</span> = </span>
                </td>
                <td class="" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nao)"> {{
                    }}
                </td>
            </tr>
            <tr>
                <td class="">2.</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очно-заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.nav)">{{ }}</td>
                <td style="vertical-align: middle">     => </td>
                <td class="" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NAV</span> = </span>
                </td>
                <td class="" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nav)"> {{
                    }}
                </td>
            </tr>
            <tr>
                <td class="">3.</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.naz)">{{ }}</td>
                <td style="vertical-align: middle"> => </td>
                <td class="" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NAZ</span> = </span>
                </td>
                <td class="" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.naz)"> {{
                    }}
                </td>
            </tr>
           
           
            </tbody>
        </table>

        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.3.</p>
            <p class="calculation__about-text">
                Численность обучающихся за счет всех источников финансирования, человек
            </p>
        </div>
        <table class="indicators__table">
            <thead class="b24-thead">
            <tr class="">
                <th class="" rowspan="2">№ п/г</th>
                <th class="">Уровень</th>
                <th class="">УГСН Код</th>
                <th class="">УГСН Наименование</th>
                <th class="">Форма обучения</th>
                <th class="">Численность<br> обучающихся<br> 2020 год</th>
                <th rowspan="2"></th>
                <th class="" colspan="2" style="vertical-align: middle">Расчет исходных значений</th>
            </tr>
            <tr class="">
                <th class="">1</th>
                <th class="">2</th>
                <th class="">3</th>
                <th class="">4</th>
                <th class="">5</th>
                <th class="">переменная</th>
                <th class="">значение</th>
            </tr>
            </thead>
            <tbody class="b24-tbody">
            <tr>
                <td class="">1.</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.pno)">{{ }}</td>
                <td style="vertical-align: middle"> => </td>
                <td class="" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">PNO</span> = </span></td>
                <td class="" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.pno)"> {{}}</td>
            </tr>
            <tr>
                <td class="">2.</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очно-заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.pnv)">{{ }}</td>
                <td style="vertical-align: middle">     => </td>
                <td class="" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">PNV</span> = </span>
                </td>
                <td class="" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.pnv)"> {{
                    }}
                </td>
            </tr>
            <tr>
                <td class="">3.</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
                <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.pnz)">{{ }}</td>
                <td style="vertical-align: middle"> => </td>
                <td class="" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">PNZ</span> = </span>
                </td> 
                <td class="" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.pnz)"> {{
                    }}
                </td>
            </tr>
           
           
            </tbody>
        </table>

        <section class="section-score">
            <h5 class="score-subsubtitle">
                Расчет приведенных контингентов по корреспондирующим УГСН по данным 2020
                года:
            </h5>

            <div class="calculation">
                <div class="calculation__row">
                    <div class="calculation__cell">NAP</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular"> 1.0 ×</span> NAO</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> NAV</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> NAZ</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> {{
                        rowOfValues.data.nao }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> {{
                        rowOfValues.data.nav }}
                    </div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> {{
                        rowOfValues.data.naz }}
                    </div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell">{{ rowOfValues.data.nap }}</div>
                </div>


                <div class="calculation__row">
                    <div class="calculation__cell">PN</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1.0 × </span>PNO</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> PNV</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> PNZ</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> {{
                        rowOfValues.data.pno }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.25 × </span>{{
                        rowOfValues.data.pnv }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> {{
                        rowOfValues.data.pnz }}
                    </div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell">{{ rowOfValues.data.pn }}</div>
                </div>
            </div>

            <div class="calculation__about">
                <p class="calculation__about-text">
                  NAP	- приведенный контингент обучающихся по договорам о целевом обучении;
                </p>
                <p class="calculation__about-text">
                  PN	- приведенный контингент обучающихся;
                </p>
            </div>
        </section>

        <!-- calculation -->

        <!-- formula -->
        <section class="section-score">
            <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

            <div class="formula">
                <div class="formula-full"><span>O23<span class="font-size-little">0</span> =</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline">
                        NAP
                    </div>
                    <div class="formula-half">PN</div>
                </div>
                <div class="formula-full">=</div>
                <div class="formula-full">
                  <div class="formula-half formula-half_underline"> <span v-html="checkData(rowOfValues.data.nap)"></span>
                  </div>
                  <div class="formula-half"><span v-html="checkData(rowOfValues.data.pn)"></span></div>
                </div>
                <div class="formula-full"> <div>
                  <span
                      class="font-weight-regular"> = </span><span v-html="checkData(rowOfValues.data.o23b0)"></span>
                </div>
                </div>
            </div>

            <!-- formula -->
        </section>

    </div>
</template>

<script>
    export default {
        name: "o23",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
        methods: {
            checkData(value) {
                return value;
                // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
            }
        },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

        .b24-thead
            th
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px

                &:nth-child(7)
                    border: 0
                    min-width: 20px

                &:nth-child(8)
                    border: 0

                &:nth-child(9)
                    border: 0

            tr
                &:last-child
                    th
                        &:nth-child(6)
                            border: 0
                            text-align: right
                            padding-right: 10px
                            border-bottom: 1px solid #546e7a

                        &:nth-child(7)
                            border: 0
                            padding-left: 10px
                            border-bottom: 1px solid #546e7a

        .b24-tbody
            td
                text-align: center
                border: 1px solid #546e7a
                padding: 3px

                &:nth-child(7)
                    border: 0
                    min-width: 20px

                &:nth-child(8)
                    border: 0
                    padding-right: 5px
                    text-align: right

                &:nth-child(9)
                    border: 0
                    padding-left: 2px
                    text-align: left

        .grid-container__header
            display: grid
            //grid-template-rows: 1fr 1fr
            div
                border: 1px solid black
                text-align: center
            //grid-auto-rows: 50px
            grid-gap: 3px
            grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

            .grid-row-span-2
                grid-column: 1
                grid-row: 1/3

            &_inner
                display: grid
                grid-template-columns: 1fr 1fr
                grid-gap: 2px
</style>