<template>
  <div>
    <div v-if="empty">
      Выберите значение по которому хотите посмотреть подробности
    </div>
    <div
        v-else-if="error !=null"
    >
      Ошибка загрузки данных
    </div>
    <div v-else>

      <HeaderMain
          v-if="indicators"

      >
        <slot v-if="indicators.sprKCPGroup">
          <h1 class="main-header__title-list">{{indicators.sprOrg.orgName}}</h1>
          <p class="header-subtitle-indicators"><span class="font-weight-bold"> {{ indicators.sprKCPGroup.sprUsl.uslName }}</span>.
            {{ indicators.sprKCPGroup.code }} {{
              indicators.sprKCPGroup.name
            }}. {{foName}}</p>
        </slot>
      </HeaderMain>


      <div class="indicators">
        <div
            class="b-indicator__wrapper"
        >

          <card-indicator
              v-if="indicators"
              :item="{
          title: 'Сводная оценка заявки',
          about: 'Сумма баллов по всем показателям',
          count: middleValue,
          dataFieldName: 'generalValue',
          max: indicators.ow,
          available: true,
          typeStyle: 'general',
          idUsl: idUsl
        }"
              :row-of-values="indicator"
          />
        </div>
        <v-progress-linear indeterminate v-if="loading"/>
        <div
            v-else
            class="b-indicator__wrapper"
            v-for="(b, index) in bIndicatorsCalculated"
            :key="index"
        >
          <card-indicator v-if="indicators" :item="b" :row-of-values="indicator"/>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/services/request";
import {mapGetters} from 'vuex';
import HeaderMain from "@/components/headers/HeaderMain";
import CardIndicator from "@/components/cards/custom/indicatiors/cardIndicator";
import {fixedTo1, fixedTo2, fixedTo3} from '@/assets/json/indicators';

export default {
  components: {
    CardIndicator,
    HeaderMain,
  },
  data() {
    return {
      indicators: null,
      show: false,
      loading: false,
      error: null,
      empty: true,
      foList: [
        {id: 1, text: 'Очная'},
        {id: 2, text: 'Очно-заочная'},
        {id: 3, text: 'Заочная'},
      ],
      bIndicators: [
        {
          title: 'Б11',
          dataFieldName: 'b11',
          about: 'Средний балл ЕГЭ',
          count: '64.8',
          max: 23,
          idUsl: 1,
        },
        {
          title: 'Б12',
          dataFieldName: 'b12',
          about: 'Исполнение КЦП прошлых лет',
          count: '19.4',
          max: 3,
          idUsl: 1,
        },
        {
          title: 'Б13',
          dataFieldName: 'b13',
          about: 'Доля целевого приема',
          count: '3.0',
          max: 4,
          idUsl: 1,
        },
        {
          title: 'Б21',
          dataFieldName: 'b21',
          about: 'Наличие ПОА',
          count: '1.8',
          max: 2,
          idUsl: 1,
        },
        {
          title: 'Б22',
          dataFieldName: 'b22',
          about: 'Соотношение (Маг+Асп)/БС',
          count: '9.0',
          max: 9,
          idUsl: 1,
        },
        {
          title: 'Б23',
          dataFieldName: 'b23',
          about: 'Соотношение (Маг+Асп+Орд)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б24',
          dataFieldName: 'b24',
          about: 'Соотношение (Маг+Асп+Аcc)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б25',
          dataFieldName: 'b25',
          about: 'Соотношение ДПО/(БС+Маг)',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б28',
          dataFieldName: 'b28',
          about: 'Доля целевого обучения',
          count: '9.0',
          max: 9,
          idUsl: 1,
        },
        {
          title: 'Б31',
          dataFieldName: 'b31',
          about: 'Трудоустройство выпускников',
          count: '18.7',
          max: 25,
          idUsl: 1,
        },
        {
          title: 'Б32',
          dataFieldName: 'b32',
          about: 'Сохранность контингента',
          count: '4.7',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б41',
          dataFieldName: 'b41',
          about: 'Публикации на 100 НПР',
          count: '0.4',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б42',
          dataFieldName: 'b42',
          about: 'Доходы от НИОКР на 1 НПР',
          count: '1.3',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б43',
          dataFieldName: 'b43',
          about: 'Доля иностранных студентов',
          count: '2.4',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б44',
          dataFieldName: 'b44',
          about: 'Доходы на 1 студента',
          count: '0.9',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б11',
          dataFieldName: 'b11',
          about: 'Средний балл ЕГЭ',
          count: '64.8',
          max: 23,
          idUsl: 2,
        },
        {
          title: 'Б12',
          dataFieldName: 'b12',
          about: 'Исполнение КЦП прошлых лет',
          count: '19.4',
          max: 3,
          idUsl: 2,
        },
        {
          title: 'Б13',
          dataFieldName: 'b13',
          about: 'Доля целевого приема',
          count: '3.0',
          max: 4,
          idUsl: 2,
        },
        {
          title: 'Б21',
          dataFieldName: 'b21',
          about: 'Наличие ПОА',
          count: '1.8',
          max: 2,
          idUsl: 2,
        },
        {
          title: 'Б22',
          dataFieldName: 'b22',
          about: 'Соотношение (Маг+Асп)/БС',
          count: '9.0',
          max: 9,
          idUsl: 2,
        },
        {
          title: 'Б23',
          dataFieldName: 'b23',
          about: 'Соотношение (Маг+Асп+Орд)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 2,
        },
        {
          title: 'Б24',
          dataFieldName: 'b24',
          about: 'Соотношение (Маг+Асп+Асс)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 2,
        },
        {
          title: 'Б25',
          dataFieldName: 'b25',
          about: 'Соотношение ДПО/(БС+Маг)',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 2,
        },
        {
          title: 'Б28',
          dataFieldName: 'b28',
          about: 'Доля целевого обучения',
          count: '9.0',
          max: 9,
          idUsl: 2,
        },
        {
          title: 'Б31',
          dataFieldName: 'b31',
          about: 'Трудоустройство выпускников',
          count: '18.7',
          max: 25,
          idUsl: 2,
        },
        {
          title: 'Б32',
          dataFieldName: 'b32',
          about: 'Сохранность контингента',
          count: '4.7',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б41',
          dataFieldName: 'b41',
          about: 'Публикации на 100 НПР',
          count: '0.4',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б42',
          dataFieldName: 'b42',
          about: 'Доходы от НИОКР на 1 НПР',
          count: '1.3',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б43',
          dataFieldName: 'b43',
          about: 'Доля иностранных студентов',
          count: '2.4',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'Б44',
          dataFieldName: 'b44',
          about: 'Доходы на 1 студента',
          count: '0.9',
          max: 5,
          idUsl: 2,
        },
        {
          title: 'M11',
          dataFieldName: 'm11',
          about: 'Заявления от выпусников др.организаций',
          idUsl: 3,
        },
        {
          title: 'M12',
          dataFieldName: 'm12',
          about: 'Поданные заявления / прием',
          idUsl: 3,
        },
        {
          title: 'M13',
          dataFieldName: 'm13',
          about: 'Доля платного приема',
          idUsl: 3,
        },
        {
          title: 'M14',
          dataFieldName: 'm14',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 3,
        },
        {
          title: 'M21',
          dataFieldName: 'm21',
          about: 'Наличие ПОА',
          idUsl: 3,
        },
        {
          title: 'M22',
          dataFieldName: 'm22',
          about: 'Соотношение Асп/Маг',
          idUsl: 3,
        },
        {
          title: 'M23',
          dataFieldName: 'm23',
          about: 'Соотношение ДПО/(БС+Маг)',
          idUsl: 3,
        },
        {
          title: 'M26',
          dataFieldName: 'm26',
          about: 'Доля целевого обучения',
          idUsl: 3,
        },
        {
          title: 'M31',
          dataFieldName: 'm31',
          about: 'Ср.доходы выпускников / СПМ',
          idUsl: 3,
        },
        {
          title: 'M32',
          dataFieldName: 'm32',
          about: 'Сохранность контингента',
          idUsl: 3,
        },
        {
          title: 'M41',
          dataFieldName: 'm41',
          about: 'Публикации на 100 НПР',
          idUsl: 3,
        },
        {
          title: 'M42',
          dataFieldName: 'm42',
          about: 'Доходы от НИОКР на 1 НПР',
          idUsl: 3,
        },
        {
          title: 'M43',
          dataFieldName: 'm43',
          about: 'Доля иностранных студентов',
          idUsl: 3,
        },
        {
          title: 'M44',
          dataFieldName: 'm44',
          about: 'Доходы на 1 студента',
          idUsl: 3,
        },
        {
          title: 'О11',
          dataFieldName: 'o11',
          about: 'Заявления от выпускников др.организаций',
          idUsl: 9,
        },
        {
          title: 'О12',
          dataFieldName: 'o12',
          about: 'Поданные заявления / прием',
          idUsl: 9,
        },
        {
          title: 'О13',
          dataFieldName: 'o13',
          about: 'Доля платного приема',
          idUsl: 9,
        },
        {
          title: 'О14',
          dataFieldName: 'o14',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 9,
        },
        {
          title: 'О21',
          dataFieldName: 'o21',
          about: 'Наличие ПОА',
          idUsl: 9,
        },
        {
          title: 'О22',
          dataFieldName: 'o22',
          about: 'Соотношение ДПО/(БС+Маг+Орд)',
          idUsl: 9,
        },
        {
          title: 'О23',
          dataFieldName: 'o23',
          about: 'Доля целевого обучения',
          idUsl: 9,
        },
        {
          title: 'О31',
          dataFieldName: 'o31',
          about: 'Ср.доходы выпускников / СПМ',
          idUsl: 9,
        },
        {
          title: 'О41',
          dataFieldName: 'o41',
          about: 'Публикации на 100 НПР',
          idUsl: 9,
        },
        {
          title: 'О42',
          dataFieldName: 'o42',
          about: 'Доходы от НИОКР на 1 НПР',
          idUsl: 9,
        },
        {
          title: 'О43',
          dataFieldName: 'o43',
          about: 'Доля иностранных студентов',
          idUsl: 9,
        },
        {
          title: 'О44',
          dataFieldName: 'o44',
          about: 'Доходы на обучающегося',
          idUsl: 9,
        },
        {
          title: 'K11',
          dataFieldName: 'k11',
          about: 'Заявления от выпускников др.организаций',
          idUsl: 12,
        },
        {
          title: 'K12',
          dataFieldName: 'k12',
          about: 'Поданные заявления / прием',
          idUsl: 12,
        },
        {
          title: 'K13',
          dataFieldName: 'k13',
          about: 'Доля платного приема',
          idUsl: 12,
        },
        {
          title: 'K14',
          dataFieldName: 'k14',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 12,
        },
        {
          title: 'K21',
          dataFieldName: 'k21',
          about: 'Наличие ПОА',
          idUsl: 12,
        },
        {
          title: 'K22',
          dataFieldName: 'k22',
          about: 'Соотношение ДПО/(БС+Маг+Асс)',
          idUsl: 12,
        },
        {
          title: 'K23',
          dataFieldName: 'k23',
          about: 'Доля целевого обучения',
          idUsl: 12,
        },
        {
          title: 'K31',
          dataFieldName: 'k31',
          about: 'Ср.доходы выпускников / СПМ',
          idUsl: 12,
        },
        {
          title: 'K32',
          dataFieldName: 'k32',
          about: 'Сохранность контингента',
          idUsl: 12,
        },
        {
          title: 'K41',
          dataFieldName: 'k41',
          about: 'Доля иностранных студентов',
          idUsl: 12,
        },
        {
          title: 'K42',
          dataFieldName: 'k42',
          about: 'Доходы на 1 обучающегося',
          idUsl: 12,
        },
        {
          title: 'A11',
          dataFieldName: 'a11',
          about: 'Исполнение КЦП прошлых лет',
          idUsl: 6,
        },
        {
          title: 'A21',
          dataFieldName: 'a21',
          about: 'Соотношение защиты/выпуск',
          idUsl: 6,
        },
        {
          title: 'A22',
          dataFieldName: 'a22',
          about: 'Соотношение защиты/прием',
          idUsl: 6,
        },
        {
          title: 'A23',
          dataFieldName: 'a23',
          about: 'Доля защит при целевом обучении',
          idUsl: 6,
        },
        {
          title: 'A31',
          dataFieldName: 'a31',
          about: 'Публикации на 100 НПР',
          idUsl: 6,
        },
        {
          title: 'A32',
          dataFieldName: 'a32',
          about: 'Доходы от НИОКР на 1 НПР',
          idUsl: 6,
        },
        {
          title: 'A33',
          dataFieldName: 'a33',
          about: 'Доходы от внебюджетных НИОКР на 1 НПР',
          idUsl: 6,
        },
        {
          title: 'A34',
          dataFieldName: 'a34',
          about: 'Доля иностранных аспирантов',
          idUsl: 6,
        },
        {
          title: 'A35',
          dataFieldName: 'a35',
          about: 'Доходы на 1 НПР',
          idUsl: 6,
        },
      ],
    }
  },
  mounted() {
    if (this.id != null)
      this.getIndicator(this.id);
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {authData: 'authData'}),
    indicator() {
      return this.indicators != null ? {data: this.indicators, fo: this.idFO} : {data: null, fo: this.idFO}
    },
    idFO() {
      return this.$route.query.fo != null ? Number.parseInt(this.$route.query.fo) : 1
    },
    foName() {
      return this.foList.find(el => el.id === this.idFO)?.text
    },
    middleValue() {
      return this.$route.query.value != null ? Number.parseFloat(this.$route.query.value) : null
    },
    idUsl() {
      return this.indicator.data ? this.indicator.data.sprKCPGroup.sprUsl.uslID : 1
    },
    id() {
      return this.$route.query.id != null ? Number.parseInt(this.$route.query.id) : null
    },
    bIndicatorsCalculated() {
      if (this.indicator.data != null)
        return this.bIndicators
            .filter(el => el.idUsl === this.idUsl)
            .map(el => {
              return {
                ...el,
                count: this.indicator.data[el.dataFieldName + 's0'] === '1' ? this.indicator.data[el.dataFieldName + this.idFO] :
                    'Показатель не рассчитывается для этой УГСН(НПС)',
                max: this.indicator.data[el.dataFieldName + 'w']
              }
            })
      else return []
    }
  },
  methods: {

    async getIndicator(id) {
      this.empty = false
      this.loading = true
      let indicators = await request({
        endpoint: `/api/zayavka/getCalcByID?id=${id}&uslID=${this.$route.query.usl}`,
        method: 'GET',
      });
      if (indicators.status === 200) {
        // костыль
        const newData = {...indicators.data};
        for (let el in newData) {
          if (typeof newData[el] === 'number' || newData[el] == null) {
            if (newData[el] == null) {
              newData[el] = 'н/д';
            } else if (fixedTo1.find(item => item === el)) {
              newData[el] = newData[el].toFixed(1);
            } else if (fixedTo2.find(item => item === el)) {
              newData[el] = newData[el].toFixed(2);
            } else if (fixedTo3.find(item => item === el)) {
              newData[el] = newData[el].toFixed(3);
            } else {
              newData[el] = newData[el].toFixed();
            }
          }
        }
        // костыль
        this.indicators = newData;
        this.loading = false
      } else {
        this.loading = false
        this.error = indicators.error
      }
    }
  }
}

</script>


<style lang="sass" scooped>

.indicators
  background: #fff
  border-radius: 10px
  border: 1px solid #CAD2D8
  padding: 20px
.main-header__title-list
  font-size: 32px
  line-height: 35.2px
  color: #00599B
  font-family: 'Montserrat'
  font-weight: 700
  max-width: 100%
  margin-bottom: 22px
.header-subtitle-indicators
  font-size: 17px
  color: #eb722e

</style>