<template>
  <section>
    <div class="container-files-kcp">
      <div class="container-files-kcp__title">
        Приложение к приказу от «29» апреля 2021 г. № 345
      </div>
      <div>
        <template v-if="loadedFiles">
          <card-file-k-c-p v-for="item in firstFilesBlock" :key="item.id" :item="item"/>
        </template>
      </div>
    </div>
    <v-divider class="my-4"/>
    <div class="container-files-kcp">
      <div class="container-files-kcp__title">
        Приложение к приказу от «29» апреля 2021 г. № 346
      </div>
      <div>
        <template v-if="loadedFiles">
          <card-file-k-c-p v-for="item in secondFilesBlock" :key="item.id" :item="item"/>
        </template>
      </div>
    </div>
    <v-divider class="my-4"/>
    <div class="container-files-kcp">
      <div class="container-files-kcp__title">
        Приложение к приказу от «15» июня 2022 г. № 549
      </div>
      <div>
        <template v-if="loadedFiles">
          <card-file-k-c-p v-for="item in thirdFilesBlock" :key="item.id" :item="item"/>
        </template>
      </div>
    </div>
  </section>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import request from "@/services/request";
import CardFileKCP from "@/components/cards/custom/CardFileKCP";

export default {
  components: {
    CardFileKCP,

  },
  data() {
    return {
      files: [
        {
          name: 'Контрольные цифры приема по программам бакалавриата, программам специалитета, программам магистратуры, имеющим государственную аккредитацию',
          fileType: 'BSM_ACRED',
          fileNum: 0,
        },
        {
          name: 'Контрольные цифры приема по программам бакалавриата, программам специалитета, программам магистратуры, не имеющим государственную аккредитацию',
          fileType: 'BSM_NE_ACRED',
          fileNum: 1,
        },
        {
          name: 'Контрольные цифры приема по программам подготовки научно-педагогических кадров в аспирантуре, программам ординатуры, программам ассистентуры-стажировки, имеющим государственную аккредитацию',
          fileType: 'AOA_ACRED',
          fileNum: 2
        },
        {
          name: 'Контрольные цифры приема по программам подготовки научно-педагогических кадров в аспирантуре, программам ординатуры, программам ассистентуры-стажировки, не имеющим государственную аккредитацию',
          fileType: 'AOA_NE_ACRED',
          fileNum: 3
        },
        {
          name: 'Дополнительные контрольные цифры приема по направлениям подготовки и (или) укрупненным группам направлений подготовки для обучения по образовательным программам высшего образования (программам магистратуры) за счет бюджетных ассигнований федерального бюджета',
          fileType: 'DOPKCP_ACRED',
          fileNum: 4
        },
      ],
      loadedFiles: false,
    }
  },

  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
    firstFilesBlock() {
      return this.files.filter(el => el.fileNum < 2)
    },
    secondFilesBlock() {
      return this.files.filter(el => el.fileNum >= 2 && el.fileNum < 4)
    },
    thirdFilesBlock() {
      return this.files.filter(el => el.fileNum >= 4)
    }

  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    async getDocuments() {
      this.loadedFiles = false;
      const res = await request({
        endpoint: `/api/fileServer/getFinalRaspredFile?sprOrgID=${this.get_replace_id_admin}`,//&userProfile=${this.authData.userProfile}
        method: 'GET',
      });
      res.data.forEach(el => {
        let overlapIndex = this.files.findIndex(file => file.fileType === el.raspredFileType);
        if (overlapIndex !== -1) {
          this.files[overlapIndex] = Object.assign(this.files[overlapIndex], el);
        }
      });
      this.loadedFiles = true;
    },
    async uploadFile() {

    },

    async replaceFile() {


    },
  },
}
</script>

<style lang="sass">
.container-files-kcp
  display: flex
  flex-direction: row
  align-items: center

  &__title
    display: flex
    flex-direction: row
    flex-basis: 200px
    min-width: 200px
    margin-right: 10px

.info-card__button
  margin-top: 10px
  margin-left: 10px

.file-edit
  display: flex
  align-items: center

.file-edit .v-file-input
  max-width: 300px
  margin-right: 10px
  min-width: 200px

.file-edit
  display: inline-flex
  align-items: center
  background: #FFEDE2
  padding: 0px 15px
  border-radius: 10px
  margin: 20px

.border_margin_right
  margin-right: 10px
</style>
