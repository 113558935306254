<template>
  <div>
    <div class="score-about">
      <p><b>п.5.2.1. Приложения 2 Порядка:</b></p>
      <p>Соотношение количества защищенных диссертаций на соискание ученой степени кандидата наук и численности
        выпускников образовательных организаций, прошедших обучение по программам подготовки научно-педагогических
        кадров в аспирантуре</p>
    </div>
    <v-divider class="mt-4"/>
    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about">
      <p class="calculation__about-text">
        Форма 5.11.<br>
        Показатели приема и выпуска апирантуры
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b31-thead">
      <tr class="">
        <th class="" rowspan="3">№ п/г</th>
        <th class="" colspan="2">УГСН</th>
        <th class="" colspan="2">Число защищенных диссертаций</th>
        <th colspan="2">Число выпускников аспирантуры</th>
      </tr>
      <tr class="">
        <th class="">Код</th>
        <th class="">Наименование</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
      </tr>
      </thead>
      <tbody class="b31-tbody">
      <tr>
        <td class="">1.</td>
        <td class="">
          {{ rowOfValues.data.sprKCPGroup.code }}
        </td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
        <td class="">{{ rowOfValues.data.zkn2018 }}</td>
        <td class="">{{ rowOfValues.data.zkn2019 }}</td>
        <td class="">{{ rowOfValues.data.cva2018 }}</td>
        <td class="">{{ rowOfValues.data.cva2019 }}</td>
        <!--      <td rowspan="2" style="vertical-align: middle">-->
        <!--        <img style="height: 51px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"-->
        <!--             alt=""/>-->
        <!--      </td>-->
        <!--      <td class="" rowspan="2" style="vertical-align:middle;"><span style="">NBO = </span></td>-->
        <!--      <td class="" rowspan="2" style="vertical-align:middle;"> {{ rowOfValues.data.nbo }}</td>-->
      </tr>
      <tr>
        <td colspan="3">
        </td>
        <td colspan="2" style="">
          <div><img style="height: 20px; margin: 0; width:210px; "
                    src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                    alt=""/>
          </div>
        </td>
        <td colspan="2" style="">
          <div><img style="height: 20px; margin: 0; width:210px; "
                    src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                    alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="3">
        </td>
        <td colspan="2" style="">
          <div>
            ЗКН = <span v-html="checkData(rowOfValues.data.zkn)"></span>
          </div>
        </td>
        <td colspan="2" style="">
          <div>
            ЧВА = <span v-html="checkData(rowOfValues.data.cva)"></span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <!-- formula   v-if="rowOfValues.data != null && rowOfValues.data.sasp1 != null && rowOfValues.data.sasp1 == 0"-->
    <section

        class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>A21<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">ЗКН</div>
          <div class="formula-half" style="margin-left: 25%">ЧВА</div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.zkn)"></span>
          </div>
          <div class="formula-half" style="margin-left: 25%"><span v-html="checkData(rowOfValues.data.cva)"></span>
          </div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full" >
          {{  rowOfValues.data != null && rowOfValues.data.sasp1 != null && rowOfValues.data.sasp1 == 1 ? 'н/д' : checkData(rowOfValues.data.a21b) }}
        </div>
      </div>

      <!-- formula -->

      <div class="score-about mb-4">
        <p class="ml-4">
          Если значение показателя ЧВА менее 5, значения показателей ЗКН и ЧВА рассчитываются по области образования, к
          которой относится УГСН.
        </p>
      </div>
      <div class="calculation__about">
        <p class="calculation__about-text">
          ЗКН - защищенных диссертаций лицами, успешно освоившими программы аспирантуры (независимо от образовательной
          организации, в которой была защищена диссертация) в 2018-2019 годах
        </p>
        <p class="calculation__about-text">
          ЧВА - число выпускников аспирантуры в 2018-2019 годах
        </p>
      </div>
    </section>
    <!--v-else-->
    <section
        v-if="rowOfValues.data != null && rowOfValues.data.sasp1 != null && rowOfValues.data.sasp1 == 1"
        class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя по области образования:</h5>

      <div class="formula">
        <div class="formula-full"><span>A21<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">ЗКН+</div>
          <div class="formula-half" style="margin-left: 15%">ЧВА+</div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.zknn)"></span>
          </div>
          <div class="formula-half" style="margin-left: 25%"><span v-html="checkData(rowOfValues.data.cvan)"></span>
          </div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">×100</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full" v-html="checkData(rowOfValues.data.a21b0)"></div>
      </div>

      <!-- formula -->

      <div class="score-about mb-4">
        <p class="ml-4">
          Если значение показателя ЧВА, рассчитанное по области образования, менее 5:<br>
          - если частное показателей ЗКН и ЧВА, рассчитанных по области образования, равно или более 0,6, то показатель
          рассчитывается по указанной выше формуле;<br>
          - в иных случаях в целях оценки заявки значение показателя не рассчитывается, а его нормированная оценка
          определяется в соответствии с пунктом 7 Методики.
        </p>
      </div>
      <div class="calculation__about">
        <p class="calculation__about-text">
          ЗКН+ - защищенных диссертаций лицами, успешно освоившими программы аспирантуры (независимо от образовательной
          организации, в которой была защищена диссертация) в 2018-2019 годах по области образования
        </p>
        <p class="calculation__about-text">
          ЧВА+ - число выпускников аспирантуры в 2018-2019 годах по области образования
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "a21",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%

.b31-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px

.b31-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px

  tr
    &:nth-child(2)
      td
        border: 0

    &:nth-child(3)
      td
        border: 0
</style>