import Api from "@/utils/Api";
export  default {
    namespaced: true,
    state: {
        sprDataOrg: {},
        sprDataOrgLoading: false,
        sprDataOrgError: null,
        sprDataSpec: {},
        sprDataSpecLoading: false,
        sprDataSpecError: null,
        sprDataUsl: {},
        sprDataUslLoading: false,
        sprDataUslError: null,
        sprDataFf: {},
        sprDataFfLoading: false,
        sprDataFfError: null,
        sprDataFo: {},
        sprDataFoLoading: false,
        sprDataFoError: null,
        sprDataUgs: {},
        sprDataUgsLoading: false,
        sprDataUgsError: null,
    },
    actions:{
        async dataForSpr({commit}){
            commit('SET_DATA_SPR_LOADING', true)
            let typesSpr = ['getUsl','getAllSpec','getAllOrgs','getAllFF','getFO','getUgs']
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getUsl'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllSpec'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllOrgs'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllFF'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllFo'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllUgs'})

            let [sprResUsl,sprResSpec,sprResOrg,sprResFf,sprResFo,sprResUgs] = await Api.sprDataLoad(typesSpr)

            if (sprResUsl.status===200){
                commit('SET_DATA_SPR', {data: sprResUsl.data,type: 'getUsl'})
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getUsl'})
                // dispatch('notifications/addNotification',{notificationStatus: 'success', notificationMessage: 'Данные успешно сохранены sprResUsl', timeout: 3000}, {root:true})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getUsl'})
                commit('SET_DATA_SPR_ERROR', {data: sprResUsl.error,type: 'getUsl'})
            }
            if (sprResSpec.status===200){
                commit('SET_DATA_SPR',  {data: sprResSpec.data,type: 'getAllSpec'})
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllSpec'})
                // dispatch('notifications/addNotification',{notificationStatus: 'success', notificationMessage: 'Данные успешно сохранены sprResSpec', timeout: 3000}, {root:true})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllSpec'})
                commit('SET_DATA_SPR_ERROR',  {data: sprResSpec.error,type: 'getAllSpec'})
            }
            if (sprResOrg.status===200){
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOrgs'})
                commit('SET_DATA_SPR',  {data: sprResOrg.data,type: 'getAllOrgs'})
                localStorage.setItem('sprAllOrgs', sprResOrg.data);
                // dispatch('notifications/addNotification',{notificationStatus: 'success', notificationMessage: 'Данные успешно сохранены sprResOrg', timeout: 3000}, {root:true})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOrgs'})
                commit('SET_DATA_SPR_ERROR', {data: sprResOrg.error,type: 'getAllOrgs'})
            }
            if (sprResFf.status===200){
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllFF'})
                commit('SET_DATA_SPR',{data: sprResFf.data,type: 'getAllFF'})
                // dispatch('notifications/addNotification',{notificationStatus: 'success', notificationMessage: 'Данные успешно сохранены sprResFf', timeout: 3000}, {root:true})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllFF'})
                commit('SET_DATA_SPR_ERROR', {data: sprResFf.error,type: 'getAllFF'})
            }
            if (sprResFo.status===200){
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllFo'})
                commit('SET_DATA_SPR',{data: sprResFo.data,type: 'getAllFo'})
                // dispatch('notifications/addNotification',{notificationStatus: 'success', notificationMessage: 'Данные успешно сохранены sprResFo', timeout: 3000}, {root:true})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllFo'})
                commit('SET_DATA_SPR_ERROR', {data: sprResFo.error,type: 'getAllFo'})
            }
            if (sprResUgs.status===200){
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllUgs'})
                commit('SET_DATA_SPR',{data: sprResUgs.data,type: 'getAllUgs'})
                // dispatch('notifications/addNotification',{notificationStatus: 'success', notificationMessage: 'Данные успешно сохранены sprResUgs', timeout: 3000}, {root:true})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllUgs'})
                commit('SET_DATA_SPR_ERROR', {data: sprResUgs.error,type: 'getAllUgs'})
            }
        },
    },
    mutations:{
        ['SET_DATA_SPR'](state,payload){
            switch (payload.type) {
                case 'getUsl':
                    state.sprDataUsl = {data: payload.data.sprUsl, counter: payload.data.counter}
                    break
                case 'getAllSpec':
                    state.sprDataSpec = {data: payload.data.sprSpec, counter: payload.data.counter}
                    break
                case 'getAllOrgs':
                    state.sprDataOrg ={data: payload.data.sprOrg, counter: payload.data.counter}
                    break
                case 'getAllFF':
                    state.sprDataFf = {data: payload.data.sprFF, counter: payload.data.counter}
                    break
                case 'getAllFo':
                    state.sprDataFo = {data: payload.data.sprFO, counter: payload.data.counter}
                    break
                case 'getAllUgs':
                    state.sprDataUgs = {data: payload.data.sprUgs, counter: payload.data.counter}
                    break
            }
        },
        ['SET_DATA_SPR_LOADING'](state,payload){
            switch (payload.type) {
                case 'getUsl':
                    state.sprDataUslLoading = payload.data
                    break
                case 'getAllSpec':
                    state.sprDataSpecLoading = payload.data
                    break
                case 'getAllOrgs':
                    state.sprDataOrgLoading = payload.data
                    break
                case 'getAllFF':
                    state.sprDataFfLoading = payload.data
                    break
                case 'getAllFo':
                    state.sprDataFoLoading = payload.data
                    break
                case 'getAllUgs':
                    state.sprDataUgsLoading = payload.data
                    break
            }
        },
        ['SET_DATA_SPR_ERROR'](state,payload){
            switch (payload.type) {
                case 'getUsl':
                    state.sprDataUslError = payload.data
                    break
                case 'getAllSpec':
                    state.sprDataSpecError = payload.data
                    break
                case 'getAllOrgs':
                    state.sprDataOrgError = payload.data
                    break
                case 'getAllFF':
                    state.sprDataFfError = payload.data
                    break
                case 'getAllFo':
                    state.sprDataFoError = payload.data
                    break
                case 'getAllUgs':
                    state.sprDataUgsError = payload.data
                    break
            }
        },
    },
    getters:{
        get_spr_data_usl(state){
            return state.sprDataUsl
        },
        get_spr_data_usl_loading(state){
            return state.sprDataUslLoading
        },
        get_spr_data_usl_error(state){
            return state.sprDataUslError
        },
        get_spr_data_ugs(state){
            return state.sprDataUgs
        },
        get_spr_data_ugs_loading(state){
            return state.sprDataUgsLoading
        },
        get_spr_data_ugs_error(state){
            return state.sprDataUgsError
        },
        get_spr_data_spec(state){
            return state.sprDataSpec
        },
        get_spr_data_spec_loading(state){
            return state.sprDataSpecLoading
        },
        get_spr_data_spec_error(state){
            return state.sprDataSpecError
        },
        get_spr_data_org(state){
            return state.sprDataOrg
        },
        get_spr_data_org_main(state){
            return state.sprDataOrg
            // return state.sprDataOrg.data.filter(el => el.orgStatus == '1');
        },
        get_spr_data_org_loading(state){
            return state.sprDataOrgLoading
        },
        get_spr_data_org_error(state){
            return state.sprDataOrgError
        },
        get_spr_data_ff(state){
            return state.sprDataFf
        },
        get_spr_data_ff_loading(state){
            return state.sprDataFfLoading
        },
        get_spr_data_ff_error(state){
            return state.sprDataFfError
        },
        get_spr_data_fo(state){
            return state.sprDataFo
        },
        get_spr_data_fo_loading(state){
            return state.sprDataFoLoading
        },
        get_spr_data_fo_error(state){
            return state.sprDataFoError
        },
        
        
    }
}