<template>
  <v-container>
    <div class="lk-main-content" >
      <div class="lk-main-content__navbar">
        <router-view name="nav"></router-view>
      </div>
      <main class="lk-main-content__main">
        <slot></slot>
        <router-view></router-view>
      </main>

    </div>
<!--    <div v-else style="display: flex; align-items: center; justify-content: center">-->
<!--      <alert>-->
<!--        <template #icon><v-icon color="warning">mdi-alert</v-icon></template>-->
<!--        <p>Ведутся технические работы</p>-->
<!--      </alert>-->
<!--      <v-img style=" max-height: 500px; max-width: 500px;" :src="require(`@/assets/img/photo_2021-05-20_17-04-52.png`)" alt=""/>-->
<!--    </div>-->
  </v-container>
</template>
<script>
import {mapGetters} from 'vuex'
import Alert from "@/components/alerts/Alert";
export default {
  name: 'layoutDefault',
  // eslint-disable-next-line vue/no-unused-components
  components: {Alert},
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    access(){
      return  this.authData?.role.id === 14
    }
  },
}
</script>

<style lang="sass">
.lk-main-content
  display: flex

.lk-main-content__navbar
  min-width: 250px
  max-width: 250px
  margin-right: 20px
  border-right: 1px solid #cdd1d4
  padding-right: 10px
  box-sizing: border-box
</style>