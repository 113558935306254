<template>
	<main>
		<HeaderMain
			title="Контакты"
			subtitle="Нужно указать к кому и куда обращаться по каким вопросам"
		/>
		<ContainerContactsPreview />
		
    </main>
</template>

<script>
	import ContainerContactsPreview from '@/containers/front/contacts/ContainerContactsPreview';
	import HeaderMain from '@/components/headers/HeaderMain';
	export default {
		components: {
			HeaderMain,
			ContainerContactsPreview
			},
	}
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.l-contact-item
    padding: 30px 0px 20px
.contact-item__request
    font-weight: 600
    font-size: 16px
    max-width: 300px
.layout-contact__item
    display: flex
    flex-direction: column
    margin-bottom: 20px
.contact-item__tel
    font-size: 25px
    font-weight: 600
    color: $firstColor
.contact-item__about
    font-size: 14px
    color: $greyColor 
.contact-item__contacts 
    max-width: 300px
.contact-item__text 
    font-size: 16px
.contact-item__email 
    font-size: 25px
    font-weight: 600
    color: #00599b
</style>