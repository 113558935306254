<template>
	<div class="navigation-item navigation-item_column">
		<router-link class="navigation-item__link" :to="item.link">{{item.name}}</router-link>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
		}
	}
}
	
</script>

<style lang="sass">
.navigation-item__link
    font-family: Montserrat
    font-size: 16px
    font-weight: 600
    line-height: 28px
    letter-spacing: 0.01em
    text-align: left
    color: #fff !important
</style>
