<template>
	<div class="cms">
		<LayoutFrontTopbar/>
		<LayoutAdminSidebar/>
		<!-- Контентная часть --> 
		<section class="cms-main">
		<component :is="layout">
		</component>
		</section>
		
	</div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import LayoutFrontTopbar from "@/layout/LayoutFront/LayoutFrontTopbar";
import LayoutAdminSidebar from "@/layout/LayoutAdmin/LayoutAdminSidebar";

import LayoutAdminContentDefault from "@/layout/LayoutAdmin/layoutAdminContent/LayoutAdminContentDefault";

export default {
	components: {
		Breadcrumbs,
		LayoutFrontTopbar,
		LayoutAdminContentDefault,
		LayoutAdminSidebar
	},
	computed: {
		layout() {
			return this.$route.meta.layout ? this.$route.meta.layout : 'LayoutAdminContentDefault';
		}
	},
}
</script>


<style lang="sass">
.cms
    background: #f8f9fb
    height: 100%
</style>