import { nanoid } from 'nanoid'
import moment from 'moment'
moment.locale('ru');
class ModelNewsPreview {
    constructor({articlesID, title, anonce, publicDate, kcpUser, important, titleTranslit}) {
        this.key = nanoid();
        this.id = articlesID;
        this.title = title;
        this.previewText = anonce;
        this.date = {
          day: moment(publicDate).format('D'),
          month: moment(publicDate).format('MMMM'),
          year: moment(publicDate).format('YYYY'),
        };
        this.author = kcpUser.fullFIO;
        this.important = important;
        this.titleTranslit = titleTranslit;
    }
}
export default ModelNewsPreview;