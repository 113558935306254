<template>
    <div>
        <h2 class="title app-title_main app__title main-header__title-list"
            style="font-size: 32px!important;text-align:center">«Установление объемов КЦП-ВО-2023/2024»</h2>

        <p class="okcp__title" style="margin-top: 64px"><a
                href="	http://files.ined.ru/adminpanel/cms_article_editor_1_66666_6ed3d7f5797d1c394b0072e3ebccbbc4.pdf" target="_blank" style="">
            Минобрнауки России обращает внимание, что перечень центров ответственности утверждён согласно приказу Минобрнауки России от 9 ноября 2020 г. № 1388</a>
        </p>

        <p style="text-align:center">
            По техническим и методическим вопросам можно обращаться к Горозий Елене Александровне e-mail:
            <a href="mailto:ea.goroziy@ined.ru?subject=%D0%92%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B%20%D0%BF%D0%BE%20%D0%98%D0%A1%20%D0%A0%D0%B0%D1%81%D0%BF%D1%80%D0%B5%D0%B4%D0%B8%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%9A%D0%A6%D0%9F">ea.goroziy@ined.ru</a>
            , Михаленко Алине Александровне, тел.: <a href="tel:+74992461407">+7(499)246-14-07</a>,<a href="tel:+74992463284">+7(499)246-32-84</a>,
            <a href="tel:+79104604043">+7(910) 460-40-43</a>,
            <a href="tel:+79264713669">+7(926) 417-36-69</a>
        </p>

        <p style="text-align:justify">Министерство науки и высшего образования РФ обращает внимание об организации
            второго этапа по формированию объемов и структуры контрольных цифр приема по направлениям подготовки и
            специальностям для обучения по образовательным программам высшего образования за счет бюджетных ассигнований
            федерального бюджета на 2023/2024 учебный год.</p>

        <p style="text-align:justify">Срок внесения данных - <strong><span
                style="color:#c0392b">до 5 ноября 2021 года</span> </strong>
          <em>(4 ноября 2021 г. в 23:59 по московскому времени будут закрыты рабочие кабинеты).</em></p>

        <p style="text-align:justify">В соответствии с пунктом 6 Правил высшим органам исполнительной власти субъектов
            Российской Федерации, расположенным на территории Дальневосточного и Северо - Кавказского федеральных округов,
            необходимо согласовать проект КЦП с Министерством Российской Федерации по развитию Дальнего Востока и Арктики
            и Министерством экономического развития Российской Федерации соответственно.</p>
    </div>
</template>

<script>
    export default {
        name: "regionStage"
    }
</script>

<style lang="sass" scoped>
    .okcp__title
        font-family: 'Montserrat'
        font-size: 24px
        line-height: 21.94px
</style>