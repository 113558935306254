<template>
  <div>
    <button class="button_border" @click="$router.go(-1)">Назад</button>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title" v-if="!headersLoading & headers!= null">{{headers.tableName}}</h2>
    </header>
    <DTable
        v-if="dataHeaders.length > 0"
        :data-headers="dataHeaders"
        name-dialog="universalDialog"
        name-row-component="TableRowWithMenu"
        :data-dialog-headers="dataHeadersForDialog"
        :dop-props-for-dialog="null"
        :errors-for-dialog="get_data_dialog_error"
        :flag-loading-for-dialog="get_data_dialog_loading"
        :allow-dop-dialogs="false"
        :data-rows="dataRows"
        :data-for-dialog.sync="dataForDialog"
        :error-headers="headersErrors"
        :error-rows="get_data_table_error"
        :flag-loading-headers="headersLoading"
        :flag-loading-rows="get_data_table_loading"
        :id-page.sync="idPageDefault"
        :num-page.sync="numPageDefault"
        :array-for-menu-cells="arrayForMenuCells"
        :request="null"
        :settings="null"
        :sort-mass.sync="sortBy"
        :action-access="!get_blockedEnterData && headers.accessDialog"
        v-on:send-request="sendRequest($event)"
        v-on:action-row="actionRow($event)"
    />
    <in-progress v-else/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import DTable from "@/components/DTable_new/Dtable";
import InProgress from "@/components/InProgress";
import axios from 'axios'

import {Base64 } from 'js-base64';
export default {
  name: "zayavkaKcpKcp",
  components: {
    InProgress,
    DTable
  },
  data: () => ({
    idPageDefault: 0,
    numPageDefault: 10,
    dataForDialog: null,
    sortBy: [],
    showAppealDialog: false,
    color: {},
    fav: true,
    menu: false,
    message: false,
    hints: true,
    idFile: 12,
    file : null,
    buf_file: null,
    arrayForMenuCells : ['zayavkaOchnaya','zayavkaOchnoZaochnaya','zayavkaZaochnaya','minOchnaya','minOchnoZaochnaya','minZaochnaya']
  }),
  computed: {
    ...mapGetters('tables', {get_data_headers: 'get_data_headers'}),
    ...mapGetters('tables', {get_data_headers_error: 'get_data_headers_error'}),
    ...mapGetters('tables', {get_data_headers_loading: 'get_data_headers_loading'}),
    ...mapGetters('tables', {get_data_table: 'get_data_table'}),
    ...mapGetters('tables', {get_data_table_error: 'get_data_table_error'}),
    ...mapGetters('tables', {get_data_table_loading: 'get_data_table_loading'}),
    ...mapGetters('tables', {get_data_dialog: 'get_data_dialog'}),
    ...mapGetters('tables', {get_data_dialog_error: 'get_data_dialog_error'}),
    ...mapGetters('tables', {get_data_dialog_loading: 'get_data_dialog_loading'}),
    ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
    ...mapGetters('spr', {get_spr_data_org_error: 'get_spr_data_org_error'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    headers() {
      let bufArr = this.get_data_headers.filter(el => el.routerLink === '/kcp_contest/kcp')
      return (this.get_data_headers.length === 0 && this.get_data_headers_error === null) ? null : bufArr[this.$scripts.TableJs.arraySearch(bufArr, 1, 'formID')]
    },
    dataHeaders() {
      return (this.headers != null) ? ((Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headers, 'massive')) ? this.headers.headers.massive : []) : []
    },
    dataHeadersForDialog() {
      return (this.headers != null) ? ((Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headersForDialog, 'massive')) ? this.headers.headersForDialog.massive : []) : []
    },
    dataAccessDialog() {
      return (this.headers != null) ? (Object.hasOwnProperty.call(this.headers, 'headers') ? this.headers.accessDialog : false) : false
    },
    headersErrors() {
      return this.get_data_headers.length === 0 ? this.get_data_one_header_error : this.get_data_headers_error
    },
    headersLoading() {
      return this.get_data_headers.length === 0 ? this.get_data_one_header_loading : this.get_data_headers_loading
    },
    dataRows() {
      return {
        content: ((this.headers != null) ? this.get_data_table[this.headers.tableObjectName] : []),
        totalElements: this.get_data_table.counter
      }
    },

  },
  created() {
    if (this.get_spr_data_org.data === undefined || this.get_spr_data_org.data.length === 0
        || this.get_spr_data_org_error !== null || this.get_spr_data_org_loading)
      this.dataForSpr()
    if (this.get_data_headers.length === 0  && !this.get_data_headers_loading) {
      this.getDataForHeaders()
          .then(
              (() => {
                 this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
              })
          ).catch(err => console.log("faiilllll", err))
    } else {
      if (this.headers.endPointForSelect != null)
      this.getDataForTable({
        link: this.headers.endPointForSelect,
        allValues: true,
        pageNumber: this.idPageDefault,
        recordCount: this.numPageDefault
      })
    }
    // this.getFile('967318c9-699c-6d1c-9f48-8f6ecfd1de76',2)
  },
  methods: {
    b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(window.atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    },
    Base64Decode(str, encoding = 'utf-8') {
      let bytes = Base64.toUint8Array(str);
      return new (TextDecoder)(encoding).decode(bytes);
    },
    getFile(fileServerID){
      axios.post('/api/fileServer/downloadFileByID', {fileServerID: fileServerID}, {header: this.$scripts.Utils.authHeader(), responseType: 'json'})
          .then(data => {
              console.log("data",data)
              this.file = data
              // let decoder = new TextDecoder();
              let fff=  this.Base64Decode(data.data.fileBytes)
              console.log("aaa", fff)
              this.buf_file = new File([fff], data.data.fileName/*, {type: data.data.fileformat}*/)
              // console.log("file",this.file.data)
      })
      .catch(err=>console.log("fuck" , err))
    },
    downloadFile(){
      if (!this.buf_file)
      {
        console.log("file empty")
      }
      else{
        console.log("buf_file", this.buf_file)
        let url = URL.createObjectURL(this.buf_file) /*new Blob([])*/
        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.buf_file.name) //or any other extension
        document.body.appendChild(link)
        link.click()
        // console.log("!",URL.createObjectURL(this.buf_file))
        // let reader = new FileReader()
        // reader.readAsDataURL(this.buf_file)
        // reader.onload = () => {
        //   console.log("1",reader.result)
        }

    },
    ...mapActions('spr', {dataForSpr: 'dataForSpr'}),
    ...mapActions('tables', {getDataForHeaders: 'getDataForHeaders'}),
    ...mapActions('tables', {getDataForTable: 'getDataForTable'}),
    ...mapActions('tables', {fetchDataForTable: 'fetchDataForTable'}),
    ...mapActions('tables', {updateDateForTable: 'updateDateForTable'}),
    ...mapActions('tables', {deleteDataFromTable: 'deleteDataFromTable'}),
    actionRow(obj) {
      if (obj.typeAction === 'delete' && obj.id != null) {
        this.deleteDataFromTable({id: obj.id, link: this.headers.endPointForDelete === undefined ? null : this.headers.endPointForDelete})
            .then(() => {
              if (this.headers.endPointForSelect != null)
              this.getDataForTable({
                link: this.headers.endPointForSelect,
                allValues: true,
                pageNumber: this.idPageDefault,
                recordCount: this.numPageDefault
              })
            })
      } else if (obj.typeAction === 'edit' && obj.id != null) {
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        this.dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
      }
      else if (obj.typeAction === 'menuAction'){
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        this.dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
        let sentObj = new Object({})
        let bufObj = new Object(this.dataForDialog)
        bufObj['sprKCPGroupID'] =  bufObj.sprKCPGroup.sprKCPGroupID
        bufObj['orgID'] =  bufObj.sprOrg.orgID
        bufObj[obj.dataField]=  obj.value
        this.$set(sentObj, 'data', bufObj)
        this.$set(sentObj, 'link', this.headers.endPointForUpdate === undefined ? null : this.headers.endPointForUpdate)
        this.$set(sentObj, 'id', obj.id)
        // console.log("!",sentObj)
        this.updateDateForTable(sentObj)
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                  this.dataForDialog=null
                }
            )
      }
    },
    sendRequest(data) {
      if (data.id == null) {
        // console.log("data.id =- null,", {data: {...data}, link: this.headers.endPointForInsert})
        this.fetchDataForTable(new Object({data: {...data}, link: this.headers.endPointForInsert === undefined ? null : this.headers.endPointForInsert}))
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                }
            )
        this.dataForDialog=null
      } else {
        // console.log("data.id != null,", data)
        this.updateDateForTable(new Object({
          data: {...data},
          link: this.headers.endPointForUpdate === undefined ? null : this.headers.endPointForUpdate,
          id: data.id
        }))
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                }
            )
        this.dataForDialog=null
      }
    }
  }
}
</script>

<style scoped>

</style>