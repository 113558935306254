<template>
        <ContainerNewsDetail />
</template>
<script>
import ContainerNewsDetail from '@/containers/cms/news/ContainerNewsDetail';
export default {
    components: {
        ContainerNewsDetail,
    }
}
</script>