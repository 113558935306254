import axios from 'axios'
import utils from "@/utils/utils";
import store from "@/store/index.js";


export default {
    login(payload){
        return axios.post('/api/login',
            {
                userName: payload.username,
                password: payload.password
        })
    },
    async blockEnter(sprOrgId) {
        return axios.get(`/api/userProfile/blockPrint?sprOrgID=${sprOrgId}`, {headers: utils.authHeader()})
    },
    async unblockEnter(sprOrgId) {
        return axios.get(`/api/userProfile/unblockPrint?sprOrgID=${sprOrgId}`, {headers: utils.authHeader()})
    },
    async requestActualOrgStatus(request) {
        if (request) {
            return axios.get(`/api/kcpCollectionReturn/getOrgByID?sprOrgID=${store.getters['auth/get_replace_id_admin']}&userProfile=${request.userProfile}`, {
                headers: utils.authHeader(),
                params: {id: store.getters['auth/get_replace_id_type_org_admin']}
            })
        }
        return null;
    },
    dataTable(request) {
        return axios.post('/' + request.link, request.allValues === true ? {
            pageNumber: -1,
            recordCount: request.recordCount,
            sprOrgID: store.getters['auth/get_replace_id_admin']
        } : {
            pageNumber: request.pageNumber,
            recordCount: request.recordCount,
            sprOrgID: store.getters['auth/get_replace_id_admin']
        }, {headers: utils.authHeader()})
    },
    async saveTable(request) {
        return axios.post('/' + request.link, {
            ...request.data,
            sprOrgID: store.getters['auth/get_replace_id_admin']
        }, {headers: utils.authHeader()})
    },
    async updateTable(request) {
        return axios.post('/' + request.link, {
            ...request.data,
            updateID: request.id,
            sprOrgID: store.getters['auth/get_replace_id_admin']
        }, {headers: utils.authHeader()})
    },
    async deleteFromDataTable(request) {
        return axios.post('/' + request.link, {
            id: request.id,
            sprOrgID: store.getters['auth/get_replace_id_admin']
        }, {headers: utils.authHeader()})
    },
    async requestHeadersForTablesByID(id) {
        return axios.get('/api/zayavka/getHeaderByID', {headers: utils.authHeader(), params: {id: id}})
    },
    async requestAllHeadersForTables() {
        return axios.post('/api/zayavka/getAllHeaders', null, {headers: utils.authHeader()})
    },
    async requestAllHeadersForTablesByOrgType() {
        return axios.get('/api/zayavka/getHeaderBySprOrgTypeID',  {headers: utils.authHeader(), params: {id: store.getters['auth/get_replace_id_type_org_admin']}})
    },
    async sprDataLoad(arr) {
        let request = []
        arr.forEach(el => {
            request.push(axios.post(`/api/kcpCollectionReturn/${el}`, el === 'getUsl' ? {id: 0} : {
                from: 0,
                to: 0
            }, {headers: utils.authHeader()}))
        })
        return axios.all(request)
    },
    requestNewsList(data) {
        return axios.post('/api/mainPage/getAllArticles', data);
    },
    requestNewsDetail(data) {
        return axios.get(`/api/mainPage/getOneArticle/?id=${data}`);
    },
    sendMainPageQuestionForm(data) {
        return axios.post('/api/mainPage/saveQuestion', data);
    },
    requestAllDocuments() {
        return axios.post('/api/mainPage/getAllDocuments', {"pageNumber": 0, "recordCount": 6});
    },
    requestAllQuestions() {
        return axios.post('/api/mainPage/getAllQuestions', {"pageNumber": 0, "recordCount": 6});
    },
    requestAllFAQ() {
        return axios.post('/api/mainPage/getAllQuestions', {"pageNumber": 0, "recordCount": 6});
    },
    
}