<template>
  <div>
    <component :is="layout"/>
  </div>
</template>
<script>
import InProgress from "@/components/InProgress";
// import zayavkaKcpStructureOrg from "@/pages/zayvkaKcp/zayavkaKcpStructureOrg.vue"
// import zayavkaKcpStructureFounder from "@/pages/zayvkaKcp/zayavkaKcpStructureFounder"
import founderStageOne from "@/views/private/stages/founderStageOne";
import {mapGetters} from "vuex"
export default {
  name: 'respresStages',
  components: {
    InProgress,
    // zayavkaKcpStructureOrg,
    // zayavkaKcpStructureFounder,
    founderStageOne
  },
  computed: {
    ...mapGetters('auth', {get_replace_role_id_admin:'get_replace_role_id_admin'}),
    layout() {
      let nameComponent = 'zayavkaKcpStructureOrg'
      switch (this.get_replace_role_id_admin){
        // case 1:
        //   nameComponent = 'zayavkaKcpStructureOrg'
        //   break
        case 4:
          nameComponent = 'founderStageOne'
          break
        default:
          nameComponent = 'InProgress'
          break
      }
      return nameComponent;
    }
  },
}

</script>