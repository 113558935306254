//Пагинатор возвращает количество элементов для отображения, актуальную страницу, индексы элементов для запроса с сервера

<template>
	<div class="paginator__section">
	<div class="paginator">
		<button class="paginator__arrow" @click="onClickPrevPage">
			<v-icon :size="30" v-show="$attrs.value.selectedPage > 1" class="paginator__icon"> mdi-chevron-left</v-icon>
		</button>
		<div class="paginator__pages">
			<button @click="onChangePage(1)" class="paginator__page" v-show="$attrs.value.selectedPage > 1">1</button>
			<button class="paginator__page" v-show="$attrs.value.selectedPage > 3">...</button>
			<button @click="onChangePage($attrs.value.selectedPage - 1)" class="paginator__page" v-show="$attrs.value.selectedPage > 2">{{$attrs.value.selectedPage-1}}</button>
			<span class="paginator__page paginator__page_selected">{{$attrs.value.selectedPage}}</span>
			<button @click="onChangePage($attrs.value.selectedPage + 1)" class="paginator__page" v-show="$attrs.value.selectedPage < pagesCount">{{$attrs.value.selectedPage+1}}</button>
			<button class="paginator__page" v-show="$attrs.value.selectedPage+2 < pagesCount">...</button>
			<button @click="onChangePage(pagesCount)" class="paginator__page" v-show="$attrs.value.selectedPage < pagesCount-1">{{pagesCount}}</button>
		</div>
		<button class="paginator__arrow" @click="onClickNextPage">
			<v-icon :size="30" v-show="$attrs.value.selectedPage < pagesCount" class="paginator__icon"> mdi-chevron-right</v-icon>
		</button>
	</div>

		<BSelect class="paginator__items-per-page" :data="optionsPerPage" v-model="$attrs.value.itemsPerPage" @input="onChangeItemsPerPage"/>
	</div>

</template>


<script>
	import BSelect from '@/components/controls/BSelect/BSelect';

	export default {
		components: {
			BSelect
		},
		props: {
			// Кол-во элементов всего
			propDataCount: {
				type: Number,
				required: true,
			},
			// Массив с вариантами кол-ва элементов
			propOptionsPerPage: {
				type: Array,
				default: () => {
					return [5, 10, 20, 100, 1000]
				},
			}

		},
		computed: {
			pagesCount() {
				return Math.ceil(this.propDataCount / this.$attrs.value.itemsPerPage);
			},
			itemsForRequest() {
				return {
					indexFirstItem: (this.$attrs.value.itemsPerPage * (this.$attrs.value.selectedPage - 1) + 1) - 1,
					indexLastItem: this.$attrs.value.itemsPerPage * this.$attrs.value.selectedPage
				}
			},
			optionsPerPage() {
					const indexItemToDelete = this.propOptionsPerPage.findIndex(el => el > this.propDataCount);
					if (indexItemToDelete !== -1) {
						const newOptionsPerPage = this.propOptionsPerPage.slice(0, indexItemToDelete);
						newOptionsPerPage.push(this.propDataCount);
						return newOptionsPerPage;
					} else {
						return this.propOptionsPerPage
					}
			}
		},
		methods: {
			onClickNextPage() {
				this.$attrs.value.selectedPage += 1;
				this.onChange();
			},
			onClickPrevPage() {
				this.$attrs.value.selectedPage -= 1;
				this.onChange();
			},
			onChangeItemsPerPage() {
				this.$attrs.value.selectedPage = 1;
				this.onChange();
			},
			onChangePage(page) {
				this.$attrs.value.selectedPage = page;
				this.onChange();
			},
			onChange() {
				this.$emit('input', {'selectedPage' : this.$attrs.value.selectedPage, 'itemsPerPage' : this.$attrs.value.itemsPerPage, 'itemsForRequest' : this.itemsForRequest});
			}

		},
		mounted() {
			this.onChange();
		}
	}
	
</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"
.paginator__section 
    display: flex
    justify-content: center
.paginator
    display: flex   
.paginator__items-per-page 
    margin-left: 15px
.paginator__page_selected 
    background: $firstColor
    color: #fff
.paginator__pages
    background: $color-b
    border-radius: 5px
    display: flex
.paginator__page 
    min-width: 25px
    max-width: 35px
    flex-basis: 0px
    flex-grow: 1
    padding: 5px 15px
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
.paginator__page:hover
    background: #d9dee3
    transition: 0.3s
.paginator__page_selected
    transform: scale(1.1)
    border-radius: 5px
    box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
.paginator__page_selected:hover
    background: $color-1
.paginator__arrow
    padding: 0px 10px  
.paginator__icon
    color: $color-1 !important
</style>