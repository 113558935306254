<template>
    <WrapperForm>
		<form class='form'>
            <Field
                type="text"
                v-model="form.orgFullName"
                name="orgFullName"
                placeholder="Введите полное наименование"
                label="Полное наименование организации"
                @input="onChange"
            />
            <Field
                type="text"
                v-model="form.orgName"
                name="organization"
                placeholder="Введите наименование"
                label="Наименование организации без организационно правовой формы"
                @input="onChange"
            />

            <Field
                type="text"
                v-model="form.orgShortName"
                name="organizationShort"
                placeholder="Введите наименование"
                label="Сокращенное наименование организации"
                @input="onChange"
                
            />

   <!--          <Field
                type="text"
                v-model="form.sprOrgType.orgTypeName"
                name="sprOrgType"
                placeholder="Введите тип"
                label="Тип организации"
                @input="onChange"
                
            /> -->

          <!--   <div class="field">
                <label for="name" class="label">Тип организации</label>
                <BSelect class="paginator__items-per-page" :data="form.sprOrgType" v-model="form.sprOrgType" />

            </div>
 -->

            <Field
                type="text"
                v-model="form.orgFounderName"
                name="organizationFounder"
                placeholder="Введите учредителя"
                label="Наименование учредителя в родительном падеже"
                @input="onChange"
                
            />

            <Field
                type="text"
                v-model="form.orgAddress"
                name="organizationAddress"
                placeholder="Введите адрес"
                label="Адрес"
                @input="onChange"
            />

            <Field
                type="text"
                v-model="form.inn"
                name="organizationInn"
                placeholder="Введите ИНН"
                label="ИНН"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.inn}"
            />

            <Field
                type="text"
                v-model="form.kpp"
                name="organizationKpp"
                placeholder="Введите КПП"
                label="КПП"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.kpp}"
            />

            <v-file-input
                label="Документ"
                v-model="form.file"
                class="field__input_file"
                :class="{'field_invalid' : !this.validator.file}"
            />


          
                <button :disabled="!isOnButtonSave" @click.prevent="onSubmit" :class="['button', {'button_disabled': !isOnButtonSave}]">Заявка на изменение</button>
                <button class="button_inline" @click.prevent="cancel">Отмена</button>

		</form>
     </WrapperForm>    
</template>    

<script>
import { minLength, maxLength, integer  } from 'vuelidate/lib/validators'
import Field from '@/components/fields/Field/Field';
// import BSelect from '@/components/controls/BSelect/BSelect';
import WrapperForm from '@/components/wrappers/WrapperForm';
export default {
    components: {
        Field,
        WrapperForm,
        // BSelect
    },
    data() {
        return {
           isOnButtonSave: false,
           validator: {
               inn: true,
               kpp: true,
               file: true,
            },
            form: {
                orgFullName: this.$attrs.value.orgFullName,
                orgName: this.$attrs.value.orgName,
                orgShortName: this.$attrs.value.orgShortName,
                orgFounderName: this.$attrs.value.orgFounderName,
                sprOrgType: this.$attrs.value.sprOrgType,
                orgAddress: this.$attrs.value.orgAddress,
                inn: this.$attrs.value.inn,
                kpp: this.$attrs.value.kpp,
                orgOpCode: this.$attrs.value.orgOpCode,
                file: null,
            },
            
        }
    },
    computed: {
        orgType() {
            return this.$attrs.value.sprOrgType;
                }
    },

    validations: {
            form: {
                inn: {
                    integer: integer,
                    minLength: minLength(10),
                    maxLength: maxLength(10),
                },
                kpp: {
                    integer: integer,
                    minLength: minLength(9),
                    maxLength: maxLength(9),
                },
            }
        },
    methods: {
        onSubmit() {
            this.$v.form.$touch();
            this.validator.inn = this.$v.form.inn.$invalid ? false : true;
            this.validator.kpp = this.$v.form.kpp.$invalid ? false : true;
            this.validator.file = this.form.file !== null;

            let check = Object.values(this.validator);
            if (check.every(el => el === true)) {
                this.$emit('input', this.form);
              
            }

        },
        onChange() {
            this.isOnButtonSave = true;
        },
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>

<style lang="sass">
.button_inline
    margin: 10px
    color: #00599b
    text-decoration: underline
.field__input_file
    width: 100%
.field__input_file.field_invalid
    background: #f8d7da
.field__input_file
    margin-bottom: 10px        
</style>