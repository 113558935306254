<template>
    <li class="breadcrumbs__item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
      <a href="/" itemprop="item">
        <span itemprop="name">{{item.name}}</span>
      </a>
      <meta itemprop="position" content="3" />
    </li>


</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
			}
		},
	}
</script>


<style>
</style>