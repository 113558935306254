<template>
  <section class="letters">
    <!-- <Заголовок> -->
    <HeaderMain title="Формы об ошибке" />
    <!-- <Заголовок> -->
    <!-- <Выбор по статусу> -->
    <BSelect :data="selectedOptions" v-model="selectedOption" class="letters__select-type" />
    <!-- </Выбор по статусу> -->
    <!--<Алерт>-->
    <Alert alertType="primary" v-if="selectedTypeFormErrors && !selectedTypeFormErrors.length">
      <template v-slot:icon>
        <v-icon :size="30" color="#084298">mdi-information-outline</v-icon>
      </template>
      <template v-slot:default>Активных нет</template>
    </Alert>
    <!--</Алерт>-->
    <!--<Список>-->
    <CardApellation v-for="item in selectedTypeFormErrors" :key="item.key" :item="item" @acceptFormErrors="onAccept($event)" @rejectFormErrors="onReject($event)" />
    <!--<Модальное окно>-->
    <!-- <Modal v-on:input="onCancelAcceptConvert" v-if="modalOpen" title="Принять конверт">
      <AlertError v-if="errorSending">Произошла ошибка, повторите запрос</AlertError>
      <FormAcceptConvert @acceptConvertApp="onSendOkAcceptConvert($event)" @cancel="onCancelAcceptConvert" />
    </Modal> -->
    <!--</Модальное окно>-->
    <!--</Список>-->
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/alerts/Alert';
// import AlertError from '@/components/alerts/AlertError';
// import Modal from '@/components/modals/Modal';
import request from "@/services/request";
import CardApellation from "@/components/cards/custom/CardApellation/CardApellation";
import BSelect from "@/components/controls/BSelect/BSelect";
import HeaderMain from '@/components/headers/HeaderMain';

export default {
  components: {
    CardApellation,
    // Modal,
    BSelect,
    Alert,
    HeaderMain,
    // AlertError
  },
  data() {
    return {
      formErrors: [],
      errorSending: null,
      modalOpen: false,
      selectedOptions: ['Активные ошибки', 'Обработанные ошибки', 'Все ошибки'],
      selectedOption: 'Все ошибки',
    }
  },
  mounted() {
    this.getFormErrors();
  },

  computed: {
    ...mapGetters('auth', { authData: 'authData' }),
    selectedTypeFormErrors() {
      if (this.selectedOption === 'Активные ошибки') {
        return this.formErrors.filter(el => el.formErrorsStatus === 'NOT_SET')
      } else if (this.selectedOption === 'Обработанные ошибки') {
        return this.formErrors.filter(el => el.formErrorsStatus !== 'NOT_SET')
      } else {
        return this.formErrors;
      }
    }
  },

  methods: {
    async getFormErrors() {
      let formErrors = await request({
        endpoint: `/api/admin/getAllFormErrors`,
        method: 'GET',
      });
      formErrors = formErrors.data;
      this.formErrors = formErrors.formErrors;
    },
    async onAccept(errorForm) {
      await request({
                    endpoint: '/api/admin/acceptFormError',
                    method: 'POST',
                    body: {
                         errorID: errorForm.errorID,
                         errorAnswer: errorForm.errorAnswer,
                        }
                });
      this.getFormErrors();

    },

    async onReject(errorForm) {
    await request({
                        endpoint: '/api/admin/rejectFormError',
                        method: 'POST',
                        body: {
                            errorID: errorForm.errorID,
                            errorAnswer: errorForm.errorAnswer,
                            }
                    });
      this.getFormErrors();
    },
  }
}
</script>

<style lang="sass">
.letters
        width: 100%  
.info-card__button
        margin-top: 10px
        margin-left: 10px   
.info-card__button
        margin-top: 10px
        margin-left: 10px
.info-card__button:hover
        background: #0292e1
.info-card__cell_changed
        background: #fff3cd
        border-radius: 3px
        color: #856404
        padding: 0px 3px
.modal-content
        max-width: 900px
.info-cards
        display: flex
.letter__select-type
        margin-bottom: 20px
        margin-top: 10px
        background-color: #fff
        padding: 5px 10px
        border: 1px solid #00599b
        cursor: pointer
        min-width: 200px
.apellation_error__text
    margin-right: 10px
    border: 1px solid #00599b
    border-radius: 10px
    padding: 10px
    width: 300px        
</style>