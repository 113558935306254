<template>
  <v-container>
    <!--  <cardTopCustomIndicatorDetail :row-of-values="rowOfValues" :item="item"/>-->
    <card-top-description :item="item" :row-of-values="rowOfValues"/>
    <card-top-tables :item="item" :row-of-values="rowOfValues"/>
    <card-bottom v-if="item.dataFieldName  !== 'generalValue'" :available="available" :row-of-values="rowOfValues" :item="item"/>
  </v-container>
</template>

<script>
import CardBottom from "@/components/cards/custom/indicatiors/details/cardBottom";
import CardTopDescription from "@/components/cards/custom/indicatiors/details/cardTopDescription";
import CardTopTables from "@/components/cards/custom/indicatiors/details/cardTopTables";

export default {
  name: "containerIndicatorBody",
  components: {
    CardTopTables,
    CardTopDescription,
    CardBottom,
    // cardTopCustomIndicatorDetail
  },
  props: {
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    available: {
      type: Array,
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
}
</script>

<style scoped>

</style>