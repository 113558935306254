<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col>
<!--                <p class="tittle-main-page text-center mt-11" style="color: #001520;">-->
<!--                    В процессе разработки-->
<!--                </p>-->
        <v-progress-linear
                indeterminate
                color="#00599b"
        ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "InProgress"
    }
</script>

<style scoped>

</style>