<template>
    <div class="nav_lk-sidebar">
        <div class="nav-lk__section">
            <div class="nav-lk__item">
                <img src="@/assets/img/icon-nav-lk.svg" class="nav-lk__icon icon_nav-lk">
                <router-link :to="{name: 'RegionMain'}" class="nav-lk__main-link">Личный кабинет</router-link>
            </div>
        </div>

        <div class="nav-lk__section">

            <div class="nav-lk__item">
                <img src="@/assets/img/icon-nav-other.svg" class="nav-lk__icon icon_nav-lk">
                <router-link :to="{name: 'RegionOCKP'}" class="nav-lk__main-link">
                    Установление объемов КЦП-ВО-2023
                </router-link>
            </div>
            <div class="nav-lk__under-items">
                <router-link :to="{name: 'RegionStageTwo'}" class="nav-lk__under-link" style="color: #6B7C85">
                    Этап 2 распределения объемов КЦП на 2023/24 учебный год
                </router-link>
            </div>
            <div class="nav-lk__under-items">
                <router-link :to="{name: 'RegionContactCO'}" class="nav-lk__under-link" style="color: #6B7C85">
                    Контакты центров ответственности
                </router-link>
            </div>
<!--            <div class="nav-lk__item">-->
<!--                <img src="@/assets/img/icon-nav-other.svg" class="nav-lk__icon icon_nav-lk">-->
<!--                <router-link :to="{name: 'RegionKcp'}" class="nav-lk__main-link">Распределение КЦП 2022/23</router-link>-->
<!--            </div>-->
<!--            <div class="nav-lk__under-items">-->
<!--                <router-link :to="{name: 'RegionOrgList'}" class="nav-lk__under-link" style="color: #6B7C85">-->
<!--                    Распределение КЦП между образовательными организациями-->
<!--                </router-link>-->
<!--            </div>-->
<!--            <div class="nav-lk__under-items">-->
<!--                <router-link :to="{name: 'RegionOrgUslList'}" class="nav-lk__under-link" style="color: #6B7C85">-->
<!--                    Распределение КЦП между образовательными организациями с уточнением по уровню образования-->
<!--                </router-link>-->
<!--            </div>-->
        </div>
        <div class="nav-lk__section">
            <div class="nav-lk__item">
                <a
                        href="https://files.ined.ru/adminpanel/cms_article_editor_32_66666_5b2b8fb4611984c5edc782498b8aaebf.pdf"
                        target="_blank"
                        class="nav-lk__main-link">
                    Инструкция
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sideBar",
        data: () => ({
        }),
    }
</script>
<style lang="sass" scoped>
    .nav_lk-sidebar
        display: flex
        flex-direction: column
        margin-top: 28px

    .nav-link_lk-sidebar:hover
        color: #eb8e1c

    .nav-link_lk-sidebar.nav-link_lk-sidebar_prime
        color: #646262
        font-size: 14px
        margin-left: 5px

    .nav-item_lk-sidebar
        display: flex
        align-items: flex-start
        margin-left: 20px


    .nav-lk__section
        margin-bottom: 25px

    .nav-lk__item
        display: flex

    .icon_nav-lk
        width: 40px
        height: 40px

    .nav-lk__icon
        margin-right: 16px

    .nav-lk__main-link
        color: #00599B
        font-size: 18px

    .nav-lk__under-link
        color: #426075 !important
        line-height: 1.2
        font-size: 16px

    .icon_under-items
        height: 12px
        width: 6px

    .nav-lk__under-icon
        margin-right: 10px

    .nav-lk__main-link
        display: flex
        align-items: center

    .nav-lk__under-items
        margin-bottom: 18px
        padding-left: 48px
</style>