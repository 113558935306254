import {Base64 } from 'js-base64';

export default function
    saveFile(file)
    {
        const decoderFile = file.withoutEncode ?  file.fileBytes : Base64.toUint8Array(file.fileBytes);
        const saveFile = new File([decoderFile], file.fileName + (file.fileFormat ? '.' + file.fileFormat : ''));
        const fileURL = window.URL.createObjectURL(saveFile);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', file.fileName + (file.fileFormat ? '.' + file.fileFormat : ''));

        document.body.appendChild(fileLink);
        fileLink.click();
    }

