<template>
	<div class="lk">
		<LayoutFrontTopbar/>
		<!-- Контентная часть --> 
		<section class="lk-main" style="min-height: 100%;">
		<component :is="layout">
			<v-container>
				<!-- <Breadcrumbs :data="breadcrumbs"/> -->
			</v-container>
			
		</component>
		</section>
		
	</div>
</template>

<script>
// import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import LayoutFrontTopbar from "@/layout/LayoutFront/LayoutFrontTopbar";

import LayoutLkContentDefault from "@/layout/LayoutLk/layoutLkContent/LayoutLkContentDefault";

export default {
	components: {
		// Breadcrumbs,
		LayoutFrontTopbar,
		LayoutLkContentDefault,
	},
	data () {
		return {
			breadcrumbs: [
				{link: '/', name: 'Главная', id: 1},
				{link: '/', name: 'Хлебные крошки 2', id: 2},
				{link: '/', name: 'Хлебные крошки 3', id: 3},
			],
		}
	},
	computed: {
		layout() {
			return this.$route.meta.layout ? this.$route.meta.layout : 'LayoutLkContentDefault';
		}
	},
}
</script>


<style lang="sass">
.lk
    height: 100%
    background: #f8f9fb
</style>