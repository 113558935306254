<template>
	<article class="news-item" itemscope itemtype="http://schema.org/NewsArticle" :class="{'news-item_important' : item.important}">
		<header class="news-item__header">
		<time class="news-item__date" itemprop="dateline">
			<div class="news-item__day">{{item.date.day}}</div>
			<div class="news-item__month">{{item.date.month}}</div>
			<div class="news-item__year">{{item.date.year}}</div>
		</time>
		</header>
		<div class="news-item__content">
			<h3 class="news-item__title" :class="{'news-item__title_important' : item.important}" itemprop="headline">{{item.title}}</h3>
			<div class="news-item__preview-text" itemprop="about">
				{{item.previewText}}...
			</div>
			<router-link :to="/news/ + item.id" class="news-item__more-link">Подробнее</router-link>
		</div>
	</article>
</template>	

<script>
export default {
	props: {
		item: {
			type: Object,
		}
	},
}
</script>
<style lang="sass">
	.news-card 
		flex-basis: 30%
</style>