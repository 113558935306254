<template>
  <v-form
      class="contact-card__input-1"
      ref="form_contact"
      v-model="valid"
      lazy-validation
  >
    <div class="info-card__field">
      <div class="info-card__name">
        Фамилия Имя Отчество:
      </div>
      <v-text-field
          v-model="form.fio"
          :rules="[rules.required, rules.counter]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>
    <div class="info-card__field">
      <div class="info-card__name">
        Должность:
      </div>
      <v-text-field
          v-model="form.position"
          :rules="[rules.required, rules.counter]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>
    <div class="info-card__field">
      <div class="info-card__name">
        Контактный e-mail:
      </div>
      <v-text-field
          v-model="form.email"
          :rules="[rules.required, rules.email]"
          class="info-card__cell"
          color="#00599B"
          outlined
          dense
      />
    </div>
    <div class="info-card__field">
      <div class="info-card__name pb-0">
        Номер рабочего телефона:
      </div>
      <VuePhoneNumberInput
          class=""
          v-model="form.workPhoneNumber"
          :only-countries="['RU']"
          default-country-code="RU"
          valid-color="#00599B"
          error-color="#ED393E"
          :error="!validator.workPhoneNumber"
          color="#546e7a"
          @update="inputPhoneValue($event, 'workPhoneNumber','workPhoneNumber')"
          @input="inputPhone($event,'workPhoneNumber')"
          :translations="{
  countrySelectorLabel: 'Код страны',
  countrySelectorError: 'Неверный код страны',
  phoneNumberLabel: 'Номер рабочего телефона',
  example: 'Пример :'
}"
      />
    </div>
    <div class="info-card__field mt-4">
      <div class="info-card__name pb-0">
        Номер мобильного телефона:
      </div>
      <VuePhoneNumberInput
          v-model="form.personalPhoneNumber"
          :only-countries="['RU']"
          default-country-code="RU"
          valid-color="#00599B"
          error-color="#ED393E"
          :error="!validator.personalPhoneNumber && !ignorePersonalPhoneNumber"
          color="#546e7a"
          @input="inputPhone($event,'personalPhoneNumber')"
          @update="inputPhoneValue($event, 'personalPhoneNumber','personalPhoneNumber')"
          :translations="{
  countrySelectorLabel: 'Код страны',
  countrySelectorError: 'Неверный код страны',
  phoneNumberLabel: 'Номер мобильного телефона',
  example: 'Пример :'
}"
      />
    </div>
    <div class="d-flex justify-end mt-4">
      <v-btn
          :disabled="!isOnButtonSave || savingData"
          @click="sendData"
          @keyup.enter="sendData"
          :loading="savingData"
          :class="['success__v-btn mr-2', {'button_disabled': !isOnButtonSave}]">
        Сохранить
      </v-btn>
      <v-btn class="white__v-btn " @click.prevent="cancel">Отмена</v-btn>
    </div>
  </v-form>

</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input/dist/vue-phone-number-input.common';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  name: "CardInput",
  components: {
    VuePhoneNumberInput,
  },
  props: {
    value: {
      type: Object,
    },
    savingData:{
      type: Boolean,
      default: false
    },
    ignorePersonalPhoneNumber: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      valid: false,
      isOnButtonSave: false,
      countryCode: '7',
      validator: {
        email: true,
        workPhoneNumber: false,
        personalPhoneNumber: false,
        position: true,
        fio: true
      },
      form: {
        fio: this.value.fio,
        position: this.value.position,
        email: this.value.email,
        workPhoneNumber: this.value.workPhoneNumber,
        personalPhoneNumber: this.value.personalPhoneNumber,
        personID: this.value.personID
      },
      rules: {
        required: value => !!value || 'Обязательное поле!',
        counter: value => value.length > 10 || 'Поле не может содержать меньше 10 символов',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неверно указан адрес почты'
        },
      },
    }
  },
  methods: {
    inputPhone(str,  variable){
      if (!str)
        this.form[variable] = ''
      this.onChange()
    },
    inputPhoneValue(obj, validateVariable, variable){
      this.form[variable] = obj.formattedNumber;
      this.countryCode = obj.countryCallingCode
      this.$nextTick(() => {
        this.validator[validateVariable] = obj.isValid
      });
    },
    async validate() {
      this.$refs.form_contact.validate()
    },
    async sendData(){
      await this.validate();
      this.validator.personalPhoneNumber = this.validator.personalPhoneNumber || this.ignorePersonalPhoneNumber;
      const check = Object.values(this.validator);
      if (this.valid && check.every(el => el === true)) {
        this.$emit('input', {...this.value,...this.form})
      }
      else
        console.log("не все данные заполнены")
    },
    onChange() {
      this.isOnButtonSave = true;
    },
    cancel() {
      this.$emit('cancel');
    }
  }

}
</script>

<style lang="sass" >
.contact-card__input-1
  .input-country-selector
    input
      background-color: #E3EFFA!important
      color: #00599B
  .input-phone-number
    input
      background-color: #E3EFFA!important
      color: #00599B
  .info-card__name
    color: #455058
    font-size: 16px
    margin-right: 21px
    white-space: nowrap
    line-height: 18.75px
    font-weight: 400
  .info-card__cell
    &.v-text-field--outlined fieldset :not(.error--text)
      //&:not(.error--text)
      border-color: #0D72BD
      border-radius: 7px
      max-height: 36px
    .v-input__slot
      min-height: 20px!important
      max-height: 36px!important
      background: #E3EFFA!important
    .v-text-field__slot
      input
        color: #00599B!important

  .info-card__field
    display: flex
    align-items: center
  //margin-bottom: 14px

  .info-card__button
    margin-top: 10px
    margin-left: 10px
</style>