<template>
	<div>
		<h3 class="section-header__subtitle subtitle_contact-preview">Ответственные представители по вопросам проведения конкурса:</h3>
		<div class="contact-preview">
		<CardContact v-for="(contact, index) in contactsEvents" :item="contact" :key="index" />	
		</div>

		<h3 class="section-header__subtitle subtitle_contact-preview">Ответственные представители по вопросам проведения конкурса:</h3>
		<div class="contact-preview">
		<CardContact v-for="(contact, index) in contactsApplications" :item="contact" :key="index" />	
		</div>
		
	</div>
</template>

<script>
import CardContact from "@/components/cards/custom/CardContact/CardContact";
export default {
	components: {
		CardContact
	},
	data () {
		return {
			contactsEvents: [
				{name: 'Грекова Кристина Петровна', phone: '8 (495) 547-13-66 (доб. 7224)', email: 'grekovakp@minobrnauki.gov.ru'},
				{name: 'Дулатов Ильшат Тагирович', phone: '8 (495) 547-13-66 (доб. 7215)', email: 'dulatovit@minobrnauki.gov.ru'},
				{name: 'Дьяков Дмитрий Александрович', phone: '8 (495) 547-13-66 (доб. 7339)', email: 'dyakovda@minobrnauki.gov.ru'},
			],
			contactsApplications: [
				{name: 'Горозий Елена Александровна', phone: '8 (910) 460-40-43', email: 'ea.goroziy@ined.ru'},
				{name: 'Михаленко Алина Александровна', phone: '8 (926) 417-36-69', email: 'aa.mihalenko@ined.ru'},
			],
		}
	}
}
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"

.contact-preview
    margin-bottom: 20px
    display: flex
    flex-wrap: wrap
.subtitle_contact-preview
    font-family: Montserrat
    font-size: 18px
    font-weight: 600
    line-height: 16px
    letter-spacing: 0.001em
    margin-bottom: 26px
    color: #00599B  
.l-contact-item
    padding: 30px 0px 20px
.contact-item__request
    font-weight: 600
    font-size: 16px
    max-width: 300px
.layout-contact__item
    display: flex
    flex-direction: column
    margin-bottom: 20px

</style>