<template>
  <article class="cms-card-news" itemscope itemtype="http://schema.org/NewsArticle">
        <div class="card-info">
            <div :to="'/kcp-contest-admin/reference/' + item.regionID" class="card__info card__info__id">
                {{item.regionID ? item.regionID : '-'}}
            </div>
            <div class="card__info">
                <h3 class="cms-card-news__title" itemprop="headline">{{item.regionName ? item.regionName : '-'}}</h3>
            </div>
<!--            <div class="card__info card__info__action">-->
<!--               <router-link :to="'/kcp-contest-admin/reference/' + item.orgID" class="button_border">Подробнее</router-link>-->
<!--            </div>-->
<!--          <div class="card__info card__info__action">-->
<!--            <button class="button_border" @click="blockRegion(item)">{{item.block ? 'Разблокировать' : 'Заблокировать'}}</button>-->
<!--          </div>-->
            <div class="card__info card__info__action">
               <button class="button_border" @click="onAuthInRegion(item)">Войти</button>
            </div>
      </div>
  </article>
</template> 

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    onAuthInRegion(item) {
        this.$emit('auth-in-region', new Object({regionID:  item.regionID, role: 9}));
    },
    blockRegion(item){
      this.$emit('block-region', new Object({regionID:  item.regionID, block: item.block}));
    }
  }
 }
</script>
<style lang="sass">
.cms-card-news__actions
    display: flex
.cms-card-news
    background: #fff
    margin-bottom: 15px
    padding: 5px 10px
    display: flex
    justify-content: space-between
    align-items: center
    border-left: 3px solid #00599b
    transition: 0.3s
.cms-card-news__header
    flex-grow: 0
    flex-basis: 700px
    overflow: hidden
.cms-card-news__title
    font-weight: 500
    color: #00599b
.cms-card-news:hover
    transform: translateY(-5px)
    box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
    transition: 0.3s
.cms-card-news__edit
    background: #fff3cd
    padding: 3px  
    border-radius: 3px
    border: 1px solid #ffeeba
    margin-right: 10px
    cursor: pointer
.cms-card-news__edit:hover
    background: #ffeeba
.cms-card-news__delete
    background: #f8d7da
    padding: 3px
    border-radius: 3px
    border: 1px solid #f5c6cb
    cursor: pointer
.cms-card-news__delete:hover
    background: #f5c6cb
.card-info
    display: flex
    width: 100%
    align-items: center
.card__info
    flex-basis: 0px
    flex-grow: 1
    margin-right: 10px
    min-height: 50px
    display: flex
    align-items: center

.card__info__status, .card__info__id, .card__info__action
    flex-basis: 150px
    flex-grow: 0    
</style>