<template>
	<div class="page">
		<LayoutFrontTopbar />
		<!-- Контентная часть --> 

		<section class="main-content">
		<component :is="layout">
			<v-container>
				<!-- <Breadcrumbs :data="breadcrumbs"/> -->
			</v-container>
		</component>
		</section>
		<Footer/>
	</div>
</template>

<script>
// import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import TopBar from "@/layout/TopBar";
import Footer from "@/layout/Footer";
import LayoutFrontTopbar from "@/layout/LayoutFront/LayoutFrontTopbar";

import LayoutFrontContentDefault from "@/layout/LayoutFront/layoutFrontContent/LayoutFrontContentDefault";
import LayoutFrontContentMain from "@/layout/LayoutFront/layoutFrontContent/LayoutFrontContentMain";
import LayoutFrontContentDetail from "@/layout/LayoutFront/layoutFrontContent/LayoutFrontContentDetail";

export default {
	components: {
		// Breadcrumbs,
		TopBar,
		Footer,
		LayoutFrontContentDefault,
		LayoutFrontContentDetail,
		LayoutFrontContentMain,
		LayoutFrontTopbar
	},
	data () {
		return {
			breadcrumbs: [
				{link: '/', name: 'Главная', id: 1},
				{link: '/', name: 'Хлебные крошки 2', id: 2},
				{link: '/', name: 'Хлебные крошки 3', id: 3},
			],
		}
	},
	computed: {
		layout() {
			return this.$route.meta.layout ? this.$route.meta.layout : 'LayoutFrontContentDefault';
		}
	},
}
</script>