<template>

    <v-flex
        v-if="!authStatus"
        text-center
        class="login-form__wrapper"
    >
      <h1 class="ma-3 login-form__title">
        Авторизация в личном кабинете
      </h1>
      <v-card
          class="pa-5 login-form"
      >
        <v-form
        class="login-form__form">
          <v-text-field
              v-model="loginName"
              label="Логин"
              type="text"
              :rules="loginNameRules"
              class="login-form__field"
              required
          />
          <v-text-field
              v-model="password"
              label="Пароль"
              type="password"
              :rules="passwordRules"
              class="login-form__field"
              required
              @keyup.enter="login"
          />
          <v-btn @click="login" class="login-form__button">
            Авторизоваться
          </v-btn>
        </v-form>
      </v-card>
    </v-flex>
    <v-flex
        v-else
        text-center>
      <div v-if="nameOrg=== false">
        <in-progress/>
      </div>
       <h2 v-else>
       Личный кабинет {{nameOrg}}
     </h2>
    </v-flex>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import InProgress from "@/components/InProgress";
export default {
name: "Login",
  data: () => ({
    loginName : '',
    loginNameRules: [
      v => !!v || 'Обязательное поле ввода',
    ],
    password : '',
    passwordRules: [
      v => !!v || 'Обязательное поле ввода',
    ]
  }),
  components:{
    InProgress
  },
  computed: {
    ...mapGetters('auth',{authStatus:'authStatus'}),
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
    ...mapGetters('spr', {get_spr_data_org_error: 'get_spr_data_org_error'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    nameOrg(){
      if (this.get_spr_data_org.data !== undefined && this.get_spr_data_org.data.length>= 0
          && this.get_spr_data_org_error=== null && !this.get_spr_data_org_loading)
      {
        if (this.authData.role.id === 1)
          return this.get_spr_data_org.data.filter(el => el.orgID === this.authData.userProfile)[0].orgFullName
        else if(this.authData.role.id === 6)
          return this.authData.fio
        else if (this.authData.role.id === 4)
          return this.authData.sprOrgFounderName
        else
          return ''
      }
      else
        return false
    }
  },
  watch: {
    authStatus: function () {
      if (this.authStatus) {
        if (this.authData.role.id === 1) {
          this.$router.push({name: 'LkMain'});
        } else if (this.authData.role.id === 9) {
            this.$router.push({name: 'RegionMain'});
        } else if (this.authData.role.id === 14) {
          this.$router.push({name: 'AdminMain'});
        } else if (this.authData.role.id === 10) {
          this.$router.push({name: 'AdminFounders'});
        } else if (this.authData.role.id === 15) {
          this.$router.push({name: 'CMSMain'});
        } else if (this.authData.role.id === 4 || this.authData.role.id === 6) {
          this.$router.push({name: 'LkMain'});
        }
      }
    }
  },
  methods: {
    ...mapActions('auth',{loginX:'login'}),
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('spr', {dataForSpr:'dataForSpr'}),
    login ()
    {
      let data = {username: this.loginName, password: this.password};
      this.loginX(data);
    }
  }
}
</script>

<style lang="sass" >

.login-form__title
  font-family: Montserrat
  font-size: 32px
  font-style: normal
  font-weight: 700
  line-height: 35px
  letter-spacing: 0em
  text-align: center
  color: #00599B
  max-width: 317px
.login-form__wrapper
    display: flex
    flex-direction: column
    align-items: center
.login-form
    box-shadow: none !important
    border: 1px solid #CAD2D8 !important
    margin-top: 30px
.login-form__field input
    background: #E3EFFA
    padding: 8px 13px
    border-radius: 7px
    color: #00599B
    font-weight: 400
    sont-size: 16px
    line-height: 18.75px
    font-family: 'Roboto'

.login-form__form
  >.login-form__field .v-text-field >.v-input__control > .v-input__slot::after, .v-text-field > .v-input__control > .v-input__slot::before
    display: none
.login-form__form
  >.login-form__field .v-text-field > .v-input__control > .v-input__slot::after, .v-text-field > .v-input__control > .v-input__slot::after
    display: none

.login-form__field .v-label.theme--light.error--text
    padding-left: 5px
    font-weight: 400
    sont-size: 16px
    line-height: 18.75px
    font-family: 'Roboto'
    color: #EB722E
.login-form__field .v-text-field__slot input
  padding-left: 10px
  padding-right: 10px
  min-height: 36px
  box-sizing: border-box
  width: 270px


.login-form__button
  margin-top: 20px
.login-form__button span
    font-family: Montserrat
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 21px
    letter-spacing: 0em
    text-align: center
    text-transform: none
    padding: 10px 34px
    border-radius: 7px
.pa-5.login-form.v-card.v-sheet.theme--light
    padding: 45px 50px !important
login-form .v-label.v-label--active.theme--light
  font-family: Roboto
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 19px
  letter-spacing: 0em
  text-align: left
</style>