<template>
  <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :nudge-width="additionalDataActive ? 500 : 250"
      max-width="500"
      offset-y
  ><!--menu.find(el => {return el.dataField === cell.dataField})-->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="blue__v-btn-for-menu"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          :loading="loading.value && id === loading.id"
          @click="showMenu = !showMenu"
      >
        {{ value }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-text-field
                  :value="value"
                  :label="title + (' текущее значение')"
                  class="mt-2"
                  readonly
                  dense
                  required
              />
              <v-text-field
                  :value="minValue"
                  :label="title + (' минимальное значение')"
                  class="mt-2"
                  readonly
                  disabled
                  dense
                  required
              />
              <v-text-field
                  :value="maxValue"
                  :label="title + (' максимальное значение')"
                  class="mt-2"
                  readonly
                  disabled
                  dense
                  required
              />
              <v-text-field
                  v-model="menuValue"
                  type="Number"
                  :rules="valMenuRules(maxValue,minValue)"
                  :label="title + (' новое значение')"
                  class="mt-2"
                  dense
                  clearable
                  required
              />
            </v-form>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            class="error__v-btn"
            @click="showMenu = false"
        >
          Отменить
        </v-btn>
        <v-btn
            class="blue__v-btn"
            text
            :loading="loading.value && id === loading.id"
            @click="sendNewValueFromMenu()"
            :disabled="!valid"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "vMenuStageRegion",
  props: {
    value: {
      type: [Number, String, Object],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    maxValue: {
      type: [String, Number]
    },
    minValue: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    },
    cell: {
      type: [Object, String]
    },
    row: {
      type: [Object, String]
    },
    loading: {
      type: [Boolean, Object],
      default: false
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    additionalDataActive: {
      type: Boolean,
      default: false
    },
    additionalData: {
      type: [Object, Array]
    },
    loadingAdditionalData: {
      type: [Boolean, Object],
      default: false
    },
  },
  data() {
    return {
      showMenu: false,
      valid: false,
      menuValue: 0
    }
  },
  watch: {
    showMenu(newValue){
      if (newValue === true){
        this.$emit('get-additional-data', this.row.id)
      }
    },
    loading(newValue, oldValue) {
      if (oldValue.value === true && newValue.value === false)
        this.showMenu = false
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate()
    },
    valMenuRules(maxValue = 0, minValue = 0) {
      return [
        v => !!v || 'введите значение',
        v => (v && this.menuValue >= minValue) || 'значение не должно быть меньше минимального',
        v => (v && this.menuValue <= maxValue) || 'значение не должно превышать максимальное'
      ]
    },
    async sendNewValueFromMenu() {
      await this.validate()
      if (this.valid) {
        this.$emit('component-action', {
          id: this.id,
          value: parseInt(this.menuValue),
          maxValue: this.maxValue,
          row: this.row,
          dataField: this.cell.dataField
        });
      }
    },
  },
}
</script>

<style lang="sass">
.blue__v-btn-for-menu
  &.v-btn--disabled
    .v-btn__content
      color: #0D72BD

  .v-progress-circular__overlay
    color: #FFFFFF

  &.theme--light
    background-color: #0D72BD !important

  &.v-btn.v-btn--contained.theme--light.v-size--default
    background-color: #0D72BD !important

  .v-btn__content
    font-size: 14px
    line-height: 20px
    font-style: normal
    font-weight: 600
    letter-spacing: 0em
    color: #FFFFFF
    text-align: center
    text-transform: none
    padding: 6px 14px
  border: 1px solid #0D72BD
  border-radius: 7px
</style>