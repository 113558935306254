<template>

  <article class="reference-detail" v-if="reference">
    <div class="reference-detail__top">
      <router-link :to="{name: 'AdminReference'}" class="button_border">К списку</router-link>
    </div>
    <button v-if="reference && !mainOrg" class="button_border" @click="goToMainOrg">Перейти к головной</button>

    <HeaderSection
        :title="reference.orgFullName"
    />
   

<!-- <Организация> -->
<WrapperPreviewCategory customClass="section-preview_lk">
<WrapperCollapse
    title="Информация об организации"
    :initialOpen="true"
    >
    
    <CardAdminOrgInfo :item="referenceDetailOrg" />

</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Организация> -->

<!-- <Контактное лицо> -->
<WrapperPreviewCategory customClass="section-preview_lk" v-if="mainOrg">
<WrapperCollapse
    title="Данные контактного лица"
    >
    <CardAdminOrgInfo :item="referenceDetailOrgContactPerson" />
<Alert alertType="warning" v-if="!referenceDetailOrgContactPerson">
    <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-alert</v-icon>
    </template>
    <template v-slot:default>
        Данные контактного лица не заполнены
    </template>
</Alert>
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Контактное лицо> -->



<!-- <Руководитель> -->
<WrapperPreviewCategory customClass="section-preview_lk" v-if="mainOrg">
<WrapperCollapse
    title="Данные руководителя"
    >
    <CardAdminOrgInfo :item="referenceDetailOrgDirectorPerson" />
<Alert alertType="warning" v-if="!referenceDetailOrgDirectorPerson">
    <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-alert</v-icon>
    </template>
    <template v-slot:default>
        Данные руководителя лица не заполнены
    </template>
</Alert>
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Руководитель> -->


<!-- <Документы> -->
<WrapperPreviewCategory customClass="section-preview_lk" v-if="mainOrg">
<WrapperCollapse
    title="Документы"
    >
    
    <div class="card-documents">
        <div class="card-documents-load" v-if="loadFile">
            <v-progress-circular
                indeterminate
                color="#eb722e"
                size="50"
        />
        </div>
        <CardDocumentAdmin v-for="(sub, index) in referenceDetailOrgFiles" :item="sub" :key="index" @downloadFile="requestDownloadFile($event)" />
    </div>
    
<Alert alertType="warning" v-if="!referenceDetailOrgFiles">
    <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-alert</v-icon>
    </template>
    <template v-slot:default>
        Документы не загружены
    </template>
</Alert>
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Документы> -->


<!-- <Филиалы> -->
<WrapperPreviewCategory customClass="section-preview_lk" v-if="mainOrg">
<WrapperCollapse
    title="Филиалы"
    >
    <CardAdminOrgInfo v-for="(sub, index) in referenceDetailOrgSubs" :item="sub" :key="index" class="info-card_sub-org" />


<Alert alertType="warning" v-if="!referenceDetailOrgSubs">
    <template v-slot:icon>
        <v-icon :size="30" color="#856404">mdi-alert</v-icon>
    </template>
    <template v-slot:default>
        Филиалы не добавлены
    </template>
</Alert>
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Филиалы> -->




<!-- <Заявки> -->
<WrapperPreviewCategory customClass="section-preview_lk" v-if="mainOrg">
<WrapperCollapse
    title="Заявки организации"
    :initialOpen="true"
    >
    
      <div class="requests-buttons">
    <button class="button requests-buttons__button" @click="request = 'Org'">Заявки на изменение данных организации</button>  
    <button class="button requests-buttons__button" @click="request = 'SubOrgs'">Заявки на изменение данных филиалов организации</button> 
  </div>

    <component :is="container">
   </component>

</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Заявки> -->





</article>
</template>


<script>
import {mapGetters} from 'vuex'
import request from "@/services/request";
import saveFile from '@/utils/saveFile';
import HeaderSection from '@/components/headers/HeaderSection';
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
import ContainerRequestsOneOrg from '@/containers/admin/ContainerRequestsOneOrg';
import ContainerRequestsOneSubOrgs from '@/containers/admin/ContainerRequestsOneSubOrgs';
import CardAdminOrgInfo from '@/components/cards/custom/CardAdminOrg/CardAdminOrgInfo';
import CardDocumentAdmin from '@/components/cards/custom/CardDocument/CardDocumentAdmin';
import Alert from '@/components/alerts/Alert';
export default {
components: {
    HeaderSection,
    WrapperCollapse,
    WrapperPreviewCategory,
    ContainerRequestsOneOrg,
    ContainerRequestsOneSubOrgs,
    CardAdminOrgInfo,
    CardDocumentAdmin,
    Alert
},
data() {
    return {
        reference: null,
        request: 'Org',
        loadFile: false,
    }
},
computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    mainOrg() {
        return this.reference.sprOrg.orgOwner == this.reference.sprOrg.orgID;
    },
    referenceDetailOrg() {
        let statusOrg;
        if (this.reference.sprOrg.orgStatus == 1) {
            statusOrg = 'Работает';
        } else if (this.reference.sprOrg.orgStatus == 2) {
            statusOrg = 'Реорганизаован';
        } else if (this.reference.sprOrg.orgStatus == 3) {
            statusOrg = 'Ликвидирован';
        } else if (this.reference.sprOrg.orgStatus == 4) {
            statusOrg = 'Тестовая организация';
        }

        let org = {
                'ID организации': this.reference.sprOrg.orgID,
                'Статус': statusOrg,
                'Положение': this.mainOrg ? 'Головная' : 'Филиал',
                'Полное название': this.reference.sprOrg.orgFullName,
                'Название': this.reference.sprOrg.orgName,
                'Сокращенное название': this.reference.sprOrg.orgShortName,
                'Тип организации': this.reference.sprOrg.sprOrgType.orgTypeName,
                'Наименование учредителя': this.reference.sprOrg.orgFounderName,
                'Адрес': this.reference.sprOrg.orgAddress,
                'ИНН': this.reference.sprOrg.inn,
                'Статус ЕГРЮЛ': this.reference.sprOrg.orgEgrulStatues,

        };
        if (!this.mainOrg) org['Головная организация'] = this.reference.sprOrg.orgOwner;

        return org;
    },
    referenceDetailOrgContactPerson() {
        if (this.reference.sprOrg.sprOrgContactPersons[0]) {
            return {
            'ID' : this.reference.sprOrg.sprOrgContactPersons[0].sprOrgContactPersonID,
            'ФИО' : this.reference.sprOrg.sprOrgContactPersons[0].fio,
            'ФИО (род. падеж)' : this.reference.sprOrg.sprOrgContactPersons[0].fioRodPad,
            'E-mail' : this.reference.sprOrg.sprOrgContactPersons[0].email,
            'Должность' : this.reference.sprOrg.sprOrgContactPersons[0].position,
            'Должность (род. падеж)' : this.reference.sprOrg.sprOrgContactPersons[0].positionRodPad,
            'Рабочий телефон' : this.reference.sprOrg.sprOrgContactPersons[0].workPhoneNumber,
            'Личный телефон' : this.reference.sprOrg.sprOrgContactPersons[0].personalPhoneNumber,
        }  
        } else {
            return null;
        }   
    },
        referenceDetailOrgDirectorPerson() {
        if (this.reference.sprOrg.sprOrgDirectors[0]) {
            return {
            'ID' : this.reference.sprOrg.sprOrgDirectors[0].sprOrgDirectorID,
            'ФИО' : this.reference.sprOrg.sprOrgDirectors[0].fio,
            'ФИО (род. падеж)' : this.reference.sprOrg.sprOrgDirectors[0].fioRodPad,
            'E-mail' : this.reference.sprOrg.sprOrgDirectors[0].email,
            'Должность' : this.reference.sprOrg.sprOrgDirectors[0].position,
            'Должность (род. падеж)' : this.reference.sprOrg.sprOrgDirectors[0].positionRodPad,
            'Рабочий телефон' : this.reference.sprOrg.sprOrgDirectors[0].workPhoneNumber,
            'Личный телефон' : this.reference.sprOrg.sprOrgDirectors[0].personalPhoneNumber,
        }  
        } else {
            return null;
        }   
    },
    referenceDetailOrgSubs() {
        if (this.reference.subOrgList[0]) {
            let subOrgs = [];
            this.reference.subOrgList.forEach(el => {
                let subOrg = {
                    'ID организации': el.orgID,
                    'Полное название': el.orgFullName,
                    'Название': el.orgName,
                    'Сокращенное название': el.orgShortName,
                    'Тип организации': el.orgTypeName,
                    'Наименование учредителя': el.orgFounderName,
                    'Адрес': el.orgAddress,
                    'ИНН': el.inn,
                    'КПП': el.kpp,
                }
                subOrgs.push(subOrg);
            });
            return subOrgs;
        } else {
            return null;
        }   
    },
    referenceDetailOrgFiles() {
        if (this.reference.applicationsFiles[0]) {
            return this.reference.applicationsFiles;
        } else {
            return null;
        }   
    },


    container() {
        return 'ContainerRequestsOne' + this.request;
      }
},
created() {
        this.getReference();
    },
    methods: {
        async getReference() {
            let reference = await request({
                    endpoint: `/api/admin/getOneOrg?orgID=${this.$route.params.id}`,
                    method: 'GET',
                });
            this.reference = reference.data;
        },
        goToMainOrg() {
            this.$router.push('/kcp-contest-admin/reference/' + this.reference.sprOrg.orgOwner);
            this.getReference();
        },
        async requestDownloadFile(e) {
            this.loadFile = true;
        let response = await request({
            endpoint: '/api/fileServer/downloadFileByID',
            method: 'POST',
            body: {
                fileServerID: e.fileServerID,
                fileName: e.fileName,
                fileFormat: e.fileFormat,
                }
        });
        this.loadFile = false;
        saveFile(response.data);
    },
    },

}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"
.reference-detail
    width: 100%
.info-card__button
    margin-top: 10px
    margin-left: 10px
.info-card__button:hover
    background: #0292e1
.info-card__cell_changed
    background: #fff3cd
    border-radius: 3px
    color: #856404
    padding: 0px 3px
.info-card__file
    color: #EB722E
    text-decoration: underline
    cursor: pointer
.reference-detail__top
    margin-top: 10px
    margin-bottom: 20px
.requests-buttons__button
  margin: 10px
.view-request
    width: 100%
.section-preview_lk
    background: #fff
    border-radius: 10px
    border: 1px solid #00599b
    margin-bottom: 20px
.collapse__title
    color: #00599b
    text-transform: uppercase
    font-weight: 600
    font-size: 16px
.info-card_sub-org
    border: 1px solid #00599b
    border-radius: 10px
    margin-bottom: 15px
    padding: 10px 0px
    background: #f8f9fb
.document-item
    cursor: pointer
    color: #0f63a1
.card-documents
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-content: space-evenly
    position: relative
    background: #ffffff7d
    z-index: 1
.card-documents-load
    position: absolute
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center  
</style>