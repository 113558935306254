<template>
  <div>

    <div class="score-about">
      <p><b>п.4.1.1. Приложения 2 Порядка:</b></p>
      <p>Процент заявлений на поступление, принятых от лиц, имеющих диплом бакалавра, диплом специалиста, диплом магистра, полученном в другой образовательной организации</p>
    </div>

    <v-divider class="mt-4"/>

    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about">
      <p class="calculation__about-text">Форма 5.10.</p>
      <p class="calculation__about-text">
        <!--               Показатели приема на обучение по программам магистратуры.-->
        Число поданных заявлений на I курс очной формы, единиц
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b24-thead">
      <tr class="">
        <th class="" rowspan="2">№ п/г</th>
        <th class="">УГСН Код</th>
        <th class="">УГСН Наименование</th>
        <th class="">Всего подано заявлений на поступление на I курс <br> 2020 год</th>
        <th class="">Из них от лиц с дипломом других организаций<br> 2020 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
      </tr>
      </thead>
      <tbody class="b24-tbody">
      <tr>
        <td class="">1.</td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.code }}</td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
        <td class="" v-html="checkData(rowOfValues.data.zm)"></td>
        <td class="" v-html="checkData(rowOfValues.data.zmd)"></td>
      </tr>
      <tr>
        <td colspan="3">
        </td>
        <td colspan="1" style="">
          <div><img style="height: 20px; margin: 0; width:300px; "
                    src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                    alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div><img style="height: 20px; margin: 0; width:300px; "
                    src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                    alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="3">
        </td>
        <td colspan="1" style="">
          <div>
            ЗМ
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            ЗМД
          </div>
        </td>
      </tr>
      </tbody>
    </table>


    <!-- formula -->
    <section class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>K11<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline">
            ЗМД
          </div>
          <div class="formula-half">ЗМ</div>
        </div>
        <div class="formula-full">=</div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline"><span v-html="checkData(rowOfValues.data.zmd)"></span>
          </div>
          <div class="formula-half"><span v-html="checkData(rowOfValues.data.zm)"></span></div>
        </div>
        <div class="formula-full">
          <div>
                  <span
                      class="font-weight-regular"> = </span><span v-html="checkData(rowOfValues.data.k11b0)"></span>
          </div>
        </div>
      </div>

      <!-- formula -->
    </section>
    <div class="calculation__about">
      <p class="calculation__about-text">
        ЗМД - число заявлений на поступление на I курс очной формы, поданных в 2020 году лицами с дипломом бакалавра, специалиста, магистра, полученном в другой образовательной организации
      </p>
      <p class="calculation__about-text">
        ЗМ - всего заявлений на поступление на I курс очной формы, поданных в 2020 году
      </p>
    </div>


  </div>
</template>

<script>
export default {
  name: "k11",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;// this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  //position: relative
  //display: block
  //border-radius: 5px
  width: 100%

  .b24-thead
    th
      background-color: inherit
      border: 1px solid #546e7a
      padding: 5px

    /*tr*/
    /*  &:last-child*/
    /*    th*/
  //&:nth-child(6)
  //    border: 0
  //    text-align: right
  //    padding-right: 10px
  //    border-bottom: 1px solid #546e7a

  //&:nth-child(7)
  //    border: 0
  //    padding-left: 10px
  //    border-bottom: 1px solid #546e7a

  .b24-tbody
    td
      text-align: center
      border: 1px solid #546e7a
      padding: 3px

    //&:nth-child(7)
    //    border: 0
    //    min-width: 20px
    //
    //&:nth-child(8)
    //    border: 0
    //    padding-right: 5px
    //    text-align: right
    //
    //&:nth-child(9)
    //    border: 0
    //    padding-left: 2px
    //    text-align: left

    tr
      &:nth-child(2)
        td
          border: 0

      &:nth-child(3)
        td
          border: 0

</style>