<template>
	<main>
	<v-container>
		<ContainerNews/>
	</v-container>
    </main>
</template>


<script>
import ContainerNews from '@/containers/front/news/ContainerNewsCategory';

export default {
	components: {
		ContainerNews,
	},
}
</script>