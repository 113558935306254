<template>
  <div class="cms-sidebar">

    <nav class="cms-sidebar-nav">

      <router-link v-if="authData.userProfile>0" :to="{name: 'AdminReference'}" class="cms-sidebar-nav__item">
        <v-icon :size="40" color="#ffffff" class="fr-topbar__icon">mdi-monitor-dashboard</v-icon>
      </router-link>
      <router-link v-if="authData.userProfile>0" :to="{name: 'AdminLetters'}" class="cms-sidebar-nav__item">
        <v-icon :size="40" color="#ffffff" class="fr-topbar__icon">mdi-email-alert</v-icon>
      </router-link>
      <router-link v-if="authData.userProfile>0" :to="{name: 'AdminExport'}" class="cms-sidebar-nav__item">
        <v-icon :size="40" color="#ffffff" class="fr-topbar__icon">mdi-briefcase-download</v-icon>
      </router-link>
      <router-link v-if="authData.userProfile>0" :to="{name: 'AdminApellation'}" class="cms-sidebar-nav__item">
        <v-icon :size="40" color="#ffffff" class="fr-topbar__icon">mdi-alert-minus-outline</v-icon>
      </router-link>
      <router-link v-if="authData.userProfile>0" :to="{name: 'AdminRequests'}" class="cms-sidebar-nav__item">
        <v-icon :size="40" color="#ffffff" class="fr-topbar__icon">mdi-alert-circle-check</v-icon>
      </router-link>
      <router-link v-if="authData.userProfile>0" :to="{name: 'AdminAppeals'}" class="cms-sidebar-nav__item">
        <v-icon :size="40" color="#ffffff" class="fr-topbar__icon">mdi-email-alert</v-icon>
      </router-link>
      <router-link :to="{name: 'AdminFounders'}" class="cms-sidebar-nav__item">
        <span class="white--text ml-2">ЦО</span>
      </router-link>
      <router-link :to="{name: 'AdminRegions'}" class="cms-sidebar-nav__item">
        <span class="white--text ml-2">РЕГ</span>
      </router-link>

    </nav>

  </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
  },
  methods: {
    ...mapActions('auth', {storeLogout: 'logout'}),
    logout() {
      this.storeLogout()
      this.$router.push('/')
    }
  }
}
</script>

<style lang="sass">
.cms-sidebar
  position: absolute
  background: #6e8da2
  min-width: 85px
  height: 100%
  box-sizing: border-box

.cms-sidebar-nav
  display: flex
  flex-direction: column

.cms-sidebar-nav__item
  border-bottom: 1px solid #9cb5cb
  padding: 25px

.cms-sidebar-nav__item:hover
  background: #587a93

.cms-sidebar-nav__item.router-link-active
  background: #587a93
</style>