<template>
  <div>

    <div v-if="list">
      <div v-for="(org, index) of list" :key="index">
        <h2
            class="title app-title_main app__title"
            :class="org.isOwner ? 'main-header__title-list' : ''"
            :style="org.isOwner ? 'font-size: 32px!important;' : ''"
        >
          {{ org.orgName }}
        </h2>
        <!--        <div class="app-header" v-if="org.isOwner">-->
        <!--          <div class="app-header__form"></div>-->
        <!--          <div class="app-header__form"></div>-->
        <!--          <div class="app-header__form">-->
        <!--             <span class="app-header__about"> Очно </span>-->
        <!--          </div>-->
        <!--          <div class="app-header__form">-->
        <!--            <span class="app-header__about">-->
        <!--              Очно-заочно-->
        <!--            </span>-->
        <!--          </div>-->
        <!--          <div class="app-header__form">-->
        <!--            <span class="app-header__about">-->
        <!--              Заочно-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </div>-->
        <div v-for="(formUsl, index) of org.data" :key="index" class="app-list">
          <div class="app-header">
            <div class="app-header__form">
              <h3 class="title app__title text-left" v-text="formUsl.uslName"></h3>
            </div>
            <div class="app-header__form"></div>
            <div class="app-header__form">
              <span class="app-header__about"> Очно </span>
            </div>
            <div class="app-header__form">
            <span class="app-header__about">
              Очно-заочно
            </span>
                        </div>
                        <div class="app-header__form">
            <span class="app-header__about">
              Заочно
            </span>
                        </div>
                    </div>

                    <div v-for="(item, index) of formUsl.data" :key="index" class="app">
                        <div v-text="item.sprKCPGroup.code" class="app__form"></div>
                        <div v-text="item.sprKCPGroup.name" class="app__form"></div>
                        <div class="app__form app__form_link  "
                             @click="openScore(item.id, item.s0b, 1, item.o1, item.sprKCPGroup,item)"
                             :style="calcBackground(item.raspredOchnaya, 100,!item.raspredOchnayaRejection)"
                             v-if="(item.raspredOchnaya || item.raspredOchnaya === 0) && (!item.raspredOchnayaRejectionLoading)">
                            <div class="app__score"
                                 v-text="item.raspredOchnaya || item.raspredOchnaya === 0 ? item.raspredOchnaya : 'н/д'">
                            </div>

                            <div v-if="!admin_block">
                                <v-tooltip
                                        top
                                        :color="item.raspredOchnayaRejection ? 'success' : 'error'"
                                        nudge-top="10"
                                >
                                    <template v-slot:activator="{on,attrs}">
                                        <v-btn
                                                @click="takePartInTheCompetition(item,formUsl,org,'raspredOchnayaRejection')"
                                                v-bind="attrs"
                                                v-on="on"
                                                max-height="24"
                                                icon>
                                            <v-icon
                                                    :color="item.raspredOchnayaRejection ? '#eb722e' : '#FFFFFF'"
                                            >
                                                {{ item.raspredOchnayaRejection ? 'mdi-check' : 'mdi-close' }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ item.raspredOchnayaRejection ? 'Принять КЦП'  : 'Отказаться от КЦП'}}</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <div v-else-if="item.raspredOchnayaRejectionLoading" class="app__form justify-center">
                            <v-progress-circular indeterminate/>
                        </div>
                        <div v-else class="app__form">

                        </div>
                        <div class="app__form app__form_link  "
                             @click="openScore(item.id, item.s0b, 2, item.o2, item.sprKCPGroup,item)"
                             :style="calcBackground(item.raspredOchnoZaochnaya, 100, !item.raspredOchnoZaochnayaRejection)"
                             v-if="(item.raspredOchnoZaochnaya || item.raspredOchnoZaochnaya === 0)&& (!item.raspredOchnoZaochnayaRejectionLoading)">
                            <div class="app__score"
                                 v-text="item.raspredOchnoZaochnaya || item.raspredOchnoZaochnaya === 0 ? item.raspredOchnoZaochnaya : 'н/д'">
                            </div>

                            <div v-if="!admin_block">
                                <v-tooltip
                                        top
                                        :color="item.raspredOchnayaRejection ? 'success' : 'error'"
                                        nudge-top="10"
                                >
                                    <template v-slot:activator="{on,attrs}">
                                        <v-btn
                                                @click="takePartInTheCompetition(item,formUsl,org,'raspredOchnoZaochnayaRejection')"
                                                v-bind="attrs"
                                                v-on="on"
                                                max-height="24"
                                                icon>
                                            <v-icon :color="item.raspredOchnoZaochnayaRejection ? '#eb722e' : '#FFFFFF'">
                                                {{ item.raspredOchnoZaochnayaRejection ? 'mdi-check' : 'mdi-close' }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ item.raspredOchnoZaochnayaRejection ? 'Принять КЦП'  : 'Отказаться от КЦП' }}</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <div v-else-if="item.raspredOchnoZaochnayaRejectionLoading" class="app__form justify-center">
                            <v-progress-circular indeterminate/>
                        </div>
                        <div v-else class="app__form">
                        </div>
                        <div class="app__form app__form_link"
                             @click="openScore(item.id, item.s0b, 3, item.o3, item.sprKCPGroup,item)"
                             :style="calcBackground(item.raspredZaochnaya, 100, !item.raspredZaochnayaRejection)"
                             v-if="(item.raspredZaochnaya || item.raspredZaochnaya === 0)&& (!item.raspredZaochnayaRejectionLoading)">
                            <!--              <div class="app__calc" v-text="item.za3 ? item.za3 : 'н/д'"></div>-->
                            <div class="app__score"
                                 v-text="item.raspredZaochnaya || item.raspredZaochnaya === 0 ? item.raspredZaochnaya : 'н/д'"></div>

                            <div v-if="!admin_block">
                                <v-tooltip
                                        top
                                        :color="item.raspredZaochnayaRejection ? 'success' : 'error'"
                                        nudge-top="10"
                                >
                                    <template v-slot:activator="{on,attrs}">
                                        <v-btn
                                                @click="takePartInTheCompetition(item,formUsl,org,'raspredZaochnayaRejection')"
                                                v-bind="attrs"
                                                v-on="on"
                                                max-height="24"
                                                icon>
                                            <v-icon :color="item.raspredZaochnayaRejection ? '#eb722e' : '#FFFFFF'">
                                                {{ item.raspredZaochnayaRejection ? 'mdi-check' : 'mdi-close' }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ item.raspredZaochnayaRejection ? 'Принять КЦП'  : 'Отказаться от КЦП' }}</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <div v-else-if="item.raspredZaochnayaRejectionLoading" class="app__form justify-center">
                            <v-progress-circular indeterminate/>
                        </div>
                        <div v-else class="app__form">
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
    import request from "@/services/request";
    // import HeaderMain from "@/components/headers/HeaderMain";
    import {mapGetters} from 'vuex'

    export default {
        components: {
            // HeaderMain,
        },
        data() {
            return {
                list: null,
                // statusKCPCellLoading: false,
                // statusKCPCellError: null,
                id: null,
                alert: false,
                agree: false,
                admin_block: true
            }
        },
        created() {
            this.getAllCalcOrgAndKCPGroup(this.get_replace_id_admin)
        },
        computed: {
            ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
        },
        methods: {
            async getAllCalcOrgAndKCPGroup(orgID) {
                let res = await request({
                    endpoint: `/api/zayavka/getAllZayavkaKCPRaspredFin?sprOrgID=${orgID}`,
                    method: 'get'
                })
                let list = {};
                if (res.status === 200) {

                    res.data?.zayavkaKcpRaspredFins.forEach(el => {
                        // Фильтрация по организации (организация и филиалы)
                        if (!(el.sprOrg.orgName in list)) {
                            list[el.sprOrg.orgName] = {};
                            list[el.sprOrg.orgName].orgName = el.sprOrg.orgName;
                            list[el.sprOrg.orgName].status = el.sprOrg.orgID === el.sprOrg.orgOwner ? 'Головная' : 'Филиал';
                            list[el.sprOrg.orgName].isOwner = el.sprOrg.orgID === el.sprOrg.orgOwner;
                            list[el.sprOrg.orgName].data = {};
                        }
                        const orgData = list[el.sprOrg.orgName].data;
                        // Фильтрация по уровню
                        if (!(el.sprKCPGroup.sprUsl.uslName in orgData)) {
                            orgData[el.sprKCPGroup.sprUsl.uslName] = {};
                            orgData[el.sprKCPGroup.sprUsl.uslName].uslName = el.sprKCPGroup.sprUsl.uslName;
                            orgData[el.sprKCPGroup.sprUsl.uslName].data = [];
                        }
                        orgData[el.sprKCPGroup.sprUsl.uslName].data.push({
                            ...el, agree: true,
                            raspredOchnayaRejectionLoading: false, raspredOchnayaRejectionError: null,
                            raspredOchnoZaochnayaRejectionLoading: false, raspredOchnoZaochnayaRejectionError: null,
                            raspredZaochnayaRejectionLoading: false, raspredZaochnayaRejectionError: null
                        });
                    });
                    this.list = list;

                }
            },
            calcBackground(value, maxValue, flag) {
                let a = Number.parseFloat(value)
                let b = Number.parseFloat(maxValue)
                // eslint-disable-next-line no-unused-vars
                let per = a * 100 / b
                return flag ? `  background: #eb722e; border-color: #CDD1D4;` : ` color: #eb722e; background: #f3f3f4; border-color: #CDD1D4;`
            },
            openScore(id, s0b, appForm, score, sprKCPGroup,item) {
                // console.log(id, s0b, appForm, score, sprKCPGroup,item)
                // сдлеать переход ессли s0b == 0
                // if (s0b === 1 || s0b === '1')
                    this.$router.push({
                        path: '/private/indicators/final-list',
                        query: {fo: appForm, id: id, value: score,kcp: sprKCPGroup.sprKCPGroupID, usl: sprKCPGroup.sprUsl.uslID,
                      org: item.sprOrg.orgID}
                    })
            },
            async alertFunc(val) {
                this.agree = val
                this.alert = false
            },
            async takePartInTheCompetition(item, formUsl, org, prop) {
                if (item[prop] === false)
                    this.agree = confirm('Отказаться от КЦП?')
                else
                    this.agree = true
                if (this.agree) {
                    this.id = item.id

                    const ff = this.list?.[org['orgName']]?.data[formUsl.uslName].data.find(el => el.id === item.id)
                    ff[prop + 'Loading'] = true
                    let obj = new Object({})
                    obj[prop] = !item[prop]
                    let res = await request({
                        endpoint: '/api/zayavka/updateKCPRejection',
                        method: 'post',
                        body: {
                            id: item.id,
                            ...obj
                        }
                    })
                    if (res.status === 200) {
                        ff[prop] = res.data[prop]
                        ff[prop + 'Loading'] = false
                        ff[prop + 'Error'] = null
                    } else {
                        ff[prop + 'Loading'] = false
                        ff[prop + 'Error'] = res.error
                    }
                }
            }
        }
    }
</script>
<style lang="sass">
    .app
        background: #fff
        margin-bottom: 15px
        padding: 5px 10px
        display: flex
        justify-content: space-between
        align-items: center
        border-left: 3px solid #00599b
        transition: 0.3s

    .main-header__title-list
        font-size: 32px !important
        line-height: 35.2px !important
        color: #00599B !important
        font-family: 'Montserrat' !important
        font-weight: 700 !important
        max-width: 100% !important
        margin-bottom: 22px

    .app:hover
        transform: translateY(-5px)
        box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
        transition: 0.3s

    .app__form
        display: flex
        max-width: 200px
        justify-content: space-between
        flex-basis: 200px
        margin-right: auto

        &:nth-child(1)
            margin-right: 0
            max-width: 100px
            flex-basis: 100px

        &:nth-child(2)
            max-width: 400px
            flex-basis: 400px

    .app-header
        font-weight: 600
        color: #00599b
        margin-bottom: 10px
        display: flex
        width: 100%
        justify-content: right
        padding: 5px 0px

    .app-header__form
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        margin-right: auto
        max-width: 200px
        flex-basis: 200px

        &:nth-child(1)
            margin-right: 0
            max-width: 100px
            flex-basis: 100px

        &:nth-child(2)
            max-width: 400px
            flex-basis: 400px


    .app-header__about
        //font-size: 15px
        text-align: center
        width: 100%

    .app-header__calc, .app-header__score
        font-size: 10px

    .app__title
        margin-bottom: 15px
        color: #00599b

    .app__form_link
        background: #eb8e1c
        align-items: center
        justify-content: space-between
        color: #fff
        font-weight: 600
        border-radius: 7px
        padding: 4px 7px
        cursor: pointer

    .app-title_main
        //text-transform: uppercase
        font-weight: 900
        color: #eb8e1c
    //margin-top: 50px

    .app-list
        margin-bottom: 30px
</style>