<template>
  <div>
    <div v-if="authData.role.id === 1">
      <div v-if="loadedFiles">
        <CardFile v-for="item in files" :key="item.id" :item="item" @uploadFile="uploadFile($event)"
                  @replaceFile="replaceFile($event)"/>
      </div>
    </div>
    <div v-else>
      <div v-if="loadedFiles">
        <CardFile v-for="item in files" :key="item.id" :item="item"/>
      </div>
    </div>


  </div>
</template>

<script>

import CardFile from "@/components/cards/custom/CardFile";
import {mapGetters, mapActions} from 'vuex'
import request from "@/services/request";

export default {
  components: {
    CardFile
  },
  data() {
    return {
      files: [
        {name: 'Устав образовательной организации', fileType: 'USTAV_EDUCATIONAL_ORG'},
        {name: 'Лицензия на осуществление образовательной деятельности', fileType: 'LICENSE_EDUCATIONAL_ORG'},
        {
          name: 'Приложения к лицензии на осуществление образовательной деятельности',
          fileType: 'APP_LICENSE_EDUCATIONAL_ORG'
        },
        {name: 'Свидетельство о государственной аккредитации', fileType: 'CERTIFICATE_GOS_ACCREDITATION'},
        {
          name: 'Приложения к свидетельству о государственной аккредитации',
          fileType: 'APP_CERTIFICATE_GOS_ACCREDITATION'
        },
        {
          name: 'Приказ учредителя о назначении руководителя образовательной организации',
          fileType: 'DECREE_ABOUT_DIRECTOR_OBR_ORG'
        },
      ],
      loadedFiles: false,
    }
  },

  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),

  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    async getDocuments() {
      this.loadedFiles = false;
      const res = await request({
        endpoint: `/api/fileServer/getAllOrgMainFilesName?sprOrgID=${this.get_replace_id_admin}&userProfile=${this.authData.userProfile}`,
        method: 'GET',
      });
      res.data.forEach(el => {
        let overlapIndex = this.files.findIndex(file => file.fileType === el.fileType);
        if (overlapIndex !== -1) {
          this.files[overlapIndex] = Object.assign(this.files[overlapIndex], el);
        }
      });
      this.loadedFiles = true;
    },
    async uploadFile(event) {
      let fileInfo = {
        sprOrgID: this.authData.sprOrgID,
        fileType: event.fileType,
        userID: this.authData.userID,
      };
      let fd = new FormData();
      fd.append('fileUploadDTO', JSON.stringify(fileInfo));
      fd.append('file', event.file);
      await request({
        endpoint: '/api/fileServer/uploadOrgMainFiles',
        method: 'POST',
        body: fd,
      });
      this.getDocuments();
      this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
    },

    async replaceFile(event) {
      let fileInfo = {
        sprOrgID: this.authData.sprOrgID,
        fileType: event.fileType,
        userID: this.authData.userID,
        id: event.id,
      };
      let fd = new FormData();
      fd.append('fileUploadDTO', JSON.stringify(fileInfo));
      fd.append('file', event.file);
      await request({
        endpoint: '/api/fileServer/uploadOrgMainFiles',
        method: 'POST',
        body: fd,
      });
      this.getDocuments();
      this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});

    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px

.file-edit
  display: flex
  align-items: center

.file-edit .v-file-input
  max-width: 300px
  margin-right: 10px
  min-width: 200px

.file-edit
  display: inline-flex
  align-items: center
  background: #FFEDE2
  padding: 0px 15px
  border-radius: 10px
  margin: 20px

.border_margin_right
  margin-right: 10px
</style>