<template>
  <div>
        <button class="button_border" @click="$router.go(-1)">Назад</button>

    <router-view>

    </router-view>
  </div>
</template>

<script>
export default {
  name: "f5_router"
}
</script>

<style scoped>

</style>