<template>
    <div>
        <v-tabs @change="changeFo($event)" align-with-title v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
                    :key="item.fo"
                    class="font-weight-bold"
                    v-for="item in tabs"
            >
                {{ item.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item
                    :key="item.fo"
                    v-for="item in tabs"
            >
                <v-card flat>
                    <v-card-text>
                        <GridTable
                                :data-rows="chosenData"
                                :headers="headers.massive"
                                :headers-for-rows="headersForRows"
                                :loading-data="loadingData"
                                :num-cols="headers.countCells"
                        >
                            <template #defaultHeader="{headers}">
                                <div
                                        :key="i" :style="`grid-column: span ${(Number(item.cols) )};`"
                                        class="table-grid-header__cell"
                                        v-for="(item, i) in headers"
                                >
                                    <span>{{ item.text }}</span>
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-width="-250"
                                            offset-y
                                            v-if="item.headerTooltip != null && item.headerTooltip"
                                            v-model="item.headerTooltipShow"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn @click="item.headerTooltipShow = !item.headerTooltipShow"
                                                   icon
                                                   v-bind="attrs"
                                                   v-on="on"
                                            >
                                                <v-icon
                                                        :size="15"
                                                        class="btn__icon grey-light-color__important"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title v-text="item.headerTooltipHeaderText()"></v-card-title>
                                            <v-card-text v-html="item.headerTooltipText()"></v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                        @click="item.headerTooltipShow = false"
                                                        class="error__v-btn"
                                                        text
                                                >
                                                    Закрыть
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </div>
                            </template>
                            <template #regionName="{row}">
                  <span><v-icon color="warning" size="12"
                                v-if="row.haveOffers">mdi-checkbox-blank-circle</v-icon> {{ row['kcpGroupName'].trim() }}</span>
                            </template>
                            <template #regValueStage2="{row,cell}">
                                <component :is="'vMenuStageRegion'" @component-action="cell.callback($event)"
                                           v-bind="cell.props(row,cell)"/>
<!--                                  @get-additional-data="cell.callback2($event)"-->
                            </template>
                        </GridTable>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import request from "@/services/request";
    import GridTable from "@/components/GridTable/GridTable";
    import vMenuStageRegion from "@/components/cards/custom/vMenuStageRegion"

    export default {
        name: "regionStageTwoFoData",
        components: {GridTable, vMenuStageRegion},
        props: {
            usl: Object
        },
        data() {
            return {
                tab: 0,
                show: false,
                loadingMenu: {value: false, id: ''},
                loadingData: false,
                chosenData: [],
                errorData: false,
                serverData: {bak:{ochn:[],vo:[],zaoch:[]},spec:{ochn:[],vo:[],zaoch:[]},mag:{ochn:[],vo:[],zaoch:[]}},
                tabs: [{fo: "ochn", foId: 1, text: 'Очная'}, {fo: "vo", foId: 2, text: 'Очно-заочная'}, {fo: "zaoch", foId: 3, text: 'Заочная'}],
                headers: {
                    countCells: 11,
                    massive: [
                        {
                            dataField: 'kcpGroupName',
                            cols: 1,
                            text: 'Конкурсная группа',
                            class: 'justify-start',
                            template: function (value) {
                                return value['kcpGroupName'].trim();
                            }
                        },
                        {
                            dataField: 'applications2019',
                            cols: 1,
                            text: 'Заявления 2019',
                        },
                        {
                            dataField: 'applications2020',
                            cols: 1,
                            text: 'Заявления 2020',
                        },
                        {
                            dataField: 'enrolled2019',
                            cols: 1,
                            text: 'Зачисленные 2019',
                        },
                        {
                            dataField: 'enrolled2020',
                            cols: 1,
                            text: 'Зачисленные 2020',
                        },
                        {
                            dataField: 'ege2019',
                            cols: 1,
                            text: 'ЕГЭ 2019',
                        },
                        {
                            dataField: 'ege2020',
                            cols: 1,
                            text: 'ЕГЭ 2020',
                        },
                        {
                            dataField: 'kcp2021',
                            cols: 1,
                            text: 'КЦП  2021',
                        },
                        {
                            dataField: 'kcp2022',
                            cols: 1,
                            text: 'КЦП  2022',
                        },
                        {
                            dataField: 'coValueStage1',
                            cols: 1,
                            text: 'Предложение головного ЦО',
                        },
                        {
                            dataField: 'regValueStage2',
                            cols: 1,
                            text: 'Предложение Субъекта РФ',
                            component: 'vMenuStageRegion',
                            class: '',
                            styles: 'justify-content: unset;',
                            props: (row, cell) => {
                                return {
                                    value: row[cell.dataField],
                                    title: 'Предложение субъекта РФ',
                                    minValue: Math.min(Number.parseInt(row.baseValue * 0.8), row.baseValue - row.rg < 0 ? 0 : row.baseValue - row.rg ),
                                    maxValue: Math.max(Number.parseInt(row.baseValue * 1.2), row.rg + row.baseValue, 0),
                                    cell: cell,
                                    id: row.id,
                                    row: row,
                                    loading: this.loadingMenu,
                                    disabled: true // this.get_blockedEnterData
                                }
                            },
                            callback: (value) => {
                                this.sendValueFromMenu(value)
                            }
                        },
                    ]
                },
            }
        },
        mounted() {
            this.changeFo(0);
        },
        computed: {
            ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
            ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
            ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
            headersForRows() {
                return this.headers.massive.filter(el => el.variable !== false)
            },
            getFo(){
                return this.tabs[this.tab]
            }
        },
        methods: {
            ...mapActions('notifications', {addNotification: 'addNotification'}),
            async getServerData(usl, fo) {
                console.log(usl, fo)
                let hasData = this?.serverData[usl.usl][fo.fo].length > 0;
                let data = [];
                if (!hasData) {
                    this.loadingData = true;
                    const res = await request({
                        endpoint: `/api/stageData/byRegion/${this.get_replace_id_admin}/usl/${usl.uslId}/fo/${fo.foId}`,
                        method: 'get',
                    });
                    if (res.status === 200) {
                        this.loadingData = false;
                        this.serverData[usl.usl][fo.fo] = res.data;
                        data = res.data;
                    } else {
                        await this.addNotification({
                            notificationStatus: 'error',
                            notificationMessage: 'Ошибка получения данных',
                            timeout: 3000
                        });
                        this.errorData = res.error;
                        this.loadingData = false;
                    }
                } else {
                    data = this.serverData[usl.usl][fo.fo]
                }
                return data;
            },
            async changeFo(tabsIndex) {
                const foundedTab = this.tabs.find((el, id) => id === tabsIndex);
                this.chosenData = await this.getServerData(this.usl, foundedTab);
            },
            async sendValueFromMenu(obj) {
                this.loadingMenu = {value: true, id: obj.id};
                const res = await request({
                    endpoint: `/api/stageData/update`,
                    method: 'post',
                    body: {
                        id: obj.id,
                        regValueStage2: obj.value,
                    }
                })
                if (res.status === 200) {
                    this.loadingMenu = {value: false, id: obj.id};
                    const serverDataIndex = this.serverData[this.usl.usl][this.getFo.fo].findIndex(el => el.id === res.data.id)
                    const chosenDataIndex = this.chosenData.findIndex(el => el.id === res.data.id)
                    this.serverData[this.usl.usl][this.getFo.fo].splice(serverDataIndex, 1, res.data)
                    this.chosenData.splice(chosenDataIndex, 1, res.data)
                    await this.addNotification({
                        notificationStatus: 'success',
                        notificationMessage: 'Данные успешно сохранены',
                        timeout: 3000
                    })
                    // this.changeUsl(this.tab)
                } else {
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка сохранения данных',
                        timeout: 3000
                    })
                    // this.errorData = res.error;
                    this.loadingMenu = {value: false, id: obj.id};
                }

            }
        }
    }
</script>

<style scoped>

</style>