<template>
  <div class="table-grid">
    <div
        class="table-grid__container"
        :style="`grid-template-columns : repeat(${numCols}, 1fr);`"
    >
      <!--       header -->
      <div
          class="table-grid-header-row"
          :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
          ref="table-grid-header-row"
      >
        <slot name="defaultHeader" :headers="headers">
          <div
              v-for="(item, i) in headers" :key="i"
              class="table-grid-header__cell"
              :ref="'table-grid-header__cell-'+item.dataField"
              :style="`grid-column: span ${(Number(item.cols) )}; grid-row: span ${Number(item.rows)};
               top: ${topFixedPositionForHeaderCell(item).offsetTop}px;`"
          >
            <span>{{ item.text }}</span>
          </div>
        </slot>
        <slot name="customHeader" :headers="headers">

        </slot>
      </div>
      <!--        body-->
      <template v-if="loadingData">
        <div class="d-flex flex-row align-center" :style="`grid-column: span ${numCols}; min-height: 200px;`">
          <v-progress-linear indeterminate></v-progress-linear>
        </div>
      </template>
      <template v-else>
        <div class="table-grid-body-row"
             v-for="(row) in dataRows"
             :key="row.id"
             :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
             @click="rowAction(row)"
        >
          <div
              class="table-grid-body-row__cell"
              v-for="(cell) in headersForRows"
              :key="cell.dataField"
              :class="[cell.class, painCell(row, cell)]"
              :style="`grid-column: span ${(Number(cell.cols) )};` + `${cell.styles}`"
          >
            <slot  :name="cell.dataField" :row="row" :cell="cell">
              <template v-if="!cell.component">
                {{ cell.template ? cell.template(row) : row[cell.dataField] }}
              </template>
            </slot>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "GridTable",
  props: {
    headers: {
      type: [Array],
      default: () => []
    },
    numCols: {
      type: [Number, String],
      default: 0
    },
    headersForRows: {
      type: [Array],
      default: () => []
    },
    loadingData: {
      type: Boolean,
      default: false
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paintBackground: {
      type: [Function],
      default: () => {
      }
    },
    importsComponents: {
      type: [Array, Object]
    }
  },
  components: {},
  computed: {
    headersFixedAttrs() {
      return this.headers.map(el => {
        const offsetHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetHeight : '';
        const offsetTop = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetTop : '';
        const clientHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientHeight : '';
        const clientWidth = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientWidth : '';
        return {
          ...el,
          offsetHeight: offsetHeight,
          offsetTop: offsetTop - 2,
          clientHeight: clientHeight,
          clientWidth: clientWidth
        }
      })
    }
  },
  methods: {
    rowAction(row) {
      this.$emit('row-action', row)
    },
    topFixedPositionForHeaderCell(cell){
      return this.headersFixedAttrs.length > 0 ? this.headersFixedAttrs.find(el => el.dataField === cell.dataField) : ''
    },
    // eslint-disable-next-line no-unused-vars
    painCell(row, cell) {
      if (this.paintBackground(row))
        return this.paintBackground(row)();
    }
  },
}
</script>

<style scoped>

</style>