<template>
  <div>
    <div v-if="!componentName">
      <h5 class="score-subsubtitle">
        Учет влияния головной организации в значении показателя (проводится
        только для филиалов):
      </h5>

      <div class="calculation">
        <div class="calculation__row">
          <div class="calculation__cell"> {{ item.title }}<span class="font-size-little">0</span></div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span class="font-weight-regular">(1- G)</span></div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">×</div>
          <div class="calculation__cell"> {{ item.title }}<span class="font-size-little">ф</span></div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell font-weight-regular">G</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">×</div>
          <div class="calculation__cell"> {{ item.title }}<span class="font-size-little">г</span></div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell font-weight-regular">(1 - 0.35)</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">×</div>
          <div class="calculation__cell"><span v-html="checkData(rowOfValues.data[item.dataFieldName+'b0'])"/></div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
          <div class="calculation__cell font-weight-regular">0.35</div>
          <div class="calculation__cell calculation__cell_no-grow font-weight-regular">×</div>
          <div class="calculation__cell"><span v-html="checkData(rowOfValues.data[item.dataFieldName+'b0h'])"/></div>
          <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
          <div class="calculation__cell"><span v-html="checkData(rowOfValues.data[item.dataFieldName+'b'])"/></div>
        </div>
      </div>

      <div class="calculation__about">
        <p class="calculation__about-text">
          G - доля оценки головной организации в значении по филиалу равная 0,35
        </p>
        <p class="calculation__about-text">
          {{ item.title }} - с индексами "ф" и "г" −  значения показателя соответственно
          для филиала и его головной образовательной организации
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardS1",
  props: {
    componentName: {
      type: String,
      default: ''
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    item:{
      type: Object,
      default: () => {}
    }
  },
  methods: {
    checkData(value) {
      // console.log("1",value)
      return value//this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style scoped>

</style>