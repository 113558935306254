import { render, staticRenderFns } from "./zayavkaKcpLicense.vue?vue&type=template&id=0ada4800&scoped=true&"
import script from "./zayavkaKcpLicense.vue?vue&type=script&lang=js&"
export * from "./zayavkaKcpLicense.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ada4800",
  null
  
)

export default component.exports