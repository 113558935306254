<template>
  <article class="cms-card-news" :class="'application-status_'+item.formErrorsStatus" itemscope
           itemtype="http://schema.org/NewsArticle" v-if="item.sprOrg">
    <div class="applications__header">
      <div class="applications__title"><span>Организация:</span>
        <router-link :to="'/kcp-contest-admin/reference/' + item.sprOrg.orgID"> {{ item.sprOrg.orgName }}</router-link>
      </div>
      <div class="applications__id"><span>ID ошибки:</span> {{ item.errorID }}</div>
      <div class="applications__date"><span>Дата обращения:</span> {{ createDate }}</div>
      <div class="applications__date"><span>Текст ошибки:</span> {{ item.errorText }}</div>
      <div class="applications__date"><span>Файлы</span>
        <span
            @click="onOpenApplicationFile({idServer: item.firstPDFFileServerID, fileName: item.firstPDFFileName, fileFormat: item.firstPDFFileFormat})"
            class="applications__file">xls</span>
        <span
            @click="onOpenApplicationFile({idServer: item.secondXLSServerID, fileName: item.secondXLSFileName, fileFormat: item.secondXLSFileFormat})"
            class="applications__file">pdf</span>
      </div>

    </div>


    <div class="applications__actions">

      <textarea placeholder="Комментарий для организации (необязательное поле)" class="apellation_error__text"
                v-model="errorAnswer" v-if="item.formErrorsStatus === 'NOT_SET'"></textarea>
      <div class="cms-card-news__ok" @click="accept" v-if="item.formErrorsStatus === 'NOT_SET'">
        <v-icon :size="30" color="#ffffff" class="fr-topbar__icon">mdi-check-bold</v-icon>
      </div>
      <div class="cms-card-news__delete" @click="reject" v-if="item.formErrorsStatus === 'NOT_SET'">
        <v-icon :size="30" color="#ffffff" class="fr-topbar__icon">mdi-cancel</v-icon>
      </div>

      <div class="application-moderate">
        <div class="application__status" v-if="item.formErrorsStatus !== 'NOT_SET'">
          {{ item.formErrorsStatus === 'ACCEPTED' ? 'Изменения приняты' : '' }}
          {{ item.formErrorsStatus === 'REJECTED' ? 'Изменения отклонены' : '' }}
          {{ item.formErrorsStatus === 'PROTOCOL' ? 'Протокол №4' : '' }}
        </div>
        <span v-if="editDate" class="application__moderate-time">{{ editDate }}</span>
      </div>
    </div>
  </article>


</template>

<script>
import moment from 'moment';

moment.locale('ru');
import request from "@/services/request";
import saveFile from '@/utils/saveFile';

export default {
  props: {
    item: {
      type: Object,
    }
  },
  data() {
    return {
      errorAnswer: '',
    }

  },
  computed: {
    createDate() {
      return moment(this.item.createDate).format('LLL')
    },
    editDate() {
      if (this.item.editDate) {
        return moment(this.item.editDate).format('LLL')
      } else {
        return '';
      }
    },
  },
  methods: {
    accept() {
      this.$emit('acceptFormErrors', {errorID: this.item.errorID, errorAnswer: this.errorAnswer});

    },
    reject() {
      this.$emit('rejectFormErrors', {errorID: this.item.errorID, errorAnswer: this.errorAnswer});
    },

    async onOpenApplicationFile(fileInfo) {
      let response = await request({
        endpoint: '/api/fileServer/downloadFileByID',
        method: 'POST',
        body: {
          fileServerID: fileInfo.idServer,
          fileName: fileInfo.fileName,
          fileFormat: fileInfo.fileFormat
        }
      });
      saveFile(response.data);
    }
  },

}
</script>
<style lang="sass">
.cms-card-news__actions
  display: flex

.cms-card-news
  background: #fff
  margin-bottom: 15px
  padding: 5px 10px
  display: flex
  justify-content: space-between
  align-items: center
  border-left: 3px solid #00599b
  transition: 0.3s

.cms-card-news__header
  flex-grow: 0
  flex-basis: 700px
  overflow: hidden

.application-change__title
  font-weight: 500
  color: #eb8e1c

.cms-card-news:hover
  transform: translateY(-5px)
  box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
  transition: 0.3s

.cms-card-news__ok
  background: #41cf8f
  padding: 3px
  border-radius: 3px
  border: 1px solid #129a5d
  cursor: pointer

.cms-card-news__ok:hover
  background: #108751

.cms-card-news__edit:hover
  background: #ffeeba

.cms-card-news__delete
  background: #ce303f
  padding: 3px
  border-radius: 3px
  border: 1px solid #8c0c1a
  cursor: pointer

.cms-card-news__delete:hover
  background: #4c040b

.application-status_ACTIVE
  background: #fff

.application-change-content__field
  display: flex

.application-change-content__label
  white-space: nowrap
  color: #00599b
  font-weight: 500
  margin-right: 15px

.application-status_ACCEPTED .application__status
  color: #155724
  background: #d4edda
  border: 1px solid #c3e6cb

.application-status_PROTOCOL .application__status
  color: #636363
  background: #d4d4d4
  border: 1px solid #c3c3c3

.application-status_REJECTED .application__status
  color: #721c24
  background: #f8d7da
  border: 1px solid #f5c6cb

.applications__actions
  min-width: 200px

.application__status
  text-align: center
  font-weight: 500
  padding: 5px
  border-radius: 5px
  min-width: 190px

.applications__actions
  min-width: 200px
  display: flex
  justify-content: center
  align-items: center

.cms-card-news__ok
  margin-right: 5px

.application__moderate-time
  font-size: 12px

.application-moderate
  text-align: center

.application-status_DONE, .application-status_REJECT
  opacity: 0.7

.application-status_DONE:hover, .application-status_REJECT:hover
  opacity: 1

.applications__header span
  background: #e3effb
  color: #00599b
  padding: 1px 7px
  border-radius: 3px
  margin-right: 10px
  display: inline-block
  white-space: nowrap
  margin-bottom: 5px

.applications__content
  text-align: center

.application__file_link
  color: #0d72bd
  text-decoration: underline
  cursor: pointer

.convert_span
  font-size: 12px
  font-weight: 400

.application__status
  max-width: 225px
  background: #EBF9EB
  color: #29A128
  border: 1px solid #8FE28E
  border-radius: 10px

.applications__file
  background: #eb8e1c !important
  color: #fff !important
  cursor: pointer
</style>