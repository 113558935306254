<template>
<v-container>
	<HeaderMain
		title="ФГБОУ ВО «Московский политехнический университет»"
		subtitle="01.03.00 Математика и механика. Бакалавриат"
	/>

	<!-- header -->

	<section class="section-score">
	<div class="score__header">
		<div class="score-title">
			<h3 class="score-title__name">Б22</h3>
			<span class="score-title_about">Соотношение (Маг+Асп)/БС</span>
		</div>
		<div class="score-scale">
			<div class="score-scale__line">
				<div class="score-scale__score">9,0</div>
			</div>
			<div class="score-scale__max">
				<span>9</span>
				<span>max</span>
			</div>
		</div>
		<div class="score-rating">
			<span>Б22.r1 из Б22.q1</span>
		</div>
	</div>

	<div class="score-about">
		<p><b>п.1.2.2 Методики:</b></p>
		<p>Соотношение приведенного контингента обучающихся по программам магистратуры, программам ординатуры, программам подготовки научно-педагогических кадров в
аспирантуре, и приведенного контингента студентов, обучающихся по программам бакалавриата, программам специалитета в области здравоохранения и медицинских
наук</p>

	</div>
	</section>


<!-- header -->
<!-- table -->
<!-- Базовое значение показателя -->


<h4 class="score-subtitle">1. Базовое значение показателя</h4>
<section class="section-score">
<h5 class="score-subsubtitle">Исходные данные:</h5>
<span class="score-subsubtitle__about">Форма 5.3. Численность обучающихся, (за счет всех источников финансирования, 2020 год)</span>

<div class="tables">

<div class="table">
	<div class="table-tr">
		<div class="table-td">№ п/п</div>
		<div class="table-td">Уровень</div>
		<div class="table-td">УГСН Код</div>
		<div class="table-td">Наименование</div>
		<div class="table-td">Форма обучения</div>
		<div class="table-td">Численность обучающихся Всего, человек</div>
	</div>
		<div class="table-tr">
		<div class="table-td">1</div>
		<div class="table-td">2</div>
		<div class="table-td">3</div>
		<div class="table-td">4</div>
		<div class="table-td">5</div>
		<div class="table-td">6</div>
	</div>
		<div class="table-tr">
		<div class="table-td">1</div>
		<div class="table-td">Бакалавриат</div>
		<div class="table-td">UGSb</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очная</div>
		<div class="table-td">NBbO</div>
	</div>
		<div class="table-tr">
		<div class="table-td">2</div>
		<div class="table-td">Специалитет</div>
		<div class="table-td">UGSs</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очная</div>
		<div class="table-td">NBsO</div>
	</div>
		<div class="table-tr">
		<div class="table-td">3</div>
		<div class="table-td">Магистратура</div>
		<div class="table-td">UGSm</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очная</div>
		<div class="table-td">NMO</div>
	</div>
		<div class="table-tr">
		<div class="table-td">4</div>
		<div class="table-td">Аспирантура</div>
		<div class="table-td">UGSa</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очная</div>
		<div class="table-td">ACO</div>
	</div>
		<div class="table-tr">
		<div class="table-td">5</div>
		<div class="table-td">Бакалавриат</div>
		<div class="table-td">UGSb</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очно-заочная</div>
		<div class="table-td">NBbV</div>
	</div>
		<div class="table-tr">
		<div class="table-td">6</div>
		<div class="table-td">Специалитет</div>
		<div class="table-td">UGSs</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очно-заочная</div>
		<div class="table-td">NBsV</div>
	</div>
		<div class="table-tr">
		<div class="table-td">7</div>
		<div class="table-td">Магистратура</div>
		<div class="table-td">UGSm</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очно-заочная</div>
		<div class="table-td">NMV</div>
	</div>
		<div class="table-tr">
		<div class="table-td">8</div>
		<div class="table-td">Аспирантура</div>
		<div class="table-td">UGSa</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">очно-заочная</div>
		<div class="table-td">ACV</div>
	</div>
		<div class="table-tr">
		<div class="table-td">9</div>
		<div class="table-td">Бакалавриат</div>
		<div class="table-td">UGSb</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">заочная</div>
		<div class="table-td">NBbZ</div>
	</div>
		<div class="table-tr">
		<div class="table-td">10</div>
		<div class="table-td">Специалитет</div>
		<div class="table-td">UGSs</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">заочная</div>
		<div class="table-td">NBsZ</div>
	</div>
		<div class="table-tr">
		<div class="table-td">11</div>
		<div class="table-td">Магистратура</div>
		<div class="table-td">UGSm</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">заочная</div>
		<div class="table-td">NMZ</div>
	</div>
		<div class="table-tr">
		<div class="table-td">12</div>
		<div class="table-td">Аспирантура</div>
		<div class="table-td">UGSa</div>
		<div class="table-td">UGS.name</div>
		<div class="table-td">заочная</div>
		<div class="table-td">ACZ</div>
	</div>
	



</div>


<div class="table">
<div class="table-tr">
	<div class="table-td">Расчет исходных значений</div>
</div>
<div class="table-tr">
	<div class="table-td">значение</div>
	<div class="table-td">переменная</div>
</div>
<div class="table-tr">
	<div class="table-td">NBO</div>
	<div class="table-td">= NBO</div>
</div>
<div class="table-tr">
	<div class="table-td">NMO</div>
	<div class="table-td">= NMO</div>
</div>
<div class="table-tr">
	<div class="table-td">ACO</div>
	<div class="table-td">= ACO</div>
</div>
<div class="table-tr">
	<div class="table-td">NBV</div>
	<div class="table-td">= NBV</div>
</div>
<div class="table-tr">
	<div class="table-td">NMV</div>
	<div class="table-td">= NMV</div>
</div>
<div class="table-tr">
	<div class="table-td">ACV</div>
	<div class="table-td">= ACV</div>
</div>
<div class="table-tr">
	<div class="table-td">NBZ</div>
	<div class="table-td">= NBZ</div>
</div>
<div class="table-tr">
	<div class="table-td">NMZ</div>
	<div class="table-td">= NMZ</div>
</div>
<div class="table-tr">
	<div class="table-td">ACZ</div>
	<div class="table-td">= ACZ</div>
</div>



</div>


</div>

</section>


<!-- table -->

<!-- calculation -->


<section class="section-score">
<h5 class="score-subsubtitle">Расчет приведенных контингентов по корреспондирующим УГСН по данным отчетного года:</h5>

<div class="calculation">

	<div class="calculation__row">
		<div class="calculation__cell">NMP</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">1,0 × NMO</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,25 × NMV</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × NMZ</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">1,0 × NMO</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">1,0 × ACO</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,25 × ACV</div>
		<div class="calculation__cellcalculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × ACZ</div>
		<div class="calculation__cellcalculation__cell_no-grow">=</div>
		<div class="calculation__cell">ACP</div>
	</div>
		<div class="calculation__row">
		<div class="calculation__cell">ACP</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">1,0 × ACO</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,25 × ACV</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × ACZ</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">1,0 × 8</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">0,25 × 0</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × 0</div>
		<div class="calculation__cellcalculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × ACZ</div>
		<div class="calculation__cellcalculation__cell_no-grow">=</div>
		<div class="calculation__cell">ACP</div>
	</div>
		<div class="calculation__row">
		<div class="calculation__cell">NBP</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">1,0 × NBO</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,25 × NBV</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × NBZ</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">1,0 × NBO</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">0,25 × NBV</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × NBZ</div>
		<div class="calculation__cellcalculation__cell_no-grow">+</div>
		<div class="calculation__cell">0,1 × ACZ</div>
		<div class="calculation__cellcalculation__cell_no-grow">=</div>
		<div class="calculation__cell">NBP</div>
	</div>


</div>

<div class="calculation__about">
	<p class="calculation__about-text">NMP - приведенный контингент магистратуры (не учитывается при расчете показателя по программам специалитета);</p>
<p class="calculation__about-text">ACP - приведенный контингент аспирантуры;</p>
<p class="calculation__about-text">NBP - приведенный контингент бакалавриата (и программам специалитета).</p>

</div>
</section>

<!-- calculation -->

<!-- formula -->
<section class="section-score">
<h5 class="score-subsubtitle">Расчет базового значения:</h5>

<div class="formula">

<div class="formula-full">Б22 0 =</div>
<div class="formula-full">
	<div class="formula-half formula-half_underline">NMP+3×ACP</div>
	<div class="formula-half">NBP</div>
</div>
<div class="formula-full">=</div>
<div class="formula-full">
	<div class="formula-half">NMP+3×ACP</div>
	<div class="formula-half">NBP</div>
</div>
<div class="formula-full">= Б22.B0</div>

</div>


<!-- formula -->

<div class="score-about">
<p><b>Учет влияние базового значения головной организации (проводится только для филиалов):</b></p>
<p>Б22 0=(1 − G) × Б22 ф + G × Б22 г = (1 −G Б22.B0 G Б22.B0h = Б22.B</p>
<p>G - доля оценки головной организации в базовом значении по филиалу: 0,35 или 0 (при отсутствии значения у головной организации);</p>
<p>Б22 - с индексами "ф" и "г" − базовые значения показателя соответственно для филиала и его головной образовательной организации.</p>
</div>


</section>




<!-- 2 Нормированная оценка показателя -->

<h4 class="score-subtitle">2. Нормированная оценка показателя</h4>


<section class="section-score">

<h5 class="score-subsubtitle">Расчет нормированной оценки по базовому значению:</h5>


<div class="formula">
	<div class="formula-full"><span>Б22 N = <img src="/assets/img/bracket.png"></span></div>
	<div class="formula-full">
		<div class="formula-half">0, если Б22 ≤ Vmin</div>
		<div class="formula-half">1, если Б22 ≥ Vmax</div>
		<div class="formula-half">Б22 − Vmin, в иных</div>
		<div class="formula-half">Vmax−Vmin случаях</div>
	</div>
	<div class="formula-full"><span>=<img src="/assets/img/bracket.png"></span></div>
		<div class="formula-full">
		<div class="formula-half">0, если Б22.B ≤ Б22.min</div>
		<div class="formula-half">1, если Б22.B ≥ Б22.max</div>
		<div class="formula-half">Б22.B − Б22.min, в иных</div>
		<div class="formula-half">Б22.max − Б22.min случаях</div>
	</div>
	<div class="formula-full">= Б22.N</div>

</div>


<div class="calculation__about">
<p class="calculation__about-text">Б22 - базовое значение показателя (для филиалов с учетом влияния оценки головной организации)</p>
<p class="calculation__about-text">Vmin - установленное минимальное значение показателя (Приложение №2 к Порядку)</p>
<p class="calculation__about-text">Vmax - установленное максимальное значение показателя (Приложение №2 к Порядку)</p>

</div>


	<div class="score-about">
		<p><b>Расчет нормированной оценки по средним:</b></p>
		<p>При невозможности расчета базового значения показателя в качестве его нормированной оценки принимается минимальное из средних
нормированных значений по субъекту РФ, федеральному округу или Российской Федерации в целом. Расчет средних проводится по
нормированным оценкам всех образовательных организаций, которые были допущены к участию в конкурсе и в отношении которых имеются
фактические значения. Расчет проводится раздельно по формам обучения:</p>

	</div>



	<div class="score-about">


<p>Б22 N оччная формаа = Min { Б22 РФочБ22 ФОочБ22 Роч } = Min {Б22.RF1 Б22.FO1 Б22.RE1 } = Б22.N1</p>
<p>Б22 N очч..-заочная форма= Min { Б22 РФвч Б22 ФОвч Б22 Рвч } = Min { Б22.RF2 Б22.FO2 Б22.RE2 } = Б22.N2</p>
<p>Б22 N ззаоччная формаа = Min { Б22 РФзч Б22 ФОзч Б22 Рзч } = Min { Б22.RF3 Б22.FO3 Б22.RE3 } = Б22.N3</p>

		<p>Для УГСН (НПС), контрольные цифры приема по которым распределяются без учета места нахождения образовательных организаций,
нормированная оценка принимается равной среднему значению по Российской Федерации в целом</p>

	</div>
</section>

<!-- 3 Итоговое балльное значение показателя -->

<h4 class="score-subtitle">3. Итоговое балльное значение показателя</h4>

<section class="section-score">


<div class="calculation">
<div class="calculation__row">
		<div class="calculation__cell calculation__cell_color">Б22 очная форма</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">Б22 N-оч</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">Б22.N1</div>
		<div class="calculation__cell calculation__cell_no-grow">×</div>
		<div class="calculation__cell">Б22.W</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">Б22.1</div>
		<div class="calculation__cell calculation__cell_no-grow"> </div>
		<div class="calculation__cell">Б22.r1</div>
		<div class="calculation__cell calculation__cell_no-grow">из</div>
		<div class="calculation__cell">Б22.q1</div>
</div>
<div class="calculation__row">
		<div class="calculation__cell calculation__cell_color">Б22 оч.-заочная форма</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">Б22 N-вч</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">W</div>
		<div class="calculation__cell calculation__cell_no-grow">×</div>
		<div class="calculation__cell">Б22.N2</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">Б22.W</div>
		<div class="calculation__cell calculation__cell_no-grow"> </div>
		<div class="calculation__cell">Б22.2</div>
		<div class="calculation__cell calculation__cell_no-grow">из</div>
		<div class="calculation__cell">Б22.r2</div>
</div>
<div class="calculation__row">
		<div class="calculation__cell calculation__cell_color">Б22 заочная форма</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">Б22 N-зч</div>
		<div class="calculation__cell calculation__cell_no-grow">+</div>
		<div class="calculation__cell">W</div>
		<div class="calculation__cell calculation__cell_no-grow">×</div>
		<div class="calculation__cell">Б22.N3</div>
		<div class="calculation__cell calculation__cell_no-grow">=</div>
		<div class="calculation__cell">Б22.W</div>
		<div class="calculation__cell calculation__cell_no-grow"> </div>
		<div class="calculation__cell">Б22.3</div>
		<div class="calculation__cell calculation__cell_no-grow">из</div>
		<div class="calculation__cell">Б22.r3</div>
</div>


</div>

</section>

</v-container>
</template>


<script>
import HeaderMain from '@/components/headers/HeaderMain';


export default {
components: {
	HeaderMain,

},
}

</script>


<style lang="sass">
.score-subtitle
	color: #eb8e1c
	font-weight: 500
	font-size: 20px
	margin-top: 15px
.score-about
	color: #546e7a
	font-size: 15px
	margin-top: 15px	
.score-subsubtitle
	font-size: 14px
	color: #eb8e1c
	font-weight: 500
.formula
	display: flex
	font-size: 20px
	font-weight: 600
.formula-full
	align-items: center
	display: flex
	justify-content: center
	flex-direction: column
	margin: 10px
.formula-half_underline
	border-bottom: 2px solid #000
.score-about-text
	margin-bottom: 0 !important
.calculation__row
	display: flex
	font-weight: 500
.calculation__cell
	display: flex
	flex-basis: 0
	flex-grow: 1
	justify-content: center
	font-size: 15px
.calculation__cell_no-grow
	flex-grow: 0
.score__header
	display: flex
	justify-content: space-between
.score-scale
	display: flex
.score-scale__line
	min-width: 400px
	background: #eb8e1c
	justify-content: flex-end
	display: flex
	align-items: center
	padding: 5px 10px
	border-radius: 5px
.score-scale__score
	font-weight: 900
	color: #fff
	font-size: 20px
.score-scale__max
	display: flex
	flex-direction: column
	align-items: center
	margin-left: 10px	
.score-title__name
	color: #eb8e1c
	font-size: 20px
	font-weight: 700
.score-title_about
	font-size: 18px
.section-score
	margin-bottom: 25px
	margin-top: 25px
.calculation__about-text
	margin-bottom: 0 !important
.score-rating
	display: flex
	align-items: center
.score-about p
	margin-bottom: 0
.table-tr
	display: flex
.table-td
	display: flex
	flex-basis: 0
	flex-grow: 1
	border: 1px solid #546e7a
	padding: 2px 5px
.tables
	display: flex
	justify-content: space-between
.calculation__cell_color
	color: #eb8e1c		
</style>