<template>
	<main>
	<v-container>
		<ContainerDocumentsCategory />
	</v-container>
    </main>
</template>

<script>
	import ContainerDocumentsCategory from '@/containers/front/documents/ContainerDocumentsCategory';

	export default {
		components: {
			ContainerDocumentsCategory,
			},
	}
</script>

<style lang="sass">
</style>