import { render, staticRenderFns } from "./ContainerNewsCategory.vue?vue&type=template&id=93e3762c&"
import script from "./ContainerNewsCategory.vue?vue&type=script&lang=js&"
export * from "./ContainerNewsCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports