var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('v-divider',{staticClass:"mt-4"}),_c('h4',{staticClass:"score-subtitle"},[_vm._v("1. Значение показателя")]),_c('h5',{staticClass:"score-subsubtitle"},[_vm._v(" Исходные данные: ")]),_vm._m(1),_c('table',{staticClass:"indicators__table"},[_vm._m(2),_c('tbody',{staticClass:"b32-tbody"},[_c('tr',[_c('td',{},[_vm._v("1.")]),_c('td',{},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.sprUsl.uslName))]),_c('td',{},[_vm._v(" "+_vm._s(_vm.rowOfValues.data.sprKCPGroup.code)+" ")]),_c('td',{},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.name))]),_c('td',{attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.h))}}),_c('td',{attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.k))}})]),_vm._m(3),_vm._m(4)])]),_c('section',{staticClass:"section-score"},[_c('h5',{staticClass:"score-subsubtitle"},[_vm._v("Расчет значения показателя:")]),_c('div',{staticClass:"formula"},[_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"formula-full"},[_vm._v("=")]),_c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"formula-half formula-half_underline px-3"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.k))}})]),_c('div',{staticClass:"formula-half",staticStyle:{"margin-left":"25%"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.h))}})])]),_vm._m(8),_c('div',{staticClass:"formula-full"},[_vm._v("=")]),_c('div',{staticClass:"formula-full",domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.m32b0))}})]),_vm._m(9),_vm._m(10)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-about"},[_c('p',[_c('b',[_vm._v("п.2.3.2. Приложения 2 Порядка:")])]),_c('p',[_vm._v("Процент сохранности контингента обучающихся")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calculation__about"},[_c('p',{staticClass:"calculation__about-text"},[_vm._v(" Форма 5.13."),_c('br'),_vm._v("Сохранность контингента обучающихся по программам магистратуры и ассистентуры-стажировки, человек ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"b32-thead"},[_c('tr',{},[_c('th',{attrs:{"rowspan":"3"}},[_vm._v("№ п/г")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Уровень")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("УГСН")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("Магистров I курса")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("Магистров II курса")])]),_c('tr',{},[_c('th',{},[_vm._v("Код")]),_c('th',{},[_vm._v("Наименование")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("на 01.10.2017")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("на 01.10.2020")])]),_c('tr',{},[_c('th',{},[_vm._v("1")]),_c('th',{},[_vm._v("2")]),_c('th',{},[_vm._v("3")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("4")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("5")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{attrs:{"colspan":"3"}},[_c('div',[_c('img',{staticStyle:{"height":"20px","margin":"0","width":"210px"},attrs:{"src":require("@/assets/img/brackets/bottom-bracket-removebg-preview.png"),"alt":""}})])]),_c('td',{attrs:{"colspan":"3"}},[_c('div',[_c('img',{staticStyle:{"height":"20px","margin":"0","width":"210px"},attrs:{"src":require("@/assets/img/brackets/bottom-bracket-removebg-preview.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{attrs:{"colspan":"3"}},[_c('div',[_vm._v(" H ")])]),_c('td',{attrs:{"colspan":"3"}},[_c('div',[_vm._v(" K ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('span',[_vm._v("М32"),_c('span',{staticClass:"font-size-little"},[_vm._v("0")]),_vm._v(" =")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"formula-half formula-half_underline px-3"},[_vm._v("K")]),_c('div',{staticClass:"formula-half",staticStyle:{"margin-left":"25%"}},[_vm._v("H")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"font-weight-regular"},[_vm._v("×100")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"font-weight-regular"},[_vm._v("×100")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-about mb-4"},[_c('p',{staticClass:"ml-4"},[_vm._v("Если значение показателя Н менее 5, фактическое значение показателя не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calculation__about"},[_c('p',{staticClass:"calculation__about-text"},[_vm._v(" K - численность магистров II курса, обучавшихся по очной форме обучения на 01.10.2020 года ")]),_c('p',{staticClass:"calculation__about-text"},[_vm._v(" H - численность магистров I курса, обучавшихся по очной форме обучения на 01.10.2019 года ")])])
}]

export { render, staticRenderFns }