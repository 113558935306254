<template>
<div>

<HeaderMain
	title="Личный кабинет"
	subtitle=""
/>

<AlertSuccess v-if="convertAccepted"> 
      <p>Конверт принят {{convertAcceptInfo.data}} под номером {{convertAcceptInfo.id}} <button @click="getConvert" class="button_border button_border_application">Просмотр загруженного файла</button></p>
    </AlertSuccess>

<AlertSuccess v-if="get_blockedAll && !convertAccepted"> 
      <p>Заявка отправлена на конкурс</p>
</AlertSuccess>
<AlertError v-if="get_check_filled && !get_check_filled.contactCheck"> 
      <p>Заполнены не все данные контактного лица</p>
</AlertError>
<AlertError v-if="get_check_filled && !get_check_filled.directorCheck"> 
      <p>Заполнены не все данные руководителя</p>
</AlertError>
<AlertError v-if="get_check_filled && !get_check_filled.filesCheck"> 
      <p>Загружены не все файлы</p>
</AlertError>


<!-- <Alert alertType="danger" v-if="get_check_filled && !get_check_filled.orgCheck">
	<template v-slot:icon>
		<v-icon :size="30" color="#6a1a21">mdi-alert</v-icon>
	</template>
	<template v-slot:default>
		Не заполнены данные организации
	</template>
</Alert> -->


<!-- <ФАйлы о распределении итоговом КЦП> -->
<WrapperPreviewCategory customClass="section-preview_lk">
  <WrapperCollapse
      title="Результаты открытого публичного конкурса по распределению контрольных цифр приема за счет бюджетных ассигнований федерального бюджета на 2022/23 учебный год"
      :initialOpen="true"
  >
    <container-k-c-p-information-documents/>
  </WrapperCollapse>
</WrapperPreviewCategory>

<!-- <Представитель> -->
<WrapperPreviewCategory customClass="section-preview_lk">
<WrapperCollapse
	title="Контактная информация представителя образовательной организации, ответственного за участие в конкурсе"
	:initialOpen="true"
	>
<ContainerPersonContact />
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Представитель> -->

<!-- <Руководитель> -->
<WrapperPreviewCategory customClass="section-preview_lk">
<WrapperCollapse
	title="Сведения о руководителе образовательной организации"
	:initialOpen="true"
	>
	<ContainerPersonHead />
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Руководитель> -->

<!-- <Организация> -->
<WrapperPreviewCategory customClass="section-preview_lk">
<WrapperCollapse
	title="Информация об организации"
	:initialOpen="true"
	>
	<ContainerOrganization />
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Организация> -->


<!-- <Филиалы> -->
<WrapperPreviewCategory customClass="section-preview_lk">
<WrapperCollapse
	title="Информация о структурных подразделениях/филиалах"
	:initialOpen="true"
	>
	<ContainerBranchOffice />
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Филиалы> -->

<!-- <Уставные документы> -->
<WrapperPreviewCategory customClass="section-preview_lk">
<WrapperCollapse
	title="Уставные документы"
	:initialOpen="true"
	>
	<ContainerDocuments />
</WrapperCollapse>
</WrapperPreviewCategory>
<!-- </Уставные документы> -->

</div>
</template>


<script>
import saveFile from '@/utils/saveFile';
import request from "@/services/request";
import moment from 'moment';
moment.locale('ru');
import {mapGetters, mapActions} from 'vuex';

import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
// import Alert from '@/components/alerts/Alert';
import AlertSuccess from '@/components/alerts/AlertSuccess';
import AlertError from '@/components/alerts/AlertError';
import HeaderMain from '@/components/headers/HeaderMain';

import ContainerPersonContact from '@/containers/private/ContainerPersonContact';
import ContainerPersonHead from '@/containers/private/ContainerPersonHead';
import ContainerBranchOffice from '@/containers/private/ContainerBranchOffice';
import ContainerOrganization from '@/containers/private/ContainerOrganization';
import ContainerDocuments from '@/containers/private/ContainerDocuments';
import ContainerKCPInformationDocuments from "@/containers/private/ContainerKCPInformationDocuments";

export default {
		components: {
      ContainerKCPInformationDocuments,
		WrapperPreviewCategory,
		WrapperCollapse,
		// Alert,
		HeaderMain,
		ContainerPersonContact,
		ContainerPersonHead,
		ContainerOrganization,
		ContainerBranchOffice,
		ContainerDocuments,
		AlertSuccess,
		AlertError,

	},
	data() {
		return {
			convertAcceptInfo: null,
			convertAccepted: false,
		}
	},
	mounted() {
	if (this.authData.role.id !== 4 && this.authData.role.id !== 6) this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
	if (this.get_blockedAll) {
      this.getStatusConvert();
    }
	},
	computed: {
		...mapGetters('auth', {authData: 'authData'}),
		...mapGetters('checkFilled', {get_check_filled: 'get_check_filled'}),
		...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
		...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
	},
	methods: {
		...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
		async getStatusConvert() {
      let response = await request({
                endpoint: `/api/userProfile/getConvertByOrgID?sprOrgID=${this.get_replace_id_admin}`,
                method: 'GET',
            });
      response = response.data;      
      if (response.convertStatus === 'RECEIVED') {
        this.convertAccepted = true;
      }
        this.convertAcceptInfo = {
          id: response.receivedConvertID,
          fileServerID: response.convertFileServerID,
          data: moment(response.receiptDate).format('LLL'),
          name: response.convertFileName,
          format: response.convertFileFormat,
        }
      
	},
	async getConvert() {
        let response = await request({
            endpoint: '/api/fileServer/downloadFileByID',
            method: 'POST',
            body: {
                fileServerID: this.convertAcceptInfo.fileServerID,
                fileName: this.convertAcceptInfo.name,
                fileFormat: this.convertAcceptInfo.format,
                }
        });
        saveFile(response.data);
    },
	}
}
	
</script>