import Api from "@/utils/Api";
// import moment from "moment";

export default {
    namespaced: true,
    state: {
        newsListMain: [],
        newsList: [],
        newsDetail: null,
        newsListCounter: 0,
    },
    mutations: {
        ['ADD_NEWS_LIST'](state, payload) {
            state.newsList = payload;
        },
        ['ADD_NEWS_LIST_COUNTER'](state, payload) {
            state.newsListCounter = payload;
        },
        ['ADD_NEWS_LIST_MAIN'](state, payload) {
            state.newsListMain = payload;
        },
        ['ADD_NEWS_DETAIL'](state, payload) {
            state.newsDetail = payload;
        },        
    },
    actions: {
        async requestNewsList({commit, getters}, payload = {'pageNumber': 0, 'recordCount': 0, 'type': ''}) {
            const {pageNumber, recordCount, type} = payload;
            if (type === 'main') {
                if (getters.get_news_list_main.length) return;
                const newsList = await Api.requestNewsList({pageNumber, recordCount});
                commit('ADD_NEWS_LIST_MAIN', newsList.data.articles)
            } else {
                const newsList = await Api.requestNewsList({pageNumber, recordCount});
                commit('ADD_NEWS_LIST', newsList.data.articles)
                commit('ADD_NEWS_LIST_COUNTER', newsList.data.counter)
            }


            
            
        },
        async getNewsDetail({commit}, payload) {
            const newsDetail = await Api.requestNewsDetail(payload);
            commit('ADD_NEWS_DETAIL', newsDetail.data)
        }
    },
    getters: {
        get_news_list(state) {
            return state.newsList
        },
        get_news_list_counter(state) {
            return state.newsListCounter
        },
        get_news_list_main(state) {
            return state.newsListMain
        }
    }
}