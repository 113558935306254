<template>
<ContainerAppeals />
</template>


<script>
import ContainerAppeals from '@/containers/admin/appeals/ContainerAppeals';
export default {
	components: {
        ContainerAppeals,
	},
	data() {
			return {

		}
	},
}

</script>