<template>
    <div>
        <div class="score-about">
            <p><b>п.1.1.2. Приложения 2 Порядка:</b></p>
            <p>Процент приема в пределах контрольных цифр приема</p>
        </div>
        <h4 class="score-subtitle">1. Значение показателя</h4>
        <h5 class="score-subsubtitle">
            Исходные данные:
        </h5>
        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.6.</p>
            <p class="calculation__about-text">Прием на обучение за счет средств федерального бюджета, человек</p>
        </div>
        <table class="indicators__table">
            <thead class="indicator-thead">
                <tr>
                    <th class="border" rowspan="3">№ п/п</th>
                    <th class="border" rowspan="2">Уровень</th>
                    <th class="border" colspan="2">УГСН </th>
                    <th class="border" rowspan="2">Форма обучения</th>
                    <th class="border" colspan="3">Численность зачисленных на I курс обучения</th>
                    <th class="min" rowspan="3"></th>
                    <th class="border_bottom" colspan="2" rowspan="2" style="vertical-align: middle">Расчет исходных значений</th>
                </tr>
                <tr>
                    <th class="border">Код</th>
                    <th class="border">Наименование</th>
                    <th class="border">2018 год</th>
                    <th class="border">2019 год</th>
                    <th class="border">2020 год</th>
                </tr>
                <tr>
                    <th class="border">1</th>
                    <th class="border">2</th>
                    <th class="border">3</th>
                    <th class="border">4</th>
                    <th class="border">5</th>
                    <th class="border">6</th>
                    <th class="border">7</th>
                    <th>переменная</th>
                    <th>значение</th>
                </tr>
            </thead>
            <tbody class="indicator-tbody">
                <tr>
                    <td class="border" colspan="8">{{ rowOfValues.data.sprOrg.orgName }}</td>
                    <td rowspan="8">
                        <img src="@/assets/img/brackets/open-brackets.svg" alt=""/>
                    </td>
                    <td class="text_right" rowspan="8">ПРФ = </td>
                    <td class="text_left" rowspan="8">{{ rowOfValues.data.pfr }}</td>
                </tr>
                <tr>
                    <td class="border">1.</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="border">Очная</td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfro18)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfro19)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfro20)"></td>
                </tr>
                <tr>
                    <td class="border">2.</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="border">Очно-заочная</td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrv18)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrv19)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrv20)"></td>
                </tr>
                <tr>
                    <td class="border">3.</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="border">Заочная</td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrz18)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrz19)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrz20)"></td>
                </tr>
                <tr>
                    <td colspan="8">Филиалы</td>
                </tr>
                <tr>
                    <td class="border">1.</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="border">Очная</td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfro18f)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfro19f)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfro20f)"></td>
                </tr>
                <tr>
                    <td class="border">2.</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="border">Очно-заочная</td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrv18f)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrv19f)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrv20f)"></td>
                </tr>
                <tr>
                    <td class="border">3.</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="border">Заочная</td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrz18f)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrz19f)"></td>
                    <td class="border text_right" v-html="checkData( rowOfValues.data.pfrz20f)"></td>
                </tr>
            </tbody>
        </table>

        <div class="calculation__about mt-4">
            <p class="calculation__about-text">Установленные контрольные цифры приема (КЦП), мест</p>
        </div>
        <table class="indicators__table">
            <thead class="indicator-thead">
            <tr>
                <th class="border" rowspan="3">№ п/п</th>
                <th class="border" rowspan="2">Уровень</th>
                <th class="border" colspan="2">УГСН </th>
                <th class="border" rowspan="2">Форма обучения</th>
                <th class="border" colspan="3">Контрольные цифры приема (КЦП)</th>
                <th class="min" rowspan="3"></th>
                <th class="border_bottom" colspan="2" rowspan="2" style="vertical-align: middle">Расчет исходных значений</th>
            </tr>
            <tr>
                <th class="border">Код</th>
                <th class="border">Наименование</th>
                <th class="border">2018 год</th>
                <th class="border">2019 год</th>
                <th class="border">2020 год</th>
            </tr>
            <tr>
                <th class="border">1</th>
                <th class="border">2</th>
                <th class="border">3</th>
                <th class="border">4</th>
                <th class="border">5</th>
                <th class="border">6</th>
                <th class="border">7</th>
                <th>переменная</th>
                <th>значение</th>
            </tr>
            </thead>
            <tbody class="indicator-tbody">
            <tr>
                <td class="border" colspan="8">{{ rowOfValues.data.sprOrg.orgName }}</td>
                <td rowspan="8">
                    <img src="@/assets/img/brackets/open-brackets.svg" alt=""/>
                </td>
                <td class="text_right" rowspan="8">КЦО = </td>
                <td class="text_left" rowspan="8">{{ rowOfValues.data.kco }}</td>
            </tr>
            <tr>
                <td class="border">1.</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="border">Очная</td>
                <td class="border text_right" v-html="checkData(rowOfValues.data.kcoo18)"></td>
                <td class="border text_right" v-html="checkData(rowOfValues.data.kcoo19)"></td>
                <td class="border text_right" v-html="checkData(rowOfValues.data.kcoo20)"></td>
            </tr>
            <tr>
                <td class="border">2.</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="border">Очно-заочная</td>
                <td class="border text_right" v-html="checkData(rowOfValues.data.kcov18)"></td>
                <td class="border text_right" v-html="checkData(rowOfValues.data.kcov19)"></td>
                <td class="border text_right" v-html="checkData(rowOfValues.data.kcov20)"></td>
            </tr>
            <tr>
                <td class="border">3.</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="border">Заочная</td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoz18)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoz19)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoz20)"></td>
            </tr>
            <tr>
                <td colspan="8">Филиалы</td>
            </tr>
            <tr>
                <td class="border">1.</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="border">Очная</td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoo18f)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoo19f)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoo20f)"></td>
            </tr>
            <tr>
                <td class="border">2.</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="border">Очно-заочная</td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcov18f)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcov19f)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcov20f)"></td>
            </tr>
            <tr>
                <td class="border">3.</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="border">Заочная</td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoz18f)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoz19f)"></td>
                <td class="border text_right" v-html="checkData( rowOfValues.data.kcoz20f)"></td>
            </tr>
            </tbody>
        </table>
        <!-- formula -->
        <section class="section-score">
            <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

            <div class="formula">
                <div class="formula-full"><span>M14<span class="font-size-little__bottom">0</span> =</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline">ПРФ</div>
                    <div class="formula-half">КЦО</div>
                </div>
                <div class="formula-full"><span class="font-weight-regular">×</span></div>
                <div class="formula-full"><span class="font-weight-regular">100</span></div>
                <div class="formula-full"><span class="font-weight-regular">=</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline">{{ rowOfValues.data.pfr }}</div>
                    <div class="formula-half">{{ rowOfValues.data.kco }}</div>
                </div>
                <div class="formula-full"><span class="font-weight-regular">×</span></div>
                <div class="formula-full"><span class="font-weight-regular">100</span></div>
                <div class="formula-full"><span class="font-weight-regular">=</span></div>
                <div class="formula-full">{{ rowOfValues.data.m14b0 }}</div>
            </div>
            <div class="score-about">
              <p>ПФР - число обучающихся, зачисленных на обучение за счет средств федерального бюджета на I курс обучения по всем формам обучения</p>
              <p>КЦО - сумма контрольных цифр приема по всем формам обучения (без учета отказа образовательной организации от установленных контрольных цифр приема)</p>
            </div>
        </section>

    </div>
</template>

<script>
    export default {
        name: "m14",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
        methods: {
            checkData(value) {
                return value;
                // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value.value,'float',value.rnd,false, 'color: #C6C8CB;')
            }
        },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

    .indicator-thead
        th
            text-align: center
            &.border
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px
            &.min
                min-width: 40px
            &.border_bottom
                border-bottom: 1px solid #546e7a
    .indicator-tbody
        td
            &.border
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px
            &.min
                min-width: 40px
            img
                height: 260px
                margin-right: 0px
                width: 40px
                position: absolute
            &.text
                &_left
                    font-weight: bold
                    vertical-align: middle
                    text-align: left
                &_right
                    font-weight: bold
                    vertical-align: middle
                    text-align: right
                &_center
                    vertical-align: middle
                    text-align: center

    .grid-container__header
        display: grid
        //grid-template-rows: 1fr 1fr
        div
            border: 1px solid black
            text-align: center
        //grid-auto-rows: 50px
        grid-gap: 3px
        grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

        .grid-row-span-2
            grid-column: 1
            grid-row: 1/3

        &_inner
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 2px
</style>