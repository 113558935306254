<template>

    <div class="section-table" v-if="!loadingFlag & !errorHeaders" >
      <div class="table__wrapper">

        <div v-if="!loadingFlagRows & !errorRows" class="table-topscroll" ref="tabletopscroll" @scroll="tableTopScroll">
            <div class="table-topscroll__div" ref="tabletopscrolldiv" :style="{width: scrollWidth + 'px'}"></div>
        </div>

      <table ref="table" class="table" @scroll="tableScroll">

       <TableHeader
            v-if="!loadingFlag & !errorHeaders"
            v-on:sortHed="sortBySoloAttr($event)"
            :sort-by="sortByArr"
            :action-access="actionAccess"
            :headers-props="tableHeaders"
            :rolled-cols="rolledByArr"
            :show-dialog.sync="showDialogNext"
            v-on:hidden:hed="hideColumn($event)"
            :scrollWidth="scrollWidth"
        /><!--           :show-dialog.sync="$emit('update:show-dialog', $event.target.value)"-->
        <component
            :is="rowComponent"
            v-if="!loadingFlagRows & !errorRows"
            :rows-props="typePagination === 0 ? paginatedSelectForVuetify : paginatedSelect"
            :color="color"
            :action-access="actionAccess"
            :rolled-cols="rolledByArr"
            :order-columns="tableHeaders"
            :custom-action-props="customActionProps"
            :array-for-menu-cells="arrayForMenuCells"
            v-on:action-row="actionRow($event)"
            v-on:scrollBodyTableWidth = "scrollWidth = $event"
        />
<!--        <TableRow-->
<!--            v-if="!loadingFlagRows & !errorRows"-->
<!--            :rows-props="paginatedSelect"-->
<!--            :color="color"-->
<!--            :action-access="actionAccess"-->
<!--            :rolled-cols="rolledByArr"-->
<!--            :order-columns="tableHeaders"-->
<!--            v-on:action-row="actionRow($event)"-->
<!--            v-on:scrollBodyTableWidth = "scrollWidth = $event"-->
<!--        />-->
            <div v-else style="display: flex;align-items: center;justify-content: center; min-height: 300px; width: 100%;">
              <v-progress-linear
                  indeterminate
                  color="#00599b"
                  width="100%"
            ></v-progress-linear>
            </div>

<!--        <transition name="rows" >-->
<!--        </transition>-->
      </table>
      </div>
      <div v-if="!loadingFlagRows & !errorRows" class="v-table__pagination">
        <!--
        link for doc on github for vue-ads-pagination https://github.com/arnedesmedt/vue-ads-pagination
        -->
        <v-container>
          <v-row no-gutters
          justify="space-between"
          align="center">
            <v-col cols="4" class="v-table__selector_page" >
              <span class="selector_page__about">Строк на странице </span>
              <Selector
                  class="selector_page__number"
                  :options-arr="pagesPaginate"
                  v-on:update:option="paginateUpdate($event)"
                  :value="userPages"/>
<!--              <v-select-->
<!--                  :items="pagesPaginateVuetify"-->
<!--                  :menu-props="{ top: true, offsetY: true }"-->
<!--                  label="Label"-->
<!--              ></v-select>-->
            </v-col>
            <v-col
                class="custom-pagination">
              <v-pagination
                  v-if="typePagination === 0"
                  v-model="pageNumberVuetify"
                  :length="pagesVuetify"
                  :total-visible="9"
                  color="#0D72BD"
                  @input="pageClickVuetify"
              ></v-pagination>
              <vue-ads-pagination
                  v-else
                  :total-items="totalElements"
                  :items-per-page="parseInt(userPages.val, 10)"
                  :max-visible-pages="countsButtonsPaginator"
                  :page="pageNumber"
                  @page-change="pageClick"
              >
                <template slot-scope="props">
                  <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    Отображены строки с {{ props.start }} по {{ props.end }}, всего строк: {{ props.total }}
                  </div>
                </template>
                <template
                    slot="buttons"
                    slot-scope="props"
                >
                  <vue-ads-page-button
                      v-for="(button, key) in props.buttons"
                      :key="key"
                      v-bind="button"
                      class="vue-ads-page-button-rewrite"
                      :class="{'bg-yellow-dark': button.active}"
                      @page-change="pageClick(button.page)"
                      @range-change="start = button.start; end = button.end"
                  />
                </template>
              </vue-ads-pagination>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div
        v-else-if="loadingFlagRows"
        style="display: flex; align-items: center;justify-content: center;padding-bottom: 40px;padding-top: 40px;min-height: 300px; width: 100%;"
    >
      <v-progress-linear
          indeterminate
          color="#00599b"
          width="100%"
      ></v-progress-linear><!--:width="scrollWidth"-->
    </div>
    <div
        v-else
        style="display: flex; align-items: center;justify-content: center;"
    >
      <h2>
        Ошибка загрузки, обновите страницу
      </h2>
    </div>
</template>

<script>
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableRowWithMenu from "./TableRowWithMenu";
import TableRowWithRoute from "@/components/DTable_new/TableRowWithRoute";
import Selector from "@/components/UI/Selector";
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import 'vue-ads-pagination/dist/vue-ads-pagination.css';
export default {
  name: "Table",
  components: {
    TableHeader,TableRow,Selector,  VueAdsPagination, VueAdsPageButton,TableRowWithMenu,TableRowWithRoute
  },
  props:{
    tableData:{
      type: Object,
    },
    typePagination:{
      type: Number,
      default: 0
    },
    nameRowComponent:{
      type: String,
    },
    arrayForMenuCells: {
      type: Array
    },
    color:{
      type: Object,
    },
    actionAccess:{
      type: Boolean,
      default: false
    },
    loadingFlag:{
      type:Boolean,
      default: false
    },
    loadingFlagRows:{
      type:Boolean,
      default: false
    },
    tableHeaders:{
      type: Array,
      default: () => []
    },
    hiddenCols:{
      type: Array,
      default: () => []
    },
    rolledCols:{
      type: Array,
      default: () => []
    },
    errorHeaders: {
      default: null
    },
    errorRows: {
      default: null
    },
    showDialog: {
      type: Object,
      default: ()=>{ return {show:false, typeDialog: 1}},
    },
    customActionProps: {},
    pagesPaginate:{
      type:Array,
      default: ()=>[
        {val: 5, name: 5},
        {val: 10, name: 10},
        {val: 25, name: 25},
        {val: 50, name: 50},
        {val: 100, name: 100},
        {val: 250, name: 250},
        {val: 500, name: 500},
        {val: 1000, name: 1000},
      ]
    },
    pagesPaginateVuetify:{
      type:Array,
      default: ()=>[
        {value: 5,    text: 5},
        {value: 10,   text: 10},
        {value: 25,   text: 25},
        {value: 50,   text: 50},
        {value: 100,  text: 100},
        {value: 250,  text: 250},
        {value: 500,  text: 500},
        {value: 1000, text: 1000},
      ]
    },
    sortByProp:{
      type: Array,
      default : () => []
    }
  },
  data: () => ({
    userPages: {val:50, name:50},
    pageNumber: 0,
    pageNumberVuetify: 1,
    start: 0,
    end: 0,
    sortBy:[],
    hiddenBy: [],
    scrollWidth: 0,
    removeDopFilter: undefined
  }),
  computed:{
    rowComponent() {
      return this.nameRowComponent != null ? this.nameRowComponent : TableRow
    },
    showDialogNext:{
      get() {
        return this.showDialog
      },
      set (value){
        this.$emit('update:show-dialog',value)
      }
    },
    countsButtonsPaginator(){
      let width = document.documentElement.clientWidth;
      if (width < 1000)
         return 3
      else if (width < 1500 && width>= 1000)
          return 7
        else
          return 10
    },
    pagesVuetify(){
      return Math.ceil(this.totalElements/parseInt(this.userPages.val, 10))
    },
    pages() {
      return Math.ceil(this.tableData.page.totalPages)
    },
    totalElements(){
      if (Object.prototype.hasOwnProperty.call(this.tableData, 'totalElements')  && this.tableData.totalElements !=null)
        return this.tableData.totalElements
      else
        return 0
    },
    paginatedSelect(){
      if (Object.prototype.hasOwnProperty.call(this.tableData, 'content') && this.tableData.content !=null)
        return this.tableData.content.slice(this.pageNumber * this.userPages.val, (this.pageNumber+1) * this.userPages.val )
      else
        return []
    },
    paginatedSelectForVuetify(){
      if (Object.prototype.hasOwnProperty.call(this.tableData, 'content') && this.tableData.content !=null)
        return this.tableData.content.slice((this.pageNumberVuetify-1) * this.userPages.val, (this.pageNumberVuetify) * this.userPages.val)
      else
        return []
    },
    sortByArr:{
      get: function (){
        return this.sortByProp;
      },
      set: function (arr){
        this.$emit('send:sorts',arr);
      }
    },
    rolledByArr:{
      get: function (){
        return this.rolledCols;
      },
      set: function (arr){
        this.$emit('send:hiddenColumn',arr);
      }
    },
    // errorHeaderFlag(){
    //   // let ind= this.errors.length
    //   return false;
    // },
    // errorRowFlag(){
    //   // let ind= this.errors.length;
    //   // return ind > 0;
    //   return false
    // },

  },

  methods: {
    actionRow(obj){
      this.$emit('action-row', obj)
    },
    tableTopScroll() {
        this.$refs.table.scrollLeft = this.$refs.tabletopscroll.scrollLeft;
    },
    tableScroll() {
        this.$refs.tabletopscroll.scrollLeft = this.$refs.table.scrollLeft;
    },
    paginateUpdate(value){
      this.userPages={val:parseInt(value),name:value};
      this.$emit('sendNumOfPaginatePage',this.userPages);
    },
    pageClickVuetify(page){
      this.$emit('sendNumPage',page-1);
    },
    pageClick(page){
      this.pageNumber=page;
      this.$emit('sendNumPage',this.pageNumber);
    },
    hideColumn(obj){
      if (this.rolledByArr.length>0){
        let indexOfHiddenBy = this.$scripts.TableJs.arraySearch(this.rolledByArr, obj.dataField, 'dataField');
        if (indexOfHiddenBy >= 0) {
          if (obj.hidden === false){
            this.rolledByArr=this.$scripts.TableJs.arrayRemove(this.rolledByArr,obj.dataField);
          }
        } else {
          this.rolledByArr.push({dataField: obj.dataField, hidden: obj.hidden});
          this.addHiddenColumn(this.rolledByArr)
        }
      }
      else {
        this.rolledByArr.push({dataField: obj.dataField, hidden: obj.hidden});
        this.addHiddenColumn(this.rolledByArr)
      }
    },
    addHiddenColumn(arr) {
      this.$emit('send:hiddenColumn', arr);
    },
    sortBySoloAttr(obj){
      if (this.sortByArr.length>0) {
        let indexOfSortBy = this.$scripts.TableJs.arraySearch(this.sortByArr, obj.dataField, 'dataField');
        if (indexOfSortBy >= 0) {
          if (obj.typeSort === undefined){
            this.sortByArr=this.$scripts.TableJs.arrayRemove(this.sortByArr,obj.dataField);
          }
          else
          {
            this.sortByArr.splice(indexOfSortBy,1,{dataField: obj.dataField, typeSort: obj.typeSort,typeVar : obj.typeVar,altSort: obj.altSort});
            this.$emit('send:sorts',this.sortByArr);
          }
        } else {
          this.sortByArr.push({dataField: obj.dataField, typeSort: obj.typeSort,typeVar : obj.typeVar,altSort: obj.altSort});
          this.$emit('send:sorts',this.sortByArr);
        }
      }
      else {
        this.sortByArr.push({dataField: obj.dataField, typeSort: obj.typeSort,typeVar : obj.typeVar,altSort: obj.altSort});
        this.$emit('send:sorts',this.sortByArr);
      }
    }
  }
}

</script>
<style lang="scss" >
/*import '../monitoringfront/node_modules/@fortawesome/fontawesome-free/css/all.css';*/
.rows-enter-active, .rows-leave-active {
  transition: all 1s;
}
.custom-pagination{
  .v-pagination{
    justify-content: flex-end;
  }
  .v-pagination__item{
    border-radius: 7px;
    color: #0D72BD!important;
  }
  .v-pagination__item--active{
    color: #FFFFFF!important;
  }
}

.rows-enter, .rows-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.vue-ads-page-button-rewrite{
  flex-grow: 1!important;
}

.v-table__selector_page.col.col-2 {
  display: flex;
  align-items: center;
}
.selector_page__about {
  margin-right: 15px;
  font-size: 12px;
}
.selector_page__number {
  background: #fff;
  color:  #0D72BD;
  //border: 1px solid #0D72BD;
  //border-radius: 7px;
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    color:  #0D72BD;
    border: 1px solid #0D72BD;;
    border-radius: 7px;
    padding: 4px 10px;
    //background: transparent;//   <path android:fillColor='#000' android:pathData='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' />
    //background-image: url("data:image/svg+xml;<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1'  width='24' height='24' viewBox='0 0 24 24'> <path fill='#000000' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /> </svg>");
    //background-repeat: no-repeat;
    //background-position-x: 100%;
    //background-position-y: 4px;
  }
}
/*
  border-color: transparent;*/
.vue-ads-page-button-rewrite.focus\:vue-ads-outline-none.vue-ads-ml-1.vue-ads-leading-normal.vue-ads-w-6.vue-ads-bg-teal-500.vue-ads-text-white.vue-ads-cursor-default.bg-yellow-dark {
  color: #ffffff;
  background: #0292e1;
}

.vue-ads-page-button-rewrite:hover {
  background: #dee7f0;
  transition: 0.2s;
}

.vue-ads-page-button-rewrite {
  color: #0292e1;
  border-radius: 5px;
  border-color: transparent;
  transition: 0.2s;
}
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}


</style>