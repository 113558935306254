<template>
<div>
    
    <FormKcpContestInfoPersonHead v-if="editMode && personToChange" v-model="personToChange" v-on:input="requestPersonUpdate" @cancel="onCancel" />
    <div v-else>
        

    <CardInfo v-if="personInitial && !editMode" :data="personToShow"/>

    <button v-if="authData.role.id === 1 && !get_blockedAll" class="button info-card__button" @click="editMode = !editMode">Редактировать</button>
    <button v-if="authData.role.id !== 1 && !get_blockedAll" class="button info-card__button">Редактировать</button>


    </div>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormKcpContestInfoPersonHead from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoPersonHead';
import CardInfo from '@/components/cards/custom/CardInfo/CardInfo';
import request from "@/services/request";

export default {
    components: {
        FormKcpContestInfoPersonHead,
        CardInfo
    
    },
    data() {
        return {
            editMode: false, 
            personToChange: null,
            personInitial: null,
        }
    },
    computed: {
        ...mapGetters('auth', {authData: 'authData'}),
        ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
        ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
        personToShow() {
            const {fio, fioRodPad, position, positionRodPad, email, workPhoneNumber, personalPhoneNumber} = this.personInitial;
            return {
                'Фамилия, Имя, Отчество руководителя': fio,
                'ФИО руководителя (в родительном падеже)': fioRodPad,
                'Должность руководителя': position,
                'Должность руководителя (в родительном падеже)': positionRodPad,
                'Контактный e-mail': email,
                'Номер рабочего телефона': workPhoneNumber,
                'Номер телефона': personalPhoneNumber,  
            }
        },
    },
    mounted() {
      this.getPersonContact();
    },

    
    methods: {
        ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
		async getPersonContact() {
			let person = await request({
            endpoint: `/api/orgContacts/getDirectors?sprOrgID=${this.get_replace_id_admin}&userProfile=${this.authData.userProfile}`,
			method: 'GET',
            });
            person = person.data[0];

            if (!person) {
                person = {
                    fio: '',
                    fioRodPad: '',
                    email: '',
                    position: '',
                    positionRodPad: '',
                    workPhoneNumber: '',
                    personalPhoneNumber: '',
                }
            }


            for (let key in person) {
                if (person[key] === null) {
                    person[key] = '';
                }
            }
            this.personToChange = {...person};
            this.personInitial = {...person};
        },
        async requestPersonUpdate() {
            if (this.personInitial.sprOrgDirectorID) {
                await request({
                    endpoint: '/api/orgContacts/updateDirector',
                    method: 'POST',
                    body: {
                        ...this.personToChange,
                        sprOrgID: this.authData.sprOrgID,
                        userProfile: this.authData.userProfile,
                        updateID: this.personInitial.sprOrgDirectorID,

                        },
            });
            } else {
                await request({
                    endpoint: '/api/orgContacts/createNewDirector',
                    method: 'POST',
                    body: {
                        ...this.personToChange,
                        sprOrgID: this.authData.sprOrgID,
                        userProfile: this.authData.userProfile,
                        
                        },
            });

            }

            this.getPersonContact();
            this.editMode = false;
            this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
        },
        onCancel() {
            this.personToChange = {...this.personInitial};
            this.editMode = false;
       
        },
	},
}
</script>

<style lang="sass">
.info-card__button
    margin-top: 10px
    margin-left: 10px
</style>