<template>
  <div
      :class="cardClass"
      @click="btnClick(show)"
  >
    <div class="b-indicator__header">
      <div class="b-indicator__title">{{ item.title }}</div>
      <div class="b-indicator__about">{{ item.about }}</div>
    </div>
    <div class="b-indicator__score">
      <div class="b-indicator__count" :class="isNaN(+item.count) ? 'b-indicator__count_disable' : ''" :style="calcBackground(item.count, item.max)">
        <span :style=" !isNaN(item.count) ? '' : 'font-size: 14px;'">{{ !isNaN(item.count) ? item.count :'Показатель не рассчитывается для этой УГСН(НПС)'}}</span>
      </div>
      <div class="b-indicator__max" :class="isNaN(item.count) ? 'b-indicator__max_disable' : ''">{{ item.max }} <span>max</span></div>
    </div>
  </div>
</template>

<script>
export default {
name: "cardIndicatorHead",
  props: {
    showProp:{
      type: Boolean
    },
    item: {
      type: Object,
      default: () => {
        return {title: '', about: '', count: '', max: ''}
      }
    },
    available: {
      type: Boolean,
      default: false
    },
    typeStyle:{
      type: String,
      default: ''
    }
  },
  computed: {
    show: {
     get(){
       return this.$attrs.value;
     },
     set(val){
       this.$emit('input',val)
     }
    },
    cardClass(){
      let cssClass = 'b-indicator'
      if (this.typeStyle !== 'general' && !this.available)
        return "b-indicator b-indicator-unavailable"
      else
      {
        switch (this.typeStyle){
        case 'general':
          cssClass = "b-indicator b-indicator-general"
          break;
        case 'unavailable':
          cssClass = "b-indicator b-indicator-unavailable"
          break;
        default:
          cssClass = "b-indicator"
          break;
      }
      return cssClass
      }
    }
  },
  methods: {
    btnClick(value) {
      if (this.available)
        this.show = !value
    },
    calcBackground(value, maxValue){
      let a=  Number.parseFloat(value)
      let b=  Number.parseFloat(maxValue)
      let per = a * 100 / b
      return  isNaN(per) ?`  background: #f3f3f4; border-color: #CDD1D4`:
      `background: -webkit-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:    -moz-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:     -ms-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:      -o-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:         linear-gradient(to right, #eb722e ${per}%,#f9d3be ${per}%);`
    }
  },
}
</script>

<style >
.b-indicator__wrapper {
    border-bottom: 1px solid #CAD2D8;
    padding: 18px 0px;
}
.b-indicator__wrapper:first-of-type, .b-indicator__wrapper:last-of-type {
    padding-top: 0px;
}
.b-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.b-indicator__title {
    font-size: 17px;
    color: #00599B;
    font-weight: 600;
    line-height: 31px;
}
.b-indicator__about {
    color: #455058;
    font-size: 14px;
}
.b-indicator__score {
    display: flex;
}
.b-indicator__count {
    width: 507px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    border: 1px solid #EB722E;
    border-radius: 5px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    max-height: 35px;
}
.b-indicator__max {
    max-width: 32px;
    color: #EB722E;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    margin-left: 12px;
    line-height: 16px;
}
.b-indicator__max span {
  font-size: 12px;
  font-weight: 400;
}
.b-indicator__max_disable {
    color: #89A0B0;
    opacity: 0;
}
.b-indicator__count_disable {
    background: #f3f3f4;
    font-family: 'Roboto';
    font-size: 17px;
    line-height: 19.92px;
    border-color: #e2e4e6;
    font-weight: 400;
    color: #89A0B0;
}
</style>