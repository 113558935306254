var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('h4',{staticClass:"score-subtitle"},[_vm._v("1. Значение показателя")]),_c('h5',{staticClass:"score-subsubtitle"},[_vm._v(" Исходные данные: ")]),_vm._m(1),_c('table',{staticClass:"indicators__table"},[_vm._m(2),_c('tbody',{staticClass:"indicator-tbody"},[_c('tr',[_c('td',{staticClass:"border",attrs:{"colspan":"6"}},[_vm._v(_vm._s(_vm.rowOfValues.data.sprOrg.orgName))])]),_c('tr',[_c('td',{staticClass:"border"},[_vm._v("1.")]),_c('td',{staticClass:"border"},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.sprUsl.uslName))]),_c('td',{staticClass:"border"},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.code))]),_c('td',{staticClass:"border"},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.name))]),_c('td',{staticClass:"border text-center",domProps:{"innerHTML":_vm._s(_vm.checkData( _vm.rowOfValues.data.p))}}),_c('td',{staticClass:"border text-center",domProps:{"innerHTML":_vm._s(_vm.checkData( _vm.rowOfValues.data.cp))}})]),_vm._m(3),_vm._m(4)])]),_c('section',{staticClass:"section-score"},[_c('h5',{staticClass:"score-subsubtitle"},[_vm._v("Расчет значения показателя:")]),_c('div',{staticClass:"formula"},[_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"formula-half formula-half_underline"},[_vm._v(_vm._s(_vm.rowOfValues.data.cp))]),_c('div',{staticClass:"formula-half"},[_vm._v(_vm._s(_vm.rowOfValues.data.p))])]),_vm._m(8),_c('div',{staticClass:"formula-full"},[_vm._v(_vm._s(_vm.rowOfValues.data.b13b0))])]),_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-about"},[_c('p',[_c('b',[_vm._v("п.1.1.3. Приложения 2 Порядка:")])]),_c('p',[_vm._v("Доля принятых на обучение в рамках квоты приема на целевое обучение")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calculation__about"},[_c('p',{staticClass:"calculation__about-text"},[_vm._v("Форма 5.7.")]),_c('p',{staticClass:"calculation__about-text"},[_vm._v("Прием на обучение по очной форме на бакалавриат и специалитет за счет всех источников финансирования, человек")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"indicator-thead"},[_c('tr',[_c('th',{staticClass:"border",attrs:{"rowspan":"3"}},[_vm._v("№ п/п")]),_c('th',{staticClass:"border",attrs:{"rowspan":"2"}},[_vm._v("Уровень")]),_c('th',{staticClass:"border",attrs:{"colspan":"2"}},[_vm._v("УГСН ")]),_c('th',{staticClass:"border"},[_vm._v("Принятых на обучение")]),_c('th',{staticClass:"border"},[_vm._v("Из них - на целевое")])]),_c('tr',[_c('th',{staticClass:"border"},[_vm._v("Код")]),_c('th',{staticClass:"border"},[_vm._v("Наименование")]),_c('th',{staticClass:"border"},[_vm._v("2020 год")]),_c('th',{staticClass:"border"},[_vm._v("2020 год")])]),_c('tr',[_c('th',{staticClass:"border"},[_vm._v("1")]),_c('th',{staticClass:"border"},[_vm._v("2")]),_c('th',{staticClass:"border"},[_vm._v("3")]),_c('th',{staticClass:"border"},[_vm._v("4")]),_c('th',{staticClass:"border"},[_vm._v("5")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"20px"}},[_c('td',{attrs:{"colspan":"4"}}),_c('td',[_c('div',[_c('img',{staticStyle:{"height":"20px","margin":"0","width":"185px"},attrs:{"src":require("@/assets/img/brackets/bottom-bracket-removebg-preview.png"),"alt":""}})])]),_c('td',[_c('div',[_c('img',{staticStyle:{"height":"20px","margin":"0","width":"162px"},attrs:{"src":require("@/assets/img/brackets/bottom-bracket-removebg-preview.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"text_center"},[_c('b',[_vm._v("П")])]),_c('td',{staticClass:"text_center"},[_c('b',[_vm._v("ЦП")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('span',[_vm._v("Б13"),_c('span',{staticClass:"font-size-little__bottom"},[_vm._v("0")]),_vm._v(" =")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"formula-half formula-half_underline"},[_vm._v("ЦП")]),_c('div',{staticClass:"formula-half"},[_vm._v("П")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v("=")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v("=")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-about"},[_c('p',[_vm._v("ЦП - численность студентов, принятых на обучение в рамках квоты приема на целевое обучение по очной форме обучения в отчетном году;")]),_c('p',[_vm._v("П - общая численность студентов, принятых на обучение по очной форме обучения в отчетном году.")])])
}]

export { render, staticRenderFns }