<template>
  <tbody class="table-body" ref="tablebody">
  <!--      <div v-if="rowsProps.length === 0" class="table-body__no-result">-->
  <!--        <p class="title title_h4 table-body__no-result__title">Нет данных по данному запросу. Измените набор данных.</p>-->
  <!--        <router-link to="/settings">-->
  <!--          <span class="btn table__button btn_style_3"> <v-icon :size="20" color="#fff" class="btn__icon" > mdi-filter-outline</v-icon>Изменить набор данных</span>-->
  <!--        </router-link>-->
  <!--      </div>-->
  <tr class="table-body__tr"
      :style="`background-color: ${paintRow(row).backGroundColor}!important; color: ${paintRow(row).color}!important; width: ${tableBodyScrollWidth}px;`"
      v-for="(row,i) in rowsProps"
      :key="i"
  >
    <td class="table-body__td"
        :class="{'table-header__td_hidden' : isHidden(rolledCols,cell)}"
        v-for="(cell) in arrayAfterCheckHiddenColumns"
        :key="cell.dataField"

    >
      <!--:style="{width: tableBodyScrollWidth + 'px;'}"-->
      <v-row align-content="start">
        <v-col>
          <span
              :style=" ` color: ${paintRow(row).color}!important;` +
              `white-space: break-spaces;
               display: flex;align-items:start;`"
              class="table-body__span "
              v-show="!isHidden(rolledCols,cell)"
              v-if="cell.dataField !== 'action'"
          >
            {{
              returnValueAfterTransformTypeCell(cell, row[cell.dataField],row)
            }}
          </span>
          <!--       isNaN(+row[cell.dataField]) ? isObject(row[cell.dataField], cell.dataField) : isInteger(+row[cell.dataField])-->
          <div v-if="cell.dataField === 'action' && actionAccess && actionColumn !== undefined"
               class="table-body__td_actions">
            <button
                v-if="actionColumn.accessEdit"
                @click="actionRow(row.id, 'edit')"
                :disabled="authData && authData.role.id === 14"
                class="button_border text-none button_border_important">
              <v-icon> {{Object.prototype.hasOwnProperty.call(actionColumn, 'editIcon') ? actionColumn.editIcon : 'mdi-pencil'}}</v-icon>
              {{Object.prototype.hasOwnProperty.call(actionColumn, 'editText') ? actionColumn.editText : 'Изменить'}}
            </button>
            <button
                v-if="actionColumn.accessDelete"
                @click="actionRow(row.id, 'delete')"
                :disabled="authData && authData.role.id === 14"
                class="button_border text-none button_border_important mt-1">
              <v-icon>
                {{Object.prototype.hasOwnProperty.call(actionColumn, 'deleteIcon') ? actionColumn.deleteIcon : 'mdi-delete'}}</v-icon>
                {{Object.prototype.hasOwnProperty.call(actionColumn, 'deleteText') ? actionColumn.deleteText : 'Удалить'}}
            </button>
            <button
                v-if="actionColumn.customAction"
                @click="actionRow(row.id, 'customAction')"
                :disabled="customActionCheck(row[customActionProps.dataField]).blocked ||( authData && authData.role.id === 14)"
                class="text--center button_border text-none button_border_important mt-1">
              <v-icon>{{ customActionCheck(row[customActionProps.dataField]).icon}}</v-icon>
              {{customActionCheck(row[customActionProps.dataField]).text}}
            </button>
          </div>
        </v-col>
      </v-row>
    </td>
  </tr>
  </tbody>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "TableRow",
  props: {
    rowsProps: {
      type: Array,
      default: undefined
    },
    actionAccess: {
      type: Boolean,
      default: false
    },
    color: {
      type: Object,
      default: () => {
        return {
          dataField: '',
          colors: [
            {value: 0, backGroundColor: '#ffffff', color: '#000000', class: 'default-row-class'}
          ]
        }
      }
    },
    customActionProps: {
      type: Object,
      default: () => {
        return {
          active: 0,
          dataField: '',
          data: [
            {value: 0, icon: 'mdi-pencil', action: 'customAction'}
          ]
        }
      }
    },
    hiddenColumns: {
      type: Array,
      default: undefined
    },
    orderColumns: {
      type: Array,
      default: () => []
    },
    rolledCols: {
      type: Array,
      default: undefined
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableBodyScrollWidth: 0,
    }
  },
  computed: {
    ...mapGetters('auth', {authData:'authData'}),
    arrayAfterCheckHiddenColumns() {
      let arr = []
      arr = [].concat(...this.orderColumns).filter(el => el.active).sort((a, b) => a.order - b.order)
      return arr
    },
    actionColumn() {
      return this.arrayAfterCheckHiddenColumns.find(el => el.dataField === 'action')
    }
  },
  mounted() {
    this.$emit('scrollBodyTableWidth', this.$refs.tablebody.scrollWidth);
    this.tableBodyScrollWidth = this.$refs.tablebody.scrollWidth;
  },
  methods: {

    customActionCheck(value){
      // if (dataField === this.customActionProps.dataField) {
        let ind = this.$scripts.TableJs.arraySearch(this.customActionProps.data, value, 'value')
      // console.log(value, ind, {
      //   icon: this.customActionProps.data[ind].icon,
      //   action: this.customActionProps.data[ind].action,
      //   text: this.customActionProps.data[ind].text,
      //   blocked: this.customActionProps.data[ind].blocked
      // })
        return ind === -1 ? {
          icon: 'mdi-pencil',
          action: 'customAction',
          text: 'кастом действие',
          blocked: false
        } : {
          icon: this.customActionProps.data[ind].icon,
          action: this.customActionProps.data[ind].action,
          text: this.customActionProps.data[ind].text,
          blocked: this.customActionProps.data[ind].blocked
      }
    },
    paintRow(Obj) {
      return this.$scripts.TableJs.arraySearch(this.color.colors, Obj[this.color.dataField], 'value') === -1 ? 'default-row-class' : this.color.colors[this.$scripts.TableJs.arraySearch(this.color.colors, Obj[this.color.dataField], 'value')]
    },
    returnValueAfterTransformTypeCell(header, value,row){
      let result = ''
      switch (header.typeVar){
        case 'selector':
          result =  this.nameDataObj(value, header.dataField,row);
          break;
        case 'customSelector':
          //Надо будет доделать так как не доделано
          // console.log(header.dataField, ' ',header.data.find(el => el.value === value)) //.text
          result = header.data.find(el => el.value === value) != null ? header.data.find(el => el.value === value).text : value
          break;
        case 'date':
          result = this.$scripts.Utils.empty(value) ? value : value.slice(0,10)
          break;
        case 'integer':
          result  = value != null ? this.isInteger(+value) : 'н/д'
          break;
        case 'string':
          result = value
          break;
        case 'float':
          result = value != null ? (header.roundValue != null ? value.toFixed(header.roundValue) : value ) : 'н/д'
          break;
        default:
          result = value
          break;
      }
      return result
    },
    isObject(obj,dataField) {
      if (Object.prototype.toString.call(obj) === '[object Object]' && obj !== null) {
        return this.nameDataObj(obj, dataField);
      } else
        return obj;
    },
    isInteger(num) {
      if ((num ^ 0) === num) {
        return num;
      } else {
        return num.toFixed(1);
      }
    },
    isHidden(arr, val) {
      if (arr !== undefined && arr.length > 0) {
        let indOfHiddenBy = this.$scripts.TableJs.arraySearch(arr, val.dataField, 'dataField');
        return indOfHiddenBy >= 0 ? arr[indOfHiddenBy].hidden : false;
      } else
        return false;
    },
    nameDataObj(obj, nameField,row) {
      let full_name = '';
      if (nameField==='sprUgsCollspan')
      {
        if (row.sprSpec != null && !this.$scripts.Utils.empty(row.sprSpec.specID)){
          full_name =row.sprSpec[`specCode`] + ' - ' + row.sprSpec[`specName`]
        }
        else if (row.sprUgs != null)
        {
          full_name =row.sprUgs[`ugsCode`] + ' - ' + row.sprUgs[`ugsName`]
        }
      }
      else {
        if (!this.$scripts.Utils.empty(obj))
        {
          full_name = ['usl', 'org', 'ff', 'fo'].includes(nameField.slice(3).toLowerCase()) ? obj[`${nameField.slice(3).toLowerCase()}Name`] : obj[`${nameField.slice(3).toLowerCase()}Code`] + ' ' + obj[`${nameField.slice(3).toLowerCase()}Name`];
          if (['kcpgroup'].includes(nameField.slice(3).toLowerCase()))
            full_name = obj[`code`] + ' - ' + obj[`name`]
        }
      }
      return full_name;
    },
    openDialog() {
    },
    actionRow(id, typeAction) {
      this.$emit('action-row', {id: id, typeAction: typeAction})
    }
  }

}
</script>

<style lang="sass" scoped>
.rows-enter-active, .rows-leave-active
  transition: all 1s

.rows-enter, .rows-leave-to
  opacity: 0
  transform: translateY(30px)

.table-body__td_actions
  display: flex
  flex-direction: column


</style>