<template>


<div class="view-request">


  <div class="requests-buttons">
    <button class="button requests-buttons__button" @click="request = 'Org'">Данные организации</button>  
    <button class="button requests-buttons__button" @click="request = 'SubOrgs'">Данные филиалов</button> 
  </div>

  <component :is="container">

   </component>



</div>


</template>
<script>
import ContainerRequestsOrg from '@/containers/admin/ContainerRequestsOrg';
import ContainerRequestsSubOrgs from '@/containers/admin/ContainerRequestsSubOrgs';


export default {
    components: {
        ContainerRequestsOrg,
        ContainerRequestsSubOrgs,
    },
    data() {
      return {
        request: 'Org',
      }
    },
    computed: {
      container() {
        return 'ContainerRequests' + this.request;
      }
    }
}
</script>
<style lang="sass">
.requests-buttons__button
  margin: 10px
.view-request
    width: 100%
</style>