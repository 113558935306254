export const fixedTo1 = [
    'ok2017',
    'ok2018',
    'ok2019',
    'dn2017',
    'dn2018',
    'dn2019',
    'b13max',
    'b25max',
    'b28max',
    'm11min',
    'm11max',
    'm12min',
    'm23max',
    'm26max',
    'o11min',
    'o11max',
    'o12min',
    'o22max',
    'o23max',
    'k11min',
    'k11max',
    'k12min',
    'k22max',
    'k23max',
    'rdn2017',
    'rdn2018',
    'rdn2019',
    'rdna2017',
    'rdna2018',
    'rdna2019',
    'rdnb2017',
    'rdnb2018',
    'rdnb2019',
    'rdnc2017',
    'rdnc2018',
    'rdnc2019',
];

export const fixedTo2 = [
'nbp',
'nmp',
'acp',
'pkp',
'ppp',
'gnp',
'nap',
'pn',
'npr2017',
'npr2018',
'npr2019',
'pps12017',
'pps12018',
'pps12019',
'pps22017',
'pps22018',
'pps22019',
'nr12017',
'nr12018',
'nr12019',
'nr22017',
'nr22018',
'nr22019',
'pn2017',
'pn2018',
'pn2019',
'di',
'b22max',
'b23max',
'b24max',
'dv2017',
'dv2018',
'dv2019',
'pmt2017',
'pmt2018',
'pmt2019',
'm13max',
'm22max',
'o13max',
'k13max',
];

export const fixedTo3 = [
    "ege",
    "ki",
    "o1",
    "os1",
    "o2",
    "os2",
    "o3",
    "os3",
    "b111",
    "b112",
    "b113",
    "b11n",
    "b11n1",
    "b11rf1",
    "b11fo1",
    "b11re1",
    "b11n2",
    "b11rf2",
    "b11fo2",
    "b11re2",
    "b11n3",
    "b11rf3",
    "b11fo3",
    "b11re3",
    "b11b",
    "b11b0",
    "b121",
    "b122",
    "b123",
    "b12n",
    "b12n1",
    "b12rf1",
    "b12fo1",
    "b12re1",
    "b12n2",
    "b12rf2",
    "b12fo2",
    "b12re2",
    "b12n3",
    "b12rf3",
    "b12fo3",
    "b12re3",
    "b12b",
    "b12b0",
    "b131",
    "b132",
    "b133",
    "b13n",
    "b13n1",
    "b13rf1",
    "b13fo1",
    "b13re1",
    "b13n2",
    "b13rf2",
    "b13fo2",
    "b13re2",
    "b13n3",
    "b13rf3",
    "b13fo3",
    "b13re3",
    "b13b",
    "b13b0",
    "b211",
    "b212",
    "b213",
    "b21n",
    "b21n1",
    "b21rf1",
    "b21fo1",
    "b21re1",
    "b21n2",
    "b21rf2",
    "b21fo2",
    "b21re2",
    "b21n3",
    "b21rf3",
    "b21fo3",
    "b21re3",
    "b21b",
    "b21b0",
    "b221",
    "b222",
    "b223",
    "b22n",
    "b22n1",
    "b22rf1",
    "b22fo1",
    "b22re1",
    "b22n2",
    "b22rf2",
    "b22fo2",
    "b22re2",
    "b22n3",
    "b22rf3",
    "b22fo3",
    "b22re3",
    "b22b",
    "b22b0h",
    "b22b0",
    "b231",
    "b23n",
    "b23n1",
    "b23rf1",
    "b23fo1",
    "b23re1",
    "b23b",
    "b23b0h",
    "b23b0",
    "b241",
    "b242",
    "b243",
    "b24n",
    "b24n1",
    "b24rf1",
    "b24fo1",
    "b24re1",
    "b24n2",
    "b24rf2",
    "b24fo2",
    "b24re2",
    "b24n3",
    "b24rf3",
    "b24fo3",
    "b24re3",
    "b24b",
    "b24b0h",
    "b24b0",
    "b251",
    "b252",
    "b253",
    "b25n",
    "b25n1",
    "b25rf1",
    "b25fo1",
    "b25re1",
    "b25n2",
    "b25rf2",
    "b25fo2",
    "b25re2",
    "b25n3",
    "b25rf3",
    "b25fo3",
    "b25re3",
    "b25b",
    "b25b0h",
    "b25b0",
    "b281",
    "b282",
    "b283",
    "b28n",
    "b28n1",
    "b28rf1",
    "b28fo1",
    "b28re1",
    "b28n2",
    "b28rf2",
    "b28fo2",
    "b28re2",
    "b28n3",
    "b28rf3",
    "b28fo3",
    "b28re3",
    "b28b",
    "b28b0",
    "b311",
    "b312",
    "b313",
    "b31n",
    "b31n1",
    "b31rf1",
    "b31fo1",
    "b31re1",
    "b31n2",
    "b31rf2",
    "b31fo2",
    "b31re2",
    "b31n3",
    "b31rf3",
    "b31fo3",
    "b31re3",
    "b31b",
    "b31b0",
    "b321",
    "b322",
    "b323",
    "b32n",
    "b32n1",
    "b32rf1",
    "b32fo1",
    "b32re1",
    "b32n2",
    "b32rf2",
    "b32fo2",
    "b32re2",
    "b32n3",
    "b32rf3",
    "b32fo3",
    "b32re3",
    "b32b",
    "b32b0",
    "b411",
    "b412",
    "b413",
    "b41n",
    "b41n1",
    "b41rf1",
    "b41fo1",
    "b41re1",
    "b41n2",
    "b41rf2",
    "b41fo2",
    "b41re2",
    "b41n3",
    "b41rf3",
    "b41fo3",
    "b41re3",
    "b41b",
    "b41b0h",
    "b41b0",
    "b421",
    "b422",
    "b423",
    "b42n",
    "b42n1",
    "b42rf1",
    "b42fo1",
    "b42re1",
    "b42n2",
    "b42rf2",
    "b42fo2",
    "b42re2",
    "b42n3",
    "b42rf3",
    "b42fo3",
    "b42re3",
    "b42b",
    "b42b0h",
    "b42b0",
    "b431",
    "b432",
    "b433",
    "b43n",
    "b43n1",
    "b43rf1",
    "b43fo1",
    "b43re1",
    "b43n2",
    "b43rf2",
    "b43fo2",
    "b43re2",
    "b43n3",
    "b43rf3",
    "b43fo3",
    "b43re3",
    "b43b",
    "b43b0",
    "b441",
    "b442",
    "b443",
    "b44n",
    "b44n1",
    "b44rf1",
    "b44fo1",
    "b44re1",
    "b44n2",
    "b44rf2",
    "b44fo2",
    "b44re2",
    "b44n3",
    "b44rf3",
    "b44fo3",
    "b44re3",
    "b44b",
    "b44b0h",
    "b44b0",
    "m111",
    "m112",
    "m113",
    "m11n",
    "m11n1",
    "m11rf1",
    "m11fo1",
    "m11re1",
    "m11n2",
    "m11rf2",
    "m11fo2",
    "m11re2",
    "m11n3",
    "m11rf3",
    "m11fo3",
    "m11re3",
    "m11b",
    "m11b0",
    "m121",
    "m122",
    "m123",
    "m12n",
    "m12n1",
    "m12rf1",
    "m12fo1",
    "m12re1",
    "m12n2",
    "m12rf2",
    "m12fo2",
    "m12re2",
    "m12n3",
    "m12rf3",
    "m12fo3",
    "m12re3",
    "m12b",
    "m12b0",
    "m131",
    "m132",
    "m133",
    "m13n",
    "m13n1",
    "m13rf1",
    "m13fo1",
    "m13re1",
    "m13n2",
    "m13rf2",
    "m13fo2",
    "m13re2",
    "m13n3",
    "m13rf3",
    "m13fo3",
    "m13re3",
    "m13b",
    "m13b0",
    "m141",
    "m142",
    "m143",
    "m14n",
    "m14n1",
    "m14rf1",
    "m14fo1",
    "m14re1",
    "m14n2",
    "m14rf2",
    "m14fo2",
    "m14re2",
    "m14n3",
    "m14rf3",
    "m14fo3",
    "m14re3",
    "m14b",
    "m14b0",
    "m211",
    "m212",
    "m213",
    "m21n",
    "m21n1",
    "m21rf1",
    "m21fo1",
    "m21re1",
    "m21n2",
    "m21rf2",
    "m21fo2",
    "m21re2",
    "m21n3",
    "m21rf3",
    "m21fo3",
    "m21re3",
    "m21b",
    "m21b0",
    "m221",
    "m222",
    "m223",
    "m22n",
    "m22n1",
    "m22rf1",
    "m22fo1",
    "m22re1",
    "m22n2",
    "m22rf2",
    "m22fo2",
    "m22re2",
    "m22n3",
    "m22rf3",
    "m22fo3",
    "m22re3",
    "m22b",
    "m22b0h",
    "m22b0",
    "m231",
    "m232",
    "m233",
    "m23n",
    "m23n1",
    "m23rf1",
    "m23fo1",
    "m23re1",
    "m23n2",
    "m23rf2",
    "m23fo2",
    "m23re2",
    "m23n3",
    "m23rf3",
    "m23fo3",
    "m23re3",
    "m23b",
    "m23b0h",
    "m23b0",
    "m261",
    "m262",
    "m263",
    "m26n",
    "m26n1",
    "m26rf1",
    "m26fo1",
    "m26re1",
    "m26n2",
    "m26rf2",
    "m26fo2",
    "m26re2",
    "m26n3",
    "m26rf3",
    "m26fo3",
    "m26re3",
    "m26b",
    "m26b0",
    "m311",
    "m312",
    "m313",
    "m31n",
    "m31n1",
    "m31rf1",
    "m31fo1",
    "m31re1",
    "m31n2",
    "m31rf2",
    "m31fo2",
    "m31re2",
    "m31n3",
    "m31rf3",
    "m31fo3",
    "m31re3",
    "m31b",
    "m31b0",
    "m321",
    "m322",
    "m323",
    "m32n",
    "m32n1",
    "m32rf1",
    "m32fo1",
    "m32re1",
    "m32n2",
    "m32rf2",
    "m32fo2",
    "m32re2",
    "m32n3",
    "m32rf3",
    "m32fo3",
    "m32re3",
    "m32b",
    "m32b0",
    "m411",
    "m412",
    "m413",
    "m41n",
    "m41n1",
    "m41rf1",
    "m41fo1",
    "m41re1",
    "m41n2",
    "m41rf2",
    "m41fo2",
    "m41re2",
    "m41n3",
    "m41rf3",
    "m41fo3",
    "m41re3",
    "m41b",
    "m41b0h",
    "m41b0",
    "m421",
    "m422",
    "m423",
    "m42n",
    "m42n1",
    "m42rf1",
    "m42fo1",
    "m42re1",
    "m42n2",
    "m42rf2",
    "m42fo2",
    "m42re2",
    "m42n3",
    "m42rf3",
    "m42fo3",
    "m42re3",
    "m42b",
    "m42b0h",
    "m42b0",
    "m431",
    "m432",
    "m433",
    "m43n",
    "m43n1",
    "m43rf1",
    "m43fo1",
    "m43re1",
    "m43n2",
    "m43rf2",
    "m43fo2",
    "m43re2",
    "m43n3",
    "m43rf3",
    "m43fo3",
    "m43re3",
    "m43b",
    "m43b0",
    "m441",
    "m442",
    "m443",
    "m44n",
    "m44n1",
    "m44rf1",
    "m44fo1",
    "m44re1",
    "m44n2",
    "m44rf2",
    "m44fo2",
    "m44re2",
    "m44n3",
    "m44rf3",
    "m44fo3",
    "m44re3",
    "m44b",
    "m44b0h",
    "m44b0",
    "o111",
    "o11n",
    "o11n1",
    "o11rf1",
    "o11fo1",
    "o11re1",
    "o11b",
    "o11b0",
    "o121",
    "o12n",
    "o12n1",
    "o12rf1",
    "o12fo1",
    "o12re1",
    "o12b",
    "o12b0",
    "o131",
    "o13n",
    "o13n1",
    "o13rf1",
    "o13fo1",
    "o13re1",
    "o13b",
    "o13b0",
    "o141",
    "o14n",
    "o14n1",
    "o14rf1",
    "o14fo1",
    "o14re1",
    "o14b",
    "o14b0",
    "o211",
    "o21n",
    "o21n1",
    "o21rf1",
    "o21fo1",
    "o21re1",
    "o21b",
    "o21b0",
    "o221",
    "o22n",
    "o22n1",
    "o22rf1",
    "o22fo1",
    "o22re1",
    "o22b",
    "o22b0h",
    "o22b0",
    "o231",
    "o23n",
    "o23n1",
    "o23rf1",
    "o23fo1",
    "o23re1",
    "o23b",
    "o23b0",
    "o311",
    "o31n",
    "o31n1",
    "o31rf1",
    "o31fo1",
    "o31re1",
    "o31b",
    "o31b0",
    "o411",
    "o41n",
    "o41n1",
    "o41rf1",
    "o41fo1",
    "o41re1",
    "o41b",
    "o41b0h",
    "o41b0",
    "o421",
    "o42n",
    "o42n1",
    "o42rf1",
    "o42fo1",
    "o42re1",
    "o42b",
    "o42b0h",
    "o42b0",
    "o431",
    "o43n",
    "o43n1",
    "o43rf1",
    "o43fo1",
    "o43re1",
    "o43b",
    "o43b0",
    "o441",
    "o44n",
    "o44n1",
    "o44rf1",
    "o44fo1",
    "o44re1",
    "o44b",
    "o44b0h",
    "o44b0",
    "k111",
    "k11n",
    "k11n1",
    "k11rf1",
    "k11fo1",
    "k11re1",
    "k11b",
    "k11b0",
    "k121",
    "k12n",
    "k12n1",
    "k12rf1",
    "k12fo1",
    "k12re1",
    "k12b",
    "k12b0",
    "k131",
    "k13n",
    "k13n1",
    "k13rf1",
    "k13fo1",
    "k13re1",
    "k13b",
    "k13b0",
    "k141",
    "k14n",
    "k14n1",
    "k14rf1",
    "k14fo1",
    "k14re1",
    "k14b",
    "k14b0",
    "k211",
    "k21n",
    "k21n1",
    "k21rf1",
    "k21fo1",
    "k21re1",
    "k21b",
    "k21b0",
    "k221",
    "k22n",
    "k22n1",
    "k22rf1",
    "k22fo1",
    "k22re1",
    "k22b",
    "k22b0",
    "k231",
    "k23n",
    "k23n1",
    "k23rf1",
    "k23fo1",
    "k23re1",
    "k23b",
    "k23b0",
    "k311",
    "k31n",
    "k31n1",
    "k31rf1",
    "k31fo1",
    "k31re1",
    "k31b",
    "k31b0",
    "k321",
    "k32n",
    "k32n1",
    "k32rf1",
    "k32fo1",
    "k32re1",
    "k32b",
    "k32b0",
    "k411",
    "k41n",
    "k41n1",
    "k41rf1",
    "k41fo1",
    "k41re1",
    "k41b",
    "k41b0",
    "k421",
    "k42n",
    "k42n1",
    "k42rf1",
    "k42fo1",
    "k42re1",
    "k42b",
    "k42b0",
    "a111",
    "a11n",
    "a11n1",
    "a11rf1",
    "a11fo1",
    "a11re1",
    "a11b",
    "a11b0",
    "a211",
    "a21n",
    "a21n1",
    "a21rf1",
    "a21fo1",
    "a21re1",
    "a21b",
    "a21b0",
    "a221",
    "a22n",
    "a22n1",
    "a22rf1",
    "a22fo1",
    "a22re1",
    "a22b",
    "a22b0",
    "a311",
    "a31n",
    "a31n1",
    "a31rf1",
    "a31fo1",
    "a31re1",
    "a31b",
    "a31b0h",
    "a31b0",
    "a321",
    "a32n",
    "a32n1",
    "a32rf1",
    "a32fo1",
    "a32re1",
    "a32b",
    "a32b0h",
    "a32b0",
    "a331",
    "a33n",
    "a33n1",
    "a33rf1",
    "a33fo1",
    "a33re1",
    "a33b",
    "a33b0h",
    "a33b0",
    "a341",
    "a34n",
    "a34n1",
    "a34rf1",
    "a34fo1",
    "a34re1",
    "a34b",
    "a34b0",
    "a351",
    "a35n",
    "a35n1",
    "a35rf1",
    "a35fo1",
    "a35re1",
    "a35b",
    "a35b0h",
    "a35b0",
    "a231",
    "a23n",
    "a23n1",
    "a23rf1",
    "a23fo1",
    "a23re1",
    "a23b",
    "a23b0",
];