<template>
    <div :class="['collapse', customClass]">
        <div @click="isOpen = !isOpen" class="collapse__toggle">
            <div class="collapse__title__block">
                <div class="collapse__title">{{ title }}</div>
                <v-icon :size="30" class="collapse__icon" color="#00599b"
                >mdi-chevron-down
                </v-icon
                >
            </div>
            <div v-if="subTitle!==null">{{ subTitle }}</div>
        </div>
        <div class="collapse__content" v-if="isOpen">
            <div ref="collapseContent">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: "Открыть",
            },
            subTitle: {
                type: String,
                default: null
            },
            customClass: {
                type: String,
            },
            initialOpen: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isOpen: this.initialOpen,
            };
        },
        computed: {
            heightCollapsedContent() {
                if (this.isOpen) {
                    return this.$refs.collapseContent.clientHeight;
                } else {
                    return 0;
                }
            },
        },
    };
</script>

<style lang="sass">
    @import "@/assets/styles/utils/variables"
    .collapse__content
        height: auto
        overflow: hidden
        transition: height 0.2s

    .collapse__toggle
        display: flex
        justify-content: space-between
        flex-direction: column
        cursor: pointer
        margin-bottom: 28px

    .collapse__title__block
        display: flex
        justify-content: space-between
        flex-direction: row
        cursor: pointer

    .collapse__title
        font-family: 'Montserrat'
        color: $color-1
        text-transform: uppercase
        font-weight: 600
        font-size: 18px
        line-height: 21.94px
</style>
