<template>
  <v-app>
    <CNotificationsFromStore/>
      <router-view></router-view>
  </v-app>
</template>

<script>
import CNotificationsFromStore from "@/components/CNotificationsFromStore";
import {mapActions} from 'vuex'
import axios from 'axios'
export default {
  name: 'KCP',
  components: {
    CNotificationsFromStore,
  },
  created: function () {
    axios.interceptors.response.use(undefined,err => {
      // const el =this;
      // console.log("aaaa1", el.drawerLeftBar);
      // eslint-disable-next-line no-unused-vars
      return new Promise(/*function (resolve, reject)*/() => {
        // const el2 = this;
        // console.log("aaaa1", this.drawerLeftBar);
        if (process.env.NODE_ENV === 'development') {
          console.log("err.request",err.request)
          console.log("err.response",err.response)
          console.log("err.isAxiosError",err.isAxiosError)
          console.log("err.config",err.config)
          console.log("err.toJSON",err.toJSON)
        }
        // for (let key in err) {
          // этот код будет вызван для каждого свойства объекта
          // ..и выведет имя свойства и его значение
          //config request response isAxiosError toJSON
          // console.log("keys: ", key)
        // }
        if (err.request.status === 401 /*&& err.request.statusText === 'Unauthorized'*/ && err.isAxiosError && err.config /*&& !err.config.__isRetryRequest*/) {
          this.storeLogout()
        }
        throw err;
      });
    });
  },
  methods:{
    ...mapActions('auth', {storeLogout: 'logout'}),
  }
}
</script>
