import { render, staticRenderFns } from "./Dtable.vue?vue&type=template&id=09cd8971&scoped=true&"
import script from "./Dtable.vue?vue&type=script&lang=js&"
export * from "./Dtable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09cd8971",
  null
  
)

export default component.exports