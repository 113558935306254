<template>
    <div>
        <header class="section-header section-preview__header">
            <h2 class="section-header__title">Этап 3 распределения объемов КЦП на 2023/24 учебный год (центры
                ответственности)</h2>
        </header>
        <Alert v-if="!getBlock">
            <template #icon>
                <v-icon color="#D4990A" size="40">mdi-alert-outline</v-icon>
            </template>
            <template #default>
                <p>Правила корректировки общих объемов КЦП на 2023/24 учебный год (далее – ОКЦП) центрами
                    ответственности</p>
                <ul class="pl-0 mt-2">
                    <li>
                        <p>1. В части изменения региональных объемов установлено ограничение, в соответствии с которым
                            изменение не
                            может составлять более:</p>
                        <div class="d-flex flex-row align-center justify-start ml-6 black-color">
                            <span>max</span><img alt="" src="@/assets/img/brackets/open-bracket-removebg-preview.png"
                                                 style="height: 45px; margin: 0 5px; width: 10px"/>
                            <div class="d-flex flex-column">
                                <div>квота * 20 %</div>
                                <a href="http://files.ined.ru/adminpanel/cms_article_editor_1_66666_d33ceaa0d15c04bc6f9eb110fdc01392.pdf"
                                   target="_blank">расчетная группа</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <p>2. Общий объем контрольных цифр приема по УГСН не может превышать общий объем контрольных цифр приема по УГСН, установленный на этапе 1 распределения объемов КЦП на 2023/24 учебный год</p>
                        <ul class="pl-6 black-color">
                            <li style="list-style-type: disc">Расчетный файл</li>
                            <li style="list-style-type: disc">Пояснительная записка</li>
                            <li style="list-style-type: disc">Письмо в Минобрнауки России</li>
                        </ul>
                    </li>
                </ul>
            </template>
        </Alert>
<!--        <Alert alert-type="success">-->
<!--            <template #icon>-->
<!--                <v-icon color="#29A128" size="40">mdi-check-bold</v-icon>-->
<!--            </template>-->
<!--            <template #default>-->
<!--                <div class="d-flex flex-row justify-space-between">-->
<!--                    <p class="d-flex align-center">{{ getBlock ? 'Ввод данных заблокирован' :-->
<!--                        'Заблокировать ввод данных' }}</p>-->
<!--                    <v-btn-->
<!--                            :disabled="getBlock"-->
<!--                            :loading="loadingBlockEnterData"-->
<!--                            @click="clickBlockEnterData()"-->
<!--                            class="success__v-btn"-->
<!--                            color="#29A128"-->
<!--                    >-->
<!--                        <span style="color:#fff;">{{ getBlock ? 'Заблокировано' : 'Заблокировать ввод' }}</span>-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--                <div class="d-flex flex-row justify-space-between mt-2" v-if="getBlock">-->
<!--                    <p class="d-flex align-center">{{ 'Печать введенных данных' }}</p>-->
<!--                    <v-btn-->
<!--                            :disabled="!getBlock"-->
<!--                            :loading="loadingPrintData"-->
<!--                            @click="clickPrintData()"-->
<!--                            class="success__v-btn"-->
<!--                            color="#29A128"-->
<!--                    >-->
<!--                        <span style="color:#fff;">{{ 'Печать' }}</span>-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--            </template>-->
<!--        </Alert>-->
        <WrapperPreviewCategory customClass="section-preview_lk" v-if="getBlock">
            <WrapperCollapse
                    :initialOpen="true"
                    title="Загружаемые файлы"
            >
                <ContainerFounderDocuments stage="3" :all-files="allFiles"/>
            </WrapperCollapse>
        </WrapperPreviewCategory>
        <wrapper-preview-category customClass="section-preview_lk">
            <v-tabs
                    @change="changeUsl($event)"
                    align-with-title
                    v-model="tab"

            >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                        :key="item.usl"
                        class="font-weight-bold"
                        v-for="item in tabs"
                >
                    {{ item.text }}
                </v-tab>
<!--                <v-spacer></v-spacer>-->
<!--                <div class="d-flex align-center mr-4">-->
<!--                    <v-btn-->
<!--                            :loading="loadingExcelData"-->
<!--                            @click="clickPrintData('/api/report/getExcel/kcpProposal/stage3/', `excel.xlsx`, 'loadingExcelData')"-->
<!--                            class="success__v-btn"-->
<!--                            color="#29A128"-->
<!--                    >-->
<!--                        <span style="color:#fff;">{{ 'В Excel' }}</span>-->
<!--                    </v-btn>-->
<!--                </div>-->
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                        :key="item.usl"
                        v-for="item in tabs"
                >
                    <v-card flat>
                        <v-card-text>
                            <GridTable
                                    :data-rows="chosenData"
                                    :headers="headers.massive"
                                    :headers-for-rows="headersForRows"
                                    :loading-data="loadingData"
                                    :num-cols="17"
                                    :paint-background="paintBackgroundProp"
                                    @row-action="openFullInfo($event)"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>

        </wrapper-preview-category>
    </div>
</template>

<script>
    import Alert from "@/components/alerts/Alert";
    import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
    import request from "@/services/request";
    import {mapActions, mapGetters} from 'vuex'
    import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
    import ContainerFounderDocuments from "@/containers/private/ContainerFounderDocuments";
    import axios from "axios";
    import GridTable from "@/components/GridTable/GridTable";

    export default {
        name: "founderStageThree",
        components: {ContainerFounderDocuments, WrapperCollapse, WrapperPreviewCategory, Alert, GridTable},
        data() {
            return {
                tab: null,
                tabs: [
                    {
                        usl: 1,
                        text: 'Бакалавриат'
                    },
                    {
                        usl: 2,
                        text: 'Специалитет'
                    },
                    {
                        usl: 3,
                        text: 'Магистратура'
                    },
                ],
                headers: {
                    massive: [
                        {
                            dataField: 'sprUgs',
                            cols: 2,
                            rows: 2,
                            text: 'УГСН',
                            class: 'justify-start',
                            variable: true,
                            styles: 'min-width: 200px',
                            template: function (value) {
                                return value[`skgCode`] + ' - ' + value[`skgName`]
                            }
                        },
                        {
                            dataField: 'baseValue',
                            cols: 3,
                            rows: 1,
                            variable: false,
                            text: 'Проект КЦП 2023/24',
                        },
                        {
                            dataField: 'coValue1',
                            cols: 3,
                            rows: 1,
                            variable: false,
                            text: 'Предложение головного ЦО этап 1',
                        },
                        {
                            dataField: 'regValue2',
                            cols: 3,
                            rows: 1,
                            variable: false,
                            text: 'Предложение Субъектов РФ',
                        },
                        {
                            dataField: 'coValue3',
                            cols: 3,
                            rows: 1,
                            variable: false,
                            text: 'Предложение головного ЦО этап 3',
                        },
                        {
                            dataField: 'coValue4',
                            cols: 3,
                            rows: 1,
                            variable: false,
                            text: 'Изменение',
                        },
                        {
                            dataField: 'baseValueOchno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно',
                        },
                        {
                            dataField: 'baseValueOchnoZaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно-заочно',
                        },
                        {
                            dataField: 'baseValueZaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Заочно',
                        },
                        {
                            dataField: 'coValue1Ochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно',
                        },
                        {
                            dataField: 'coValue1OchnoZaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно-заочно',
                        },
                        {
                            dataField: 'coValue1Zaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Заочно',
                        },
                        {
                            dataField: 'regValue2Ochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно',
                        },
                        {
                            dataField: 'regValue2OchnoZaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно-заочно',
                        },
                        {
                            dataField: 'regValue2Zaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Заочно',
                        },
                        {
                            dataField: 'coValue3Ochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно',
                        },
                        {
                            dataField: 'coValue3OchnoZaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно-заочно',
                        },
                        {
                            dataField: 'coValue3Zaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Заочно',
                        },
                        {
                            dataField: 'coValue4Ochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно',
                            template: function (value) {
                                if (value[`coValue3Ochno`] - value[`regValue2Ochno`] > 0)
                                    return '+' + (value[`coValue3Ochno`] - value[`regValue2Ochno`]);
                                return value[`coValue3Ochno`] - value[`regValue2Ochno`]
                            }
                        },
                        {
                            dataField: 'coValue4OchnoZaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Очно-заочно',
                            template: function (value) {
                                if (value[`coValue3OchnoZaochno`] - value[`regValue2OchnoZaochno`] > 0)
                                    return '+' + (value[`coValue3OchnoZaochno`] - value[`regValue2OchnoZaochno`]);
                                return value[`coValue3OchnoZaochno`] - value[`regValue2OchnoZaochno`]
                            }
                        },
                        {
                            dataField: 'coValue4Zaochno',
                            cols: 1,
                            rows: 1,
                            variable: true,
                            text: 'Заочно',
                            template: function (value) {
                                if (value[`coValue3Zaochno`] - value[`regValue2Zaochno`] > 0)
                                    return '+' + (value[`coValue3Zaochno`] - value[`regValue2Zaochno`]);
                                return value[`coValue3Zaochno`] - value[`regValue2Zaochno`]
                            }
                        },

                    ]
                },
                serverData: [],
                loadingData: false,
                errorData: null,
                chosenData: [],
                loadingPrintData: false,
                loadingBlockEnterData: false,
                loadingExcelData: false,
                allFiles: false
            }
        },
        computed: {
            ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
            ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
            ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
            headersForRows() {
                return this.headers.massive.filter(el => el.variable)
            },
            getBlock() {
                return this.get_blockedEnterData
            }
        },
        created() {
            this.getServerData();
        },
        mounted() {
            // this.$refs["table-grid-header-row"].addEventListener("scroll", this.heightTableHeader)
        },
        beforeDestroy() {
            // this.$refs["table-grid-header-row"].removeEventListener("scroll", this.heightTableHeader);
        },
        methods: {
            ...mapActions('notifications', {addNotification: 'addNotification'}),
            ...mapActions('auth', {getActualOrgStatus: 'getActualOrgStatus'}),
            showAllRefs() {
                this.headers.massive = this.headers.massive.map(el => {
                    const offsetHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetHeight : '';
                    const offsetTop = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetTop : '';
                    const clientHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientHeight : '';
                    const clientWidth = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientWidth : '';
                    // console.log('table-grid-header__cell-'+el.dataField+ ' - ', offsetHeight, offsetTop, clientHeight, clientWidth)
                    return {
                        ...el,
                        offsetHeight: offsetHeight,
                        offsetTop: offsetTop - 1,
                        clientHeight: clientHeight,
                        clientWidth: clientWidth
                    }
                })
            },
            heightTableHeader() {
                console.log(this.$refs["table-grid-header-row"])
            },
            async clickBlockEnterData() {
                this.loadingBlockEnterData = true;
                const res = await request({
                    endpoint: `/api/stageData/block?founderId=${this.get_replace_id_admin}&stage=3`,
                    method: 'get'
                })
                if (res.status === 200) {
                    this.loadingBlockEnterData = false;
                    await this.getActualOrgStatus();
                } else {
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка блокировки',
                        timeout: 3000
                    })
                    this.loadingBlockEnterData = false;
                }
            },
            paintBackground(row) {
                // console.log("!!", row)
                if (!row?.editable)
                    return 'yellow-background'
                else
                    return '';
            },
            paintBackgroundProp(row) {
                return () => {
                    if (!row?.editable) return 'yellow-background'; else
                        return ''
                };
            },
            async getServerData() {
                this.loadingData = true;
                const res = await request({
                    endpoint: `/api/stageData/byFounder/${this.get_replace_id_admin}`,
                    method: 'get',
                })
                if (res.status === 200) {
                    this.loadingData = false;
                    this.serverData = res.data
                    if (this.serverData.find(el => {
                        return (el[`coValue3Ochno`] - el[`regValue2Ochno`] > 0 ||
                            el[`coValue3OchnoZaochno`] - el[`regValue2OchnoZaochno`] > 0 ||
                            el[`coValue3Zaochno`] - el[`regValue2Zaochno`] > 0) && el.editable
                    }))
                        this.allFiles = true
                    this.showAllRefs()
                    this.changeUsl(this.tab)
                } else {
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка получения данных',
                        timeout: 3000
                    })
                    this.errorData = res.error;
                    this.loadingData = false;
                }
            },
            openFullInfo(row) {
                this.$router.push({path: '/kcp_contest/stage3-details', query: {kcpGroupId: row.kcpGroupId}})
            },
            changeUsl(tabsIndex) {
                const foundedTab = this.tabs.find((el, id) => id === tabsIndex);
                this.chosenData = this.serverData.length > 0 ? this.serverData
                    .filter(el => el.uslId === foundedTab.usl)
                    .sort((a, b) => Number(b.editable) - Number(a.editable)) : []
            },

            async clickPrintData(endPoint = `/api/report/getKcpProposal3/`, fileName = "ReportFunc.pdf", loadingFlag = 'loadingPrintData') {
                this[loadingFlag] = true;
                const responseAxios = await axios.get(`${endPoint}${this.get_replace_id_admin}`, {
                    headers: this.$scripts.Utils.authHeader(),
                    responseType: 'blob'
                })
                if (responseAxios.status === 200) {
                    this[loadingFlag] = false;
                    this.saveByteArray(fileName, responseAxios.data)
                } else {
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка скачивания файла',
                        timeout: 3000
                    })
                    this[loadingFlag] = false;
                }
            },
            saveByteArray(reportName, byte) {
                const blob = new Blob([byte], {type: "application/pdf"});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = reportName;
                link.click();
            }
        },
    }
</script>

<style lang="sass">
    .yellow-background
        background-color: #fff3cd
        color: #D4990A

    .section-preview_lk
        background: #fff
        border-radius: 10px
        padding: 10px
        margin-bottom: 20px
</style>