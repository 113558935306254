// Детальная статья с 5 слотами для хедера, контента, футера, до и после контента, один дефолтный

<template>
<article class="article" itemscope :itemtype="'http://schema.org/' + schemaType">
    <header class="article__header">
        <h1 class="article__title" itemprop="headline">{{item.title}}</h1>
        <div class="article__info">
            <time class="article__date" itemprop="dateline">{{item.createDate}}</time>
            <slot name="header"></slot>
        </div>
    </header>
    <div class="article__content" itemprop="articleBody">
        <slot name="beforeContent"></slot>
        <div class="article__text" v-html="item.articleBody"></div>
        <slot name="afterContent"></slot>
        <slot name="default"></slot>
    </div>
    <footer>
        <slot name="footer"></slot>
    </footer>
</article>
</template>
<script>
export default {
	props: {
		item: {
			type: Object,
        },
        schemaType: {
            type: String,
            default: 'Article',
        }
	}
}
</script>