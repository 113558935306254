<template>
<ContainerDocumentsDetail />
</template>

<script>

import ContainerDocumentsDetail from "@/containers/front/documents/ContainerDocumentsDetail";


export default {
components: {
	ContainerDocumentsDetail,
},

 
}



</script>
