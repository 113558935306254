<template>
    <div>

        <div class="score-about">
            <p><b>п.1.2.4 Приложение 2 Порядка:</b></p>
            <p>Соотношение приведенного контингента обучающихся по программам магистратуры, программам ассистентуры-стажировки, программам подготовки научно-педагогических кадров в аспирантуре и приведенного контингента студентов, обучающихся по программам бакалавриата (программам специалитета)</p>
        </div>

        <v-divider class="mt-4"/>

        <h4 class="score-subtitle">1. Значение показателя</h4>
        <h5 class="score-subsubtitle">
            Исходные данные:
        </h5>
        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.3.</p>
            <p class="calculation__about-text">
                Численность обучающихся за счет всех источников финансирования, человек
            </p>
        </div>
        <table class="indicators__table">
            <thead class="b24-thead">
            <tr class="">
                <th class="" rowspan="2">№ п/г</th>
                <th class="">Уровень</th>
                <th class="">УГСН Код</th>
                <th class="">УГСН Наименование</th>
                <th class="">Форма обучения</th>
                <th class="">Численность<br> обучающихся Всего,<br> человек</th>
                <th rowspan="2"></th>
                <th class="" colspan="2" style="vertical-align: middle">Расчет исходных значений</th>
            </tr>
            <tr class="">
                <th class="">1</th>
                <th class="">2</th>
                <th class="">3</th>
                <th class="">4</th>
                <th class="">5</th>
                <th class="">переменная</th>
                <th class="">значение</th>
            </tr>
            </thead>
            <tbody class="b24-tbody">
            <tr>
                <td class="">1.</td>
                <td class="">Бакалавриат</td>
                <td class="">
                  {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}03.00
                </td>
                <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.nbbo)">{{ }}</td>
                <td rowspan="2" style="vertical-align: middle"> => </td>
                <td class="" rowspan="2" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NBO</span> = </span>
                </td>
                <td class="" rowspan="2" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nbo)"> {{
                    }}
                </td>
            </tr>
            <tr>
                <td class="">2.</td>
                <td class="">Специалитет</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}05.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>

                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.nbso)">{{ }}</td>
            </tr>
            <tr>
                <td class="">3.</td>
                <td class="">Магистратура</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}04.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.nmo)">{{ }}</td>
                <td> =></td>
                <td class=""><span class="font-weight-bold">NMO</span> =</td>
                <td class="" v-html="checkData(rowOfValues.data.nmo)"> {{ }}</td>
            </tr>
            <tr>
                <td class="">4.</td>
                <td class="">Аспирантура</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}06.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.aco)">{{ }}</td>
                <td>=></td>
                <td class=""><span class="font-weight-bold">ACO</span> = {{ }}</td>
                <td class="" v-html="checkData(rowOfValues.data.aco)">{{ }}</td>
            </tr>
            <tr>
                <td class="">5.</td>
                <td class="">Ассистентура</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}09.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.acc)">{{ }}</td>
                <td>=></td>
                <td class=""><span class="font-weight-bold">ACC</span> = {{ }}</td>
                <td class="" v-html="checkData(rowOfValues.data.acc)">{{ }}</td>
            </tr>
            <tr>
                <td class="">6.</td>
                <td class="">Бакалавриат</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}03.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очно-заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.nbbv)">{{ }}</td>
                <td rowspan="2" style="vertical-align: middle"> => </td>
                <td class="" rowspan="2" style="vertical-align:middle;"><span class="font-weight-bold">NBV</span> =</td>
                <td class="" rowspan="2" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nbv)"> {{
                    }}
                </td>
            </tr>
            <tr>
                <td class="">7.</td>
                <td class="">Специалитет</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}05.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очно-заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.nbsv)">{{ }}</td>
            </tr>
            <tr>
                <td class="">8.</td>
                <td class="">Магистратура</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}04.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очно-заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.nmv)">{{ }}</td>
                <td>=></td>
                <td class=""><span class="font-weight-bold">NMV</span> =</td>
                <td class="" v-html="checkData(rowOfValues.data.nmv)"> {{ }}</td>
            </tr>
            <tr>
                <td class="">9.</td>
                <td class="">Аспирантура</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}06.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очно-заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.acv)">{{ }}</td>
                <td>=></td>
                <td class=""><span class="font-weight-bold">ACV</span> =</td>
                <td class="" v-html="checkData(rowOfValues.data.acv)"> {{ }}</td>
            </tr>
            <tr>
                <td class="">10.</td>
                <td class="">Бакалавриат</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}03.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.nbbz)">{{ }}</td>
                <td rowspan="2" style="vertical-align: middle"> => </td>
                <td class="" rowspan="2" style="vertical-align:middle;"><span class="font-weight-bold">NBZ</span> =</td>
                <td class="" rowspan="2" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nbz)"> {{
                    }}
                </td>
            </tr>
            <tr>
                <td class="">11.</td>
                <td class="">Специалитет</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}05.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.nbsz)">{{ }}</td>

            </tr>
            <tr>
                <td class="">12.</td>
                <td class="">Магистратура</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}04.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.nmz)">{{ }}</td>
                <td>=></td>
                <td class=""><span class="font-weight-bold">NMZ</span> =</td>
                <td class="" v-html="checkData(rowOfValues.data.nmz)"> {{ }}</td>
            </tr>
            <tr>
                <td class="">13.</td>
                <td class="">Аспирантура</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code.substring(0, 3) }}06.00
              </td>
              <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">заочная</td>
                <td class="" v-html="checkData(rowOfValues.data.acz)">{{ }}</td>
                <td>=></td>
                <td class=""><span class="font-weight-bold">ACZ</span> =</td>
                <td class="" v-html="checkData(rowOfValues.data.acz)"> {{ }}</td>
            </tr>
            </tbody>
        </table>
        <p class="score-about">В таблице отражены агрегированные данные по корреспондирующим УГСН (НПС).
          Исходно (в Форме 5.3) эти данные представлены раздельно по каждой НПС.
        </p>

        <section class="section-score">
            <h5 class="score-subsubtitle">
                Расчет приведенных контингентов по корреспондирующим УГСН по данным 2020
                года:
            </h5>

            <div class="calculation">
                <div class="calculation__row">
                    <div class="calculation__cell">NMP</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular"> 1,0 ×</span> NMO</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,25 ×</span> NMV</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,1 ×</span> NMZ</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1,0 ×</span> {{
                        rowOfValues.data.nmo }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,25 ×</span> {{
                        rowOfValues.data.nmv }}
                    </div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,1 ×</span> {{
                        rowOfValues.data.nmz }}
                    </div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell">{{ rowOfValues.data.nmp }}</div>
                </div>
                <div class="calculation__row">
                    <div class="calculation__cell">ACP</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1,0 × </span>ACO</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,25 ×</span> ACV</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,1 ×</span> ACZ</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1,0 ×</span> {{
                        rowOfValues.data.aco }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,25 × </span>{{
                        rowOfValues.data.acv }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,1 ×</span> {{
                        rowOfValues.data.acz }}
                    </div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell">{{ rowOfValues.data.acp }}</div>
                </div>
                <div class="calculation__row">
                    <div class="calculation__cell">NBP</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1,0 ×</span> NBO</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,25 ×</span> NBV</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,1 ×</span> NBZ</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1,0 ×</span> {{
                        rowOfValues.data.nbo }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,25 × </span>{{
                        rowOfValues.data.nbv }}
                    </div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0,1 ×</span> {{
                        rowOfValues.data.nbz }}
                    </div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell">{{ rowOfValues.data.nbp }}</div>
                </div>
            </div>

            <div class="calculation__about">
                <p class="calculation__about-text">
                  NMP	- приведенный контингент магистратуры (не учитывается при расчете показателя по программам специалитета);
                </p>
                <p class="calculation__about-text">
                  ACP	- приведенный контингент аспирантуры;
                </p>
                <p class="calculation__about-text">
                  NBP	- приведенный контингент бакалавриата (и программам специалитета);
                </p>
              <p class="calculation__about-text">
                ACC - численность обучающихся по программам ассистентуры-стажировки.
              </p>
            </div>
        </section>

        <!-- calculation -->

        <!-- formula -->
        <section class="section-score">
            <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

            <div class="formula">
                <div class="formula-full"><span>Б24<span class="font-size-little">0</span> =</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline  px-3">NMP<span
                            class="font-weight-regular"> + 3 × </span>ACC <span
                        class="font-weight-regular"> + 3 × </span>ACP
                    </div>
                    <div class="formula-half" style="margin-left: 35%">NBP</div>
                </div>
                <div class="formula-full">=</div>
                <div class="formula-full">
                  <div class="formula-half formula-half_underline  px-3"> <span v-html="checkData(rowOfValues.data.nmp)"></span><span
                      class="font-weight-regular"> + 3 × </span><span v-html="checkData(rowOfValues.data.acc)"></span> <span
                      class="font-weight-regular"> + 3 × </span><span v-html="checkData(rowOfValues.data.acp)"></span>
                  </div>
                  <div class="formula-half" style="margin-left: 35%"><span v-html="checkData(rowOfValues.data.nbp)"></span></div>
                </div>
                <div class="formula-full"> <div>
                  <span
                      class="font-weight-regular"> = </span><span v-html="checkData(rowOfValues.data.b24b0)"></span>
                </div>
                </div>
            </div>

            <!-- formula -->

            <div class="score-about">
                <p>
                  Если сумма значений показателей NMP, ACC, и ACP больше нуля, а значение показателя NBP равно нулю (либо отсутствует), значение показателя принимается равным 1.
                </p>
            </div>
        </section>

    </div>
</template>

<script>
    export default {
        name: "b24",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
        methods: {
            checkData(value) {
                return value;
                // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
            }
        },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

        .b24-thead
            th
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px

                &:nth-child(7)
                    border: 0
                    min-width: 20px

                &:nth-child(8)
                    border: 0

                &:nth-child(9)
                    border: 0

            tr
                &:last-child
                    th
                        &:nth-child(6)
                            border: 0
                            text-align: right
                            padding-right: 10px
                            border-bottom: 1px solid #546e7a

                        &:nth-child(7)
                            border: 0
                            padding-left: 10px
                            border-bottom: 1px solid #546e7a

        .b24-tbody
            td
                text-align: center
                border: 1px solid #546e7a
                padding: 3px

                &:nth-child(7)
                    border: 0
                    min-width: 20px

                &:nth-child(8)
                    border: 0
                    padding-right: 5px
                    text-align: right

                &:nth-child(9)
                    border: 0
                    padding-left: 2px
                    text-align: left

        .grid-container__header
            display: grid
            //grid-template-rows: 1fr 1fr
            div
                border: 1px solid black
                text-align: center
            //grid-auto-rows: 50px
            grid-gap: 3px
            grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

            .grid-row-span-2
                grid-column: 1
                grid-row: 1/3

            &_inner
                display: grid
                grid-template-columns: 1fr 1fr
                grid-gap: 2px
</style>