import { nanoid } from 'nanoid'

class ModelFaqPreview {
    constructor({faqID, questionBody, answerBody}) {
        this.id = faqID;
        this.key = nanoid();
        this.question = questionBody;
        this.answer = answerBody;
    }
}
export default ModelFaqPreview;