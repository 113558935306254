<template>
  <div>
    <HeaderMain
        title="Личный кабинет"
        subtitle=""
    />
<!--    <Alert alertType="warning">-->
<!--      <template v-slot:icon>-->
<!--        <v-icon :size="30" color="#856404">mdi-alert</v-icon>-->
<!--      </template>-->
<!--      <template v-slot:default>-->
<!--        Уважаемые коллеги, идет обновление системы. Некоторые функции могут не работать. Приносим извинения за доставленные неудобства.-->
<!--      </template>-->
<!--    </Alert>-->
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
          title="Контактная информация представителя Центра ответственности, ответственного за
формирование предложений о корректировке проекта контрольных цифр приема"
          :initialOpen="true"
      >
        <ContainerPersonContact
            :blocked="getBlock"
            :can-be-deleted="false"
            :contact="contact1"
            :loading-contacts="savingContact1"
            :saving-data="savingContact1"
            @change-contact="changeContact($event,1)"
            is-single

        />
      </WrapperCollapse>
    </WrapperPreviewCategory>
<!--    <Alert alertType="danger" >&lt;!&ndash;v-if="get_check_filled && !get_check_filled.contactCheck"&ndash;&gt;-->
<!--      <template v-slot:icon>-->
<!--        <v-icon :size="30" color="#6a1a21">mdi-alert</v-icon>-->
<!--      </template>-->
<!--      <template v-slot:default>-->
<!--        Заполнены не все данные контактного лица-->
<!--      </template>-->
<!--    </Alert>-->
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import request from "@/services/request";
// import Alert from '@/components/alerts/Alert';
import HeaderMain from '@/components/headers/HeaderMain';
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';

import ContainerPersonContact from '@/components/contacts/ContainerPersonContact';

export default {
  components: {
    // Alert,
    HeaderMain,
    WrapperPreviewCategory,
    WrapperCollapse,
    ContainerPersonContact
  },
  data() {
    return {
      contact1:
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: ''
          },
      savingContact1: false,
      loadingContacts1: false,
      errorSaving1: null,
    }
  },
  created() {
    // if (this.authData && this.getOrgID)
    //   this.getUserInfo()
    this.getContacts()
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    headersForRows() {
      return this.headers.massive.filter(el => el.variable)
    },
    getBlock(){
      return this.get_blockedEnterData
    },
    founderId(){
      return this.get_replace_id_admin
    },
    blocked(){
      return false
    }
    // ...mapGetters('checkFilled', {get_check_filled: 'get_check_filled'}),
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    async getContacts() {
      this.loadingContacts1 = true;
      const res = await request({
        endpoint: `/api/founderService/contact?founderID=${this.get_replace_id_admin}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.contact1 = res.data
          // await this.fetchCheckFilled({headContact: this.contact1, contacts: this.contact1})
        }
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных ответственных исполнителей',
            timeout: 5000
          })
      }
      this.loadingContacts1 = false;
    },
    async changeContact(obj) {
      this.savingContact1 = true;
      const objArr = Object.entries(obj);
      console.log("obj.entries", objArr)
      const res = await request({
        endpoint: `/api/founderService/contact/${obj?.contactPersonID ? 'update' : 'add'}`,
        method: 'post',
        body: {
          ...obj,
          founderID: this.founderId,
        },
      })
      if (res.status === 200) {
         this.contact1 = res.data;
        setTimeout(() => {
          this.savingContact1 = false;
        }, 2000);
      } else {
        this.savingContact1 = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного ',
          timeout: 5000
        })
      }
    },
  },
}

</script>