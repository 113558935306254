<template>
<div>
  <div class="score-about">
    <p><b>п.1.3.1. Приложения 2 Порядка:</b></p>
    <p>Процент трудоустройства выпускников</p>
  </div>
  <v-divider class="mt-4"/>
  <h4 class="score-subtitle">1. Значение показателя</h4>
  <h5 class="score-subsubtitle">
    Исходные данные:
  </h5>
  <div class="calculation__about">
    <p class="calculation__about-text">
      Данные мониторинга трудоустройства выпускников
    </p>
  </div>
  <table class="indicators__table">
    <thead class="b31-thead">
    <tr class="">
      <th class="" rowspan="3">№ п/г</th>
      <th class="" rowspan="2">Уровень</th>
      <th class="" colspan="2">УГСН</th>
      <th class="" colspan="3">Численность выпусников</th>
      <th colspan="3"> из них - трудоустроенных</th>
    </tr>
    <tr class="">
      <th class="">Код</th>
      <th class="">Наименование</th>
      <th class="">2016 год</th>
      <th class="">2017 год</th>
      <th class="">2018 год</th>
      <th class="">2017 год</th>
      <th class="">2018 год</th>
      <th class="">2019 год</th>
    </tr>
    <tr class="">
      <th class="">1</th>
      <th class="">2</th>
      <th class="">3</th>
      <th class="">4</th>
      <th class="">5</th>
      <th class="">6</th>
      <th class="">7</th>
      <th class="">8</th>
      <th class="">9</th>
    </tr>
    </thead>
    <tbody class="b31-tbody">
    <tr>
      <td class="">1.</td>
      <td class="">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName}}</td>
      <td class="">
        {{ rowOfValues.data.sprKCPGroup.code }}
      </td>
      <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
      <td class="">{{ rowOfValues.data.do2018 }}</td>
      <td class="">{{ rowOfValues.data.do2019 }}</td>
      <td class="">{{ rowOfValues.data.do2020 }}</td>
      <td class="">{{ rowOfValues.data.ft2017 }}</td>
      <td class="">{{ rowOfValues.data.ft2018 }}</td>
      <td class="">{{ rowOfValues.data.ft2019 }}</td>
<!--      <td rowspan="2" style="vertical-align: middle">-->
<!--        <img style="height: 51px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"-->
<!--             alt=""/>-->
<!--      </td>-->
<!--      <td class="" rowspan="2" style="vertical-align:middle;"><span style="">NBO = </span></td>-->
<!--      <td class="" rowspan="2" style="vertical-align:middle;"> {{ rowOfValues.data.nbo }}</td>-->
    </tr>
    <tr>
      <td colspan="4">
      </td>
      <td colspan="3" style="">
        <div ><img style="height: 20px; margin: 0; width:210px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                     alt=""/>
        </div>
      </td>
      <td colspan="3" style="">
        <div ><img style="height: 20px; margin: 0; width:210px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                   alt=""/>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="4">
      </td>
      <td colspan="3" style="">
        <div >
          ДО = <span v-html="checkData(rowOfValues.data.d0)"></span>
        </div>
      </td>
      <td colspan="3" style="">
        <div >
          ФТ = <span v-html="checkData(rowOfValues.data.ft)"></span>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <!-- formula -->
  <section class="section-score">
    <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

    <div class="formula">
      <div class="formula-full"><span>Б31<span class="font-size-little">0</span> =</span></div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline  px-3">ФТ</div>
        <div class="formula-half" style="margin-left: 25%">ДО</div>
      </div>
      <div class="formula-full">
        <div class="font-weight-regular">×100</div>
      </div>
      <div class="formula-full">=</div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.ft)"></span></div>
        <div class="formula-half" style="margin-left: 25%"><span v-html="checkData(rowOfValues.data.d0)"></span></div>
      </div>
      <div class="formula-full">
        <div class="font-weight-regular">×100</div>
      </div>
      <div class="formula-full">=</div>
      <div class="formula-full" v-html="checkData(rowOfValues.data.b31b0)"> </div>
    </div>

    <!-- formula -->

    <div class="score-about mb-4">
      <p class="ml-4">
        Если значение показателя ДО менее 5,
        фактическое значение показателя не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики
      </p>
    </div>
    <div class="calculation__about">
      <p class="calculation__about-text">
        ФТ - число трудоустроенных выпускников очной формы (за исключением продолживших обучение), являющихся гражданами Российской Федерации
      </p>
      <p class="calculation__about-text">
        ДО - число выпускников очной формы (за исключением продолживших обучение), являющихся гражданами Российской Федерации
      </p>
    </div>
  </section>
</div>
</template>

<script>
export default {
name: "b31",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%
.b31-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px
.b31-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px
  tr
    &:nth-child(2)
      td
        border: 0
    &:nth-child(3)
      td
        border: 0
</style>