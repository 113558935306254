<template>

<main class="layout-article">
			<aside class="layout-article__aside">
				<div class="layout-article__sticky">
					<span>1</span>
					<span>2</span>
					<span>3</span>
					<span>4</span>
					<span>5</span>
					<span>61</span>
					<span>7</span>
				</div>
			</aside>
			<div class="layout-article__main">
				<div class="layout-article__content">
					<!-- Основной контент -->
					<DetailArticle :item="news.data" schemaType="NewsArticle" />
				</div>
				<aside class="layout-article__after">
					<div class="layout-article__after_part">
						<router-link to="/" class="back-link">
							<span class="back-link__arrow">←</span>
							<span class="back-link__text">вернуться на главную</span>
						</router-link>
					</div>
				</aside>
			</div>	
		</main>

	
</template>


<script>
import request from "@/services/request";
import ModelNewsDetail from '@/services/models/ModelNewsDetail';
import DetailArticle from '@/components/article/DetailArticle';
export default {
components: {
	DetailArticle,
},
data() {
	return {
		news: null
	}
},

	created() {
		this.getNews();
	},
	methods: {
		async getNews() {
			this.news = await request({
				endpoint: `/api/mainPage/getOneArticle/?id=${this.$route.params.id}`,
				method: 'GET',
				model: ModelNewsDetail
			})
		},
	} 
}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"

.article__text, .article__text strong, .article__text span, .article__text p, .article__text li, .article__text a
	color: #323C47
	font-family: "Roboto"
	font-size: 19px
	font-weight: 300
	line-height: 20.9px

.article__text span
	text-decoration: underline
.article__text a
	color: $color-1 !important
	text-decoration: underline
</style>