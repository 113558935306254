<template>
  <v-dialog v-model="show" v-if="show" scrollable>
    <v-card>
      <span>
        <v-btn
            icon
            @click="show = false"
            style="
            position: absolute;
            right:0;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-title>
        <p>
        {{ textHeaderDialog.headerText }}
      </p>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <div v-if="assignedValuesReady">
            <div v-for="field in assignedValues" :key="field.dataField">
              <p v-if="field.dopTooltip !=null" class="pb-2 main-color">{{field.dopTooltip}}</p>
              <v-autocomplete
                  v-if="field.typeVar === 'selector'  && showField(field)"
                  v-model="field[field.variable]"
                  :items="get_spr(field.sprData)"
                  :rules="field[field.variable+'Rules']"
                  class="mt-2"
                  :loading="get_spr('get_spr_data_'+ field.dataField.slice(3).toLowerCase() +'_loading')"
                  dense
                  @change="deleteValueSelectedSpr(field)"
                  no-data-text="По вашему запросу ничего нет"
                  clearable
                  :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                  :label="field.tooltip"
              ><!---->
              </v-autocomplete>
              <v-autocomplete
                  v-else-if="field.typeVar === 'customSelector'  && showField(field)"
                  v-model="field[field.variable]"
                  :items="field.data"
                  :rules="field[field.variable+'Rules']"
                  class="mt-2"
                  dense
                  @change="deleteValueSelectedSpr(field)"
                  no-data-text="По вашему запросу ничего нет"
                  clearable
                  :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                  :label="field.tooltip"
              ></v-autocomplete>
              <v-menu
                  v-else-if="field.typeVar ==='date' && showField(field)"
                  v-model="field.modalVariable"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="field[field.variable]"
                      :label="field.tooltip"
                      :rules="field[field.variable+'Rules']"
                      prepend-icon=""
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="field[field.variable]"
                    :type="field.typeDate"
                    @input="field.modalVariable = false"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                  v-else-if="showField(field)"
                  v-model="field[field.variable]"
                  :rules="field[field.variable+'Rules']"
                  :label="field.tooltip"
                  :style="field.hidden !=null && field.hidden === true ? 'display: none;': ''"
                  class="mt-2"
                  dense
                  clearable
                  required
              />
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
            @click="sendData"
            :disabled="!valid"
        >
          Отправить
        </v-btn>
        <v-btn
            @click="resetForm"
        >
          Сбросить значения
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "universalDialog",
  components: {},
  props: {
    showDialog: {
      type: Object,
      default: () => {
        return {show: false, typeDialog: 1}
      },
    },
    accessApplyEditFunction: {
        type: Boolean,
        default: false
    },
    dataForDialog: {
      default: null
    },
    dopProps: {
      default: null
    },
    variables: {
      default: null
    }
  },
  data: () => ({
    valueToChange: '',
    clearData: false,
    typesDialog: [
      {type: 1, headerText: 'Добавление записи'},
      {type: 2, headerText: 'Редактирование записи'},
    ],
    valid: false,
    assignedValues: [],
    assignedValuesReady: false,
    // accessApplyEditFunction: false,

  }),
  computed: {
    arrayOfVariablesFromHeaders() {
      return [].concat(...this.variables).filter(el => el.active && el.dataField !== 'action').sort((a, b) => a.order - b.order)
    },
    textHeaderDialog() {
      return this.typesDialog.filter(el => el.type === this.showDialog.typeDialog)[0]
    },
    show: {
      get() {
        if (this.assignedValues.length === 0)
          this.assignData()
        if (this.showDialog.typeDialog === 1)
          this.resetForm()
        else if (this.showDialog.typeDialog === 2 && !this.accessApplyEditFunction) {
          this.applyEditData()
        }
        return this.showDialog.show
      },
      set(value) {
        // console.log("show",value)
        if (value === false) {
          // this.accessApplyEditFunction = false
          this.$emit('update:access-apply-edit-function', false)
          this.$emit('clear-data-for-dialog', {show: value, typeDialog: this.showDialog.typeDialog})
        }
        this.$emit('update:show-dialog', {show: value, typeDialog: this.showDialog.typeDialog})
      }
    },
    ...mapGetters('spr', {get_spr_data_ff: 'get_spr_data_ff'}),
    ...mapGetters('spr', {get_spr_data_ff_error: 'get_spr_data_ff_error'}),
    ...mapGetters('spr', {get_spr_data_ff_loading: 'get_spr_data_ff_loading'}),
    ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
    ...mapGetters('spr', {get_spr_data_org_error: 'get_spr_data_org_error'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    ...mapGetters('spr', {get_spr_data_spec: 'get_spr_data_spec'}),
    ...mapGetters('spr', {get_spr_data_spec_error: 'get_spr_data_spec_error'}),
    ...mapGetters('spr', {get_spr_data_spec_loading: 'get_spr_data_spec_loading'}),
    ...mapGetters('spr', {get_spr_data_usl: 'get_spr_data_usl'}),
    ...mapGetters('spr', {get_spr_data_usl_error: 'get_spr_data_usl_error'}),
    ...mapGetters('spr', {get_spr_data_usl_loading: 'get_spr_data_usl_loading'}),
    ...mapGetters('spr', {get_spr_data_fo: 'get_spr_data_fo'}),
    ...mapGetters('spr', {get_spr_data_fo_error: 'get_spr_data_fo_error'}),
    ...mapGetters('spr', {get_spr_data_fo_loading: 'get_spr_data_fo_loading'}),
    ...mapGetters('spr', {get_spr_data_ugs: 'get_spr_data_ugs'}),
    ...mapGetters('spr', {get_spr_data_ugs_error: 'get_spr_data_ugs_error'}),
    ...mapGetters('spr', {get_spr_data_ugs_loading: 'get_spr_data_ugs_loading'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    get_spr_data_org_rewrite() {
      return this.get_spr_data_org.data !== undefined ? this.get_spr_data_org.data
          .filter(el => {
                if (this.dopProps != null && Object.prototype.hasOwnProperty.call(this.dopProps, 'org')) {
                  let orgStatus = Object.prototype.hasOwnProperty.call(this.dopProps.org, 'orgStatus');
                  let orgNumber = Object.prototype.hasOwnProperty.call(this.dopProps.org, 'orgNumber');
                  let orgOwner = Object.prototype.hasOwnProperty.call(this.dopProps.org, 'orgOwner');
                  let orgStat = Object.prototype.hasOwnProperty.call(this.dopProps.org, 'orgStat');
                  let orgID = Object.prototype.hasOwnProperty.call(this.dopProps.org, 'orgID');
                  if ((orgStatus === true ? this.dopProps.org.orgStatus.includes(el.orgStatus) : true) &&
                      (orgNumber === true ? this.dopProps.org.orgNumber.includes(el.orgNumber) : true) &&
                      (orgOwner === true ? this.dopProps.org.orgOwner.includes(el.orgOwner) : true) &&
                      (orgStat === true ? this.dopProps.org.orgStat.includes(el.orgStat) : true) &&
                      (orgID === true ? this.dopProps.org.orgID.includes(el.orgID) : true))
                    return el
                } else
                  return el.orgOwner === this.get_replace_id_admin
              }
          )
          .map(el => {
            return {text: el.orgName, value: el.orgID}
          }) : []
    },
    get_spr_data_ff_rewrite() {
      return this.get_spr_data_ff.data !== undefined ? this.get_spr_data_ff.data
          .filter(el => {
            if (this.dopProps != null && Object.prototype.hasOwnProperty.call(this.dopProps, 'ff')) {
              if (this.dopProps.ff.includes(el.ffID)) return el
            } else return el
          })
          .map(el => {
            return {text: el.ffName, value: el.ffID}
          }) : []
    },
    get_spr_data_fo_rewrite() {
      return this.get_spr_data_fo.data !== undefined ? this.get_spr_data_fo.data
          .filter(el => {
            if (this.dopProps != null && Object.prototype.hasOwnProperty.call(this.dopProps, 'fo')) {
              if (this.dopProps.fo.foID.includes(el.foID)) return el
            } else return el
          })
          .map(el => {
            return {text: el.foName, value: el.foID}
          }) : []
    },
    get_spr_data_usl_rewrite() {
      return this.get_spr_data_usl.data !== undefined ? this.get_spr_data_usl.data
          .filter(el => {
            if (this.dopProps != null && Object.prototype.hasOwnProperty.call(this.dopProps, 'usl')) {
              let kind = Object.prototype.hasOwnProperty.call(this.dopProps.usl, 'kind');
              let uslType = Object.prototype.hasOwnProperty.call(this.dopProps.usl, 'uslType');
              let uslID = Object.prototype.hasOwnProperty.call(this.dopProps.usl, 'uslID');
              if ((kind === true ? this.dopProps.usl.kind.includes(el.kind) : true) &&
                  (uslType === true ? this.dopProps.usl.uslType.includes(el.uslType) : true) &&
                  (uslID === true ? this.dopProps.usl.uslID.includes(el.uslID) : true))
                return el
            } else
              return el
          })
          .map(el => {
            return {text: el.uslName, value: el.uslID}
          }) : []
    },
    get_spr_data_ugs_rewrite() {
      return !this.$scripts.Utils.empty(this.get_spr_data_ugs.data) ? this.get_spr_data_ugs.data
          .filter(el => {
            if (this.dopProps != null && Object.prototype.hasOwnProperty.call(this.dopProps, 'ugs')) {
              let kind = Object.prototype.hasOwnProperty.call(this.dopProps.ugs, 'kind');
              let ugsType = Object.prototype.hasOwnProperty.call(this.dopProps.ugs, 'ugsType');
              let ugsID = Object.prototype.hasOwnProperty.call(this.dopProps.ugs, 'ugsID');
              if ((kind === true ? this.dopProps.ugs.kind.includes(el.kind) : true) &&
                  (ugsType === true ? this.dopProps.ugs.ugsType.includes(el.ugsType) : true) &&
                  (ugsID === true ? this.dopProps.ugs.ugsID.includes(el.ugsID) : true))
                return el
            } else
              return el
          })
          .map(el => {
            return {text: el.ugsCode + ' - ' + el.ugsName, value: el.ugsID}
          }) : []
    },
    get_spr_data_spec_rewrite() {
      let dopPropsUsl = []
      if (this.dopProps != null && Object.prototype.hasOwnProperty.call(this.dopProps, 'usl')) {
        dopPropsUsl = this.dopProps.usl.uslID
      }
      let indUslID = this.$scripts.TableJs.arraySearch(this.assignedValues, 'sprUsl', 'dataField')
      return !this.$scripts.Utils.empty(this.get_spr_data_spec.data) ? this.get_spr_data_spec.data
          .filter(el => {
                if (this.dopProps != null && Object.prototype.hasOwnProperty.call(this.dopProps, 'spec')) {
                  let specCode = Object.prototype.hasOwnProperty.call(this.dopProps.spec, 'specCode');
                  let specFlKlasif = Object.prototype.hasOwnProperty.call(this.dopProps.spec, 'specFlKlasif');
                  let specID = Object.prototype.hasOwnProperty.call(this.dopProps.spec, 'specID');
                  if ((specCode === true ? this.dopProps.spec.specCode.includes(el.specCode) : true) &&
                      (specFlKlasif === true ? this.dopProps.spec.specFlKlasif.includes(el.specFlKlasif) : true) &&
                      (specID === true ? this.dopProps.spec.specID.includes(el.specID) : true) &&
                      (indUslID === -1 ? (dopPropsUsl.length > 0 ? dopPropsUsl.includes(el.sprUsl.uslID) : false)
                              : (this.assignedValues[indUslID].uslID === undefined ? false : el.sprUsl.uslID === this.assignedValues[indUslID].uslID)
                      )
                  )
                    return el
                } else {
                  return indUslID === -1 ? (dopPropsUsl.length > 0 ? dopPropsUsl.includes(el.sprUsl.uslID) : false)
                      : (this.assignedValues[indUslID].uslID === undefined ? false : el.sprUsl.uslID === this.assignedValues[indUslID].uslID)
                }
              }
          )
          .map(el => {
            return {text: el.specCode + ' - ' + el.specName, value: el.specID}
          }) : []
    },
    specIDRules() {
      return [
        v => !!v || 'Выберите специальность',
        v => (v && this.uslID !== null) || 'Выберите форму обучения'
      ]
    }
  },
  mounted() {
  },
  methods: {
    get_spr(typeSpr) {
      // console.log("typeSpr",typeSpr , this[typeSpr])
      return this[typeSpr]
    },
    deleteValueSelectedSpr(Obj) {
      this.assignedValues.forEach(el => {
        if (Object.prototype.toString.call(Obj, 'dependence')) {
          if (el.dependenceDataField === Obj.dataField) {
            if (!el.dependenceValueActive.includes(Obj[Obj.variable])) {
              if (el.typeVar === 'string')
                el[el.variable] = ''
              else if (el.typeVar === 'integer' )
                el[el.variable] = '0'
              else if ( el.typeVar === 'float')
                el[el.variable] = '0.0'
              else if (el.typeVar === 'date') {
                if (el.typeDate === 'month')
                  el[el.variable] = null
                else
                  el[el.variable] = null
              } else {
                el[el.variable] = null
              }
            }
          }
        }
      })
    },
    showField(Obj) {
      if (Object.prototype.hasOwnProperty.call(Obj, 'dependence')) {
        if (Obj.dependence !== true)
          return true
        else {
          let ind = this.$scripts.TableJs.arraySearch(this.assignedValues, Obj.dependenceDataField, 'dataField')
          if (ind === -1) {
            return true
          } else {
            let buf = this.assignedValues[ind][Obj.dependenceDataField]
            return Obj.dependenceValueActive.includes(buf) == null ? false : Obj.dependenceValueActive.includes(buf);
          }
        }
      } else
        return true
    },
    assignData() {
      this.arrayOfVariablesFromHeaders.forEach(field => {
        let dataVar = field.typeVar === 'selector' ? (field.dataField.slice(3).toLowerCase() + 'ID') : field.dataField
        let bufObj = new Object(field);
        this.$set(bufObj, 'variable', dataVar)
        this.$set(bufObj, 'sprData', field.typeVar === 'selector' ? 'get_spr_data_' + field.dataField.slice(3).toLowerCase() + '_rewrite' : null)
        this.$set(bufObj, dataVar, null)
        if (field.typeVar === 'date') this.$set(bufObj, 'modalVariable', false)
        if (!Object.prototype.hasOwnProperty.call(field, 'rules')) {
          this.$set(bufObj, dataVar + 'Rules', [v => !!v || 'Поле не должно быть пустым'])
        } else {
          switch (field.rules) {
            case 1:
              this.$set(bufObj, dataVar + 'Rules', [])
              break;
            case 2:
              this.$set(bufObj, dataVar + 'Rules', [v => v > 0 || 'Значение не должно быть больше 0'])
              break;
            default:
              this.$set(bufObj, dataVar + 'Rules', [])
              break;
          }
        }
        this.assignedValues.splice(this.length, 0, bufObj)
      })
      this.assignedValues.sort((a, b) => a.order - b.order)
      this.assignedValuesReady = true
    },
    validate() {
      this.$refs.form.validate()
    },
    applyEditData() {
      // console.log("dilaog apply data", this.dataForDialog)
      if (this.dataForDialog != null) {
        this.assignedValues.forEach(el => {
          if (el.typeVar === 'string' || el.typeVar === 'integer' || el.typeVar === 'float')
            el[el.variable] = this.dataForDialog[el.variable] != null ? `${this.dataForDialog[el.variable]}` : ``
          else if (el.typeVar === 'date') {
            if (el.typeDate === 'month')
              el[el.variable] = this.dataForDialog[el.variable] != null ? `${this.dataForDialog[el.variable].slice(0,7)/*.toISOString().substr(0, 7)*/}` : null
            else
              el[el.variable] = this.dataForDialog[el.variable] != null ? `${this.dataForDialog[el.variable].slice(0,10)/*.toISOString().substr(0, 10)*/}` : null
          } else if (el.typeVar === "customSelector") {
            el[el.variable] = this.dataForDialog[el.variable]
          } else {
            if (this.dataForDialog[el.dataField] != null) {
              el[el.variable] = this.dataForDialog[el.dataField][el.variable]
            } else {
              el[el.variable] = null
            }
          }
        })
        this.$emit('update:access-apply-edit-function.sync', true)
        // this.accessApplyEditFunction = true
      }
    },

    resetForm() {
      this.assignedValues.forEach(el => {
        switch (el.typeVar) {
          case 'selector':
            el[el.variable] = null;
            break;
          case 'integer':
            el[el.variable] = '0';
            break;
          case 'float':
            el[el.variable] = '0.0';
            break;
          case 'string':
            el[el.variable] = '';
            break;
          default:
            el[el.variable] = null;
            break;
        }
        // el[el.variable] = el.typeVar === 'selector' ? null : '0'
      })
    },
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    clearChosenDataSelect(val) {
      this.clearData = val
      this.$emit('update:arr-of-chosen-data', {data: [], typeAction: '', flagClear: this.clearData})
      if (this.clearData) this.clearData = !this.clearData
    },
    async sendData() {
      await this.validate()
      let Obj = new Object({
        id: this.dataForDialog == null ? null : this.dataForDialog.id
      })
      this.assignedValues.forEach(el => {
        if (el.typeVar === 'integer')
          this.$set(Obj, el.variable, parseInt(el[el.variable]))
        else if (el.typeVar === 'float')
        {
          let bufStr = el[el.variable]
          let ind = -1
          ind= bufStr.indexOf(',')
          if (ind !== -1)
            bufStr = bufStr.slice(0,ind)+'.'+bufStr.slice(ind+1)
          this.$set(Obj, el.variable, parseFloat(bufStr))
        }
        else
          this.$set(Obj, el.variable, el[el.variable])
      })
      if (this.valid) {
        // console.log("Obj",Obj)
        this.$emit('fetch-new-data', Obj)
      } else
        this.addNotification({notificationStatus: 'error', notificationMessage: 'Ошибка добавления', timeout: 3000})
    }
  },
}
</script>

<style lang="sass">
.v-dialog
  overflow-y: visible !important
</style>
