<template>
	<div class="checkbox" :style="{ width: propSize + 'px', height: propSize + 'px' }">
		<input
            v-model="$attrs.value"
            @change="onValueChanged"
            type="checkbox"
            v-bind="$attrs"
            class="checkbox-native"
            :style="{ width: propSize + 'px', height: propSize + 'px' }"
        >
        <div
          :class="['checkbox-custom', customClass]">
        </div>
	</div>

        
     <!--    <span class="checkbox__text">{{propText}}</span> -->   
</template>


<script>
	export default {
		props: {
			customClass: {
				type: String,
				default: '',
			},
			propText: {
				type: String,
				default: '',
			},
			propSize: {
				type: Number,
				default: 20,
			}
		},
	methods: {
		onValueChanged() {
			this.$attrs.value !== this.$attrs.value
			this.$emit('input', this.$attrs.value);
		}
	}
}
	
</script>



<style lang="sass">
@import "@/assets/styles/utils/variables"
.checkbox
  position: relative
  margin-right: 8px

.checkbox-native
  position: absolute
  z-index: 2
  cursor: pointer
  
.checkbox-custom
  position: absolute
  content: ''
  display: inline-block
  top: 0
  bottom: 0
  width: 100%
  height: 100%
  z-index: 1
  background: #fff
  border: 3px solid $color-1
  border-radius: 5px


.checkbox-native:checked ~ .checkbox-custom
  background: $color-1
  display: flex
  justify-content: center
  align-items: center

.checkbox-native:checked ~ .checkbox-custom:after
    content: '✔'
    color: #fff
    font-size: $size-1
</style>