<template>
<ContainerLettersMain />
</template>


<script>
import ContainerLettersMain from "@/containers/admin/letters/ContainerLettersMain";

export default {
	components: {
		ContainerLettersMain
	},
	data() {
			return {

		}
	},
}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"

</style>