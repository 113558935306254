<template>
  <div
      style="display: flex;flex-direction: column; "
      :style="`left: ` + positionTriangle(item.count ,item.max)+'%; position: absolute;'"
  >
    <v-tooltip
        bottom
        color="primary"
        nudge-bottom="1"
    >
      <template v-slot:activator="{on,attrs}">
        <div
            v-bind="attrs"
            v-on="on"
            class="arrow-up"
            :class="anotherStyle ? 'arrow-up__darker':''"
        />
<!--        <div-->
<!--            v-bind="attrs"-->
<!--            v-on="on"-->
<!--            class="indicator-arrow__count">-->
<!--          {{ item.count }}-->
<!--        </div>-->
      </template>
      <span>{{ descriptionTooltip }}: {{ item.count }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
name: "cardTriangleWithTooltip",
  props: {
    item: {
      type: Object,
      default: () => {
        return {title: '', about: '', count: '', max: ''}
      }
    },
    anotherStyle: Boolean,
    descriptionTooltip: String
  },
  methods: {
    positionTriangle(value, maxValue) {
      let a = Number.parseFloat(value)
      let b = Number.parseFloat(maxValue)
      return a * 100 / b
    },
  }

}
</script>

<style lang="scss" scoped>
.wrapper-indicator-arrow {
  width: calc(100% - 2px);
  margin-left: -6px;
  position: relative;

  .indicator-arrow__count {
    max-width: 100px;
    font-size: 10px;
    color: #00599B;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #eb722e;
  &__darker{
    border-bottom: 7px solid #00599B;
  }
}

</style>