<template>
    <ContainerNewsDetail />
</template>

<script>
import ContainerNewsDetail from "@/containers/front/news/ContainerNewsDetail";
export default {
  components: {
    ContainerNewsDetail,
  },
};
</script>
