<template>
	<article class="cms-card-news" :class="'application-status_'+item.applicationStatus" itemscope itemtype="http://schema.org/NewsArticle">
     

 <div class="applications__header">
    <div class="applications__title"><span>Организация:</span> <router-link :to="'/kcp-contest-admin/reference/' + item.orgID"> {{item.orgName}}</router-link></div>
    <div class="applications__id"><span>ID заявки:</span> {{item.applicationID}}</div>
    <div class="applications__date"><span>Дата создания:</span> {{createDate}}</div>
</div>
<div class="applications__content">
    <button class="button_mini" @click="$emit('aboutApplication', {id: item.applicationID})">Подробно об изменениях</button>
    <button class="button_mini" @click="getDocument">Подтверждающий документ</button>
</div>

<div class="applications__actions">
     <div class="cms-card-news__ok" @click="resolve" v-if="item.applicationStatus === 'ACTIVE'">
                <v-icon :size="30" color="#ffffff" class="fr-topbar__icon">mdi-check-bold</v-icon>
            </div>
            <div class="cms-card-news__delete" @click="reject" v-if="item.applicationStatus === 'ACTIVE'">
                <v-icon :size="30" color="#ffffff" class="fr-topbar__icon">mdi-cancel</v-icon>
            </div>
                    <div class="application-moderate">
						<div class="application__status" v-if="item.applicationStatus !== 'ACTIVE'">
							{{item.applicationStatus === 'DONE' ? 'Изменения приняты' : ''}}
							{{item.applicationStatus === 'REJECT' ? 'Изменения отклонены' : ''}}
						</div>
                        <span v-if="editDate" class="application__moderate-time">{{editDate}}</span>
                    </div>    
</div>





	</article>


</template>	

<script>
import moment from 'moment';
moment.locale('ru');
import request from "@/services/request";
import saveFile from '@/utils/saveFile';

export default {
	props: {
		item: {
			type: Object,
		}
    },
    computed: {
        createDate() {
            return moment(this.item.createDate).format('LLL')
        },
        editDate() {
            if (this.item.editDate) {
                return moment(this.item.editDate).format('LLL')
            } else {
                return '';
            }
        },
    },
    methods: {
        reject() {
            this.$emit('reject', {id: this.item.applicationID});
        },
        resolve() {
            this.$emit('resolve',  {id: this.item.applicationID});
        },

    async getDocument() {
        let response = await request({
            endpoint: '/api/fileServer/downloadFileByID',
            method: 'POST',
            // headers: {ResponseType: 'blob'},
            body: {
                fileServerID: this.item.applicationsFiles.fileServerID,
                fileName: this.item.applicationsFiles.fileName,
                fileFormat: this.item.applicationsFiles.fileFormat}
        });
        saveFile(response.data);
    },
    },

}
</script>
<style lang="sass">
.cms-card-news__actions
    display: flex
.cms-card-news
    background: #fff
    margin-bottom: 15px
    padding: 5px 10px
    display: flex
    justify-content: space-between
    align-items: center
    border-left: 3px solid #00599b
    transition: 0.3s
.cms-card-news__header
    flex-grow: 0
    flex-basis: 700px
    overflow: hidden
.application-change__title
    font-weight: 500
    color: #eb8e1c
.cms-card-news:hover
    transform: translateY(-5px)
    box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
    transition: 0.3s
.cms-card-news__ok
    background: #41cf8f
    padding: 3px  
    border-radius: 3px
    border: 1px solid #129a5d
    cursor: pointer
.cms-card-news__ok:hover
    background: #108751    
.cms-card-news__edit:hover
    background: #ffeeba
.cms-card-news__delete
    background: #ce303f
    padding: 3px
    border-radius: 3px
    border: 1px solid #8c0c1a
    cursor: pointer
.cms-card-news__delete:hover
    background: #4c040b   
.application-status_ACTIVE
    background: #fff
.application-change-content__field
    display: flex
.application-change-content__label
    white-space: nowrap
    color: #00599b
    font-weight: 500
    margin-right: 15px
.application-status_DONE .application__status
    color: #155724
    background: #d4edda
    border: 1px solid #c3e6cb
.application-status_REJECT .application__status
    color: #721c24
    background: #f8d7da
    border: 1px solid #f5c6cb
.applications__actions
    min-width: 200px
.application__status
    text-align: center
    font-weight: 500
    padding: 5px
    border-radius: 5px
    min-width: 190px
.applications__actions
    min-width: 200px
    display: flex
    justify-content: center
    align-items: center
.cms-card-news__ok
    margin-right: 5px
.application__moderate-time
    font-size: 12px
.application-moderate
    text-align: center
.application-status_DONE, .application-status_REJECT
    opacity: 0.7 
.application-status_DONE:hover, .application-status_REJECT:hover
    opacity: 1
.applications__header span
    background: #e3effb
    color: #00599b
    padding: 1px 7px
    border-radius: 3px
    margin-right: 10px
    display: inline-block
    white-space: nowrap
    margin-bottom: 5px
.applications__content
    text-align: center     		
</style>