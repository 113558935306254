<template>
  <div>
    <div class="score-about">
      <p><b>п.1.4.2. Приложения 2 Порядка:</b></p>
      <p>Доходы от выполнения научно-исследовательских и опытно-конструкторских работ в расчете на одного НПР</p>
    </div>
    <v-divider class="mt-4"/>
    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about" v-if="rowOfValues.data.sorg == 1">
      <p class="calculation__about-text">Форма 5.17. <br>
        Объем доходов образовательной организации, тыс.руб.</p>
    </div>
    <table class="indicators__table" v-if="rowOfValues.data.sorg == 1">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="3">Объем поступивших средств, всего</th>
        <th colspan="3">в т.ч. от выполнения НИОКР</th>
      </tr>
      <tr class="">
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>

      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" v-html="checkData(rowOfValues.data.ok2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2019)"></td>
      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td>
<!--          <b>SC</b><span class="font-size-little">2017</span>-->
        </td>
        <td>
<!--          <b>SC</b><span class="font-size-little">2018</span>-->
        </td>
        <td>
<!--          <b>SC</b><span class="font-size-little">2019</span>-->
        </td>
        <td>
         <b>ДН</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>ДН</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>ДН</b><span class="font-size-little">2019</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="calculation__about" v-if="rowOfValues.data.sorg == 0">
      <p class="calculation__about-text">Форма 5.18.<br>
        Выполненный объем работ и услуг научной организацией, организацией дополнительного профессионального образования, тыс.руб.</p>
    </div>
    <table class="indicators__table" v-if="rowOfValues.data.sorg == 0">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="3">Выполнено работ, услуг,<br>
          произведено товаров, всего</th>
        <th colspan="3">в т.ч. от выполнения НИОКР</th>
      </tr>
      <tr class="">
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>

      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" v-html="checkData(rowOfValues.data.ok2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2019)"></td>
      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td>
<!--          <b>НПР</b><span class="font-size-little">2017</span>-->
        </td>
        <td>
<!--          <b>НПР</b><span class="font-size-little">2018</span>-->
        </td>
        <td>
<!--          <b>НПР</b><span class="font-size-little">2019</span>-->
        </td>
        <td>
          <b>ДН</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>ДН</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>ДН</b><span class="font-size-little">2019</span>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="calculation__about">
      <p class="calculation__about-text">Форма 5.14.<br>
        Среднесписочная численность профессорско-преподавательского состава, человек</p>
    </div>
    <table class="indicators__table">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="3">Профессорско-преподавательский состав<br> (без внешних совместителей)</th>
        <th colspan="3">Внешних совместителей<br> (пропорционально отработанному времени)</th>
      </tr>
      <tr class="">
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>

      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" v-html="checkData(rowOfValues.data.pps12017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.pps12018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.pps12019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.pps22017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.pps22018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.pps22019)"></td>
      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td>
          <b>НПР</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>НПР</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>НПР</b><span class="font-size-little">2019</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2019</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="calculation__about" v-if="rowOfValues.data.sorg == 1">
      <p class="calculation__about-text">Форма 5.15.<br>
        Среднесписочная численность научных работников образовательной организации, человек</p>
    </div>
    <table class="indicators__table" v-if="rowOfValues.data.sorg == 1">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="3">Научных работников<br>
          без внешних совместителей</th>
        <th colspan="3">Внешних совместителей<br> (пропорционально отработанному времени)</th>
      </tr>
      <tr class="">
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>

      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" v-html="checkData(rowOfValues.data.nr12017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nr12018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nr12019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nr22017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nr22018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nr22019)"></td>
      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2019</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>+НПР</b><span class="font-size-little">2019</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="calculation__about" v-if="rowOfValues.data.sorg == 0">
      <p class="calculation__about-text">Форма 5.15а.<br>
        Численность научных работников (исследователей) научной организации, организации дополнительного профессионального образования, человек</p>
    </div>
    <table class="indicators__table" v-if="rowOfValues.data.sorg == 0">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="6">Численность работников, выполнявших НИОКР</th>

      </tr>
      <tr class="">
        <th class="" colspan="2">2017 год</th>
        <th class="" colspan="2">2018 год</th>
        <th class="" colspan="2">2019 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="" colspan="2">2</th>
        <th class="" colspan="2">3</th>
        <th class="" colspan="2">4</th>

      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" colspan="2" v-html="checkData(rowOfValues.data.nr32017)"></td>
        <td class="" colspan="2" v-html="checkData(rowOfValues.data.nr32018)"></td>
        <td class="" colspan="2" v-html="checkData(rowOfValues.data.nr32019)"></td>

      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="2" style="">
          <div>
            <img style="height: 15px; margin: 0; width:140px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="2" style="">
          <div>
            <img style="height: 15px; margin: 0; width:140px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="2" style="">
          <div>
            <img style="height: 15px; margin: 0; width:140px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td colspan="2">
          <b>+НПР</b><span class="font-size-little">2017</span>
        </td>
        <td colspan="2">
          <b>+НПР</b><span class="font-size-little">2018</span>
        </td>
        <td colspan="2">
          <b>+НПР</b><span class="font-size-little">2019</span>
        </td>
      </tr>
      </tbody>
    </table>
    <!-- formula -->
    <section class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>М42<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full"><span style="font-size: 28px">(</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
             ДН<span class="font-size-little">2017</span>
          </div>
          <div class="formula-half" style="margin-left: 10%">HПР<span class="font-size-little">2017</span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
          ДН<span class="font-size-little">2018</span>
          </div>
          <div class="formula-half" style="margin-left: 10%">HПР<span class="font-size-little">2018</span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
         ДН<span class="font-size-little">2019</span>
          </div>
          <div class="formula-half" style="margin-left: 10%">HПР<span class="font-size-little">2019</span></div>
        </div>
        <div class="formula-full"><span style="font-size: 28px">)</span></div>
        <div class="formula-full font-weight-regular"><div>÷ <span class="font-weight-bold"> n </span> =</div></div>
      </div>
      <div class="formula">
         <div class="formula-full"> =</div>
        <div class="formula-full"><span style="font-size: 28px">(</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.dn2017)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.npr2017)"></span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.dn2018)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.npr2018)"></span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.dn2019)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.npr2019)"></span></div>
        </div>
        <div class="formula-full"><span style="font-size: 28px">)</span></div>
        <div class="formula-full font-weight-regular"><div>÷ <span class="font-weight-bold" v-html="checkData(rowOfValues.data.x1)"> </span> =</div></div>
        <div class="formula-full"><span v-html="checkData(rowOfValues.data.m42b0)"></span></div>
      </div>
      <!-- formula -->

      <div class="calculation__about">
        <p class="calculation__about-text">
          ДНk - объем поступлений от выполнения НИОКР в k-ом году
        </p>
        <p class="calculation__about-text">
          НПРk	-	численность научно-педагогических (научных) работников, по данным k-го года
        </p>
        <p class="calculation__about-text">
          n	-	число лет, за которые имеются отчетные данные НПРk.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "m42",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float', 3, false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%

.b42-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px

.b42-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px

  tr
    &:nth-child(2)
      td
        border: 0

    &:nth-child(3)
      td
        border: 0
</style>