<template>
  <section class="fr-topbar">
      <!-- <div class="lk-topbar__toggle">
          <img class="lk-topbar__icon" src="@/assets/img/icon-menu.png">
      </div> -->
      <v-container>
        <div class="fr-topbar__content">
      <a href="http://ined.ru" class="fr-topbar__logo">
        <img class="fr-logo__img_before" src="@/assets/img/logo-img.svg"/>
        <img class="fr-logo__img" src="@/assets/img/logo-text.svg"/>
      </a>

      <div class="role-admin-lk" v-if="authData && authData.role && authData.role.id === 14 && !this.$route.path.includes('kcp-contest-admin')">
        <div class="role-admin-lk__about">Режим просмотра личного кабинета</div>
        <button class="button button_role-admin-lk" @click="returnAdminLk">Вернуться в админку</button>
      </div>

      <nav class="fr-topbar__nav">
<!--        <router-link to="/question" class="button button_orange">Задать вопрос</router-link>-->
<!--        <router-link to="/" class="fr-topbar__nav-item">Главная</router-link>-->
<!--        <router-link to="/news" class="fr-topbar__nav-item">Новости</router-link>-->
      </nav>

      <div class="fr-topbar__lk">
          <router-link v-if="!authData" to="/private" class="fr-lk">
            <div class="fr-lk__title">
              Личный кабинет
            </div>
         </router-link >

         <div v-else class="fr-lk-auth">
           <router-link :to="lkpath" class="fr-lk-auth__user">
             <img class="fr-lk-auth__user-img" src="@/assets/img/icon-auth-user.svg">
             <div class="fr-lk-auth__user__info">
               <div class="fr-lk-auth__user__name">{{nameOrg}}</div>
             </div>
           </router-link>
           <div class="fr-lk-auth__logout" @click="logout">
             <img class="fr-lk-auth__logout-img" src="@/assets/img/icon-auth-logout.svg">
           </div>

         </div>

         
      </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
 computed: {
   ...mapGetters('auth', {authData: 'authData'}),
   ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
   ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
   nameOrg(){
       if (this.get_replace_role_id_admin === 1)
         return this.authData.sprOrgName
       else if(this.get_replace_role_id_admin === 6)
         return this.authData.fio
       else if (this.get_replace_role_id_admin === 4)
         return this.authData.sprOrgFounderName
       else
         return ''
     },
     lkpath(){
         if (this.get_replace_role_id_admin === 9) return {name: 'RegionMain'}
         return {name: 'LkMain'}
     }
 },
 methods: {
  ...mapActions('auth', {storeLogout: 'logout'}),
  logout() {
      this.storeLogout()
      this.$router.push('/')
    },
  returnAdminLk() {
    this.$router.push('/kcp-contest-admin/');
  }
 }
}
</script>

<style lang="sass" scoped>
@import "/src/assets/styles/utils/variables"
.fr-topbar
  display: flex
  justify-content: space-between
  align-items: center
  background: #00599B
.fr-logo__img
    height: auto
    width: 294px
.fr-topbar__logo
    height: 78px
    display: flex
    align-items: center
    position: relative
.fr-logo__img_before
    position: absolute
    left: -54px    
.fr-topbar__content
    display: flex
    justify-content: space-between
    align-items: center
.fr-topbar__nav
    margin-left: auto
    margin-right: 122px
.fr-topbar__icon
    height: 30px
.fr-user__exit
    display: flex
    align-items: center
.fr-topbar__nav-item
    text-transform: uppercase
    color: #ffffff !important
    font-size: 14px
    line-height: 17.07px
    font-family: 'Montserrat'
    font-weight: 700
    margin: 0 10px
    padding-bottom: 5px
.fr-topbar__nav-item:hover
    color: $color-3 !important
.fr-topbar__nav-item.router-link-active
    border-bottom: 2px solid #fff  
.fr-topbar__nav-item.router-link-active:hover
    border-color: $color-3
.fr-topbar .container
    padding: 2px 12px !important
.fr-lk
    display: flex
    justify-content: space-between
    align-items: center
.fr-lk__title
    color: #fff
    font-family: Montserrat
    font-size: 12px
    font-style: normal
    font-weight: 700
    line-height: 17px
    letter-spacing: 0em
    text-align: center
    text-transform: uppercase
    border: 1px solid #fff
    border-radius: 7px
    padding: 6.5px 19px
.fr-lk__title:hover
    color: #eb8e1c
    border-color: #eb8e1c
.fr-lk-auth__user__name
    font-family: Montserrat
    font-size: 11px
    font-weight: 700
    line-height: 13px
    letter-spacing: 0em
    text-align: left
    color: #fff
    max-width: 200px
    overflow: hidden
.fr-lk-auth__user__role
    font-family: Montserrat
    font-size: 9px
    font-weight: 400
    line-height: 11px
    letter-spacing: 0em
    text-align: left
    color: #fff
.fr-lk-auth__user-img
    width: 16px
    margin-right: 12px
.fr-lk-auth__user
    display: flex
    align-items: center    
.fr-lk-auth
    display: flex
.fr-lk-auth__logout
    display: flex
    align-items: center
    margin-left: 27px
.fr-lk-auth__logout-img
    cursor: pointer
.role-admin-lk
    margin-left: auto
    display: flex
    align-items: center
    color: #fff
    padding: 5px 10px
    border-radius: 10px
    font-weight: 600
.button_role-admin-lk
    margin-left: 15px
    background: #eb8e1c
    color: #ffffff
    font-weight: 400
    border: 3px solid #eb8e1c
.button_role-admin-lk:hover
    background: #fff
    color: #eb8e1c
</style>