<template>
  <div>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title">Этап 3 распределения объемов КЦП на 2023/24 учебный год (центры
        ответственности)</h2>
      <h3 class="section-header__subtitle font-size-big__important">
        {{ kcpGroupName }}
      </h3>
    </header>
    <v-row>
      <v-col>
        <v-btn class="blue__v-btn " @click="$router.go(-1)">Назад</v-btn>
        <div v-if="editable">
          <v-icon size="12" color="warning">mdi-checkbox-blank-circle</v-icon>
          - строчки где есть предложения дргуих ЦО
        </div>
      </v-col>
      <v-col class="d-flex justify-end flex-row">
<!--        <v-btn-->
<!--            v-if="editable != null && !editable"-->
<!--            :loading="loadingSoglStatus"-->
<!--            @click="clickSendSoglStatus()"-->
<!--            class="success__v-btn "-->
<!--            color="#29A128"-->
<!--        >-->
<!--          <span style="color:#fff;">{{ acceptStatus ? 'Отменить согласование' : 'Согласовать' }}</span>-->
<!--        </v-btn>-->
<!--        <div class="d-flex flex-column">-->
<!--          <p class="font-size-big text-primary">Список согласовавших ЦО:</p>-->
<!--          <ul v-if="!soglKcpListError && !soglKcpListLoading">-->
<!--            <template v-if="soglKcpList.length === 0">-->
<!--              <li>Никто не согласовал значения</li>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <li v-for="(item, i ) in soglKcpList" :key="i">{{-->
<!--                  item.sprOrgFounder ? item.sprOrgFounder.orgFounderName + ' -  ' +-->
<!--                      (item.accepted ? 'согласовал' : 'не согласовал') : '-'-->
<!--                }}-->
<!--              </li>-->
<!--            </template>-->
<!--          </ul>-->
<!--        </div>-->
      </v-col>
    </v-row>


    <wrapper-preview-category customClass="section-preview_lk">
      <v-tabs
          v-model="tab"
          align-with-title
          @change="changeUsl($event)"

      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
            v-for="item in tabs"
            :key="item.usl"
            class="font-weight-bold"
        >
          {{ item.text }}
        </v-tab>
<!--        <v-spacer></v-spacer>-->
<!--        <div class="d-flex align-center">-->
<!--          <v-btn-->
<!--              class="blue__v-btn mr-2"-->
<!--              color="primary"-->
<!--              :loading="loadingPrintData"-->
<!--              @click="clickPrintData()"-->
<!--          >В Excel-->
<!--          </v-btn>-->
<!--        </div>-->
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="item in tabs"
            :key="item.usl"
        >
          <v-card flat>
            <v-card-text>
              <GridTable
                  :data-rows="chosenData"
                  :num-cols="headers.countCells"
                  :headers="headers.massive"
                  :headers-for-rows="headersForRows"
                  :loading-data="loadingData"
              >
                <template #defaultHeader="{headers}">
                  <div
                      v-for="(item, i) in headers" :key="i"
                      class="table-grid-header__cell"
                      :style="`grid-column: span ${(Number(item.cols) )};`"
                  >
                    <span>{{ item.text }}</span>
                    <v-menu
                        v-if="!editable && item.headerTooltip != null && item.headerTooltip"
                        v-model="item.headerTooltipShow"
                        :close-on-content-click="false"
                        :nudge-width="-250"
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon
                               v-bind="attrs"
                               v-on="on"
                               @click="item.headerTooltipShow = !item.headerTooltipShow"
                        >
                          <v-icon
                              :size="15"
                              class="btn__icon grey-light-color__important"
                          >
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title v-text="item.headerTooltipHeaderText()"></v-card-title>
                        <v-card-text v-html="item.headerTooltipText()"></v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              class="error__v-btn"
                              @click="item.headerTooltipShow = false"
                          >
                            Закрыть
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                <template #regionName="{row}">
                  <span class="d-flex flex-row">
                    <v-icon
                        v-if="editable && row.haveOffers3"
                        size="12"
                        color="warning">
                      mdi-checkbox-blank-circle
                    </v-icon>
                    {{ row['regionName'].trim() }}
                     <v-icon
                         v-if="row.fileId"
                         size="15"
                         color="primary"
                         @click="downloadRegionFile(row.fileId)"
                     >
                      mdi-file
                    </v-icon>
                  </span>
                </template>
                <template #coValueStage3="{row,cell}">
                  <component :is="'vMenuStageFounders3'" v-bind="cell.props(row,cell)"
                             @component-action="cell.callback($event)" @get-additional-data="cell.callback2($event)"/>
                </template>
                <template #coValueOffer3="{row,cell}">
                  <component :is="'vMenuStageFounders3'" v-bind="cell.props(row,cell)"
                             @component-action="cell.callback($event)"/>
                </template>
              </GridTable>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </wrapper-preview-category>
  </div>
</template>

<script>
// import Alert from "@/components/alerts/Alert";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import request from "@/services/request";
import {mapGetters, mapActions} from 'vuex'
import vMenuStageFounders3 from "@/components/cards/custom/vMenuStageFounders3";
import axios from "axios";
import GridTable from "@/components/GridTable/GridTable";
import saveFile from "@/utils/saveFile";

export default {
  name: "founderStageThreeDetails",
  components: {GridTable, WrapperPreviewCategory, vMenuStageFounders3},
  data() {
    return {
      isSogl: false,
      loadingSoglStatus: false,
      loadingPrintData: false,
      tab: null,
      tabs: [
        {
          fo: 1,
          text: 'Очная'
        },
        {
          fo: 2,
          text: 'Очно-заочная'
        },
        {
          fo: 3,
          text: 'Заочная'
        },
      ],
      headersDefault: {
        countCells: 13,
        massive: [
          {
            dataField: 'regionName',
            cols: 1,
            text: 'Наименование субъекта РФ',
            class: 'justify-start',
            template: function (value) {
              return value['regionName'].trim();
            }
          },
          {
            dataField: 'applications2019',
            cols: 1,
            text: 'Заявления 2019',
          },
          {
            dataField: 'applications2020',
            cols: 1,
            text: 'Заявления 2020',
          },
          {
            dataField: 'enrolled2019',
            cols: 1,
            text: 'Зачисленные 2019',
          },
          {
            dataField: 'enrolled2020',
            cols: 1,
            text: 'Зачисленные 2020',
          },
          {
            dataField: 'ege2019',
            cols: 1,
            text: 'ЕГЭ 2019',
          },
          {
            dataField: 'ege2020',
            cols: 1,
            text: 'ЕГЭ 2020',
          },
          {
            dataField: 'kcp2021',
            cols: 1,
            text: 'КЦП  2021',
          },
          {
            dataField: 'kcp2022',
            cols: 1,
            text: 'КЦП  2022',
          },
          {
            dataField: 'baseValue',
            cols: 1,
            text: 'Проект КЦП 2023/24',
          },
          {
            dataField: 'coValueStage1',
            cols: 1,
            text:  `Предложение головного ЦО этап 1`,
            // template:  (value) => {
            //   return this.editable ? value['coValueStage1'] : value['coValueOffer1'];
            // }
          },
          {
            dataField: 'regValueStage2',
            cols: 1,
            text: 'Предложение Субъекта РФ',
          },
          {
            dataField: 'coValueStage3',
            cols: 1,
            text: 'Предложение головного ЦО этап 3',
            component: 'vMenuStageFounders',
            class: '',
            headerTooltip: true,
            headerTooltipShow: false,
            headerTooltipHeaderText: () => {
              return this.contactsData?.sprOrgFounder?.orgFounderName ?? ''
            },
            headerTooltipText: () => {
              return `<div>ФИО: <span class='blue-color'>${this.contactsData.fio ?? ' - '}</span></div>
                      <div>email: <span class='blue-color'>${this.contactsData.email ?? ' - '}</span></div>
                      <div>Должность: <span class='blue-color'>${this.contactsData.position ?? ' - '}</span></div>
                      <div>Рабочий номер: <span class='blue-color'>${this.contactsData.workPhoneNumber ?? ' - '}</span></div>
                      <div>Мобильный номер: <span class='blue-color'>${this.contactsData.personalPhoneNumber ?? ' - '}</span></div>`;
            },
            styles: 'justify-content: unset;',
            props: (row, cell) => {
              return {
                value: row[cell.dataField],
                title: 'Предложение головного ЦО',
                minValue: Math.min(Number.parseInt(row.baseValue * 0.8), Math.abs(row.rg - row.baseValue)),
                maxValue: Math.max(Number.parseInt(row.baseValue * 1.2), row.rg + row.baseValue, 0),
                cell: cell,
                id: row.id,
                row: row,
                loading: this.loadingMenu,
                additionalDataActive: !!this.editable && !!row.haveOffers3,
                loadingAdditionalData: this.loadingAdditionalData,
                additionalData: this.additionalData,
                disabled: !row.editable || this.get_blockedEnterData || [45, 62].includes(row.regionId)
              }
            },
            callback: (value) => {
              this.sendValueFromMenu(value)
            },
            callback2: (value) => {
              this.getFromSeverAdditionalData(value)
            }
          },
        ]
      },
      contactsData: {
        fio: 'Фамилия Имя Отчество',
        email: 'test@mail.ru',
        position: '',
        workPhoneNumber: '+ 7 989 5559867',
        personalPhoneNumber: ''
      },
      contactsDataLoading: false,
      contactsDataError: null,
      serverData: [],
      loadingData: false,
      errorData: null,
      chosenData: [],
      show: false,
      loadingMenu: {value: false, id: ''},
      loadingAdditionalMenu: {value: false, id: ''},
      loadingAdditionalData: {value: false, id: ''},
      additionalData: {
        htmlText: false,
        text: ``,
        data: []
      },
      soglKcpList: [],
      soglKcpListError: null,
      soglKcpListLoading: false,
      acceptStatusLoading: false,
      acceptStatus: null
    }
  },
  created() {

  },
  watch: {
    kcpGroupId() {
      this.getServerData()
    },
    editable(newValue) {
      if (newValue === false) {
        const ind = this.headersDefault.massive.findIndex(el => el.dataField === 'coValueStage1');
        this.headersDefault.massive.splice(ind, 1, {
          dataField: 'coValueOffer1',
          cols: 1,
          text: `Предложение ЦО этап 1`,
        });
        if (this.headersDefault.massive.find(el => el.dataField === 'coValueOffer3') == null) {
          this.headersDefault.countCells = 14
          this.headersDefault.massive.push({
            dataField: 'coValueOffer3',
            cols: 1,
            text: 'Предложение ЦО',
            component: 'vMenuStageFounders',
            class: '',
            styles: 'justify-content: unset;',
            props: (row, cell) => {
              return {
                value: row[cell.dataField] ?? 0,
                title: 'Предложение ЦО',
                minValue: Math.min(Number.parseInt(row.baseValue * 0.8), Math.abs(row.rg - row.baseValue)),
                maxValue: Math.max(Number.parseInt(row.baseValue * 1.2), row.rg + row.baseValue, 0), cell: cell,
                id: row.id,
                row: row,
                loading: this.loadingAdditionalMenu,
                disabled: this.get_blockedEnterData || [45, 62].includes(row.regionId)
              }
            },
            callback: (value) => {
              this.sendValueFromMenu(value)
            }
          })
        }
      }
    },

  },
  computed: {
    ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    kcpGroupId() {
      return Number.parseInt(this.$route.query.kcpGroupId)
    },
    headers(){
      return this.headersDefault
    },
    headersForRows() {
      return this.headers.massive.filter(el => el.variable !== false)
    },
    kcpGroupName() {
      return this.serverData.length > 0 ? this.serverData[0].data[0].kcpGroupName : ''
    },
    editable() {
      return this.serverData.length > 0 ? this.serverData[0].data[0].editable : null
    }
  },
  mounted() {
    if (this.kcpGroupId)
      this.getServerData()
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    async downloadRegionFile(fileId) {
      const arrFiles = fileId.split(',')
          .map(el => {
            return axios.get(`/api/fileServer/downloadById/${el}`, {
              headers: this.$scripts.Utils.authHeader(),
            })
          });
      await Promise.all(arrFiles)
          .then(
              data => {
                data.forEach(el => saveFile(el.data))
              }
          )
          .catch(err => {
            console.error(err);
            this.addNotification({
              notificationStatus: 'error',
              notificationMessage: 'Ошибка скачивания файла',
              timeout: 3000
            })
          })
      // if (responseAxios.status === 200) {
      //   saveFile(responseAxios.data)
      // } else {
      // }
    },
    async getServerData() {
      this.loadingData = true;
      const res = await request({
        endpoint: `/api/stageData/byFounder/${this.get_replace_id_admin}/kcpGroup/${this.kcpGroupId}`,
        method: 'get',
      })
      if (res.status === 200) {
        this.loadingData = false;
        const editable = res.data[0].editable
        this.serverData = [1, 2, 3].map(el => {
          return {foId: el, data: res.data.filter(el2 => el2.foId === el)}
        })
        this.changeUsl(this.tab)
        // console.log("ed", editable)
        if (editable)
          await this.getServerSoglKcpList()
        else {
          await this.getServerMainContact();
          await this.getServerAcceptStatus()
        }
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных',
          timeout: 3000
        })
        this.errorData = res.error;
        this.loadingData = false;
      }
    },
    changeUsl(tabsIndex) {
      const foundedTab = this.tabs.find((el, id) => id === tabsIndex);
      // console.log("1", foundedTab.fo,this.serverData,this.serverData.find(el => el.foId === foundedTab.fo))
      this.chosenData = this.serverData.length > 0 ? this.serverData.find(el => el.foId === foundedTab.fo).data : [];
    },
    async getFromSeverAdditionalData(id) {
      // console.log("11", id)
      this.loadingAdditionalData = {value: true, id: id};
      const res = await request({
        endpoint: `/api/stageDataOffer/byStageDataRow/${id}`,
        method: 'get',
      })
      if (res.status === 200) {
        this.loadingAdditionalData = {value: false, id: id};
        this.additionalData.data = res.data
        // this.acceptStatus = !this.acceptStatus;
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных',
          timeout: 3000
        })
        this.loadingAdditionalData = {value: false, id: id};
      }
    },
    async sendValueFromMenu(obj) {

      let endpoint = ``;
      let method = ``;
      let body = {};
      if (this.editable) {
        this.loadingMenu = {value: true, id: obj.id};
        endpoint = `/api/stageData/update`;
        method = 'post';
        body = {
          id: obj.id,
          founderId: this.get_replace_id_admin,
          coValueStage3: obj.value,
        };
      } else {
        this.loadingAdditionalMenu = {value: true, id: obj.id};
        endpoint = `/api/stageDataOffer/${obj.row?.coOfferId ? 'update' : 'add'}`;
        method = obj.row?.coOfferId ? 'put' : 'post';
        body = {
          stageData: obj.id,
          id: obj.row?.coOfferId ?? null,
          sprOrgFounder: this.get_replace_id_admin,
          coValueStage3: obj.value,
        };
      }
      const res = await request({
        endpoint: endpoint,
        method: method,
        body: body
      })
      if (res.status >= 200 && res.status < 300) {
        if (!this.editable) {
          this.loadingAdditionalMenu = {value: false, id: obj.id};
          const serverDataIndex = this.serverData[this.tab].data.findIndex(el => el.id === res.data.stageData);
          const serverChangedElement = {
            ...this.serverData[this.tab].data[serverDataIndex],
            coOfferId: res.data.id,
            coValueOffer3: res.data.coValueStage3
          };
          const chosenDataIndex = this.chosenData.findIndex(el => el.id === res.data.stageData);
          this.serverData[this.tab].data.splice(serverDataIndex, 1, serverChangedElement);
          this.chosenData.splice(chosenDataIndex, 1, serverChangedElement);
        } else {
          this.loadingMenu = {value: false, id: obj.id};
          const serverDataIndex = this.serverData[this.tab].data.findIndex(el => el.id === res.data.id);
          const chosenDataIndex = this.chosenData.findIndex(el => el.id === res.data.id);
          this.serverData[this.tab].data.splice(serverDataIndex, 1, res.data);
          this.chosenData.splice(chosenDataIndex, 1, res.data)
        }
        await this.addNotification({
          notificationStatus: 'success',
          notificationMessage: 'Данные успешно сохранены',
          timeout: 3000
        });
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных',
          timeout: 3000
        })
        this.loadingAdditionalMenu = {value: false, id: obj.id};
        this.loadingMenu = {value: false, id: obj.id};
      }
    },
    async clickSendSoglStatus() {
      this.loadingSoglStatus = true;
      const res = await request({
        endpoint: `/api/stageData/${this.isSogl ? 'deaccept' : 'accept'}?founderId=${this.get_replace_id_admin}&kcpGroupId=${this.kcpGroupId}`,
        method: 'get',
      })
      if (res.status === 200) {
        this.loadingSoglStatus = false;
        // console.log("soglStatus", res.data)
        this.acceptStatus = !this.acceptStatus;
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных',
          timeout: 3000
        })
        this.loadingSoglStatus = false;
      }
    },
    async getServerSoglKcpList() {
      this.soglKcpListLoading = true;
      const res = await request({
        endpoint: `/api/stageData/accepted?kcpGroupId=${this.kcpGroupId}`,
        method: 'get',
      })
      if (res.status === 200) {
        this.soglKcpListLoading = false;
        this.soglKcpList = res.data;
        // console.log("ogl LIst", res.data)
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения списка согласованных ЦО',
          timeout: 3000
        })
        this.soglKcpListLoading = false;
      }
    },
    async getServerMainContact() {
      this.contactsDataLoading = true;
      const res = await request({
        endpoint: `/api/founderService/contact/kcpgroup?kcpGroupID=${this.kcpGroupId}`,
        method: 'get',
      })
      if (res.status === 200) {
        this.contactsDataLoading = false;
        this.contactsData = res.data ?? this.contactsData
        // console.log("contactsData", res.data)
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения списка согласованных ЦО',
          timeout: 3000
        })
        this.contactsDataLoading = false;
      }
    },
    async getServerAcceptStatus() {
      this.acceptStatusLoading = true;
      const res = await request({
        endpoint: `/api/stageData/acceptstatus?founderId=${this.get_replace_id_admin}&kcpGroupId=${this.kcpGroupId}`,
        method: 'get',
      })
      if (res.status === 200) {
        this.acceptStatusLoading = false;
        this.acceptStatus = res.data.accepted ?? this.acceptStatus
        // console.log("acceptStatus", res.data)
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения списка согласованных ЦО',
          timeout: 3000
        })
        this.acceptStatusLoading = false;
      }
    },
    async clickPrintData() {
      this.loadingPrintData = true;//https://kcp.ined.ru/api/report/getExcel/stageData3/co/9/kcp/45
      const responseAxios = await axios.get(`/api/report/getExcel/stageData3/co/${this.get_replace_id_admin}/kcp/${this.kcpGroupId}`, {
        headers: this.$scripts.Utils.authHeader(),
        responseType: 'blob'
      })
      if (responseAxios.status === 200) {
        this.loadingPrintData = false;
        this.saveByteArray(`excel-${this.kcpGroupName}.xlsx`, responseAxios.data)
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка скачивания файла',
          timeout: 3000
        })
        this.loadingPrintData = false;
      }
    },
    saveByteArray(reportName, byte) {
      const blob = new Blob([byte], {type: "xlsx"});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    }
  },
}
</script>

<style lang="sass">
.blue-color
  color: #0D72BD !important
</style>