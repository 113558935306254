<template>
	<article class="cms-card-news" itemscope itemtype="http://schema.org/NewsArticle">
        <header class="cms-card-news__header">
			<h3 class="cms-card-news__title" itemprop="headline">{{item.title}}</h3>
        </header>
        <div class="cms-card-news__publish">
            <time class="cms-card-news__date" itemprop="dateline">{{item.date.day}} {{item.date.month}} {{item.date.year}}</time>
            <span class="cms-card-news__author">admin</span>
        </div>  
        <div class="cms-card-news__actions">
            <router-link :to="{name: 'NewsDetailCMS', params: { id: item.id }}" class="cms-card-news__edit">
                <v-icon :size="30" color="#ffffff" class="fr-topbar__icon">mdi-file-document-edit-outline</v-icon>
            </router-link>
            <div class="cms-card-news__delete">
                <v-icon :size="30" color="#ffffff" class="fr-topbar__icon">mdi-delete-forever-outline</v-icon>
            </div>
        </div>
			
		
		
	</article>
</template>	

<script>
export default {
	props: {
		item: {
			type: Object,
		}
	}
}
</script>
<style lang="sass">
.cms-card-news__actions
    display: flex
.cms-card-news
    background: #fff
    margin-bottom: 15px
    padding: 5px 10px
    display: flex
    justify-content: space-between
    align-items: center
    border-left: 3px solid #00599b
    transition: 0.3s
.cms-card-news__header
    flex-grow: 0
    flex-basis: 700px
    overflow: hidden
.cms-card-news__title
    font-weight: 500
    color: #00599b
.cms-card-news:hover
    transform: translateY(-5px)
    box-shadow: 0 6px 18px rgba(14, 21, 47, 0.1), 0 -2px 6px rgba(14, 21, 47, 0.02)
    transition: 0.3s
.cms-card-news__edit
    background: #fff3cd
    padding: 3px  
    border-radius: 3px
    border: 1px solid #ffeeba
    margin-right: 10px
    cursor: pointer
.cms-card-news__edit:hover
    background: #ffeeba
.cms-card-news__delete
    background: #f8d7da
    padding: 3px
    border-radius: 3px
    border: 1px solid #f5c6cb
    cursor: pointer
.cms-card-news__delete:hover
    background: #f5c6cb    
</style>