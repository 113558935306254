<template>
<div>
  

<header class="section-header section-preview__header">
  <h3 class="section-header__title">Формы 5.1 -5.21</h3>
</header>

  <ul style="padding-left: 0" v-if="!get_data_headers_loading">
    <li v-for="form in fivesForms" :key="form.formID" class="mt-2">
<!--      <router-link :to="currentPath+'/'+form.id" style="text-decoration: none">-->
      <router-link
          :to="/* вкл/выкл форм  *//*  !form.accessDialog ? currentPath :*/ currentPath+'/'+form.formID"
          :class="/* вкл/выкл форм  */ /*!form.accessDialog ? 'kcp-from-link__disabled':*/ 'kcp-form-link'"
      >
        <span class="kcp-form-link__underline">{{form.tableName }}</span>
        <span v-if="form.accessDialog" class="kcp-form-link__underline-none">{{  ' (требуется ввод данных)' }}</span>
      </router-link>
      <!--style="text-wrap: normal; white-space: normal!important; display: flex; justify-content: start;max-height: 75px;"-->
<!--      </router-link>-->
    </li>
  </ul>
  <v-progress-linear
      v-else
      indeterminate
      color="#00599b"
  ></v-progress-linear>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
name: "f5RouterContent",
  data: () => ({

  }),
  computed:{
      currentPath(){
        return this.$route.path
      },
    ...mapGetters('tables', {get_data_headers: 'get_data_headers'}),
    ...mapGetters('tables', {get_data_headers_error: 'get_data_headers_error'}),
    ...mapGetters('tables', {get_data_headers_loading: 'get_data_headers_loading'}),
    fivesForms(){
        let arr=this.get_data_headers.filter(el => el.routerLink === this.currentPath)
        return arr.sort((a,b) => a.formID - b.formID)
    }
  },
  created(){
    if(this.get_data_headers.length === 0  && !this.get_data_headers_loading)
      this.getDataForHeaders()
  },
  methods: {
    ...mapActions('tables', {getDataForHeaders: 'getDataForHeaders'})
  }
}
</script>

<style lang="sass" >
@import "src/assets/styles/utils/colorsClasses"
.v-btn__content
  display: flex
  justify-content: flex-start
  max-height: 75px
  width: 100%
  white-space: normal
.kcp-from-link__disabled
    @extend .grey-light-color__important
.kcp-form-link
  .kcp-form-link__underline
    text-decoration: underline
  .kcp-form-link__underline-none
    color: #eb722e
    text-decoration: none
</style>