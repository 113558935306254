<template>
<div>
  <button class="button_border" @click="$router.go(-1)">Назад</button>
  <header class="section-header section-preview__header">
    <h2 class="section-header__title" v-if="!headersLoading & headers!= null">{{headers.tableName}}</h2>
  </header>
<DTable
    v-if="dataHeaders.length > 0"
    :data-headers="dataHeaders"
    name-dialog="universalDialog"
    :data-dialog-headers="dataHeadersForDialog"
    :dop-props-for-dialog="dopPropsForDialog"
    :errors-for-dialog="get_data_dialog_error"
    :flag-loading-for-dialog="get_data_dialog_loading"
    :allow-dop-dialogs="dataAccessDialog"
    :data-rows="dataRows"
    :data-for-dialog.sync="dataForDialog"
    :error-headers="headersErrors"
    :error-rows="get_data_table_error"
    :flag-loading-headers="headersLoading"
    :flag-loading-rows="get_data_table_loading"
    :id-page.sync="idPageDefault"
    :num-page.sync="numPageDefault"
    :request="null"
    :settings="null"
    :sort-mass.sync="sortBy"
    :action-access="!get_blockedEnterData && headers.accessDialog"
    v-on:send-request="sendRequest($event)"
    v-on:action-row="actionRow($event)"
/>
  <in-progress v-else/>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import DTable from "@/components/DTable_new/Dtable";
import InProgress from "@/components/InProgress";
export default {
name: "zayavkaKcpLicense",
  components:{
    InProgress,
  DTable
  },
  data: () => ({
    idPageDefault: 0,
    numPageDefault: 10,
    dataForDialog:null,
    sortBy: [],
    showAppealDialog: false,
  }),
  computed:{
    ...mapGetters('tables', {get_data_headers: 'get_data_headers'}),
    ...mapGetters('tables', {get_data_headers_error: 'get_data_headers_error'}),
    ...mapGetters('tables', {get_data_headers_loading: 'get_data_headers_loading'}),
    ...mapGetters('tables', {get_data_table: 'get_data_table'}),
    ...mapGetters('tables', {get_data_table_error: 'get_data_table_error'}),
    ...mapGetters('tables', {get_data_table_loading: 'get_data_table_loading'}),
    ...mapGetters('tables', {get_data_dialog: 'get_data_dialog'}),
    ...mapGetters('tables', {get_data_dialog_error: 'get_data_dialog_error'}),
    ...mapGetters('tables', {get_data_dialog_loading: 'get_data_dialog_loading'}),
    ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
    ...mapGetters('spr', {get_spr_data_org_error: 'get_spr_data_org_error'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    dopPropsForDialog() {
      return {
        org: {
          orgOwner: [this.get_replace_id_admin]
        },
        usl:{
          uslID:[1,2,3,6,9,12]
        },
        spec:{
          specFlKlasif: [5]
        }
      }
    },

    headers(){
      let bufArr = this.get_data_headers.filter(el => el.routerLink === '/kcp_contest/license')
      return (this.get_data_headers.length === 0 && this.get_data_headers_error ===null) ? null : bufArr[this.$scripts.TableJs.arraySearch(bufArr, 1, 'formID')]
    },
    dataHeaders(){
      return (this.headers != null) ? ( (Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headers, 'massive')) ? this.headers.headers.massive : [] ):[]
    },
    dataHeadersForDialog(){
      return (this.headers != null)? ( (Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headersForDialog, 'massive')) ? this.headers.headersForDialog.massive : [] ):[]
    },
    dataAccessDialog(){
      return (this.headers != null)? ( Object.hasOwnProperty.call(this.headers, 'headers')  ? this.headers.accessDialog : false ):false
    },
    headersErrors(){
      return this.get_data_headers.length === 0 ? this.get_data_one_header_error : this.get_data_headers_error
    },
    headersLoading(){
      return this.get_data_headers.length === 0 ? this.get_data_one_header_loading : this.get_data_headers_loading
    },
    dataRows(){
      // console.log("this.headers", this.headers, this.headers.tableObjectName, this.get_data_table)
      return {content :  ((this.headers != null) ? this.get_data_table[this.headers.tableObjectName] : []), totalElements: this.get_data_table.counter}
    },

  },
  created(){
    if(this.get_spr_data_org.data === undefined || this.get_spr_data_org.data.length=== 0
        || this.get_spr_data_org_error!== null || this.get_spr_data_org_loading)
      this.dataForSpr()
    if (this.get_data_headers.length === 0  && !this.get_data_headers_loading)
    {
      this.getDataForHeaders()
          .then(
              (() => {
              this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
              })
          ).catch(err => console.log("faiilllll" , err))
    }
    else {
      this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
    }
  },
  methods:{
    ...mapActions('spr', {dataForSpr:'dataForSpr'}),
    ...mapActions('tables', {getDataForHeaders: 'getDataForHeaders'}),
    ...mapActions('tables', {getDataForTable: 'getDataForTable'}),
    ...mapActions('tables', {fetchDataForTable: 'fetchDataForTable'}),
    ...mapActions('tables', {updateDateForTable: 'updateDateForTable'}),
    ...mapActions('tables', {deleteDataFromTable: 'deleteDataFromTable'}),

    actionRow(obj) {
      if (obj.typeAction === 'delete' && obj.id != null) {
        this.deleteDataFromTable({id: obj.id, link: this.headers.endPointForDelete})
            .then(()=>{
            this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
            })
      } else if (obj.typeAction === 'edit' && obj.id != null) {
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        this.dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
      }
    },
    sendRequest(data) {
      if (data.id == null) {
        // console.log("data.id =- null,", {data: {...data}, link: this.headers.endPointForInsert})
        this.fetchDataForTable(new Object({data: {...data}, link: this.headers.endPointForInsert}))
        .then(
            () => {
              this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
            }
        )
        this.dataForDialog=null
      } else {
        // console.log("data.id != null,", data)
        this.updateDateForTable(new Object({data: {...data}, link: this.headers.endPointForUpdate, id: data.id})).then( () => this.dataForDialog = null)
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                }
            )
        this.dataForDialog=null
      }
    }
  }
}
</script>

<style scoped>

</style>