<template>

		<main class="layout-article">

			<div class="layout-article__main">
				<div class="layout-article__content">


				<DetailArticle :item="document.data" schemaType="DigitalDocument">
					<template v-slot:afterContent>
						<iframe :src ="document.data.document_url" class="article__iframe"></iframe>
					</template>
				</DetailArticle>


				</div>
				<aside class="layout-article__after">
					<div class="layout-article__after_part">
						<router-link to="/" class="back-link">
							<span class="back-link__arrow">←</span>
							<span class="back-link__text">вернуться на главную</span>
						</router-link>
					</div>
				</aside>
			</div>	
		</main>




</template>
<script>
import request from "@/services/request";
import ModelDocumentDetail from "@/services/models/ModelDocumentDetail";
import DetailArticle from "@/components/article/DetailArticle";
export default {
	components: {
		DetailArticle,
	},
	data() {
		return {
			document: null,
		}
		
	},
	created() {
		this.getOneDocument();
	},
	methods: {
		async getOneDocument() {
			this.document = await request({
				endpoint: `/api/mainPage/getOneDocument/?id=${this.$route.params.id}`,
				method: 'GET',
				model: ModelDocumentDetail
			})
		},
	}

}
</script>
<style lang="sass">
</style>