var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.empty)?_c('div',[_vm._v(" Выберите значение по которому хотите посмотреть подробности ")]):(_vm.error !=null)?_c('div',[_vm._v(" Ошибка загрузки данных ")]):_c('div',[(_vm.indicators)?_c('HeaderMain',[(_vm.indicators.sprKCPGroup)?_vm._t("default",function(){return [_c('h1',{staticClass:"main-header__title-list"},[_vm._v(_vm._s(_vm.indicators.sprOrg.orgName))]),_c('p',{staticClass:"header-subtitle-indicators"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.indicators.sprKCPGroup.sprUsl.uslName))]),_vm._v(". "+_vm._s(_vm.indicators.sprKCPGroup.code)+" "+_vm._s(_vm.indicators.sprKCPGroup.name)+". "+_vm._s(_vm.foName))])]}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"indicators"},[_c('div',{staticClass:"b-indicator__wrapper"},[(_vm.indicators)?_c('card-indicator',{attrs:{"item":{
        title: 'Сводная оценка заявки',
        about: 'Сумма баллов по всем показателям',
        count: _vm.middleValue,
        dataFieldName: 'generalValue',
        max: _vm.indicators.ow,
        available: true,
        typeStyle: 'general',
        idUsl: _vm.idUsl
      },"row-of-values":_vm.indicator}}):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._l((_vm.bIndicatorsCalculated),function(b,index){return _c('div',{key:index,staticClass:"b-indicator__wrapper"},[(_vm.indicators)?_c('card-indicator',{attrs:{"item":b,"row-of-values":_vm.indicator}}):_vm._e()],1)})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }