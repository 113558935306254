<template>

    <v-row dense>
      <span v-if="error">
        Ошибка загрyзки {{error}}
      </span>
      <v-col class="d-flex flex-row align-center">
        <v-file-input
            :label="text"
            v-model="file"
        />
        <button
            class="button_border button_border "
            style="max-height: 28px;"
            @click="uploadFile(file)"
            :disabled="loading && !file"
        >Загрузить
          <v-progress-circular
              v-if="loading"
              indeterminate
              color="#eb722e"
              size="12"
              width="2"
          ></v-progress-circular>
        </button>
      </v-col>
    </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import request from "@/services/request";
import {mapGetters} from 'vuex'
export default {
  name: "CardDOwnloadZipDocumentAdmin",
  data() {
    return {
      file:null,
      loading: false,
      error: null
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    documentType:{
      type: [Number,String],
      default: 1
    }
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
  },
  methods: {
    async uploadFile() {
      this.loading =true
      // let fileInfo = {
      //   fileType: this.file.fileType,
      //   userID: this.authData.userID,
      //   documentType: Number.parseInt(this.documentType)
      // };
      let fd = new FormData();
      // console.log("1",this.file)
      fd.append('userID', this.authData.userID);
      fd.append('documentType', Number.parseInt(this.documentType));
      fd.append('file', this.file);
      // console.log("2",fd)
      let result = await request({
        endpoint: '/api/fileServer/parseAndUploadZip',
        method: 'post',
        body: fd
      });
      if (result.status === 200)
      {
        this.error=null
        this.loading = false
      }
      else
      {
        this.error=result.error
        this.loading = false
      }
      // this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
    },
  },
}
</script>

<style scoped>

</style>