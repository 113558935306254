<template>
<div class="reference-main">

  <HeaderMain
    title="Список организаций"
    subtitle="Справочник организаций"
  />
<div class="cms-panel">
    <div class="cms-panel-search">
        <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon">mdi-magnify</v-icon>
        <input type="search" class="cms-panel-search__input" v-model="searchOrg">
    </div>
    <BSelect :data="searchFields" v-model="selectedSearchField" class="cms-panel-search__sort" />
</div>

            <div class="sort-panel">
              <div class="card__info card__info_sort card__info__id" @click="sort('orgID')">ID &#8595;</div>
              <div class="card__info card__info_sort card__info__name" @click="sort('orgName')">Наименование &#8595;</div>
              <div class="card__info card__info_sort" @click="sort('inn')">ИНН &#8595;</div>
              <div class="card__info card__info_sort" @click="sort('orgAddress')">Адрес &#8595;</div>
              <div class="card__info card__info__action"></div>
              <div class="card__info card__info__action"></div>
            </div>

   <div v-if="resultSearch">
     <CardAdminOrg v-for="item in resultSearch.data" :key="item.key" :item="item" @authInOrg="onAuthInOrg($event)" />
     </div> 

              <v-progress-linear
                  indeterminate
                  color="#00599b"
                  v-if="get_spr_data_org_loading"
            ></v-progress-linear>
<p class="paginator-info"> Страница: {{ this.paginatorParams.selectedPage }}, Результатов на странице: {{ this.paginatorParams.itemsPerPage }}, Всего результатов: {{resultSearch.counter}} </p>
    <BPaginator
      v-if="resultSearch"
      :propDataCount="resultSearch.counter"
      v-model="paginatorParams"
    />

</div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import CardAdminOrg from "@/components/cards/custom/CardAdminOrg/CardAdminOrg";
import BSelect from "@/components/controls/BSelect/BSelect";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";
import HeaderMain from "@/components/headers/HeaderMain";
export default {
components: {
  CardAdminOrg,
  HeaderMain,
  BSelect,
  BPaginator
},
data() {
  return {
    searchOrg: '',
    searchFields: ['ID', 'Наименование', 'ИНН', 'Адрес'],
    selectedSearchField: 'ID',
   results: 0,  
   currentSort: 'orgID',
   currentSortDir: 'asc', 
   paginatorParams: {'selectedPage' : 1, 'itemsPerPage' : 10},
  }
},
mounted() {
  if (!this.get_spr_data_org_main.data) {
    this.dataForSpr();
  }
},
computed: {
  ...mapGetters('spr', {get_spr_data_org_main: 'get_spr_data_org_main'}),
  ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),

  selectedField() {
    if (this.selectedSearchField === 'ID') {
      return 'orgID'
    } else if (this.selectedSearchField === 'Наименование') {
      return 'orgName'
    } else if (this.selectedSearchField === 'ИНН') {
      return 'inn'
    } else if (this.selectedSearchField === 'Адрес') {
      return 'orgAddress'
    } else {
      return 'orgName'
    }
  },

  resultSearch () {
    let results;
    let array = [];
    if (this.get_spr_data_org_main.data) {
      array = this.get_spr_data_org_main.data.filter(el => el.orgID === el.orgOwner);
    }
    array = array.sort((a, b) => {
        let mod = 1
        if (this.currentSortDir === 'desc') mod = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * mod
        if (a[this.currentSort] > b[this.currentSort]) return 1 * mod
        return 0
      });
    let search = this.searchOrg;
    if (!search) {
      results = array.length;
       array = array.filter((row, index) => {
        let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
        let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
        if (index >= start && index < end) return true
      })

    return {'data': array, 'counter': results};
    }


    search = search.trim().toLowerCase()
    array = array.filter((item) => {
      if (String(item[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
        return item
      }
    })
    results = array.length;
    array = array.filter((row, index) => {
        let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
        let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
        if (index >= start && index < end) return true
      })
  return {'data': array, 'counter': results};
  },
},
    methods: {
      ...mapActions('spr', {dataForSpr: 'dataForSpr'}),
      ...mapActions('auth', {replaceIdAdmin: 'replaceIdAdmin'}),
      ...mapActions('auth', {login: 'login'}),
    // Pagination
    prevPage () {
      if (this.paginatorParams.selectedPage > 1) this.paginatorParams.selectedPage-=1
    },
    nextPage () {
      if ((this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage) < this.resultSearch.counter) this.paginatorParams.selectedPage+=1
    },
    sort (e) {
      if (e === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = e
    },
    onAuthInOrg(event) {
      this.replaceIdAdmin(event);
      this.$router.push('/private');
    }
    }

}

</script>


<style lang="sass" scoped>
@import "@/assets/styles/utils/variables"

.cms-panel-search
    display: flex
    align-items: center
    background: #fff
    border-radius: 5px
    padding: 0px 10px
    border: 1px solid #cdd1d4
.cms-panel
    margin-bottom: 20px
    display: flex
.cms-panel-search__sort
    background-color: #fff
    display: flex
    align-items: center
    border-radius: 5px
    padding: 0px 10px
    border: 1px solid #cdd1d4
    margin-left: 10px
.sort-panel
    display: flex
    width: 100%
    justify-content: space-between
.reference-main
  width: 100%
.paginator-info
    text-align: center
    color: #00599b
.sort-panel
    font-weight: 600
    color: #00599b
    margin-bottom: 10px
.card__info_sort
    cursor: pointer
.card__info.card__info__action .button_border
    text-align: center
    max-height: 35px
.cms-panel-search__sort.select
    min-width: 170px
.cms-panel-search__input
    min-width: 500px        
</style>