<template>
<v-dialog v-model="show" v-if="show" scrollable>
<v-card>
  <v-card-title>Сообщение об ошибке (ошибках) в данных формы</v-card-title>
  <v-card-text>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-textarea
          label="Описание ошибки (ошибок)"
          v-model="textAppeal"
          class="mt-5"
          auto-grow
          outlined
          color="#001520"
          rows="3"
          row-height="25"
          shaped
      ></v-textarea>
      <v-file-input
          v-model="fileOne"
          :rules="rulesOne"
          placeholder="Загрузите файл с исправленными данными (xls)"
          accept=".xlsm, .xlsx, .xls"
          label="Первый файл"
          prepend-icon="mdi-file-chart-outline"
      >
        <template v-slot:selection="{ text }">
          <v-chip
              small
              label
              color="primary"
          >
         {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <v-file-input
          v-model="fileTwo"
          :rules="rulesTwo"
          placeholder="Загрузите сканы подтверждающих документов (pdf)"
          accept=".pdf"
          label="Второй файл"
          prepend-icon="mdi-file-chart-outline"
      >
        <template v-slot:selection="{ text }">
          <v-chip
              small
              label
              color="primary"
          >
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <div v-if="authData.role.id === 1">
        <div v-if="loadedFiles">
          <CardFile v-for="item in files" :key="item.id" :item="item" @uploadFile="uploadFile($event)" @replaceFile="replaceFile($event)" />
        </div>
      </div>
      <div v-else>
        <div v-if="loadedFiles">
          <CardFile v-for="item in files" :key="item.id" :item="item"/>
        </div>
      </div>
    </v-form>
    <p>
      Примечание: в качестве подтверждающих документов могут выступать формы федерального статистического наблюдения, формы бухгалтерской (финансовой) отчетности, формы ведомственной отчетности, иные документально оформленные сведения
    </p>
  </v-card-text>
  <v-card-actions>
    <v-btn class="text-none" @click="sendErrorRequestf5">Отправить</v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
</template>

<script>
import CardFile from "@/components/cards/custom/CardFile";
import {mapGetters} from 'vuex'
import request from "@/services/request";
export default {
name: "appealF5Dialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    nameTable:{
      type: String,
      default: ''
    }
  },
  components: {
    CardFile,
  },
  data: ()=> ({
    valid: false,
    textAppeal: '',
    fileOne:null,
    fileTwo: null,
    loadedFiles: false,
    rulesOne: [
      value => !value || value.size < 20000000 || 'Размер файла не должен превышать 20 МБ!',
    ],
    rulesTwo: [
      value => !value || value.size < 20000000 || 'Размер файла не должен превышать 20 МБ!',
    ],
  }),
  computed:{
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    show: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:show-dialog',  value)
      }
    }
  },
  methods: {
    async getDocuments() {
      this.loadedFiles = false;
      const res = await request({
        endpoint: `/api/fileServer/getAllOrgMainFilesName?sprOrgID=${this.get_replace_id_admin}&userProfile=${this.authData.userProfile}`,
        method: 'GET',
      });
      res.data.forEach(el => {
        let overlapIndex = this.files.findIndex(file => file.fileType === el.fileType);
        if (overlapIndex !== -1) {
          this.files[overlapIndex] = Object.assign(this.files[overlapIndex], el);
        }
      });
      this.loadedFiles = true;
    },
    async uploadFile(event) {
      let fileInfo = {
        sprOrgID: this.authData.sprOrgID,
        fileType: event.fileType,
        userID: this.authData.userID,
      };
      let fd = new FormData();
      fd.append('fileUploadDTO', JSON.stringify(fileInfo));
      fd.append('file', event.file);
      await request({
        endpoint: '/api/fileServer/uploadOrgMainFiles',
        method: 'POST',
        body: fd,
      });
      await this.getDocuments();
      // this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});
    },

    async replaceFile(event) {
      let fileInfo = {
        sprOrgID: this.authData.sprOrgID,
        fileType: event.fileType,
        userID: this.authData.userID,
        id: event.id,
      };
      let fd = new FormData();
      fd.append('fileUploadDTO', JSON.stringify(fileInfo));
      fd.append('file', event.file);
      await request({
        endpoint: '/api/fileServer/uploadOrgMainFiles',
        method: 'POST',
        body: fd,
      });
      await this.getDocuments();
      // this.fetchCheckFilled({'sprOrgID': this.get_replace_id_admin, 'userProfile': this.authData.userProfile});

    },
    sendErrorRequestf5() {
      let request = {
        filePDF: this.fileOne,
        fileXLS: this.fileTwo,
        errorText: this.textAppeal,
      }
      if (this.fileOne && this.fileTwo && this.textAppeal) {
        this.$emit('sendErrorApplication', request);
      } 
    }
  },
}
</script>

<style scoped>

</style>