    <template>
    <div class="info-card">
            <div class="info-card__field" v-for="(value, name, index) in item" :key="index">
                <div class="info-card__name">
                   {{name}}:
                </div>
                <div class="info-card__cell">
                    {{value}}
                </div>
            </div>
    </div>
    </template>


    <script>
        export default {
            props: {
                item: {
                    type: Object,
                }
            }
        }
    </script>