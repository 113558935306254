<template>
<div class="mt-5">
  <button
      v-if="headers != null && (!headers.accessDialog || headers.formId===9)"
      class="button_border button_border_important border_margin_right"
      @click="showAppealDialog = !showAppealDialog">
    Сообщение об ошибке данных
  </button>
  <appeal-f5-dialog
      :show-dialog.sync="showAppealDialog"
      @sendErrorApplication ="onSendErrorApplication($event)"
  />


  <div v-for="(form, index) in allFormErrors" :key="index">
    <component :is="isAlert(form)">
       {{ form.formErrorsStatus === 'NOT_SET' ? 'Заявка еще не обработана!' : '' }}
       {{ form.formErrorsStatus === 'ACCEPTED' ? 'Изменения внесены!' : '' }}
       {{ form.formErrorsStatus === 'REJECTED' ? 'Изменения отклонены!' : '' }}
       {{form.errorAnswer ? 'Комментарий: ' + form.errorAnswer : ''}}
    </component>
  </div>


  <header class="section-header section-preview__header">
  <h2 class="section-header__title" v-if="!headersLoading & headers!= null">{{headers.tableName}}</h2>
  </header>
  
  <p v-if="!headersLoading & headers!= null" v-html="headers.descriptionTable"></p>
  <d-table
      v-if="dataHeaders.length > 0"
      :data-headers="dataHeaders"
      name-dialog="universalDialog"
      :data-dialog-headers="dataHeadersForDialog"
      :dop-props-for-dialog="dopPropsForDialog"
      :errors-for-dialog="get_data_dialog_error"
      :flag-loading-for-dialog="get_data_dialog_loading"
      :allow-dop-dialogs="dataAccessDialog"
      :data-rows="dataRows"
      :data-for-dialog.sync="dataForDialog"
      :error-headers="headersErrors"
      :error-rows="get_data_table_error"
      :flag-loading-headers="headersLoading"
      :flag-loading-rows="get_data_table_loading"
      :id-page.sync="idPageDefault"
      :num-page.sync="numPageDefault"
      :request="null"
      :settings="null"
      :sort-mass.sync="sortBy"
      :action-access="!get_blockedEnterData && headers.accessDialog"
      v-on:send-request="sendRequest($event)"
      v-on:action-row="actionRow($event)"
  />
  <in-progress v-else></in-progress>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import DTable from "@/components/DTable_new/Dtable";
import appealF5Dialog from "@/components/Dialogs/appealF5Dialog";
import InProgress from "@/components/InProgress";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertError from "@/components/alerts/AlertError";
import AlertPrimary from "@/components/alerts/AlertPrimary";
import request from "@/services/request";
export default {
  name: "f5_1",
  components:{
    InProgress,
    AlertSuccess,
    AlertError,
    AlertPrimary,
    // eslint-disable-next-line vue/no-unused-components
    DTable,appealF5Dialog
  },
  data: () => ({
    idPageDefault: 0,
    numPageDefault: 10,
    dataForDialog:null,
    sortBy: [],
    showAppealDialog: false,
    sendErrorFormOk: false,
    allFormErrors: null,
  }),
  computed:{
    ...mapGetters('spr', {get_spr_data_org: 'get_spr_data_org'}),
    ...mapGetters('spr', {get_spr_data_org_error: 'get_spr_data_org_error'}),
    ...mapGetters('spr', {get_spr_data_org_loading: 'get_spr_data_org_loading'}),
    ...mapGetters('tables', {get_data_headers: 'get_data_headers'}),
    ...mapGetters('tables', {get_data_headers_error: 'get_data_headers_error'}),
    ...mapGetters('tables', {get_data_headers_loading: 'get_data_headers_loading'}),
    ...mapGetters('tables', {get_data_one_header: 'get_data_one_header'}),
    ...mapGetters('tables', {get_data_one_header_error: 'get_data_one_header_error'}),
    ...mapGetters('tables', {get_data_one_header_loading: 'get_data_one_header_loading'}),
    ...mapGetters('tables', {get_data_table: 'get_data_table'}),
    ...mapGetters('tables', {get_data_table_error: 'get_data_table_error'}),
    ...mapGetters('tables', {get_data_table_loading: 'get_data_table_loading'}),
    ...mapGetters('tables', {get_data_dialog: 'get_data_dialog'}),
    ...mapGetters('tables', {get_data_dialog_error: 'get_data_dialog_error'}),
    ...mapGetters('tables', {get_data_dialog_loading: 'get_data_dialog_loading'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {authData: 'authData'}),
    dopPropsForDialog() {
      return {
        org: {
          orgOwner: [this.get_replace_id_admin]
        },
        fo: {
          foID: [1, 2, 3]
        },
        usl: {
          uslID: [1, 2, 3, 6, 9, 12]
        },
        spec: {
          specFlKlasif: [5]
        }
      }
    },

    currentPath(){
      return this.$route.path
    },
    headers(){
      let bufArr = this.get_data_headers.filter(el => el.routerLink === '/kcp_contest/indicators')
      return (this.get_data_headers.length === 0 && this.get_data_headers_error ===null) ? null :
          bufArr[this.$scripts.TableJs.arraySearch(bufArr, parseInt(this.$route.params.id_form), 'formID')]
          // .filter(el => {(el.dataField !== 'action') || !this.get_blockedEnterData &&  bufArr[this.$scripts.TableJs.arraySearch(bufArr, parseInt(this.$route.params.id_form), 'formID')].accessDialog})
      // return (this.get_data_headers.length === 0 && this.get_data_headers_error ===null) ? null : this.get_data_headers[this.$scripts.TableJs.arraySearch(this.get_data_headers.filter(el => el.routerLink === '/kcp_contest/indicators'), parseInt(this.$route.params.id_form), 'formID')]
    },
    dataHeaders(){
      return (this.headers != null) ? ( (Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headers, 'massive')) ? this.headers.headers.massive : [] ):[]
    },
    dataHeadersForDialog(){
      return (this.headers != null)? ( (Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headersForDialog, 'massive')) ? this.headers.headersForDialog.massive : [] ):[]
    },
    dataAccessDialog(){
      return (this.headers != null)? ( Object.hasOwnProperty.call(this.headers, 'headers')  ? this.headers.accessDialog : false ):false
    },
    headersErrors(){
      return this.get_data_headers.length === 0 ? this.get_data_one_header_error : this.get_data_headers_error
    },
    headersLoading(){
      return this.get_data_headers.length === 0 ? this.get_data_one_header_loading : this.get_data_headers_loading
    },
    dataRows(){
      // console.log("this.headers", this.headers, this.headers.tableObjectName, this.get_data_table)
      return {content :  ((this.headers != null) ? this.get_data_table[this.headers.tableObjectName] : []), totalElements: this.get_data_table.counter}
    }
  },
  created(){
    this.getFormErrors();
    if (this.get_spr_data_org.data === undefined || this.get_spr_data_org.data.length === 0
        || this.get_spr_data_org_error !== null || this.get_spr_data_org_loading)
      this.dataForSpr()
   if (this.get_data_headers.length === 0  && !this.get_data_headers_loading)
   {
     this.getDataForHeaders()
     .then(
         (() => {
           this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
         })
     ).catch(err => console.log("faiilllll" , err))
   }
   else {
     this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
   }
  },
  methods: {
    ...mapActions('spr', {dataForSpr: 'dataForSpr'}),
    ...mapActions('tables', {getDataForHeaders: 'getDataForHeaders'}),
    ...mapActions('tables', {getDataOneHeader: 'getDataOneHeader'}),
    ...mapActions('tables', {getDataForTable: 'getDataForTable'}),
    ...mapActions('tables', {fetchDataForTable: 'fetchDataForTable'}),
    ...mapActions('tables', {updateDateForTable: 'updateDateForTable'}),
    ...mapActions('tables', {deleteDataFromTable: 'deleteDataFromTable'}),
    actionRow(obj) {
      if (obj.typeAction === 'delete' && obj.id != null) {
        this.deleteDataFromTable({id: obj.id, link: this.headers.endPointForDelete})
            .then(()=>{
              this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
            })

      } else if (obj.typeAction === 'edit' && obj.id != null) {
        let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj.id, 'id')
        this.dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
        // console.log("this.dataForDialog",this.dataForDialog)
      }
    },
    sendRequest(data) {
      if (data.id == null) {
        // console.log("data.id =- null,", {data: {...data}, link: this.headers.endPointForInsert})
        this.fetchDataForTable(new Object({data: {...data}, link: this.headers.endPointForInsert}))
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                  this.dataForDialog=null
                }
            )
        this.dataForDialog=null
      } else {
        this.updateDateForTable(new Object({data: {...data}, link: this.headers.endPointForUpdate, id: data.id})).then( () => this.dataForDialog = null)
            .then(
                () => {
                  this.getDataForTable({link: this.headers.endPointForSelect, allValues: true, pageNumber: this.idPageDefault, recordCount: this.numPageDefault})
                  this.dataForDialog=null
                }
            )
        this.dataForDialog=null
      }
    },
    async onSendErrorApplication(event) {
      let fd = new FormData();
      let formError = {
        sprOrgID: this.get_replace_id_admin,
        formID: 'f5',
        errorText: event.errorText,
        userID: this.authData.userID,
      }

      fd.append('formError', JSON.stringify(formError));
      fd.append('filePDF', event.filePDF);
      fd.append('fileXLS', event.fileXLS);

      const responce = await request({
            endpoint: '/api/userProfile/sendErrorForm',
            method: 'POST',
            body: fd,
      });
      if (responce.status === 200)
            this.sendErrorFormOk = true;
            this.showAppealDialog = false;
      },
    async getFormErrors() {
      let response = await request({
                endpoint: `/api/userProfile/getAllFormErrorsByOrgID?sprOrgID=${this.get_replace_id_admin}`,
                method: 'GET',
            });
      this.allFormErrors = response.data.formErrors;
    },
    isAlert(form) {
      if (form.formErrorsStatus === 'NOT_SET') {
        return 'AlertPrimary';
      } else if (form.formErrorsStatus === 'REJECTED') {
        return 'AlertError';
      } else if (form.formErrorsStatus === 'ACCEPTED') {
        return 'AlertSuccess';
      }
    }


  }
}
</script>

<style scoped>

</style>