<template>
    <div>
      <v-divider class="mt-4"/>
        <h4 class="score-subtitle">1. Расчет корректирующего коэффициента</h4>
        <h5 class="score-subsubtitle">
            Исходные данные:
        </h5>
        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.3.</p>
            <p class="calculation__about-text">
                Численность обучающихся за счет всех источников финансирования, человек
            </p>
        </div>
        <table class="indicators__table">
            <thead class="b22-thead">
            <tr class="">
                <th class="" rowspan="2">№ п/г</th>
                <th class="">Уровень</th>
                <th class="">УГСН Код</th>
                <th class="">УГСН Наименование</th>
                <th class="">Форма обучения</th>
                <th class="">Численность<br> обучающихся <br> 2020 год</th>
                <th class="">из них<br> инвалидов  <br> и лиц с ОВЗ</th>
                <th rowspan="2"></th>
                <th class="" colspan="5" style="vertical-align: bottom; text-align: center">Расчет исходных значений</th>
            </tr>
            <tr class="">
                <th class="">1</th>
                <th class="">2</th>
                <th class="">3</th>
                <th class="">4</th>
                <th class="">5</th>
                <th class="">6</th>
                <th class="">переменная</th>
                <th class="">значение</th>
                <th></th>
              <th class="">переменная</th>
              <th class="">значение</th>
            </tr>
            </thead>
            <tbody class="b22-tbody">
            <tr>
                <td class="">1.</td>
                <td class="">  {{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                <td class="">
                    {{ rowOfValues.data.sprKCPGroup.code }}
                </td>
                <td class=""> {{ rowOfValues.data.sprKCPGroup.name }}</td>
                <td class="">очная</td>
                <td class="" v-html="checkData(rowOfValues.data.pno)"></td>
                <td class="" v-html="checkData(rowOfValues.data.dio)"></td>
                <td rowspan="1" style="vertical-align: middle">
                  =>
                </td>
                <td class="" rowspan="1" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">PNO</span> = </span></td>
                <td class="" rowspan="1" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.pno)"> </td>
                <td></td>
              <td class="" rowspan="1" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">DIO</span> = </span></td>
              <td class="" rowspan="1" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.dio)"> </td>
            </tr>
            <tr>
              <td class="">2.</td>
              <td class="">  {{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code }}
              </td>
              <td class=""> {{ rowOfValues.data.sprKCPGroup.name }}</td>
              <td class="">очно-заочная</td>
              <td class="" v-html="checkData(rowOfValues.data.pnv)"></td>
              <td class="" v-html="checkData(rowOfValues.data.div)"></td>
              <td rowspan="1" style="vertical-align: middle">
                =>
              </td>
              <td class="" rowspan="1" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">PNV</span> = </span></td>
              <td class="" rowspan="1" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.pnv)"> </td>
              <td></td>
              <td class="" rowspan="1" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">DIV</span> = </span></td>
              <td class="" rowspan="1" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.div)"> </td>
            </tr>
            <tr>
              <td class="">3.</td>
              <td class="">  {{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
              <td class="">
                {{ rowOfValues.data.sprKCPGroup.code }}
              </td>
              <td class=""> {{ rowOfValues.data.sprKCPGroup.name }}</td>
              <td class="">заочная</td>
              <td class="" v-html="checkData(rowOfValues.data.pnz)"></td>
              <td class="" v-html="checkData(rowOfValues.data.diz)"></td>
              <td rowspan="1" style="vertical-align: middle">
                =>
              </td>
              <td class="" rowspan="1" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">PNZ</span> = </span></td>
              <td class="" rowspan="1" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.pnz)"> </td>
              <td></td>
              <td class="" rowspan="1" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">DIZ</span> = </span></td>
              <td class="" rowspan="1" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.diz)"> </td>
            </tr>
            </tbody>
        </table>

        <section class="section-score">
            <h5 class="score-subsubtitle">
                Расчет приведенных контингентов  по данным 2020 года:
            </h5>
            <div class="calculation">
                <div class="calculation__row">
                    <div class="calculation__cell">DI</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular"> 1.0 ×</span> DIO</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                  <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> DIV</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                  <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> DIZ</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                  <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> <span v-html="checkData(rowOfValues.data.dio)"/></div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                  <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> <span v-html="checkData(rowOfValues.data.div)"/></div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">+</div>
                  <div class="calculation__cell"> <span class="font-weight-regular">0.1 ×</span> <span v-html="checkData(rowOfValues.data.diz)"/></div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span v-html="checkData(rowOfValues.data.di)"/></div>
                </div>
                <div class="calculation__row">
                    <div class="calculation__cell">PN</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                  <div class="calculation__cell"><span class="font-weight-regular">1.0 × </span>PNO</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> PNV</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> PNZ</div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span class="font-weight-regular">1.0 ×</span> <span v-html="checkData(rowOfValues.data.pno)"/></div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.25 × </span><span v-html="checkData(rowOfValues.data.pnv)"/></div>
                    <div class="calculation__cell calculation__cell_no-grow font-weight-regular">+</div>
                    <div class="calculation__cell"><span class="font-weight-regular">0.1 ×</span> <span v-html="checkData(rowOfValues.data.pnz)"/></div>
                    <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
                    <div class="calculation__cell"><span v-html="checkData(rowOfValues.data.pn)"/></div>
                </div>
            </div>

            <div class="calculation__about">
                <p class="calculation__about-text">
                    DI - приведенный контингент инвалидов и лиц с ограниченными возможностями здоровья
                </p>
                <p class="calculation__about-text">
                    PN -  приведенный контингент обучающихся
                </p>
            </div>
        </section>

        <!-- calculation -->

        <!-- formula -->
        <section class="section-score">
            <h5 class="score-subsubtitle">Расчет значения корректирующего коэффициента:</h5>
            <div class="formula">
              <div class="formula-full"><span>KI<span class="font-size-little">0</span> =</span></div>
              <div class="formula-full font-weight-regular">
                1 + 0.5 ×
              </div>
                <div class="formula-full">
                  <div class="formula-half formula-half_underline  px-3"> DI / PN <span class="font-weight-regular"> - 0.2 </span></div>
                  <div class="formula-half font-weight-regular" style="margin-left: 35%">0.8</div>
                </div>
                <div class="formula-full">=</div>
              <div class="formula-full font-weight-regular">
                1 + 0.5 ×
              </div>
              <div class="formula-full">
                <div class="formula-half formula-half_underline  px-3"> <span v-html="checkData(rowOfValues.data.di)"/> / <span v-html="checkData(rowOfValues.data.pn)"/> <span class="font-weight-regular"> - 0.2 </span></div>
                <div class="formula-half font-weight-regular" style="margin-left: 35%">0.8</div>
              </div>
              <div class="formula-full ml-0">;</div>
              <div class="formula-full"><span>KI<span class="font-size-little"></span> =</span></div>
              <div class="formula-full"><span v-html="checkData(rowOfValues.data.ki)"/></div>
            </div>
            <!-- formula -->
            <div class="score-about">
                <p>
                  Значение корректирующего коэффициента не может быть менее 1
                </p>
            </div>
        </section>
      <h4 class="score-subtitle">2. Расчет сводной оценки заявки</h4>
      <div class="calculation" >
        <div class="calculation__row mt-2">
          <div class="calculation__cell">{{ item.title }}</div>
          <div class="calculation__cell calculation__cell_no-grow">=</div>
          <div class="calculation__cell"><div >сумма баллов по показателям</div></div>
          <div class="calculation__cell calculation__cell_no-grow">×</div>
          <div class="calculation__cell">KI</div>
          <div class="calculation__cell calculation__cell_no-grow">=</div>
          <div class="calculation__cell"><span v-html="checkData(rowOfValuesOs)"/></div>
          <div class="calculation__cell calculation__cell_no-grow">×</div>
          <div class="calculation__cell"><span v-html="checkData(rowOfValues.data.ki)"/></div>
          <div class="calculation__cell calculation__cell_no-grow"> =</div>
          <div class="calculation__cell"><span v-html="checkData(item.count)"/></div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "b22",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
        computed: {
          rowOfValuesOs() {
            let valueOs;
            switch(this.$route.query.fo) {
              case '1':
                valueOs = this.rowOfValues.data.os1;
              break;
              case '2':
                valueOs = this.rowOfValues.data.os2;
              break;
              case '3':
                valueOs = this.rowOfValues.data.os3;
              break;
            }
            return valueOs;
          }
        },
      methods: {
          checkData(value) {
            return value;
              // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',null,false, 'color: #C6C8CB;')
          }
      },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

    .b22-thead
        th
            background-color: inherit
            border: 1px solid #546e7a
            padding: 5px

            &:nth-child(8)
                border: 0
                min-width: 20px

            &:nth-child(9)
                border: 0

            &:nth-child(10)
                border: 0
            &:nth-child(11)
                border: 0

        tr
            &:last-child
                th
                    &:nth-child(7)
                        border: 0
                        text-align: right
                        padding-right: 10px
                        border-bottom: 1px solid #546e7a

                    &:nth-child(8)
                        border: 0
                        padding-left: 10px
                        border-bottom: 1px solid #546e7a
                    &:nth-child(10)
                        border: 0
                        text-align: right
                        padding-right: 10px
                        border-bottom: 1px solid #546e7a

                    &:nth-child(11)
                       border: 0
                       padding-left: 10px
                       border-bottom: 1px solid #546e7a

    .b22-tbody
        td
            text-align: center
            border: 1px solid #546e7a
            padding: 3px

            &:nth-child(8)
                border: 0
                min-width: 20px

            &:nth-child(9)
                border: 0
                padding-right: 5px
                text-align: right

            &:nth-child(10)
                border: 0
                padding-left: 2px
                text-align: left
            &:nth-child(11)
                border: 0
                min-width: 20px

            &:nth-child(12)
              border: 0
              padding-right: 5px
              text-align: right

            &:nth-child(13)
              border: 0
              padding-left: 2px
              text-align: left
</style>