<template>
<div>
Просмотр заявки организации

</div>
</template>


<script>

export default {
	components: {

	},
	data() {
			return {

		}
	},
}

</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"

</style>