<template>
<div>

 <Alert alertType="warning" v-for="(app, index) in applications.create" :key="index">
        <template v-slot:icon>
		<v-icon :size="30" color="#856404">mdi-map-marker-plus</v-icon>
	</template>
	<template v-slot:default>
		Заявка на добавление филиала на модерации: {{app.orgName}}
        <button class="button_mini" @click="selectApplicationToShow(app)">подробно о заявке</button>
        <button class="button_mini" @click="deleteApplication(app.applicationID)">отменить или создать новую</button>
	</template>
    </Alert> 



    <Modal v-on:input="modalOpen = !modalOpen" v-if="modalOpen" title="Подробно о заявке">
       <CardInfo :data="applicationToShow"/>
    </Modal>




<div class="branch-offices">
    <div class="branch-offices-table" v-if="branchOffices">
        <div class="branch-offices-table__th">
            <div class="branch-offices-table__td">Название</div>
            <div class="branch-offices-table__td">Адрес</div>
            <div class="branch-offices-table__td">КПП</div>
            <div class="branch-offices-table__td">Действия</div>
        </div>
        <div v-for="(office, index) in branchOffices" :key="index" class="branch-offices-table__tr">
            <div class="branch-offices-table__td">{{office.orgName}}</div>
            <div class="branch-offices-table__td">{{office.orgAddress}}</div>
            <div class="branch-offices-table__td">{{office.kpp}}</div>
            <div class="branch-offices-table__td">
                <div v-if="!applications.delete.find(el => el.orgID === office.orgID) && !applications.update.find(el => el.orgID === office.orgID) ">
                <!-- <button class="branch-offices-table__action">Изменить</button> -->

                <button v-if="authData.role.id == 1 && !get_blockedAll" class="branch-offices-table__action" @click="openDeleteBranchOffice(office.orgID)">Удалить</button>
                <button v-if="authData.role.id !== 1 && !get_blockedAll" class="branch-offices-table__action">Удалить</button>
                
                <button v-if="authData.role.id == 1 && !get_blockedAll" class="branch-offices-table__action" @click="editBranchOffice(office.orgID)">Редактировать</button>
                <button v-if="authData.role.id !== 1 && !get_blockedAll" class="branch-offices-table__action">Редактировать</button>
                

                 <Modal v-on:input="closeDeleteBranchOffice" v-if="modalDeleteOpen" title="Удаление филиала">
                    <v-file-input
                    label="Документ"
                    v-model="fileToDelete"
                    />
                <button type="submit" class="button" @click.prevent="createApplicationToDelete()">Заявка на удаление</button>

                </Modal>

                 <Modal v-on:input="closeEditBranchOffice" v-if="modalEditOpen" title="Редактировать филиал">

                    <v-progress-linear
                    v-if="!editedBranchOffice"
                      indeterminate
                      color="#00599b"
                    ></v-progress-linear>
                        <FormKcpContestInfoOrganizationSub v-else v-model="editedBranchOffice" v-on:input="requestUpdateBranchOffice" />
                </Modal>

                </div>
                <div v-else>
                <span v-if="applications.delete.find(el => el.orgID === office.orgID)">Подана заявка на удаление</span>
                <span v-if="applications.update.find(el => el.orgID === office.orgID)">Подана заявка на изменение</span>
                <span></span>
                </div>
            </div>
        </div>
    </div>

    <FormKcpContestInfoBranchOffice v-if="authData.role.id === 1 && !get_blockedAll" v-model="newBranchOffice" v-on:input="addBranchOffice" />
    <FormKcpContestInfoBranchOffice v-if="authData.role.id !== 1 && !get_blockedAll" v-model="newBranchOffice"/>
    

</div>

</div>
</template>


<script>
import {mapGetters} from 'vuex';
import Alert from '@/components/alerts/Alert';
import request from "@/services/request";
import Modal from '@/components/modals/Modal';
import CardInfo from '@/components/cards/custom/CardInfo/CardInfo';
import FormKcpContestInfoBranchOffice from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoBranchOffice';
import FormKcpContestInfoOrganizationSub from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoOrganizationSub';
export default {
    components: {
        FormKcpContestInfoBranchOffice,
        FormKcpContestInfoOrganizationSub,
        Alert,
        Modal,
        CardInfo
    },
    data() {
        return {
            isWaitingForConfirmation: false,
            applicationToShow: null,
            branchOffices: [],
            fileToDelete: null,
            branchOfficeToDelete: null,
            newBranchOffice: {
                orgName: '',
                adress: '',
                kpp: '',
                file: null,
            },
            modalOpen: false,
            modalEditOpen: false,
            editedBranchOffice: null,
            initialEditedBranchOffice: null,
            modalDeleteOpen: false,
            applications: {
                create: [],
                delete: [],
                update: [],
            },
        }
    },
    computed: {
        ...mapGetters('auth', {authData: 'authData'}),
        ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
        ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),

    },
    mounted() {
      this.getBranchOffices();
      this.getAllApplicationsByUser();
    },
    methods: {

        async requestUpdateBranchOffice() {
            let objToSend = {
                        helpfulNote: '',
                        orgFullCode: this.initialEditedBranchOffice.orgFullCode,
                        orgOpCode: this.initialEditedBranchOffice.orgOpCode,
                        orgFullName: this.editedBranchOffice.orgFullName,
                        orgName: this.editedBranchOffice.orgName,
                        orgShortName: this.editedBranchOffice.orgShortName,
                        orgAddress: this.editedBranchOffice.orgAddress,
                        inn: this.initialEditedBranchOffice.inn,
                        kpp: this.editedBranchOffice.kpp,
                        orgID: this.initialEditedBranchOffice.orgID,
                        applicationTypeMethod: 'UPDATE',
                        toChangeCase: 'SUB_ORG',
                        userID: this.authData.userID,
                        sprOrgOwnerID: this.authData.sprOrgID,

                };
            let fd = new FormData();
            fd.append('applicationData', JSON.stringify(objToSend));
            fd.append('file', this.editedBranchOffice.file);
                 
            const res = await request({
            endpoint: '/api/userProfile/createApplicationRequest',
            method: 'POST',
            body: fd,
            });

            if (res.status === 200) {
                this.getBranchOffices();
                this.getAllApplicationsByUser();
                this.modalEditOpen = false;
            }
            
        },

        closeDeleteBranchOffice() {
            this.modalDeleteOpen = false;
            this.branchOfficeToDelete = null;
        },

        selectApplicationToShow(app) {
            this.modalOpen = !this.modalOpen;
            const {orgName, orgAddress, kpp} = app;
            this.applicationToShow = {
                Название: orgName,
                Адрес: orgAddress,
                КПП: kpp,
            };
        },
        async getAllApplicationsByUser() {
            let requestapp = await request({
            endpoint: `/api/userProfile/getApplicationsByUserID?userID=${this.authData.userID}`,
            method: 'GET',

            });
            let applicationActive = requestapp.data.filter(el => el.applicationStatus === 'ACTIVE' && el.toChangeCase === 'SUB_ORG');
            this.applications.create = applicationActive.filter(el => el.applicationTypeMethod === 'CREATE');
            this.applications.delete = applicationActive.filter(el => el.applicationTypeMethod === 'DELETE');
            this.applications.update = applicationActive.filter(el => el.applicationTypeMethod === 'UPDATE');
             
        },
        async deleteApplication(id) {
            await request({
            endpoint: `/api/userProfile/deleteApplication?applicationID=${id}`,
            method: 'GET',
            });
            this.getAllApplicationsByUser();
        },

        async getBranchOffice(id) {
            let organization = await request({
            endpoint: `/api/kcpCollectionReturn/getOrgByID?sprOrgID=${id}&userProfile=${this.authData.userProfile}`,
            method: 'GET',
            });
            organization = organization.data;
            for (let key in organization) {
                if (organization[key] === null) {
                    organization[key] = '';
                }
            }
            this.editedBranchOffice = {...organization};
            this.initialEditedBranchOffice = {...organization};

        },

        editBranchOffice(id) {
            this.modalEditOpen = !this.modalEditOpen;
            this.getBranchOffice(id);

        },

		async getBranchOffices() {
			let branchOffices = await request({
            endpoint: `/api/kcpCollectionReturn/getSubOrgs?sprOrgID=${this.get_replace_id_admin}`,
			method: 'GET',
            });
            branchOffices = branchOffices.data;

            let branchOfficesTotal = branchOffices.filter(el => el.orgStatus == 1);

            this.branchOffices = branchOfficesTotal;
        },

        closeEditBranchOffice() {
            this.modalEditOpen = false;
            this.editedBranchOffice = null;
            this.initialEditedBranchOffice = null;
        },




        openDeleteBranchOffice(id) {
            this.branchOfficeToDelete = id;
            this.modalDeleteOpen = !this.modalDeleteOpen;
        },


        async createApplicationToDelete() {

            let objToSend = {
                        applicationTypeMethod: 'DELETE',
                        toChangeCase: 'SUB_ORG',
                        userID: this.authData.userID,
                        orgID: this.branchOfficeToDelete,
                        sprOrgOwnerID: this.authData.sprOrgID,
                        orgName: this.branchOffices.find(el => el.orgID === this.branchOfficeToDelete).orgName,
                };
            let fd = new FormData();
            fd.append('applicationData', JSON.stringify(objToSend));
            fd.append('file', this.fileToDelete);

            const res = await request({
            endpoint: '/api/userProfile/createApplicationRequest',
            method: 'POST',
            body: fd,
            
            });
            if (res.status === 200) {
                this.modalDeleteOpen = false;
                this.getAllApplicationsByUser();
                this.branchOfficeToDelete = null;
                this.fileToDelete = null;
            }

            
        },


        async addBranchOffice() {
            let objToSend = {
                helpfulNote: '',
                orgFullCode: '',
                orgOpCode: '',
                orgFullName: this.newBranchOffice.orgName,
                orgName: this.newBranchOffice.orgName,
                orgShortName: this.newBranchOffice.orgName,
                orgAddress: this.newBranchOffice.adress,
                inn: '',
                kpp: this.newBranchOffice.kpp,
                applicationTypeMethod: 'CREATE',
                toChangeCase: 'SUB_ORG',
                userID: this.authData.userID,
                sprOrgOwnerID: this.authData.sprOrgID,
                orgID: this.authData.sprOrgID,
                };
            let fd = new FormData();
            fd.append('applicationData', JSON.stringify(objToSend));
            fd.append('file', this.newBranchOffice.file);

            const res = await request({
            endpoint: '/api/userProfile/createApplicationRequest',
            method: 'POST',
            body: fd,
            
            });
            if (res.status === 200) {
                this.getAllApplicationsByUser();
                this.newBranchOffice.file = null;
                this.newBranchOffice.orgName = '';
                this.newBranchOffice.adress = '';
                this.newBranchOffice.kpp = '';
            }





        },

	},
}
</script>


<style lang="sass">
.branch-offices-table__th
    display: flex
    font-weight: 600
    color: #00599b
    margin-bottom: 5px
.branch-offices-table__tr
    display: flex
    justify-content: space-between
.branch-offices-table__td
    flex-basis: 0
    flex-grow: 1
    display: flex
.branch-offices-table__tr:nth-of-type(2n)
    background: #e3effb  
.branch-offices-table__tr
    padding: 10px 5px
    color: #00599b
    border-radius: 5px
.branch-offices-table__action
    color: #eb8e1c
    margin: 0px 5px
    text-decoration: underline
.branch-offices-table__action:hover
    text-decoration: none
</style>