import { nanoid } from 'nanoid'
import moment from 'moment'

class ModelDocumentPreview {
    constructor({documentID, annuonce, publicDAte, documentNAme}) {
        this.id = documentID;
        this.key = nanoid();
        this.about = annuonce;
        this.publicDAte = {
            day: moment(publicDAte).format('D'),
            month: moment(publicDAte).format('MMMM'),
            year: moment(publicDAte).format('YYYY'),
          }
        this.name = documentNAme;
    }
}
export default ModelDocumentPreview;