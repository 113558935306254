var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('v-divider',{staticClass:"mt-4"}),_c('h4',{staticClass:"score-subtitle"},[_vm._v("1. Значение показателя")]),_c('h5',{staticClass:"score-subsubtitle"},[_vm._v(" Исходные данные: ")]),_vm._m(1),_c('table',{staticClass:"indicators__table"},[_vm._m(2),_c('tbody',{staticClass:"b24-tbody"},[_c('tr',[_c('td',{},[_vm._v("1.")]),_c('td',{},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.code))]),_c('td',{},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.name))]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.zm))}}),_c('td',{domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.zmd))}})]),_vm._m(3),_vm._m(4)])]),_c('section',{staticClass:"section-score"},[_c('h5',{staticClass:"score-subsubtitle"},[_vm._v("Расчет значения показателя:")]),_c('div',{staticClass:"formula"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"formula-full"},[_vm._v("=")]),_c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"formula-half formula-half_underline"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.zmd))}})]),_c('div',{staticClass:"formula-half"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.zm))}})])]),_c('div',{staticClass:"formula-full"},[_c('div',[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" = ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.checkData(_vm.rowOfValues.data.k11b0))}})])])])]),_vm._m(7)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-about"},[_c('p',[_c('b',[_vm._v("п.4.1.1. Приложения 2 Порядка:")])]),_c('p',[_vm._v("Процент заявлений на поступление, принятых от лиц, имеющих диплом бакалавра, диплом специалиста, диплом магистра, полученном в другой образовательной организации")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calculation__about"},[_c('p',{staticClass:"calculation__about-text"},[_vm._v("Форма 5.10.")]),_c('p',{staticClass:"calculation__about-text"},[_vm._v(" Число поданных заявлений на I курс очной формы, единиц ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"b24-thead"},[_c('tr',{},[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("№ п/г")]),_c('th',{},[_vm._v("УГСН Код")]),_c('th',{},[_vm._v("УГСН Наименование")]),_c('th',{},[_vm._v("Всего подано заявлений на поступление на I курс "),_c('br'),_vm._v(" 2020 год")]),_c('th',{},[_vm._v("Из них от лиц с дипломом других организаций"),_c('br'),_vm._v(" 2020 год")])]),_c('tr',{},[_c('th',{},[_vm._v("1")]),_c('th',{},[_vm._v("2")]),_c('th',{},[_vm._v("3")]),_c('th',{},[_vm._v("4")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"3"}}),_c('td',{attrs:{"colspan":"1"}},[_c('div',[_c('img',{staticStyle:{"height":"20px","margin":"0","width":"300px"},attrs:{"src":require("@/assets/img/brackets/bottom-bracket-removebg-preview.png"),"alt":""}})])]),_c('td',{attrs:{"colspan":"1"}},[_c('div',[_c('img',{staticStyle:{"height":"20px","margin":"0","width":"300px"},attrs:{"src":require("@/assets/img/brackets/bottom-bracket-removebg-preview.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"3"}}),_c('td',{attrs:{"colspan":"1"}},[_c('div',[_vm._v(" ЗМ ")])]),_c('td',{attrs:{"colspan":"1"}},[_c('div',[_vm._v(" ЗМД ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('span',[_vm._v("K11"),_c('span',{staticClass:"font-size-little"},[_vm._v("0")]),_vm._v(" =")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"formula-half formula-half_underline"},[_vm._v(" ЗМД ")]),_c('div',{staticClass:"formula-half"},[_vm._v("ЗМ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calculation__about"},[_c('p',{staticClass:"calculation__about-text"},[_vm._v(" ЗМД - число заявлений на поступление на I курс очной формы, поданных в 2020 году лицами с дипломом бакалавра, специалиста, магистра, полученном в другой образовательной организации ")]),_c('p',{staticClass:"calculation__about-text"},[_vm._v(" ЗМ - всего заявлений на поступление на I курс очной формы, поданных в 2020 году ")])])
}]

export { render, staticRenderFns }