<template>
<main>
	<HeaderMain
			title="Ошибка 404"
			subtitle="Страница не найдена"
	/>
</main>
</template>
<script>
import HeaderMain from '@/components/headers/HeaderMain';
export default {
	components: {
		HeaderMain,
	},
}
</script>