<template>
  <div>

    <div class="score-about">
      <p><b>п.4.3.1. Приложения 2 Порядка:</b></p>
      <p>Среднее соотношение дохода выпускников образовательной организации и прожиточного минимума</p>
    </div>

    <v-divider class="mt-4"/>

    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about">
      <p class="calculation__about-text">
        Данные мониторинга трудоустройства выпускников и их среднемесячного дохода
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b24-thead">
      <tr class="">
        <th class="" rowspan="3">№ п/г</th>
        <th rowspan="2" class="">Уровень</th>
        <th rowspan="2" class="">УГСН Код</th>
        <th rowspan="2" class="">УГСН Наименование</th>
        <th class="" colspan="3">Среднемесячный доход,<br> рублей</th>
        <th class="" colspan="3">Число трудоустроенных<br> выпускников</th>
      </tr>
      <tr>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
        <th class="">8</th>
        <th class="">9</th>

      </tr>
      </thead>
      <tbody class="b24-tbody">
      <tr>
        <td class="">1.</td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
        <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
        <td class="" v-html="checkData(rowOfValues.data.dv2017)">{{ }}</td>
        <td class="" v-html="checkData(rowOfValues.data.dv2018)">{{ }}</td>
        <td class="" v-html="checkData(rowOfValues.data.dv2019)">{{ }}</td>
        <td class="" v-html="checkData(rowOfValues.data.ft2017)">{{ }}</td>
        <td class="" v-html="checkData(rowOfValues.data.ft2018)">{{ }}</td>
        <td class="" v-html="checkData(rowOfValues.data.ft2019)">{{ }}</td>
      </tr>
      <tr>
        <td colspan="4">
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="4">

        </td>
        <td>
          <b>ДВ</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>ДВ</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>ДВ</b><span class="font-size-little">2019</span>
        </td>
        <td>
          <b>ФТ</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>ФТ</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>ФТ</b><span class="font-size-little">2019</span>
        </td>
      </tr>
      <tr>
        <td colspan="7">
        </td>
        <td colspan="3" style="">
          <div>
            <img style="height: 15px; margin: 0; width: 210px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="7">
        </td>
        <td colspan="3" style="">
          <span class="font-weight-bold">ЧТ</span> = {{rowOfValues.data.ft}}
        </td>
      </tr>
      </tbody>
    </table>



    <div class="calculation__about">
      <p class="calculation__about-text">
        Величина прожиточного минимума трудоспособного населения в субъекте Российской Федерации, рублей
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b24-thead">
      <tr class="">
        <th class="" rowspan="3">№ п/г</th>
        <th class="" rowspan="2">Уровень</th>
        <th class="" rowspan="2">УГСН Код</th>
        <th class="" rowspan="2">УГСН Наименование</th>
        <th class="" colspan="3">Региональный прожиточный минимум   </th>
      </tr>
      <tr>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>

      </tr>
      </thead>
      <tbody class="b24-tbody">
      <tr>
        <td class="">1.</td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
        <td class="">{{rowOfValues.data.sprKCPGroup.code}}</td>
        <td class="">{{ rowOfValues.data.sprKCPGroup.name }}</td>
        <td class="" v-html="checkData(rowOfValues.data.pmt2017)">{{ }}</td>
        <td class="" v-html="checkData(rowOfValues.data.pmt2018)">{{ }}</td>
        <td class="" v-html="checkData(rowOfValues.data.pmt2019)">{{ }}</td>

      </tr>
      <tr>
        <td colspan="4">
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="4">

        </td>
        <td>
          <b>ПМТ</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>ПМТ</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>ПМТ</b><span class="font-size-little">2019</span>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- calculation -->

    <!-- formula -->
    <section class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>K31<span class="font-size-little">0</span> = (</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline">
            ДВ<span class="font-size-little">2017</span> * ФТ<span class="font-size-little">2017</span>
          </div>
          <div class="formula-half">ПМТ<span class="font-size-little">2017</span></div>
        </div>
        <div class="formula-full"><span>+</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline">
            ДВ<span class="font-size-little">2018</span> * ФТ<span class="font-size-little">2018</span>
          </div>
          <div class="formula-half">ПМТ<span class="font-size-little">2018</span></div>
        </div>
        <div class="formula-full"><span>+</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline">
            ДВ<span class="font-size-little">2019</span> * ФТ<span class="font-size-little">2019</span>
          </div>
          <div class="formula-half">ПМТ<span class="font-size-little">2019</span></div>
        </div>
        <div class="formula-full"><span>)  /  ЧТ = </span></div>
      </div>

      <div class="formula">
        <div class="formula-full"><span>(</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline">
            <span v-html="checkData(rowOfValues.data.dv2017)"></span>
            *
            <span v-html="checkData(rowOfValues.data.ft2017)"></span>
          </div>
          <div class="formula-half"><span v-html="checkData(rowOfValues.data.pmt2017)"></span></div>
        </div>
        <div class="formula-full"><span>+</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline">
            <span v-html="checkData(rowOfValues.data.dv2018)"></span>
            *
            <span v-html="checkData(rowOfValues.data.ft2018)"></span>
          </div>
          <div class="formula-half"><span v-html="checkData(rowOfValues.data.pmt2018)"></span></div>
        </div>
        <div class="formula-full"><span>+</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline">
            <span v-html="checkData(rowOfValues.data.dv2019)"></span>
            *
            <span v-html="checkData(rowOfValues.data.ft2019)"></span>
          </div>
          <div class="formula-half"><span v-html="checkData(rowOfValues.data.pmt2019)"></span></div>
        </div>
        <div class="formula-full"><span>)  /   <span v-html="checkData(rowOfValues.data.ft)"></span> = </span></div>
        <div class="formula-full">  <span v-html="checkData(rowOfValues.data.k31b0)"></span> </div>
      </div>
      <div class="score-about mb-4">
        <p class="ml-4">Если значение показателя ЧТ менее 5, фактическое значение показателя не рассчитывается, а его
          нормированная оценка определяется в соответствии с пунктом 7 Методики</p>
      </div>
      <!-- formula -->

      <div class="calculation__about">
        <p class="calculation__about-text">
          ДВk - среднемесячный размер дохода выпускников очной формы обучения, являющихся гражданами Российской Федерации
        </p>
        <p class="calculation__about-text">
          ФТk - число трудоустроенных выпускников очной формы, являющихся гражданами Российской Федерации
        </p>
        <p class="calculation__about-text">
          ПМТk - величина прожиточного минимума трудоспособного населения в целом по субъекту Российской Федерации в 2017-2019 годах
        </p>
        <p class="calculation__about-text">
          ЧТ - общее число трудоустроенных выпускников очной формы обучения
          (сумма значений ФТ<span class="font-size-little">2017</span>
          , ФТ<span class="font-size-little">2018</span>, ФТ<span class="font-size-little">2019</span>)
        </p>


      </div>

    </section>

  </div>
</template>

<script>
export default {
  name: "k31",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float',3, false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  //position: relative
  //display: block
  //border-radius: 5px
  width: 100%

  .b24-thead
    th
      background-color: inherit
      border: 1px solid #546e7a
      padding: 5px

    //&:nth-child(7)
    //    border: 0
    //    min-width: 20px
    //
    //&:nth-child(8)
    //    border: 0
    //
    //&:nth-child(9)
    //    border: 0

    /*tr*/
    /*  &:last-child*/
  //th
  //    &:nth-child(6)
  //        border: 0
  //        text-align: right
  //        padding-right: 10px
  //        border-bottom: 1px solid #546e7a
  //
  //    &:nth-child(7)
  //        border: 0
  //        padding-left: 10px
  //        border-bottom: 1px solid #546e7a

  .b24-tbody
    td
      text-align: center
      border: 1px solid #546e7a
      padding: 3px
    tr
      &:nth-child(n+2)
        td
          border: 0
//&:nth-child(7)
//    border: 0
//    min-width: 20px
//
//&:nth-child(8)
//    border: 0
//    padding-right: 5px
//    text-align: right
//
//&:nth-child(9)
//    border: 0
//    padding-left: 2px
//    text-align: left


</style>