<template>
	<textarea
		v-model="$attrs.value"
		@change="onValueChanged"
		:class="['textarea', customClass]"
		:placeholder="placeholder"
		:name="name"
	>
	</textarea>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
				default: '',
			},
			placeholder: {
				type: String,
				default: '',
			},
			customClass: {
				type: String,
				default: '',
			},
		},
	methods: {
		onValueChanged() {
			this.$emit('input', this.$attrs.value);
		}
	}
}
	
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.textarea
    color: $greyColor
    font-size: $size-1
    border-bottom: 1px solid $color-g
    min-height: 150px
    width: 99%
    transition: $speed-0
.textarea:focus
    border-bottom: 1px solid $color-2
    transition: $speed-0


.textarea:invalid
    color: red
.textarea:valid
    color: $color-g
</style>