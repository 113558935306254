<template>
<div>
  <div class="score-about">
    <p><b>п.3.4.3. Приложения 2 Порядка:</b></p>
    <p>Процент иностранных граждан в контингенте обучающихся</p>
  </div>
  <v-divider class="mt-4"/>
  <h4 class="score-subtitle">1. Значение показателя</h4>
  <h5 class="score-subsubtitle">
    Исходные данные:
  </h5>
  <div class="calculation__about">
    <p class="calculation__about-text">
      Форма 5.1.<br>
      Общая численность обучающихся, человек (за счет всех источников финансирования)
    </p>
  </div>
  <table class="indicators__table">
    <thead class="b32-thead">
    <tr class="">
      <th class="" rowspan="3">№ п/г</th>
      <th class="" rowspan="2">Уровень</th>
      <th class="" rowspan="2" colspan="2">Форма обучения</th>
      <th class="" colspan="3">Общая численность<br> обучающихся</th>
      <th colspan="3">из них - иностранцы</th>
    </tr>
    <tr class="">
<!--      <th class="">Код</th>-->
<!--      <th class="">Наименование</th>-->
      <th class="" colspan="3">на 01.01.2020</th>
      <th class="" colspan="3">на 01.01.2020</th>

    </tr>
    <tr class="">
      <th class="">1</th>
      <th class="" colspan="2">2</th>
<!--      <th class="">3</th>-->
      <th class="" colspan="3">3</th>
      <th class="" colspan="3">4</th>

    </tr>
    </thead>
    <tbody class="b32-tbody">
    <tr>
      <td class="">1.</td>
      <td class="">Ординатура</td>
      <td class="" colspan="2">
        очная
      </td>
      <td  class="" colspan="3" v-html="checkData(rowOfValues.data.noo2020)"></td>
      <td class=""  colspan="3" v-html="checkData(rowOfValues.data.ioo2020)"></td>
    </tr>
    <tr>
      <td colspan="4">
      </td>
      <td colspan="3" style="">
        <div ><img style="height: 20px; margin: 0; width:210px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                     alt=""/>
        </div>
      </td>
      <td colspan="3" style="">
        <div ><img style="height: 20px; margin: 0; width:210px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                   alt=""/>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="4">
      </td>
      <td colspan="3" style="">
        <div >
          NS
        </div>
      </td>
      <td colspan="3" style="">
        <div >
          ИК
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <!-- formula -->
  <section class="section-score">
    <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

    <div class="formula">
      <div class="formula-full"><span>О43<span class="font-size-little">0</span> =</span></div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline  px-3">ИК</div>
        <div class="formula-half" style="margin-left: 25%">NS</div>
      </div>
      <div class="formula-full">
        <div class="font-weight-regular">×100</div>
      </div>
      <div class="formula-full">=</div>
      <div class="formula-full">
        <div class="formula-half formula-half_underline  px-3"><span v-html="checkData(rowOfValues.data.ioo2020)"></span></div>
        <div class="formula-half" style="margin-left: 25%"><span v-html="checkData(rowOfValues.data.noo2020)"></span></div>
      </div>
      <div class="formula-full">
        <div class="font-weight-regular">×100</div>
      </div>
      <div class="formula-full">=</div>
      <div class="formula-full" v-html="checkData(rowOfValues.data.o43b0)"> </div>
    </div>

    <!-- formula -->

    <div class="score-about mb-4">
      <p class="ml-4">Если значение показателя NS менее 10, фактическое значение показателя не рассчитывается, а его нормированная оценка определяется в соответствии с пунктом 7 Методики</p>
    </div>
    <div class="calculation__about">
      <p class="calculation__about-text">
NK - иностранных граждан обучающихся по программам ординатуры на 01.01.2020 года
      </p>
      <p class="calculation__about-text">
NS - всего обучающихся по программам ординатуры на 01.01.2020 года
      </p>
    </div>
  </section>
</div>
</template>

<script>
export default {
name: "o43",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%
.b32-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px
.b32-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px
  tr
    &:nth-child(2)
      td
        border: 0
    &:nth-child(3)
      td
        border: 0
</style>