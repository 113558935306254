<template>
  <thead>
  <tr v-for="(header_row, ind) in headersProps" :key="ind" class="table-header" :style="{width: scrollWidth + 'px'}">
    <!--    <tr v-else-->
    <!--         class="table-header__td" :width="header.width">-->
    <!--
    git link tooltip https://github.com/Akryum/v-tooltip#usage
    -->
    <th
        v-for="(header,i) in header_row"
        :key="i"
        :colspan="header.cols"
        :rowspan="header.rows"
        :header="header.text"
    ><!--@click="(header.dataField === 'action' && header.active && actionAccess) ? openDialog() : ''"-->
      <v-row no-gutters>
        <v-col
            cols="12"
            style="padding: 5px"
        ><!--max-width: 200px!important;-->
          <span
              v-show="isHidden(rolledCols,header) === false"
              class="table-header__title"
              style="white-space: break-spaces; width:250px;"
          >{{ header.text }}<!----></span>
           <div class="table-header__icons" v-if="header.dataField !== 'action' ">
            <div v-show="!isHidden(rolledCols,header)" class="table-header__icon table-header__icon_about"
            >
              <v-tooltip
                  top
                  v-if="header.comment !=null"
                  color="#00599b"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      :size="15"
                      class="btn__icon grey-light-color__important"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <p class="white--text">{{header.comment}}</p>
              </v-tooltip>
            </div>
            <div
                v-if="header.active"
                class="table-header__icon table-header__icon_hidden"
                @click="addHiddenColumn(header)"
            >               <v-icon :size="15" class="btn__icon grey-light-color">{{
                  isHidden(rolledCols, header) ? 'mdi-eye' : 'mdi-eye-off'
                }}
              </v-icon>
            </div>
          </div>
          <div v-else-if="header.active && actionAccess && actionColumn.accessAdd"
               class="table-header__icons"
          >
            <v-btn
                icon
                @click="openDialog()"
                :disabled="authData && authData.role.id === 14"
            >
              <v-icon color="#EB722E" size="40">mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </th>
  </tr>
  </thead>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: "TableHeader",
  props: {
    showDialog: {
      type: Object,
      default: () => {
        return {show: false, typeDialog: 1}
      },
    },
    actionAccess: {
      type: Boolean,
      default: false
    },
    headersProps: {
      type: Array,
      default: undefined
    },
    sortBy: {
      type: Array,
      default: undefined
    },
    rolledCols: {
      type: Array,
      default: undefined
    },
    scrollWidth: {
      type: Number,
    },
  },
  data: () => ({}),
  computed: {
    arrayAfterCheckHiddenColumns() {
      let arr = []
      arr = [].concat(...this.headersProps).filter(el => el.active).sort((a, b) => a.order - b.order)
      return arr
    },
    actionColumn() {
      return this.arrayAfterCheckHiddenColumns.find(el => el.dataField === 'action')
    },
    ...mapGetters('auth', {authData:'authData'}),
  },
  methods: {
    openDialog() {
      this.$emit('update:show-dialog', {show: !this.showDialog.show, typeDialog: 1})
    },
    // removeDopFilter(val){
    //   this.$emit('update:remove-dop-filter', val)
    // },
    // existDopFilter(val){
    //   return this.dopFilters.findIndex(el => el.dataField === val.dataField) > -1
    // },
    numOfSort(mas) {
      let a = this.$scripts.TableJs.arraySearch(this.sortBy, mas.dataField, 'dataField')
      return a >= 0 ? a : undefined
    },

    // ...mapActions('table', {tableHeadersChangeOrder: 'tableHeadersChangeOrder'}),
    // onDrop(dropResult) {
    //   this.tableHeadersChangeOrder(dropResult)
    // },
    choseSortHed(mas) {
      if (mas.sortable) {
        let sendedSortObject;
        if (!this.isSort(mas)) {
          sendedSortObject = {dataField: mas.dataField, typeSort: 1, typeVar: mas.typeVar, altSort: false};
        } else {
          let valOfSortArr = this.isSort(mas);
          if (mas.alternativeSortable === true) {
            switch (valOfSortArr.altSort) {
              case true:
                switch (valOfSortArr.typeSort) {
                  case 1:
                    sendedSortObject = {
                      dataField: valOfSortArr.dataField,
                      typeSort: -1,
                      typeVar: mas.typeVar,
                      altSort: true
                    };
                    break;
                  case -1:
                    sendedSortObject = {
                      dataField: valOfSortArr.dataField,
                      typeSort: undefined,
                      typeVar: mas.typeVar,
                      altSort: false
                    };
                    break;
                }
                break;
              case false:
                switch (valOfSortArr.typeSort) {
                  case 1:
                    sendedSortObject = {
                      dataField: valOfSortArr.dataField,
                      typeSort: -1,
                      typeVar: mas.typeVar,
                      altSort: false
                    };
                    break;
                  case -1:
                    sendedSortObject = {
                      dataField: valOfSortArr.dataField,
                      typeSort: 1,
                      typeVar: mas.typeVar,
                      altSort: true
                    };
                    break;
                }
                break;
            }
          } else
            switch (valOfSortArr.typeSort) {
              case 1:
                sendedSortObject = {
                  dataField: valOfSortArr.dataField,
                  typeSort: -1,
                  typeVar: mas.typeVar,
                  altSort: false
                };
                break;
              case -1:
                sendedSortObject = {
                  dataField: valOfSortArr.dataField,
                  typeSort: undefined,
                  typeVar: mas.typeVar,
                  altSort: false
                };
                break;
            }
        }
        this.$emit('sortHed', sendedSortObject);
      }
    },
    isSort(mas) {
      if (this.sortBy !== undefined && this.sortBy.length > 0) {
        let indOfSortby = this.$scripts.TableJs.arraySearch(this.sortBy, mas.dataField, 'dataField');
        if (indOfSortby >= 0)
          return this.sortBy[indOfSortby];
        else
          return false;
      } else
        return false;
    },
    isHidden(arr, val) {
      if (arr !== undefined && arr.length > 0) {
        let indOfHiddenBy = this.$scripts.TableJs.arraySearch(arr, val.dataField, 'dataField');
        if (indOfHiddenBy >= 0)
          return arr[indOfHiddenBy];
        else
          return false;
      } else
        return false;
    },
    addHiddenColumn(val) {
      let sendHiddenObject = {};
      if (!this.isHidden(this.rolledCols, val)) {
        sendHiddenObject = {dataField: val.dataField, hidden: true};
      } else {
        sendHiddenObject = {dataField: val.dataField, hidden: false};
      }
      this.$emit('hidden:hed', sendHiddenObject);
    }
  }
}
</script>

<style lang="sass" scoped>
.smooth-dnd-container.horizontal
  display: flex !important

.table-header__icon .btn__icon_dop_filter_exist
  color: #fff
  background: #001520
  border-color: #001520

.table-header__icons
  display: flex
  flex-direction: row
  padding: 0 10px 0 10px

//.table-header__title
//  padding: 10px

</style>