<template>
    <div>
        <header class="section-header section-preview__header">
            <h2 class="section-header__title">Этап 2 распределения объемов КЦП на 2023/24 учебный год (субъекты РФ)</h2>
        </header>
        <Alert>
            <template #icon>
                <v-icon color="#D4990A" size="40">mdi-alert-outline</v-icon>
            </template>
            <template #default>
                <p>Правила корректировки общих объемов КЦП на 2023/24 учебный год (далее – ОКЦП) субъектами РФ</p>
                <ul class="pl-0 mt-2">
                    <li>
                        <p>1. В части изменения региональных объемов установлено ограничение, в соответствии с которым изменение не
                            может составлять более:</p>
                        <div class="d-flex flex-row align-center justify-start ml-6 black-color">
                            <span>max</span><img alt="" style="height: 45px; margin: 0 5px; width: 10px"
                                                 src="@/assets/img/brackets/open-bracket-removebg-preview.png"/>
                            <div class="d-flex flex-column">
                                <div>квота * 20 %</div>
                                <a href="http://files.ined.ru/adminpanel/cms_article_editor_1_66666_d33ceaa0d15c04bc6f9eb110fdc01392.pdf" target="_blank">расчетная группа</a>
                            </div>
                        </div>
                      <p>(максимально допустимое изменение - это максимальная величина из расчетного размера группы или 20% от исходного значения)</p>
                    </li>
                    <li>
                        <p>2. Если предлагается увеличить общий объем по субъекту
                            в целом (в том числе менее чем на 20%), при блокировке будут запрошены следующие файлы:</p>
                        <ul class="pl-6 black-color">
                            <li style="list-style-type: disc">Расчетный файл</li>
                            <li style="list-style-type: disc">Пояснительная записка</li>
                            <li style="list-style-type: disc">Письмо в Минобрнауки России</li>
                        </ul>
                    </li>
                </ul>
            </template>
        </Alert>
<!--        <Alert alert-type="success">-->
<!--            <template #icon>-->
<!--                <v-icon color="#29A128" size="40">mdi-check-bold</v-icon>-->
<!--            </template>-->
<!--            <template #default>-->
<!--                <div class="d-flex flex-row justify-space-between">-->
<!--                    <p class="d-flex align-center">{{ getBlock ? 'Ввод данных заблокирован' :-->
<!--                        'Заблокировать ввод данных' }}</p>-->
<!--                    <v-btn-->
<!--                            :disabled="getBlock"-->
<!--                            :loading="loadingBlockEnterData"-->
<!--                            @click="clickBlockEnterData()"-->
<!--                            class="success__v-btn"-->
<!--                            color="#29A128"-->
<!--                    >-->
<!--                        <span style="color:#fff;">{{ getBlock ? 'Заблокировано' : 'Заблокировать ввод' }}</span>-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--                <div class="d-flex flex-row justify-space-between mt-2" v-if="getBlock">-->
<!--                    <p class="d-flex align-center">{{ 'Печать введенных данных' }}</p>-->
<!--                    <v-btn-->
<!--                            :disabled="!getBlock"-->
<!--                            :loading="loadingPrintData"-->
<!--                            @click="clickPrintData()"-->
<!--                            class="success__v-btn"-->
<!--                            color="#29A128"-->
<!--                    >-->
<!--                        <span style="color:#fff;">{{ 'Печать' }}</span>-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--            </template>-->
<!--        </Alert>-->
        <WrapperPreviewCategory v-if="getBlock" customClass="section-preview_lk">
            <WrapperCollapse
                    title="Загружаемые файлы"
                    :initialOpen="true"
            >
                <ContainerRegionDocuments :all-files="allFiles" :min-file="specialRegions"/>
            </WrapperCollapse>
        </WrapperPreviewCategory>
        <WrapperPreviewCategory customClass="section-preview_lk">
            <v-tabs
                    @change="changeUsl($event)"
                    align-with-title
                    v-model="tab"
            >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                        :key="item.usl"
                        class="font-weight-bold"
                        v-for="item in tabs"
                >
                    {{ item.text }}
                </v-tab>
<!--                <v-spacer></v-spacer>-->
<!--                <div class="d-flex align-center mr-4" >-->
<!--                    <v-btn-->
<!--                            :loading="loadingExcelData"-->
<!--                            @click="clickPrintData(`/api/report/getExcel/kcpProposal/region/`, `excel.xlsx`, 'loadingExcelData')"-->
<!--                            class="success__v-btn"-->
<!--                            color="#29A128"-->
<!--                    >-->
<!--                        <span style="color:#fff;">{{ 'В Excel' }}</span>-->
<!--                    </v-btn>-->
<!--                </div>-->
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                        :key="item.usl"
                        v-for="item in tabs"
                >
                    <region-stage-two-fo-data :usl="getUsl"></region-stage-two-fo-data>
                </v-tab-item>
            </v-tabs-items>

        </WrapperPreviewCategory>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Alert from "@/components/alerts/Alert";
    import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
    import RegionStageTwoFoData from "./regionStageTwoFoData";
    import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
    import ContainerRegionDocuments from "@/containers/private/ContainerRegionDocuments";
    import request from "@/services/request";
    import axios from "axios";

    export default {
        name: "regionStageTwo",
        components: {RegionStageTwoFoData, WrapperPreviewCategory, Alert, ContainerRegionDocuments, WrapperCollapse},
        data() {
            return {
                tab: 0,
                tabs: [{usl: "bak", uslId: 1, text: 'Бакалавриат'},{usl: "spec", uslId: 2, text: 'Специалитет'},{usl: "mag", uslId: 3, text: 'Магистратура'}],
                needAllFiles: false,
                loadingPrintData: false,
                loadingBlockEnterData: false,
                loadingExcelData: false,
                sums: {base: 0, reg: 0},
                loadingData: false
            }
        },
        computed: {
            ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
            ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
            ...mapGetters('checkFilled', {get_check_filled: 'get_check_filled'}),
            getUsl(){
                return this.tabs[this.tab]
            },
            getBlock(){
                return true //this.get_blockedEnterData
            },
            specialRegions(){
                return [5,11,17,18,28,42,56,65,66,81,87,90,16,20,24,29,69,71,85].indexOf(this.get_replace_id_admin) !== -1
            },
            allFiles() {
                return this.sums.reg > this.sums.base;
            }
        },
        created() {
            this.getServerData();
        },
        methods: {
            ...mapActions('auth', {getActualOrgStatus: 'getActualOrgStatus'}),
            async getServerData() {
                this.loadingData = true;
                const res = await request({
                    endpoint: `/api/stageData/byRegion/${this.get_replace_id_admin}`,
                    method: 'get',
                });
                if (res.status === 200) {
                    this.sums = res.data.reduce((sum, el)=>{
                        sum.base += el.coValueStage1;
                        sum.reg += el.regValueStage2;
                        return sum;
                    }, {base: 0, reg: 0})
                    console.log(this.sums)
                } else {
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка получения данных',
                        timeout: 3000
                    });
                    this.errorData = res.error;
                    this.loadingData = false;
                }
            },
            async clickBlockEnterData(){
                this.loadingBlockEnterData  = true;
                const res = await request({
                    endpoint: `/api/stageData/block?founderId=${this.get_replace_id_admin}&stage=2`,
                    method: 'get'
                });
                if (res.status === 200) {
                    this.loadingBlockEnterData = false;
                    await this.getActualOrgStatus();
                } else {
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка блокировки',
                        timeout: 3000
                    });
                    this.loadingBlockEnterData = false;
                }
            },
            async clickPrintData(endPoint = `/api/report/getKcpProposalRegion/`, fileName = "ReportFunc.pdf", loadingFlag = 'loadingPrintData') {
                this[loadingFlag] = true;
                const responseAxios = await axios.get(`${endPoint}${this.get_replace_id_admin}`, {
                    headers: this.$scripts.Utils.authHeader(),
                    responseType: 'blob'
                })
                if (responseAxios.status === 200) {
                    this[loadingFlag] = false;
                    this.saveByteArray(fileName, responseAxios.data)
                } else {
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка скачивания файла',
                        timeout: 3000
                    })
                    this[loadingFlag] = false;
                }
            },
            saveByteArray(reportName, byte) {
                const blob = new Blob([byte], {type: "application/pdf"});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = reportName;
                link.click();
            }
        }
    }
</script>

<style lang="sass">
    .section-preview_lk
        background: #fff
        border-radius: 10px
        padding: 10px
        //border: 1px solid #00599b
        margin-bottom: 20px
</style>