var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('h4',{staticClass:"score-subtitle"},[_vm._v("1. Значение показателя")]),_c('h5',{staticClass:"score-subsubtitle"},[_vm._v(" Исходные данные: ")]),_vm._m(1),_c('table',{staticClass:"indicators__table"},[_vm._m(2),_c('tbody',{staticClass:"indicator-tbody"},[_c('tr',[_c('td',{},[_vm._v("1.")]),_c('td',{},[_vm._v(_vm._s(_vm.rowOfValues.data.sprKCPGroup.sprUsl.uslName))]),_c('td',{},[_vm._v(" "+_vm._s(_vm.rowOfValues.data.sprKCPGroup.code)+" ")]),_c('td',{},[_vm._v(" "+_vm._s(_vm.rowOfValues.data.sprKCPGroup.name))]),_c('td',{},[_vm._v(_vm._s(_vm.rowOfValues.data.ege))])])])]),_c('section',{staticClass:"section-score"},[_c('h5',{staticClass:"score-subsubtitle"},[_vm._v("Расчет значения показателя:")]),_c('div',{staticClass:"formula"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"formula-full"},[_vm._v("=")]),_c('div',{staticClass:"formula-full"},[_vm._v(_vm._s(_vm.rowOfValues.data.b11b0))])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-about"},[_c('p',[_c('b',[_vm._v("п.1.1.1. Приложения 2 Порядка:")])]),_c('p',[_vm._v("Средний балл единого государственного экзамена студентов, принятых на обучение по очной форме")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calculation__about"},[_c('p',{staticClass:"calculation__about-text"},[_vm._v("Форма 5.8.")]),_c('p',{staticClass:"calculation__about-text"},[_vm._v(" Средний балл ЕГЭ студентов, принятых на обучение по очной форме на бакалавриат и специалитет ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"indicator-thead"},[_c('tr',[_c('th',{attrs:{"rowspan":"3"}},[_vm._v("№ п/п")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Уровень")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("УГСН ")]),_c('th',[_vm._v("Средний бал ЕГЭ")])]),_c('tr',[_c('th',[_vm._v("Код")]),_c('th',[_vm._v("Наименование")]),_c('th',[_vm._v("2020 год")])]),_c('tr',{},[_c('th',{},[_vm._v("1")]),_c('th',{},[_vm._v("2")]),_c('th',{},[_vm._v("3")]),_c('th',{},[_vm._v("4")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('span',[_vm._v("Б11"),_c('span',{staticClass:"font-size-little__bottom"},[_vm._v("0")]),_vm._v(" =")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formula-full"},[_c('div',{staticClass:"formula-half formula-half_underline"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('span',{staticStyle:{"font-size":"28px","font-weight":"lighter"}},[_vm._v("Σ")]),_vm._v(" "),_c('div',[_vm._v("ЕГЭ"),_c('span',{staticClass:"font-size-little__bottom"},[_vm._v("k")])])])]),_c('div',{staticClass:"formula-half",staticStyle:{"margin-left":"35%"}},[_vm._v("EN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-about"},[_c('p',[_vm._v("ЕГЭk - средний балл ЕГЭ k-го студента, принятого на обучение по очной форме;")]),_c('p',[_vm._v("ЕN - численность студентов, принятых на обучение по очной форме, по которым был учтен средний балл ЕГЭ.")])])
}]

export { render, staticRenderFns }