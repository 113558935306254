<template>
        <ContainerNewsCategory />
</template>
<script>
import ContainerNewsCategory from '@/containers/cms/news/ContainerNewsCategory';
export default {
    components: {
        ContainerNewsCategory,
    }
}
</script>