<template>
  <div>
    <div class="kcp-nav">
      

      <router-link v-for="navItem in navListAfterCheckOnDeploy" :key="navItem.link" class="nav-item_kcp"
                   :to="currentPath+'/'+navItem.link">
        <span class="nav-item-kcp_title">{{ navItem.text }}</span>
      </router-link>
      <button
          v-if="this.authData.role.id !==4 && this.authData.role.id !==6"
          class="nav-item_kcp nav-item_kcp__text"
          @click="print"
          :disabled="uploadNow"
      >
        <span class="nav-item-kcp_title" v-if="!uploadNow">Печать конкурсной заявки</span>
        <v-progress-linear
            v-else
            indeterminate
        /><!--size="14"
            width="3"-->
      </button>
    </div>
    <v-btn
        v-if="this.authData.role.id !==4 && this.authData.role.id !==6 && !get_blockedAll"
        @click="blockEnter({sprOrgId: authData.sprOrgID, status: !get_blockedEnterData})"
        width="100%"
        :disabled="false"
        class="text-none"
        color="#EB722E"
    ><!--class="button_border" :class="get_blockedEnterData ? 'blocked-btn': 'blocked-btn'"-->
      <span style="color:#fff;">
        {{ get_blockedEnterData ? 'Разблокировать ввод' : 'Заблокировать ввод' }}
      </span>
    </v-btn>

    <CardConvert v-if="get_blockedEnterData && !get_blockedAll" :item="uploadFile" @uploadFile="uploadFileApplication($event)" />

    

    <AlertSuccess v-if="get_blockedAll && !convertAccepted"> 
      <p>Заявка отправлена на конкурс <button @click="getConvert" class="button_border button_border_application">Просмотр загруженного файла</button></p>
    </AlertSuccess>

    <AlertSuccess v-if="convertAccepted"> 
      <p>Конверт принят {{convertAcceptInfo.data}} под номером {{convertAcceptInfo.id}} <button @click="getConvert" class="button_border button_border_application">Просмотр загруженного файла</button></p>
    </AlertSuccess>

    


  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import CardConvert from "@/components/cards/custom/CardConvert";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import request from "@/services/request";
import saveFile from '@/utils/saveFile';
import moment from 'moment';
moment.locale('ru');
import axios from 'axios'
export default {
  name: "zayvkaKcpContent",
  components: {
    CardConvert,
    AlertSuccess,
  },
  data: () => ({
    uploadNow: false,
    convertAcceptInfo: null,
    convertAccepted: false,
    uploadFile: {name: 'ВНИМАНИЕ!!! После прикрепления подписанного скана печатных форм заявки и нажатия кнопки "Отправить на конкурс" разблокировка ввода и внесение изменений, замена прикрепленного файла будет невозможно.<br>' +
          'ВНИМАНИЕ!!! Без прикрепленного скана заявки прием конверта не возможен!<br>'+
          'ВНИМАНИЕ!!! Скан должен содержать формы, которые выходят на печать, подписанные руководителем организации с печатью организации (формы, согласно Приложению №3 к Объявлению).<br>' +
          'Скан грузится одним pdf-файлом размером до 20 МБ', fileType: ''},
    navList: [
      {link: 'license', text: 'Лицензированные НПС/УГНС', dependency: false,userGroup:[1,14,15]},
      {link: 'accreditation', text: 'Государственная аккредитация', dependency: false,userGroup:[1,14,15]},
      {link: 'structure-organisations', text: 'Организации подавшие заявки', dependency: false, userGroup: [4, 6, 14]},
      {link: 'structure', text: 'Все заявки на рассмотрении', dependency: false, userGroup: [4, 6, 14]},
      {link: 'structure', text: 'Реестр НПС/УГНС, состав заявки', dependency: false,userGroup:[1,14,15]},
      {link: 'kcp', text: 'Предложения по КЦП', dependency: false,userGroup:[1,14,15]},
      {link: 'indicators', text: 'Показатели деятельности образовательной организации', dependency: false,userGroup:[1,14,15]},
      // {link: 'print', text: 'Печать конкурсной заявки', dependency: false, dependencyDataField: 'get_blockedEnterData',userGroup:[1,14,15]},
    ]
  }),
  created() {
    
    if (this.get_data_headers.length === 0 && !this.get_data_headers_loading && this.authData.role !=null && ![4,6].includes(this.authData.role.id)) {
      this.getDataForHeaders()
    }
    if (this.get_blockedAll) {
      this.getStatusConvert();
    }

  },
  computed: {
    currentPath() {
      return this.$route.path
    },
    ...mapGetters('tables', {get_data_headers: 'get_data_headers'}),
    ...mapGetters('tables', {get_data_headers_error: 'get_data_headers_error'}),
    ...mapGetters('tables', {get_data_headers_loading: 'get_data_headers_loading'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_actual_org_status: 'get_actual_org_status'}),
    ...mapGetters('auth', {get_replace_role_id_admin: 'get_replace_role_id_admin'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    navListAfterCheckOnDeploy(){
      return this.navList.filter(el => {
        return ((!el.dependency || (this[el.dependencyDataField])) && el.userGroup.includes(this.get_replace_role_id_admin))
      })
    }
  },
  methods: {
    ...mapActions('tables', {getDataForHeaders: 'getDataForHeaders'}),
    ...mapActions('auth', {blockEnter: 'blockEnter'}),
    ...mapActions('auth', {getActualOrgStatus: 'getActualOrgStatus'}),
    async print() {
      // console.log("print id", this.get_replace_id_admin)
      this.uploadNow = true;
      await this.getFile()
    },
    async getFile() {
      let responseAxios = await axios.get(`/api/report/run/${this.get_replace_id_admin}`, {
        headers: this.$scripts.Utils.authHeader(),
        responseType: 'blob'})
      this.uploadNow = false;
      this.saveByteArray("ReportFunc.pdf",responseAxios.data)
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/pdf"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
    async uploadFileApplication(event) {
            let fileInfo = {
                sprOrgID: this.authData.sprOrgID,
                userID: this.authData.userID,
            };
            let fd = new FormData();
            fd.append('convert', JSON.stringify(fileInfo));
            fd.append('file', event.file);
            const sendingFileApplication = await request({
                endpoint: '/api/userProfile/sendConvert',
                method: 'POST',
                body: fd,
            });
            if (sendingFileApplication.status === 200) {
              this.getActualOrgStatus();
              this.getStatusConvert();
            }
            
        },

    async getStatusConvert() {
      let response = await request({
                endpoint: `/api/userProfile/getConvertByOrgID?sprOrgID=${this.get_replace_id_admin}`,
                method: 'GET',
            });
      response = response.data;      
      if (response.convertStatus === 'RECEIVED') {
        this.convertAccepted = true;
      }
        this.convertAcceptInfo = {
          id: response.receivedConvertID,
          fileServerID: response.convertFileServerID,
          data: moment(response.receiptDate).format('LLL'),
          name: response.convertFileName,
          format: response.convertFileFormat,
        }
      
    },
    async getConvert() {
        let response = await request({
            endpoint: '/api/fileServer/downloadFileByID',
            method: 'POST',
            body: {
                fileServerID: this.convertAcceptInfo.fileServerID,
                fileName: this.convertAcceptInfo.name,
                fileFormat: this.convertAcceptInfo.format,
                }
        });
        saveFile(response.data);
    },

  }
}
</script>

<style lang="sass" >
@import "src/assets/styles/utils/variables"
.nav-item_kcp__text
  color: $primary-color
.blocked-btn
  //border-color: #EB722E
  background-color: #EB722E
  >.v-btn__content
     padding: 10px
     background: #EB722E
     color: #fff
     //&:hover
     //  background-color: #fff!important
     //  color: #EB722E
     //  .v-icon
     //    color: #EB722E
  //&:hover
  //  background-color: #fff!important
  //  >.v-btn__content
  //    background-color: #fff!important
  //    color: #EB722E


.blocked-btn__disabled
    background-color: #e2e8f0!important
    >.v-btn__content
      padding: 10px
      color: #546e7a
      border-color: #e2e8f0
.button_border_application
    padding: 2px 10px !important
    margin-left: 15px !important
    color: #fff !important
    background: #29a128 !important
    border-color: #29a128 !important  
.button_border_application:hover
    background: #fff !important
    color: #29a128 !important

.nav-item_kcp
    background: #fff
    border: 1px solid #CAD2D8
    border-radius: 10px
    max-width: 380px
    max-height: 78px
    box-sizing: border-box
    min-width: 380px
    min-height: 78px
    margin-bottom: 26px
    margin-right: 30px
    border-left: 14px solid #4aa14e
    display: flex
    align-items: center
    padding-left: 18px
    transition: 0.3s
.nav-item_kcp:hover 
    box-shadow: 0 2px 8px rgba(18,17,36,.04),0 4px 32px rgba(18,17,36,.08)
    transition: 0.3s
.nav-item_kcp:nth-of-type(2n+1)
  border-left: 14px solid #e99475
.nav-item_kcp:nth-of-type(3n+1)
  border-left: 14px solid #5ebad9
.nav-item_kcp:nth-of-type(4n+1)
  border-left: 14px solid #a7ab99

.kcp-nav
    display: flex

.nav-item-kcp_title
    font-size: 18px
    color: #00599B    

</style>