<template>
  <Alert alertType="success">
    <template v-slot:icon>
      <img src="@/assets/img/icon-success.svg" class="success__icon">
    </template>
    <template v-slot:default>
      <slot name="default"></slot>
    </template>
   
  </Alert>
</template>
<script>
import Alert from '@/components/alerts/Alert';
export default {
  components: {
    Alert,
  },
}
</script>