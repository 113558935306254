<template>
	<div class="navigation">
		<NavigationListViewColumn :data="data"/>
	</div>
</template>

<script>
import NavigationListViewColumn from '@/components/navigation/viewColumn/NavigationListViewColumn'; 
export default {
	components: {
		NavigationListViewColumn
	},
	props: {
		data: {
			type: Array,
		}
	}
}
	
</script>



<style lang="sass">
.navigation-item_column 
    margin-bottom: 11px
	
</style>