<template>
  <ContainerKcpCorrectedValuesList/>
</template>

<script>
import ContainerKcpCorrectedValuesList from "@/containers/private/indicators/ContainerKcpCorrectedValuesList";
export default {
  name: "viewKcpCorrect",
  components: {ContainerKcpCorrectedValuesList}
}
</script>

<style scoped>

</style>