<template>
  <v-footer
      app
      id="footer"
      color="#426075"
      padless
  >

<v-container>
  <div class="footer-content">
   
      <div class="footer-content__column">
        <img class="footer-content__logo" src="@/assets/img/logo-footer.svg">
      </div>
 
      <div class="footer-content__column">
        <div class="footer-content__nav">
            <Navigation :data="navLinks"/>
        </div>
      </div>
    <div class="footer-content__column">
      <div class="footer-content__nav">
          <Navigation :data="helpLinks"/>
      </div>
    </div>
    <div class="footer-content__column">
      <span class="footer-content__email">info@ined.ru</span>
      <span class="footer-content__adress">119021, Москва, Большой Чудов пер., 8 стр. 1</span>
    </div>

  </div>


      <div class="footer-content__copyright">
        © ФГБУ «Интеробразование», 2007–{{ new Date().getFullYear() }}
      </div>

</v-container>
  </v-footer>
</template>

<script>
import Navigation from '@/components/navigation/Navigation';  
export default {
name: "Footer",
components: {
  Navigation
},
data() {
  return {
    navLinks: [
      {id: '4', name: 'Личный кабинет', link: {name: 'LkMain'}},
      {id: '5', name: 'КЦП', link: '/kcp_contest'},     
    ],
    helpLinks: [
      {id: '1', name: 'Главная', link: {name: 'Main'}},
      {id: '2', name: 'Контакты', link: {name: 'Contacts'}},
      {id: '2', name: 'Новости', link: {name: 'NewsCategory'}},   

    ]
  }
}
}
</script>

<style lang="sass">
.footer-content 
    display: flex
    justify-content: space-between
    padding: 50px 0 53px 0
    color: #fff
    flex-wrap: wrap
.footer-content__column 
    display: flex
    flex-direction: column
.footer-content__logo 
    max-width: 132px
.footer-content__tel
    margin-bottom: 11px
.footer-content__copyright
    font-family: Montserrat
    font-size: 14px
    font-weight: 600
    line-height: 16px
    text-align: left
    color: #E0EFFA
    text-align: center
    border-top: 1px solid #92A9B9 
    padding: 34px 0 38px 0
.footer-content__email
    font-family: Montserrat
    font-size: 16px
    font-weight: 600
    line-height: 28px
    letter-spacing: 0.01em
.footer-content__adress
    font-family: Montserrat
    font-size: 16px
    font-weight: 500
    line-height: 28px
    letter-spacing: 0.01em
.navigation-item__link:hover
    color: #eb8e1c !important    
</style>