<template>
    <div class="question">
        <FormQuestion v-if="!questionSent && !questionSending && !questionError" v-model="formQuestion" @input="sendFormQuestion" />

         <v-progress-linear
            v-if="questionSending"
                indeterminate
            ></v-progress-linear>


        <div class="question_success" v-if="questionSent && !questionSending">
            <p class="question_success__p">Ваш вопрос успешно отправлен.</p>
            <button @click="sendMore" class="feedback-form__button">Отправить еще</button>
        </div>

        <Alert v-if="questionError" alertType="warning">
            <template v-slot:icon>
                <v-icon :size="30" color="#ED393E">mdi-warning</v-icon>
            </template>
            <template v-slot:default>
                Ошибка отправки
                <button @click="sendMore" class="feedback-form__button">Отправить еще раз</button>
            </template>
        </Alert>

           
    </div>
</template>

<script>
import request from "@/services/request";
import FormQuestion from '@/components/forms/custom/FormQuestion/FormQuestion';
import Alert from '@/components/alerts/Alert';
export default {
    components: {
        FormQuestion,
        Alert
    },
    data() {
        return {
            formQuestion: {
              name: '',
              organization: '',
              position: '',
              email: '',
              text: '',
              accept: false,
            },
            questionSent: false,
            questionSending: false,
            questionError: false,
        }
    },
    computed: {
        dataToSend() {
            return {
                fio: this.formQuestion.name,
                orgName: this.formQuestion.organization,
                position: this.formQuestion.position,
                emailFrom: this.formQuestion.email,
                questionBody: this.formQuestion.text,
                emailSendTo: 'ea.goroziy@ined.ru',
            }
        }
    },
    methods: {
        async sendFormQuestion() {
          if (this.formQuestion.accept) {
            this.questionSending = true;
            const sendRequest = await request(
                {
                    endpoint: '/api/mainPage/saveQuestion',
                    method: 'POST',
                    body: this.dataToSend
                }
            );
            this.questionSending = sendRequest.status === 200 ? false : true;

            if (sendRequest.status === 200) {
                this.questionSent = true;
                this.questionSending = false;
                for (let key in this.formQuestion) {
                this.formQuestion[key] = '';
            }

            } else {
                this.questionError = true;
                this.questionSending = false;
            }

            
          }

        },
        sendMore() {
            for (let key in this.formQuestion) {
                this.formQuestion[key] = '';
            }
            this.questionSent = false;
            this.questionError = false;
        }
    }
}
</script>

<style lang="sass">
.feedback-form__button_disable
    opacity: 0.5
.question_success__p
    font-size: 20px    
</style>