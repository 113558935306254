<template>
        <div class="info-card">
            <div class="info-card__field" v-for="(value, name, index) in data" :key="index">
                <div class="info-card__name">
                   {{name}}:
                </div>
                <div class="info-card__cell">
                    {{value}}
                </div>
            </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
        }
    }
}
</script>
<style lang="sass">
.info-card__button
    margin-top: 10px
    margin-left: 10px
</style>
