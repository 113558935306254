import RegionApi from "@/utils/region";

export  default {
    namespaced: true,
    state: {
        sprDataRegion: {},
        sprDataRegionLoading: false,
        sprDataRegionError: null,
    },
    actions:{
        async dataForSprRegion({state, commit}){
            if (state.sprDataRegion.data) return;
            commit('SET_DATA_SPR_REGION_LOADING', true)
            commit('SET_DATA_SPR_REGION_LOADING', {data: true})
            let sprRes = await RegionApi.sprRegionDataLoad();

            if (sprRes.status===200){
                commit('SET_DATA_SPR_REGION', {data: sprRes.data})
                commit('SET_DATA_SPR_REGION_LOADING', {data: false})
            }
            else {
                commit('SET_DATA_SPR_REGION_LOADING', {data: false})
                commit('SET_DATA_SPR_REGION_ERROR', {data: sprRes.error})
            }
        },
    },
    mutations:{
        ['SET_DATA_SPR_REGION'](state,payload){
            state.sprDataRegion = {data: payload.data, counter: payload.data.length}
        },
        ['SET_DATA_SPR_REGION_LOADING'](state,payload){
            state.sprDataRegionLoading = payload.data
        },
        ['SET_DATA_SPR_REGION_ERROR'](state,payload){
            state.sprDataRegionError = payload.data
        },
    },
    getters:{
        get_spr_data_region(state){
            return state.sprDataRegion
        },
        get_spr_data_region_loading(state){
            return state.sprDataRegionLoading
        },
        get_spr_data_region_error(state){
            return state.sprDataRegionError
        },
    }
}