<template>
  <div>
    <section class="section-score">
      <card-s1 v-if="available[1]" :rowOfValues="rowOfValues" :item="item"/>
    </section>
    <h4 class="score-subtitle">2. Нормированная оценка показателя</h4>
    <section class="section-score">
      <h5 class="score-subsubtitle">
        Расчет нормированной оценки по значению показателя:
      </h5>

      <div class="formula">
        <div class="formula-full">
          <div class="formula__row"><div><span>{{ item.title }}<span class="font-size-little__bottom">N</span> =</span></div>
          <img class="bracket-img" style="height: 120px; margin-right: 0;  width: 20px"
               src="@/assets/img/brackets/open-bracket-removebg-preview.png" alt=""/>
          </div>
        </div>
        <div class="formula-full">
          <div class="formula-half"><span class="font-weight-regular">0, если</span> {{ item.title }}<span class="font-size-little__bottom">0</span> <span
              class="font-weight-regular">≤</span> V<span class="font-size-little__bottom">min</span></div>
          <div class="formula-half"><span class="font-weight-regular">1, если</span> {{ item.title }}<span class="font-size-little__bottom">0</span> <span
              class="font-weight-regular">≥</span> V<span class="font-size-little__bottom">max</span></div>
          <div class="formula-half"><span class="formula-half_underline">{{ item.title }}<span class="font-size-little__bottom">0</span> − V<span
              class="font-size-little__bottom">min</span></span><span class="font-weight-regular ml-1">, в иных</span></div>
          <div class="formula-half">V<span class="font-size-little__bottom">max</span>−V<span
              class="font-size-little__bottom">min</span> <span class="font-weight-regular ml-2">случаях</span></div>
        </div>
        <div class="formula-full">
          <span
          >=
            <img class="bracket-img" style="height: 120px; margin-right: 0; width: 20px"
                 src="@/assets/img/brackets/open-bracket-removebg-preview.png" alt=""/>
            <!--         <img class="bracket-img" src="@/assets/img/brackets/bracket.png" alt=""/>-->
</span>
        </div>
        <div class="formula-full">
          <div class="formula-half">
            <span class="font-weight-regular">0, если</span>   <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'b'])"/> <span class="font-weight-regular">≤ </span>
            <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'min'])"/>
          </div>
          <div class="formula-half">
            <span class="font-weight-regular">1, если</span>    <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'b'])"/> <span class="font-weight-regular"> ≥ </span>
            <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'max'])"/>
          </div>
          <div class="formula-half">
            <span class="formula-half_underline">
              <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'b'])"/>
              − <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'min'])"/></span>
            <span class="font-weight-regular ml-1">, в иных</span>
          </div>
          <div class="formula-half">
            <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'max'])"/> − <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'min'])"/>
            <span class="font-weight-regular ml-3">случаях</span>
          </div>
        </div>
        <div class="formula-full"> <span> =  <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'n'])"/></span> </div>
      </div>

      <div class="calculation__about">
        <p class="calculation__about-text">
          {{ item.title }}<span
            class="font-size-little__bottom">0</span> - значение показателя
        </p>
        <p class="calculation__about-text">
          V<span class="font-size-little__bottom">min</span>,  V<span class="font-size-little__bottom">max</span> -
          установленные минимальное и максимальное значения показателя
        </p>
      </div>
      <card-s2 v-if="available[2]" :rowOfValues="rowOfValues" :item="item"/>
      <h4 class="score-subtitle">3. Сумма баллов по показателю</h4>
      <div class="calculation" >
        <div class="calculation__row mt-2">
          <div class="calculation__cell">{{ item.title }}<span
              class="font-size-little__bottom">{{ foName ? foName.name : '' }}</span></div>
          <div class="calculation__cell calculation__cell_no-grow">=</div>
          <div class="calculation__cell">{{ item.title }}<span
              class="font-size-little__bottom">N</span></div>
          <div class="calculation__cell calculation__cell_no-grow">×</div>
          <div class="calculation__cell">W</div>
          <div class="calculation__cell calculation__cell_no-grow">=</div>
          <div class="calculation__cell">
            <span v-html="checkData(rowOfValues.data[item.dataFieldName + 'n' + rowOfValues.fo])"/></div>
          <div class="calculation__cell calculation__cell_no-grow">×</div>
          <div class="calculation__cell"><span v-html="checkData(rowOfValues.data[item.dataFieldName + 'w'])"/></div>
          <div class="calculation__cell calculation__cell_no-grow"> = </div>
          <div class="calculation__cell"><span v-html="checkData(rowOfValues.data[item.dataFieldName + rowOfValues.fo])"/></div>
        </div>
      </div>
      <p class="calculation__about-text">
        W - вес показателя в сводной оценке
      </p>
    </section>
  </div>
</template>

<script>
import CardS2 from "@/components/cards/custom/indicatiors/details/cardS2";
import CardS1 from "@/components/cards/custom/indicatiors/details/cardS1";

export default {
  name: "cardBottom",
  // eslint-disable-next-line vue/no-unused-components
  components: {CardS1, CardS2,},
  props: {
    available: {
      type: Array,
      default: () => []
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    nameS1Component: {
      type: String,
      default: ''
    },
    nameS2Component: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      foData: [
        {id: 1, name: 'оч'},
        {id: 2, name: 'вч'},
        {id: 3, name: 'зч'},
      ]
    }
  },
  computed: {
    foName() {
      return this.foData.find(el => el.id === this.rowOfValues.fo);
    }
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value,'float',3,false, 'color: #C6C8CB;')
    }
  },
}
</script>

<style lang="sass" scoped>
.calculation__row
  div
    font-size: 18px!important
</style>