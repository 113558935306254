<template>
  <div>
    <h2 class="title app-title_main app__title main-header__title-list" style="font-size: 32px!important;">
      {{ regionName }}
    </h2>
    <!-- <Представитель> -->
    <WrapperPreviewCategory customClass="section-preview_lk mt-4">
      <WrapperCollapse title="Контактная информация представителя региона" :initialOpen="true">
        <ContainerPersonContact
            :blocked="getBlock"
            :can-be-deleted="false"
            :contact="contact1"
            :loading-contacts="savingContact1"
            :saving-data="savingContact1"
            @change-contact="changeContact($event,1)"
            is-single
        />
      </WrapperCollapse>
    </WrapperPreviewCategory>
    <!-- </Представитель> -->
  </div>
</template>
<script>
import WrapperPreviewCategory from "../../components/wrappers/WrapperPreviewCategory";
import WrapperCollapse from "../../components/wrappers/WrapperCollapse";
import ContainerPersonContact from '@/components/contacts/ContainerPersonContact';
import {mapActions, mapGetters} from 'vuex'
import request from "@/services/request";

export default {
  name: "ViewMainRegion",
  components: {
    WrapperPreviewCategory, WrapperCollapse, ContainerPersonContact
  },
  data() {
    return {
      regionName: null,
      contact1:
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: ''
          },
      savingContact1: false,
      loadingContacts1: false,
      errorSaving1: null,
    }
  },
  created() {
    this.getRegionName();
    this.getContacts();
  },
  computed: {
    ...mapGetters('auth', {
      get_replace_id_admin: 'get_replace_id_admin',
      authData: 'authData',
      get_blockedEnterData: 'get_blockedEnterData'
    }),
    ...mapGetters('region', {get_spr_data_region: 'get_spr_data_region'}),
    getBlock(){
      return this.get_blockedEnterData
    },
    regionId(){
      return this.get_replace_id_admin
    },
  },
  methods: {
    ...mapActions('auth', {getActualOrgStatus: 'getActualOrgStatus'}),
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {setCheckFilled:'setCheckFilled'}),
    async getRegionName() {
      await this.getActualOrgStatus();
      this.regionName = this.get_spr_data_region.data.find((el) => {
        return el.regionID == this.get_replace_id_admin
      })?.regionName;
    },
    async getContacts() {
      this.loadingContacts1 = true;
      try {
        const res = await request({
          endpoint: `/api/orgContacts/getRegionContactPerson?regionID=${this.get_replace_id_admin}`,
          method: 'get'
        })
        if (res.status === 200) {
          if (res.data) {
            this.contact1 = res.data
            this.setCheckFilled({headContact: this.contact1, contacts: this.contact1})
          }
        } else {
          if (res.status !== 404)
            await this.addNotification({
              notificationStatus: 'error',
              notificationMessage: 'Ошибка получения данных ответственных исполнителей',
              timeout: 5000
            })
        }
        this.loadingContacts1 = false;
      }catch (e) {
        this.loadingContacts1 = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных ответственных исполнителей',
          timeout: 5000
        })
      }

    },
    async changeContact(obj) {
      this.savingContact1 = true;
      const objArr = Object.entries(obj);
      console.log("obj.entries", objArr)
      const res = await request({
        endpoint: `/api/orgContacts/${obj?.regionContactPersonID ? 'update' : 'add'}SprRegionsContactInfo`,
        method: 'post',
        body: {
          ...obj,
          sprRegionID: this.regionId,
        },
      })
      if (res.status === 200 || res.status === 201) {
        this.contact1 = res.data;
        setTimeout(() => {
          this.savingContact1 = false;
        }, 2000);
      } else {
        this.savingContact1 = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного ',
          timeout: 5000
        })
      }
    },
  }
}
</script>
<style lang="sass">

</style>