<template>
  <div>

    <div class="score-about">
      <p><b>п.4.4.2. Приложения 2 Порядка:</b></p>
      <p>Доходы образовательной организации из всех источников в расчете на приведенный контингент обучающихся</p>
    </div>
    <v-divider class="mt-4"/>
    <h4 class="score-subtitle">1. Значение показателя</h4>
    <h5 class="score-subsubtitle">
      Исходные данные:
    </h5>
    <div class="calculation__about" v-if="rowOfValues.data.sorg == 1">
      <p class="calculation__about-text">Форма 5.17. <br>
        Объем доходов образовательной организации, тыс.руб.</p>
    </div>
    <table class="indicators__table" v-if="rowOfValues.data.sorg == 1">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="3">Объем поступивших средств, всего</th>
        <th colspan="3">в т.ч. от выполнения НИОКР</th>
      </tr>
      <tr class="">
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>

      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" v-html="checkData(rowOfValues.data.ok2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2019)"></td>
      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <b>OK</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>OK</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>OK</b><span class="font-size-little">2019</span>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>
    <div class="calculation__about" v-if="rowOfValues.data.sorg == 0">
      <p class="calculation__about-text">Форма 5.18.<br>
        Выполненный объем работ и услуг научной организацией, организацией дополнительного профессионального
        образования, тыс.руб.</p>
    </div>
    <table class="indicators__table" v-if="rowOfValues.data.sorg == 0">
      <thead class="b42-thead">
      <tr class="">
        <th class="" rowspan="2">Наименование участника конкурса / филиала</th>
        <th class="" colspan="3">Выполнено работ, услуг,<br>
          произведено товаров, всего
        </th>
        <th colspan="3">в т.ч. от выполнения НИОКР</th>
      </tr>
      <tr class="">
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>

      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <th class="">6</th>
        <th class="">7</th>
      </tr>
      </thead>
      <tbody class="b42-tbody">
      <tr>
        <td class="">
          {{ rowOfValues.data.sprOrg.orgName }}
        </td>
        <td class="" v-html="checkData(rowOfValues.data.ok2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.ok2019)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.dn2019)"></td>
      </tr>
      <tr>
        <td colspan="1">
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>
            <img style="height: 15px; margin: 0; width:70px; "
                 src="@/assets/img/brackets/bottom-bracket-removebg-preview.png"
                 alt=""/>
          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
        <td colspan="1" style="">
          <div>

          </div>
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td>
          <b>OK</b><span class="font-size-little">2017</span>
        </td>
        <td>
          <b>OK</b><span class="font-size-little">2018</span>
        </td>
        <td>
          <b>OK</b><span class="font-size-little">2019</span>
        </td>
        <td>
          <!--          <b>+НПР</b><span class="font-size-little">2017</span>-->
        </td>
        <td>
          <!--          <b>+НПР</b><span class="font-size-little">2018</span>-->
        </td>
        <td>
          <!--          <b>+НПР</b><span class="font-size-little">2019</span>-->
        </td>
      </tr>
      </tbody>
    </table>
    <div class="calculation__about">
      <p class="calculation__about-text">Форма 5.1.</p>
      <p class="calculation__about-text">
        Общая численность обучающихся, человек (за счет всех источников финансирования)
      </p>
    </div>
    <table class="indicators__table">
      <thead class="b44-thead">
      <tr class="">
        <th class="" rowspan="3">№ п/г</th>
        <th class="" rowspan="2">Уровень</th>
        <th class="" rowspan="2">Форма обучения</th>
        <th class="" colspan="3">Численность обучающихся
        </th>
        <th rowspan="3"></th>
        <th class="" colspan="5" style="vertical-align: middle; text-align: center">Расчет исходных значений</th>
      </tr>
      <tr class="">
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
        <!--        <th class="">2020 год</th>-->
        <th class="">переменная</th>
        <th class="text-center" colspan="3">значения</th>
      </tr>
      <tr class="">
        <th class="">1</th>
        <th class="">2</th>
        <th class="">3</th>
        <th class="">4</th>
        <th class="">5</th>
        <!--        <th class="">6</th>-->
        <th></th>
        <th class="">2017 год</th>
        <th class="">2018 год</th>
        <th class="">2019 год</th>
      </tr>
      </thead>
      <tbody class="b44-tbody">
      <tr>
        <td class="">1.</td>
        <td class="">Бакалавриат</td>
        <td class="">очная</td>
        <td class="" v-html="checkData(rowOfValues.data.nbo2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nbo2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nbo2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nbo2020)"></td>-->
        <td rowspan="3" style="vertical-align: middle">
          <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
               alt=""/>
          <!--                  =>-->
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NO</span>
          <span class="font-size-little">k</span>
          = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.no2017)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.no2018)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.no2019)"></td>
      </tr>
      <tr>
        <td class="">2.</td>
        <td class="">Специалитет</td>
        <td class="">очная</td>
        <td class="" v-html="checkData(rowOfValues.data.nso2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nso2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nso2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nso2020)"></td>-->
      </tr>
      <tr>
        <td class="">3.</td>
        <td class="">Магистратура</td>
        <td class="">очная</td>
        <td class="" v-html="checkData(rowOfValues.data.nmo2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nmo2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nmo2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nmo2020)"></td>-->
      </tr>
      <tr>
        <td class="">4.</td>
        <td class="">Бакалавриат</td>
        <td class="">очно-заочная</td>
        <td class="" v-html="checkData(rowOfValues.data.nbv2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nbv2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nbv2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nbv2020)"></td>-->
        <td rowspan="3" style="vertical-align: middle">
          <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
               alt=""/>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NV</span><span class="font-size-little">k</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nv2017)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nv2018)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nv2019)"></td>
      </tr>
      <tr>
        <td class="">5.</td>
        <td class="">Специалитет</td>
        <td class="">очно-заочная</td>
        <td class="" v-html="checkData(rowOfValues.data.nsv2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nsv2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nsv2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nsv2020)"></td>-->
      </tr>
      <tr>
        <td class="">6.</td>
        <td class="">Магистратура</td>
        <td class="">очно-заочная</td>
        <td class="" v-html="checkData(rowOfValues.data.nmv2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nmv2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nmv2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nmv2020)"></td>-->
      </tr>
      <tr>
        <td class="">7.</td>
        <td class="">Бакалавриат</td>
        <td class="">заочная</td>
        <td class="" v-html="checkData(rowOfValues.data.nbz2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nbz2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nbz2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nbz2020)"></td>-->
        <td rowspan="3" style="vertical-align: middle">
          <img style="height: 81px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
               alt=""/>
          <!--                  =>-->
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NZ</span><span class="font-size-little">k</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nz2017)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nz2018)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.nz2019)"></td>
      </tr>
      <tr>
        <td class="">8.</td>
        <td class="">Специалитет</td>

        <td class="">заочная</td>
        <td class="" v-html="checkData(rowOfValues.data.nsz2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nsz2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nsz2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nsz2020)"></td>-->

      </tr>
      <tr>
        <td class="">9.</td>
        <td class="">Магистратура</td>
        <td class="">заочная</td>
        <td class="" v-html="checkData(rowOfValues.data.nmz2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nmz2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nmz2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nmz2020)"></td>-->
      </tr>
      <tr>
        <td class="">10.</td>
        <td class="">Ординатура</td>
        <td class="">очная</td>
        <td class="" v-html="checkData(rowOfValues.data.noo2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.noo2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.noo2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.noo2020)"></td>-->
        <td rowspan="2" style="vertical-align: middle">
          <img style="height:57px; margin: 0;" src="@/assets/img/brackets/close-bracket-removebg-preview.png"
               alt=""/>
          <!--                  =>-->
        </td>
        <td class="" rowspan="2" style="vertical-align:middle;"><span style=""><span class="font-weight-bold">NOA</span><span class="font-size-little">k</span> = </span>
        </td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.noa2017)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.noa2018)"></td>
        <td class="" rowspan="3" style="vertical-align:middle;" v-html="checkData(rowOfValues.data.noa2019)"></td>
      </tr>
      <tr>
        <td class="">11.</td>
        <td class="">Ассистентура</td>
        <td class="">очная</td>
        <td class="" v-html="checkData(rowOfValues.data.nko2017)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nko2018)"></td>
        <td class="" v-html="checkData(rowOfValues.data.nko2019)"></td>
        <!--        <td class="" v-html="checkData(rowOfValues.data.nko2020)"></td>-->
      </tr>
      </tbody>
    </table>
    <h5 class="score-subsubtitle">Расчет приведенных контингентов по корреспондирующим УГСН по данным 2020 года:</h5>
    <div class="calculation">
      <div class="calculation__row">
        <div class="calculation__cell">PN<span class="font-size-little">2017</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="font-weight-regular"> 1.0 ×</span> NO<span class="font-size-little">2017</span>
        </div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> NV<span class="font-size-little">2017</span>
        </div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular"> 0.1 ×</span> NZ<span class="font-size-little">2017</span>
        </div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell">NOA<span class="font-size-little">2017</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="font-weight-regular">1.0 × </span>
          <span v-html="checkData(rowOfValues.data.no2017)"></span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular">0.25 × </span>
          <span v-html="checkData(rowOfValues.data.nv2017)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span  class="font-weight-regular ml-1"> 0.1 × </span>
          <span v-html="checkData(rowOfValues.data.nz2017)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell">
          <span class="ml-1" v-html="checkData(rowOfValues.data.noa2017)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="ml-1" v-html="checkData(rowOfValues.data.pn2017)"></span></div>
      </div>
      <div class="calculation__row">
        <div class="calculation__cell">PN<span class="font-size-little">2018</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="font-weight-regular"> 1.0 ×</span> NO<span class="font-size-little">2018</span>
        </div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular"> + </div>
        <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span> NV<span class="font-size-little">2018</span>
        </div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular"> + </div>
        <div class="calculation__cell"><span class="font-weight-regular ml-1"> 0.1 ×</span> NZ<span class="font-size-little">2018</span>
        </div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular"> + </div>
        <div class="calculation__cell">NOA<span class="font-size-little">2018</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="font-weight-regular">1.0 × </span>
          <span v-html="checkData(rowOfValues.data.no2018)"></span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular">0.25 × </span>
          <span v-html="checkData(rowOfValues.data.nv2018)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular ml-1"> 0.1 × </span>
          <span v-html="checkData(rowOfValues.data.nz2018)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell">
          <span class="ml-1" v-html="checkData(rowOfValues.data.noa2018)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="ml-1" v-html="checkData(rowOfValues.data.pn2018)"></span></div>
      </div>
      <div class="calculation__row">
        <div class="calculation__cell">PN<span class="font-size-little">2019</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="font-weight-regular"> 1.0 ×</span> NO<span class="font-size-little">2019</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular">0.25 ×</span>  NV<span class="font-size-little">2019</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular ml-1"> 0.1 ×</span>  NZ<span class="font-size-little">2019</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell">NOA<span class="font-size-little">2019</span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell"><span class="font-weight-regular">1.0 × </span>
          <span v-html="checkData(rowOfValues.data.no2019)"></span></div>
        <div class="calculation__cell calculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"><span class="font-weight-regular"> 0.25 × </span>
          <span v-html="checkData(rowOfValues.data.nv2019)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell"> <span class="font-weight-regular ml-1"> 0.1 × </span>
          <span v-html="checkData(rowOfValues.data.nz2019)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">  +  </div>
        <div class="calculation__cell">
          <span class="ml-1" v-html="checkData(rowOfValues.data.noa2019)"></span></div>
        <div class="calculation__cellcalculation__cell_no-grow font-weight-regular">=</div>
        <div class="calculation__cell">   <span class="ml-1" v-html="checkData(rowOfValues.data.pn2019)"></span></div>
      </div>
    </div>
    <section class="section-score">
      <h5 class="score-subsubtitle">Расчет значения показателя:</h5>

      <div class="formula">
        <div class="formula-full"><span>K42<span class="font-size-little">0</span> =</span></div>
        <div class="formula-full"><span style="font-size: 28px">(</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            ОК<span class="font-size-little">2017</span>
          </div>
          <div class="formula-half" style="margin-left: 17%">PN<span class="font-size-little">2017</span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            ОК<span class="font-size-little">2018</span>
          </div>
          <div class="formula-half" style="margin-left: 17%">PN<span class="font-size-little">2018</span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            ОК<span class="font-size-little">2019</span>
          </div>
          <div class="formula-half" style="margin-left: 17%">PN<span class="font-size-little">2019</span></div>
        </div>
        <div class="formula-full"><span style="font-size: 28px">)</span></div>
        <div class="formula-full font-weight-regular"><div>÷ <span class="font-weight-bold"> n </span> =</div></div>
        <!--        /-->
        <div class="formula-full"><span style="font-size: 28px">(</span></div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.ok2017)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.pn2017)"></span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.ok2018)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.pn2018)"></span></div>
        </div>
        <div class="formula-full">
          <div class="font-weight-regular">+</div>
        </div>
        <div class="formula-full">
          <div class="formula-half formula-half_underline  px-3">
            <span v-html="checkData(rowOfValues.data.ok2019)"></span>
          </div>
          <div class="formula-half" style="margin-left: 10%"><span v-html="checkData(rowOfValues.data.pn2019)"></span></div>
        </div>
        <div class="formula-full"><span style="font-size: 28px">)</span></div>
        <div class="formula-full font-weight-regular"><div>÷ <span class="font-weight-bold" v-html="checkData(rowOfValues.data.x2)"> </span> =</div></div>
        <div class="formula-full"><span v-html="checkData(rowOfValues.data.k42b0)"></span></div>
      </div>


      <div class="calculation__about">
        <p class="calculation__about-text">
          OKk - объем всех поступлений образовательной организации из всех источников в k-ом году;
        </p>
        <p class="calculation__about-text">
          PNk - приведенный контингент студентов образовательной организации, по данным k-го года;
        </p>
        <p class="calculation__about-text">
          n - число лет, за которые имеются отчетные данные PNk.
        </p>

      </div>
    </section>

    <!-- calculation -->

    <!-- formula -->


  </div>
</template>

<script>
export default {
  name: "k42",
  props: {
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    checkData(value) {
      return value;
      // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value, 'float', 3, false)
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators__table
  width: 100%

.b42-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px

.b42-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px

  tr
    &:nth-child(2)
      td
        border: 0

    &:nth-child(3)
      td
        border: 0

.b44-thead
  th
    background-color: inherit
    border: 1px solid #546e7a
    padding: 5px
  tr
    &:first-child
      th
        &:nth-child(n+4)
          border: 0
          min-width: 20px
    &:nth-child(2)
      th
        &:nth-child(n+4)
          border: 0
        &:nth-child(4)
          border: 0
          padding-left: 10px
          border-bottom: 1px solid #546e7a
        //
        &:nth-child(5)
          border: 0
          //text-align: right
          padding-right: 10px
          border-bottom: 1px solid #546e7a
    &:last-child
      th
        &:nth-child(n+6)
          border: 0

//&:nth-child(7)
//  border: 0
//  padding-left: 10px
//  border-bottom: 1px solid #546e7a
//
//&:nth-child(8)
//  border: 0
//  text-align: right
//  padding-right: 10px
//  border-bottom: 1px solid #546e7a


.b44-tbody
  td
    text-align: center
    border: 1px solid #546e7a
    padding: 3px

    &:nth-child(7)
      border: 0
      min-width: 20px

    //&:nth-child(8)
    //  padding-right: 5px
    //  text-align: right

    &:nth-child(8)
      border: 0
      padding-left: 2px
      text-align: right

    &:nth-child(n+9)
      border: 0
      padding-left: 2px
//text-align: left

</style>