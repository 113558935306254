<template>
		<form @submit.prevent="onSubmit" class="feedback-form">		
            <div class="feedback-form__field">
                <label class="feedback-form__label" for="organization">Организация</label>
                <BInput type="text" v-model="$attrs.value.organization" name="organization" placeholder="Введите организацию"/>
            </div>
            <div class="feedback-form__field feedback-form__field_size_half">
				<label class="feedback-form__label" for="name">ФИО</label>
				<BInput type="text" v-model="$attrs.value.name" name="name" placeholder="Введите ФИО"/>
			</div>
			<div class="feedback-form__field feedback-form__field_size_half">
				<label class="feedback-form__label" for="name">Должность</label>
				<BInput type="tel" v-model="$attrs.value.position" name="tel" placeholder="Введите должность"/>
			</div>
			<div class="feedback-form__field">
				<label class="feedback-form__label" for="email">Контактный e-mail</label>
				<BInput type="email" v-model="$attrs.value.email" name="email" placeholder="Введите e-mail"/>
			</div>
			<div class="feedback-form__field">
				<label class="feedback-form__label" for="text">Текст обращения</label>
				<BTextarea v-model="$attrs.value.text" name="text" placeholder="Введите обращение" />
			</div>		
			<div class="feedback-form__field feedback-form__field_сheckbox">
				<BCheckbox v-model="$attrs.value.accept" propText="Согласие на обработку перс. данных" :propSize="30" />
                <span>Даю согласие на обработку персональных данных</span>
			</div>
			
			<button type="submit" class="feedback-form__button" :class="{'feedback-form__button_disable' : !$attrs.value.accept}">Отправить сообщение</button>
		</form>
</template>    

<script>
import BInput from '@/components/controls/BInput/BInput';
import BTextarea from '@/components/controls/BTextarea';
import BCheckbox from '@/components/controls/BCheckbox/BCheckbox';
export default {
    components: {
        BInput, BTextarea, BCheckbox
    },
    methods: {
        onSubmit() {
            this.$emit('input', this.$attrs.value);
        }
    }
}
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.feedback-form 
    display: flex
    flex-wrap: wrap
.feedback-form__field 
    display: flex
    flex-direction: column
    margin-bottom: 30px
    flex-basis: 100%
.feedback-form__label 
    font-size: 20px
    font-weight: 500
.feedback-form__input
    color: $greyColor
    background-color: transparent
    font-size: 18px
    height: 45px
    width: 99%
    border-bottom: 1px solid #9DABC9
    outline: none
.feedback-form__textarea 
    height: 127px
    resize: none
    width: 99%
    border: 0
    border-bottom: 1px solid #ABB3C7
    color: $greyColor
    font-size: 18px
.feedback-form__field_size_half 
    flex-basis: 50%
.feedback-form__input_size_half 
    width: 98%
.feedback-form__button
    text-transform: uppercase
    padding: 20px 35px
    background: #00599b
    color: #fff
    font-weight: 500
    font-size: 18px
    min-width: 250px
    transition: 0.3s
    border-radius: 10px
.feedback-form__button:hover 
    background: #0292e1
    transition: 0.3s
.feedback-form__field_сheckbox
    flex-direction: row
.feedback-form__button_disable
    opacity: 0.5    
</style>