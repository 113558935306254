<template>

  <section class="section-score">
<!--    <div class="score__header">-->
<!--      <div class="score-title">-->
<!--        <h3 class="score-title__name">{{ item.title }}</h3>-->
<!--        <span class="score-title_about">{{ item.about }}</span>-->
<!--      </div>-->
<!--      <div class="score-scale">-->
<!--        <div class="score-scale__line"-->
<!--             :style="calcBackground(rowOfValues.data[item.dataFieldName+rowOfValues.fo], rowOfValues.data[item.dataFieldName+'W'])">-->
<!--          <div-->
<!--              class="score-scale__score"-->
<!--          >-->
<!--            {{ rowOfValues.data[item.dataFieldName + rowOfValues.fo] }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="score-scale__max">-->
<!--          <span>{{ rowOfValues.data[item.dataFieldName + 'W'] }}</span>-->
<!--          <span>max</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="score-about" v-html="item.descriptionText">-->
<!--    </div>-->
  </section>
</template>

<script>
export default {
  name: "cardTopDescription",
  props: {
    nameComponent: {
      type: String,
      default: ''
    },
    item: {
      type: Object
    },
    rowOfValues: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    calcBackground(value, maxValue) {
      let a = Number.parseFloat(value)
      let b = Number.parseFloat(maxValue)
      let per = a * 100 / b
      return isNaN(per) ? `  background: #eb8e1c2e` : `background: -webkit-linear-gradient(left, #eb8e1c ${per}%,#eb8e1c2e ${per}%);
      background:    -moz-linear-gradient(left, #eb8e1c ${per}%,#eb8e1c2e ${per}%);
      background:     -ms-linear-gradient(left, #eb8e1c ${per}%,#eb8e1c2e ${per}%);
      background:      -o-linear-gradient(left, #eb8e1c ${per}%,#eb8e1c2e ${per}%);
      background:         linear-gradient(to right, #eb8e1c ${per}%,#eb8e1c2e ${per}%);`
    }
  },
}
</script>

<style scoped>

</style>