<template>
  <div>
    <div v-if="empty">
      Выберите значение по которому хотите посмотреть подробности
    </div>
    <div
        v-else-if="error !=null"
    >
      Ошибка загрузки данных
    </div>
    <v-progress-linear indeterminate v-else-if="loading"/>
    <div v-else>
      <HeaderMain
          v-if="indicators"

      >
        <slot v-if="indicators.sprKCPGroup">
          <h1 class="main-header__title-list">{{ indicators.sprOrg.orgName }}</h1>
          <p class="header-subtitle-indicators"><span class="font-weight-bold"> {{
              indicators.sprKCPGroup.sprUsl.uslName
            }}</span>.
            {{ indicators.sprKCPGroup.code }} {{
              indicators.sprKCPGroup.name
            }}. {{ foName }}</p>
        </slot>
      </HeaderMain>
      <HeaderMain
          v-if="indicators"
      >
        <slot>
          <h2 class="main-color font-weight-bold">Итоги распределения КЦП</h2>
        </slot>
      </HeaderMain>
      <div class="header-final-value  ">
        <div
            v-for="head in yearsArr"
            :key="head.id"
            class="header-final-value__form border-bottom"
        >
          <div class="header-final-value__form-cell ">
            <span class="header-final-value__about-top font-size-small"
                  :class="head.dopInfo ? 'darker-orange-color' : ''"
                  v-html="head.year"></span>
          </div>
        </div>
      </div>
      <div class="header-final-value">
        <div
            v-for="head in yearsArr"
            :key="head.id"
            class="header-final-value__form"
        >
          <div class="header-final-value__form-cell">
            <span class="header-final-value__about font-size-big"
                  :class="head.dopInfo ? 'darker-orange-color font-size-extra-big' :
                  head.dataField === 'kcpd' ? indicators[head.dataField] >= 0 ?  indicators[head.dataField] === 0 ? 'black-color':'main-color' : 'danger-color' : ''"
                  >
              {{head.dataField === 'kcpd' ? indicators[head.dataField] > 0 ?
                '+'+indicators[head.dataField] : ''+indicators[head.dataField]
                : indicators[head.dataField]}}
            </span>
          </div>
        </div>
      </div>
      <div class="header-final-value ">
        <div class="header-final-value__form bracket">
          <div class="header-final-value__form-cell" style="width: 100%;justify-content: center ">
            <div style="height: 47px">

            </div>
            <div class="header-final-value mb-0 font-size-medium" style="width: 100%; line-height: 17px;">
              Получено мест по конкурсным этапам
            </div>
            <div v-if="indicators.sblock === 1 && indicators.slost === 1"
                 class="header-final-value darker-orange-color__important font-size-small" style="width: 100%">
              потери мест из-за мин.заявки
            </div>
          </div>
        </div>
        <div class="header-final-value__form bracket ">
          <div style="display:flex; flex-wrap: wrap; width: 100%; justify-content: center;">
            <div style="transform: rotate(90deg); max-height: 43px; max-width: 500px; margin-left: 460px">
              <!--              <img  :src="require(`@/assets/img/brackets/curly-brace.svg`)" alt="bracket">-->
              <svg width="27" height="500" viewBox="0 0 43 222" fill="none" xmlns="http://www.w3.org/2000/svg"
                   preserveAspectRatio="none">
                <path
                    d="M41 219.962L29.5 219.962C23.1487 219.962 18 214.803 18 208.452C18 176.566 18 159.351 18 123.695"
                    stroke="#eb722e" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.5 110.859L11.9025 114.905C15.5787 116.335 18 119.875 18 123.819V123.819" stroke="#eb722e"
                      stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M41 2.03772L29.5 2.03772C23.1487 2.03772 18 7.19685 18 13.5481C18 45.4342 18 62.6493 18 98.3053"
                    stroke="#eb722e" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.5 111.141L11.9025 107.095C15.5787 105.665 18 102.125 18 98.1807V98.1807" stroke="#eb722e"
                      stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <div class="header-final-value mb-0 pb-0" style="width: 100%; align-items: end;">
              <div class="header-final-value__form justify-center">
                <span class="font-size-big">{{ indicators.kcp0 }}</span>
              </div>
              <div class="header-final-value__form justify-center">
                <div v-if="indicators.kcp1Sum!= null">
                  <span class="font-size-big">{{ indicators.kcp1 }}</span>
                  <span class="mx-1 font-size-small">из</span>
                  <span class="font-size-small">{{ indicators.kcp1Sum }}</span>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
              </div>
              <div class="header-final-value__form justify-center">
                <div v-if="indicators.kcp2Sum!= null">
                  <span class="font-size-big">{{ indicators.kcp2 }}</span>
                  <span class="mx-1 font-size-small">из</span>
                  <span class="font-size-small">{{ indicators.kcp2Sum }}</span>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
              </div>
              <div class="header-final-value__form justify-center">
                <div v-if="indicators.kcp3Sum!= null">
                  <span class="font-size-big">{{ indicators.kcp3 }}</span>
                  <span class="mx-1 font-size-small">из</span>
                  <span class="font-size-small">{{ indicators.kcp3Sum }}</span>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
              </div>
              <div class="header-final-value__form justify-center">
                <div v-if="indicators.kcp4Sum!= null">
                  <span class="font-size-big"> {{ indicators.kcp4 }}</span>
                  <span class="mx-1 font-size-small">из</span>
                  <span class="font-size-small">{{ indicators.kcp4Sum }}</span>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
              </div>
            </div>
            <div class="header-final-value mb-0 py-0" style="width: 100%;align-items: flex-start;">
              <div class="header-final-value__form justify-center">
                <span class="font-size-extra-small">базовый</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span class="font-size-extra-small">федеральный</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span class="font-size-extra-small">региональный</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span class="font-size-extra-small">окружной</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span class="font-size-extra-small">заключительный</span>
              </div>
            </div>
            <div v-if="indicators.sblock === 1 && indicators.slost === 1"
                 class="header-final-value darker-orange-color__important"
                 style="width: 100%;align-items: flex-start;">
              <div class="header-final-value__form justify-center">
                <span>{{ indicators.kcp0Lost }}</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span>{{ indicators.kcp1Lost }}</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span>{{ indicators.kcp2Lost }}</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span>{{ indicators.kcp3Lost }}</span>
              </div>
              <div class="header-final-value__form justify-center">
                <span>{{ indicators.kcp4Lost }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="indicators.sblock === 1">
        <HeaderMain
            v-if="indicators"
        >
          <slot>
            <h2 class="darker-orange-color"> Анализ причин неполучения КЦП (неполучения КЦП в размере заявки)</h2>
          </slot>
        </HeaderMain>
        <ul class="indicators-ul-list " style="font-size: 14px;">
          <li v-for="(item, i ) in  indicators.notetxtArray" :key="i">
          {{ item }}
          </li>
        </ul>
        <!--        <p v-html="indicators.notetxt"></p>-->
        <HeaderMain
            v-if="indicators"
        >
          <slot>
            <h2 class="darker-orange-color mt-5"> Анализ рейтинга сводной оценки</h2>
          </slot>
        </HeaderMain>
        <div>
          <card-indicator-for-final-compare
              :item="{
                    title: 'Сводная оценка заявки',
                    about: 'сумма баллов по показателям',
                    count: indicators.o,
                    dataFieldName: 'generalValue',
                    max: indicators.ow,
                    centerValuesHide: true,
                    available: true,
                    typeStyle: 'general',
                    idUsl: idUsl
             }"
              :row-of-values="indicator"
          >
          </card-indicator-for-final-compare>
          <div class="general-value-block mt-5">
            <div class="general-value-block__one">
              <div class="darker-orange-color font-size-medium">Рейтинги оценки</div>
              <div>по конкурсным этапам</div>
            </div>
            <div class="general-value-block__two">
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.r1Sum != null"
                     class="darker-orange-color font-size-small"><span class="font-size-big">{{ indicators.r1 }}</span> из
                  {{ indicators.r1Sum }}
                </div>
                <div v-if="indicators.r1Sum != null">
                  <v-icon size="12" :color=" indicators.r1Stars >= 1 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r1Stars >= 2 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r1Stars >= 3 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r1Stars >= 4 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r1Stars >= 5 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">федеральный</div>
              </div>
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.r2Sum != null" class="darker-orange-color font-size-small"><span class="font-size-big">{{ indicators.r2 }}</span> из
                  {{ indicators.r2Sum }}
                </div>
                <div v-if="indicators.r2Sum != null">
                  <v-icon size="12" :color=" indicators.r2Stars >= 1 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r2Stars >= 2 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r2Stars >= 3 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r2Stars >= 4 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r2Stars >= 5 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div  class="font-size-extra-small">региональный</div>
              </div>
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.r3Sum != null" class="darker-orange-color font-size-small"><span class="font-size-big">{{ indicators.r3 }}</span> из
                  {{ indicators.r3Sum }}
                </div>
                <div v-if="indicators.r3Sum != null">
                  <v-icon size="12" :color=" indicators.r3Stars >= 1 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r3Stars >= 2 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r3Stars >= 3 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r3Stars >= 4 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r3Stars >= 5 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">окружной</div>
              </div>
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.r4Sum != null" class="darker-orange-color font-size-small"><span class="font-size-big">{{ indicators.r4 }}</span> из
                  {{ indicators.r4Sum }}
                </div>
                <div v-if="indicators.r4Sum != null">
                  <v-icon size="12" :color=" indicators.r4Stars >= 1 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r4Stars >= 2 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r4Stars >= 3 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r4Stars >= 4 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                  <v-icon size="12" :color=" indicators.r4Stars >= 5 ? '#eb722e' : '#f9d3be'">mdi-star</v-icon>
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">заключительный</div>
              </div>
            </div>
          </div>
          <div class="general-value-block mt-5">
            <div class="general-value-block__one justify-start">
              <div class="font-size-medium">Оценки иных участников:</div>
              <div>отставание:</div>
            </div>
            <div class="general-value-block__two">
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.olim1D != null" class="font-size-big">{{ indicators.olim1.toFixed(3) }}</div>
                <div v-else style="opacity: 0;">!</div>
                <div v-if="indicators.olim1D != null" class="darker-orange-color"> {{
                    indicators.olim1D.toFixed(3)
                  }}
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">
                  допустимый минимум
                </div>
                <div class="font-size-extra-small">федерального этапа</div>
              </div>
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.olim2D != null" class="font-size-big">{{ indicators.olim2.toFixed(3) }}</div>
                <div v-else style="opacity: 0;">!</div>
                <div v-if="indicators.olim2D != null" class="darker-orange-color"> {{
                    indicators.olim2D.toFixed(3)
                  }}
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">
                  допустимый минимум
                </div>
                <div class="font-size-extra-small">регионального этапа</div>
              </div>
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.olim4D != null" class="font-size-big" >{{ indicators.olim4.toFixed(3) }}</div>
                <div v-else style="opacity: 0;">!</div>
                <div v-if="indicators.olim4D != null" class="darker-orange-color"> {{
                    indicators.olim4D.toFixed(3)
                  }}
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">
                  допустимый минимум
                </div>
                <div class="font-size-extra-small">заключительного этапа</div>
              </div>
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.obest1D != null" style="position: relative">
                  <div class="font-size-big">{{ indicators.obest1.toFixed(3) }} <span class="arrow-up pt-4" style="position: absolute"></span>
                  </div>
                </div>
                <div v-else style="opacity: 0;">!</div>
                <div v-if="indicators.obest1D != null" class="darker-orange-color">{{
                    indicators.obest1D.toFixed(3)
                  }}
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">
                  региональный лидер
                </div>
                <div class="font-size-extra-small">по УГСН (НПС)</div>
              </div>
              <div class="general-value-block__two-first_row">
                <div v-if="indicators.obest0D != null" style="position: relative">
                  <div class="font-size-big">{{ indicators.obest0.toFixed(3) }} <span class="arrow-up__darker pt-4"
                                                                style="position: absolute"></span></div>
                </div>
                <div v-else style="opacity: 0;">!</div>
                <div v-if="indicators.obest0D != null" class="darker-orange-color">{{
                    indicators.obest0D.toFixed(3)
                  }}
                </div>
                <div v-else>
                  <v-icon size="14">
                    mdi-window-close
                  </v-icon>
                </div>
                <div class="font-size-extra-small">
                  лидер
                </div>
                <div class="font-size-extra-small">по УГСН (НПС)</div>
              </div>
            </div>
          </div>
        </div>
        <HeaderMain
            v-if="indicators"
        >
          <slot>
            <h2 class="darker-orange-color mt-5">Анализ значений показателей</h2>
          </slot>
        </HeaderMain>
        <div class="indicators-1">
          <div
              class="b-indicator__wrapper border-null"
          >
            <div class="b-indicator-head font-size-small">
              <div class="b-indicator-head__form">
                Показатели
              </div>
              <div class="b-indicator-head__form">
                <div>Сумма балов</div>
                <div class="ml-3 font-size-extra-small"></div>
              </div>
              <div class="b-indicator-head__form-analyzing">
                <div class="font-size-extra-small">min</div>
                <div>Значение показателя</div>
                <div class="font-size-extra-small">max</div>
              </div>
            </div>
            <card-indicator-for-final-compare
                v-for="(b,l) in bIndicatorsCalculated" :key="l"
                :item="b" :row-of-values="indicator"
            >
            </card-indicator-for-final-compare>
          </div>
        </div>
        <p class="font-size-small" style="text-align: justify; opacity: 0.5;">
          Примечание: средние значения показателей определены с учетом максимальных и минимальных значений, установленных Порядком конкурсного отбора в целях нормирования. В случае, если значение показателя было выше максимального (ниже минимального), для расчета средних оно принималось равным максимальному (минимальному) значению.
        </p>
        <HeaderMain
            v-if="indicators"
        >
          <slot>
            <h2 class="darker-orange-color mt-4"> Факторный анализ причин изменения КЦП на 2022/23 год по сравнению с
              КЦП на 2021/22 год</h2>
          </slot>
        </HeaderMain>
        <div class="indicators1">
          <div
              class="b-indicator__wrapper border-null"
          >
            <div class="b-indicator-head font-size-small ">
              <div class="b-indicator-head__form ">
                Фактор
              </div>
              <div class="b-indicator-head__form " style="flex-basis: 150px;">
                Влияние
              </div>
              <div class="b-indicator-head__form ">
                Описание
              </div>
            </div>
            <template v-if="indicator.data.sf === 1">
              <div
                  class="b-indicator-body py-3"
                  :class="indicator.data.sf1 === 0 ? 'b-indicator-body__opacity':''"
              >
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">1</div>
                  <div>Изменение методики конкурсного распределения КЦП</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f1)">
                  <span v-if="indicator.data.f1 != null  ">{{ indicator.data.f1 > 0 ? '+'+indicator.data.f1 : indicator.data.f1 }}</span>
                </div>
                <div class="b-indicator-body__form">
                  Основным изменением, повлиявшим на распределение КЦП на 2022/2023 год стал механизм, в соответствии с
                  которым, если на региональном этапе общего объема КЦП недостаточно для распределения организации КЦП в
                  объеме, необходимом для достижения мин. заявки, соответствующий объем КЦП распределялся следующей по
                  рейтингу заявке в регионе. В ряде случаев это привело к тому, что больший объем КЦП был распределен
                  вузам
                  на региональном этапе и меньший – на окружном и заключительном этапах. Еще одним важным изменением
                  стала
                  регионализация распределения КЦП по программам ординатуры. Влияние данного изменения учтено в рамках
                  следующего фактора
                </div>
              </div>
              <div class="b-indicator-body py-3"
                   :class="indicator.data.sf2 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">2</div>
                  <div>Изменение общего объема КЦП и (или) региональной квоты</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f2)">
                  <span v-if="indicator.data.f2 != null ">{{ indicator.data.f2 > 0 ? '+'+indicator.data.f2 : indicator.data.f2 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение общего объема распределяемых бюджетных мест (в т.ч.
                  объема, запланированного для отдельного региона) может привести к росту КЦП, а его снижение – к
                  соответствующему снижению КЦП для организации. Здесь же учтен переход к распределению КЦП по
                  программам ординатуры с учетом региональных квот (аналогично программам специалитета и магистратуры).
                </div>
              </div>
              <div class="b-indicator-body py-3"
                   :class="indicator.data.sf3 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">3</div>
                  <div>Изменение минимальных значений сводной оценки для допуска к распределению КЦП</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f3)">
                  <span v-if="indicator.data.f3 != null ">{{ indicator.data.f3 > 0 ? '+'+indicator.data.f3 : indicator.data.f3 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение минимальных значений сводной оценки заявки могло привести
                  к недопуску ряда заявок к распределению КЦП на федеральном и последующих этапах и, как следствие,
                  снижению КЦП для соответствующих организаций. И наоборот, ряд образовательных организаций мог выиграть
                  от того, что часть участников не была допущена к распределению КЦП на указанных этапах.
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf4 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">4</div>
                  <div>Изменение минимальных заявок образовательных организаций</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f4)">
                  <span v-if="indicator.data.f4 != null  ">{{ indicator.data.f4 > 0 ? '+'+indicator.data.f4 : indicator.data.f4 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение минимальной заявки образовательной организации может
                  привести к обнулению КЦП на одном из этапов распределения, а снижение – наоборот, к росту КЦП.
                  Аналогичные изменения по другим организациям также могли оказать влияние на объем распределенных
                  образовательной организации КЦП (например, если число обнулений на базовом этапе по другим
                  организациям снизилось, то объем КЦП, подлежащий распределению на региональном и последующих этапах,
                  также снизится; это, в свою очередь, могло привести к снижению КЦП для образовательной организации,
                  даже если ее минимальная заявка осталась неизменной)
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf5 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">5</div>
                  <div>Изменение заявок образовательных организаций</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f5)">
                  <span v-if="indicator.data.f5 != null ">{{ indicator.data.f5 > 0 ? '+'+indicator.data.f5 : indicator.data.f5 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение заявки образовательной организации при достаточно высоком
                  рейтинге заявки могло привести к росту КЦП. Снижение заявки при наличии КЦП на 2021/22 год могло
                  привести к соответствующему снижению КЦП. Аналогичные изменения по другим организациям также могли
                  оказать влияние на объем распределенных образовательной организации КЦП. Например, если организации,
                  имеющие более высокий рейтинг, увеличили свои заявки, то объем КЦП, распределяемый организации, мог
                  снизиться. И наоборот, если лидеры рейтинга свои заявки сократили, распределенный организации объем
                  КЦП мог возрасти
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf6 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">6</div>
                  <div>Снижение влияния переходного периода с 85% до 60%</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f6)">
                  <span v-if="indicator.data.f6 != null ">{{ indicator.data.f1 > 0 ? '+'+indicator.data.f6 : indicator.data.f6 }}</span>
                </div>
                <div class="b-indicator-body__form">Снижение влияния переходного периода (доли КЦП, распределяемых
                  пропорционально КЦП на 2018-2020 гг.) могло привести к снижению числа мест для образовательных
                  организаций, имеющих невысокий рейтинг заявки и наоборот, росту числа мест для образовательных
                  организаций с высокими показателями оценки деятельности.
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf7 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">7</div>
                  <div>Изменение доли федерального этапа по УГСН (НПС)</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f7)">
                  <span v-if="indicator.data.f7 != null ">{{ indicator.data.f7 > 0 ? '+'+indicator.data.f7 : indicator.data.f7 }}</span>
                </div>
                <div class="b-indicator-body__form">
                  В ситуации, когда доля федерального этапа увеличивается до 100%, объемы КЦП могли увеличиться для
                  образовательных организаций, находящихся в крупных регионах (кроме столичных) и имеющих высокий
                  рейтинг заявки. И наоборот, переход к региональному распределению мог повысить объем КЦП для
                  образовательных организаций, имеющих не очень высокий рейтинг, но расположенных в регионах с высокой
                  потребностью в подготовке соответствующих кадров
                </div>
              </div>
              <div class="b-indicator-body   py-3" :class="indicator.data.sf8 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">8</div>
                  <div>Изменение рейтинга и (или) сводных оценок заявок</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f8)">
                  <span v-if="indicator.data.f8 != null ">{{ indicator.data.f8 > 0 ? '+'+indicator.data.f8 : indicator.data.f8 }}</span>
                </div>
                <div class="b-indicator-body__form">При повышении рейтинга заявки относительно других организаций
                  распределяемый объем КЦП мог вырасти, а при снижении рейтинга – наоборот, снизиться.
                </div>
              </div>
              <div class="b-indicator-body   py-3">
                <div class="b-indicator-body__form darker-orange-color" style="font-size: 20px; ">
                  Всего
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.fsum)">
                  {{ indicator.data.fsum > 0 ? '+'+indicator.data.fsum : indicator.data.fsum }}
                </div>
                <div class="b-indicator-body__form"></div>
              </div>
            </template>
            <template v-else>

              <div class="b-indicator-body   py-3" :class="indicator.data.sf1 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">1</div>
                  <div>Изменение методики конкурсного распределения КЦП</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f1)">
                  <span v-if="indicator.data.f1 != null ">{{ indicator.data.f1 > 0 ? '+'+indicator.data.f1 : indicator.data.f1 }}</span>
                </div>
                <div class="b-indicator-body__form">Существенных изменений в методику распределения внесено не было.
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf2 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">2</div>
                  <div>Изменение общего объема КЦП</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f2)">
                  <span v-if="indicator.data.f2 != null ">{{ indicator.data.f2 > 0 ? '+'+indicator.data.f2 : indicator.data.f2 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение общего объема распределяемых бюджетных мест могло
                  привести к росту КЦП, а его снижение – к соответствующему снижению КЦП для организации.
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf3 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">3</div>
                  <div>Изменение минимальных значений сводной оценки для допуска к распределению КЦП</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f3)">
                  <span v-if="indicator.data.f3 != null ">{{ indicator.data.f3 > 0 ? '+'+indicator.data.f3 : indicator.data.f3 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение минимальных значений сводной оценки заявки могло привести
                  к недопуску ряда заявок к распределению КЦП на федеральном и заключительном этапах и, как следствие,
                  снижению КЦП для соответствующих организаций до нуля. И наоборот, ряд образовательных организаций мог
                  выиграть от того, что часть участников не была допущена к распределению КЦП.
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf4 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">4</div>
                  <div>Изменение минимальных заявок образовательных организаций</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f4)">
                  <span v-if="indicator.data.f4 != null ">{{ indicator.data.f4 > 0 ? '+'+indicator.data.f4 : indicator.data.f4 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение минимальной заявки образовательной организации могло
                  привести к обнулению КЦП на одном из этапов распределения, а снижение – наоборот, к росту КЦП.
                  Аналогичные изменения по другим организациям также могли оказать влияние на объем распределенных
                  образовательной организации КЦП (например, если число обнулений на базовом этапе по другим
                  организациям снизилось, то объем КЦП, подлежащий распределению на последующих этапах, также снизится;
                  это, в свою очередь, могло привести к снижению КЦП для образовательной организации, даже если ее
                  минимальная заявка осталась неизменной)
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf5 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">5</div>
                  <div>Изменение заявок образовательных организаций</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f5)">
                  <span v-if="indicator.data.f5 != null ">{{ indicator.data.f5 > 0 ? '+'+indicator.data.f5 : indicator.data.f5 }}</span>
                </div>
                <div class="b-indicator-body__form">Увеличение заявки образовательной организации при достаточно высоком
                  рейтинге заявки могло привести к росту КЦП. Снижение заявки при наличии КЦП на 2021/22 год могло
                  привести к соответствующему снижению КЦП. Аналогичные изменения по другим организациям также могли
                  оказать влияние на объем распределенных образовательной организации КЦП. Например, если организации,
                  имеющие более высокий рейтинг, увеличили свои заявки, то объем КЦП, распределяемый организации, мог
                  снизиться. И наоборот, если лидеры рейтинга свои заявки сократили, распределенный организации объем
                  КЦП мог возрасти
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf6 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">6</div>
                  <div>Снижение влияния переходного периода с 85% до 60%</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f6)">
                  <span v-if="indicator.data.f6 != null ">{{ indicator.data.f6 > 0 ? '+'+indicator.data.f6 : indicator.data.f6 }}</span>
                </div>
                <div class="b-indicator-body__form">Снижение влияния переходного периода (доли КЦП, распределяемых
                  пропорционально КЦП на 2018-2020 гг.) могло привести к снижению числа мест для образовательных
                  организаций, имеющих невысокий рейтинг заявки и наоборот, росту числа мест для образовательных
                  организаций с высокими показателями оценки деятельности.
                </div>
              </div>
              <div class="b-indicator-body   py-3"
                   :class="indicator.data.sf8 === 0 ? 'b-indicator-body__opacity':''">
                <div class="b-indicator-body__form">
                  <div class="darker-orange-color mr-2" style="display: flex; align-items: center">7</div>
                  <div>Изменение рейтинга и (или) сводных оценок заявок</div>
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="calcColor(indicator.data.f8)">
                  <span v-if="indicator.data.f8 != null">{{ indicator.data.f8 > 0 ? '+'+indicator.data.f8 : indicator.data.f8 }}</span>
                </div>
                <div class="b-indicator-body__form">При повышении рейтинга заявки относительно других организаций
                  распределяемый объем КЦП мог вырасти, а при снижении рейтинга – наоборот, снизиться.
                </div>
              </div>
              <div class="b-indicator-body py-3">
                <div class="b-indicator-body__form darker-orange-color" style="font-size: 20px; ">
                  Всего
                </div>
                <div class="b-indicator-body__form"
                     style="font-size: 20px"
                     :style="indicator.data.fsum > 0 ? 'color: #00599B;' : 'color: #ED393E;'">
                  {{ indicator.data.fsum > 0 ? '+'+indicator.data.fsum : indicator.data.fsum }}
                </div>
                <div class="b-indicator-body__form"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
<!--      <div v-else>-->
<!--        {{ indicators.notetxt }}-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
import request from "@/services/request";
import {mapGetters} from 'vuex';
import HeaderMain from "@/components/headers/HeaderMain";
import CardIndicator from "@/components/cards/custom/indicatiors/cardIndicator";
// import {fixedTo1, fixedTo2, fixedTo3} from '@/assets/json/indicators';
import CardIndicatorForFinalCompare from "@/components/cards/custom/indicatiors/cardIndicatorForFinalCompare";

export default {
  components: {
    CardIndicatorForFinalCompare,
    // eslint-disable-next-line vue/no-unused-components
    CardIndicator,
    HeaderMain,
  },
  data() {
    return {
      indicators: null,
      show: false,
      headItem: {
        title: 'Сводная оценка заявки',
        about: 'Сумма баллов по всем показателям',
        dataFieldName: 'generalValue',
        available: true,
        typeStyle: 'general',
      },
      yearsArr: [
        {id: 0, year: 2018, value: 14, dataField: 'kcp2018', dopInfo: false},
        {id: 1, year: 2019, value: 18, dataField: 'kcp2019', dopInfo: false},
        {id: 2, year: 2020, value: 20, dataField: 'kcp2020', dopInfo: false},
        {id: 3, year: 2021, value: 25, dataField: 'kcp2021', dopInfo: false},
        {id: 4, year: 2022, value: 23, dataField: 'kcp2022', dopInfo: true},
        {id: 5, year: '&#916;2021/2022', value: -2, dataField: 'kcpd', dopInfo: false},
      ],
      loading: false,
      error: null,
      empty: true,
      descriptionList: [
        {text: "Снижение объема КЦП составило 2 . Неудовлетворенный остаток заявки - 2 ."},
        {text: "Образовательная организация получила 23 КЦП на базовом, федеральном и региональном эапах."},
        {text: "На базовом этапе образовательная организация получила 10 КЦП, соответственно ее доле в КЦП на 2018-2020 гг. по УГСН (НПС)."},
        {text: "На федеральном этапе распределялось 122 КЦП. Образовательная организация не смогла получить КЦП, т.к. имела 80-й рейтинг заявки из 126. Для получения КЦП на федеральном этапе был необходим рейтинг заявки не ниже 4-го."},
        {text: "На региональном этапе распределялось 108 КЦП. Образовательная организация получила 13 КЦП (в размере ниже остатка заявки), т.к. имела 5-й рейтинг заявки из 5. Для получения КЦП на региональном этапе был необходим рейтинг заявки не ниже 5-го. Для получения КЦП в размере остатка заявки объем распределяемых КЦП оказался недостаточным."},
        {text: "На окружном этапе распределялось 79 КЦП. Образовательная организация не смогла получить КЦП, т.к. имела 5-й рейтинг заявки из 8. Для получения КЦП на окружном этапе был необходим рейтинг заявки не ниже 3-го."},
        {text: "На заключительном этапе распределялось 131 КЦП. Образовательная организация не смогла получить КЦП, т.к. имела 15-й рейтинг заявки из 36. Для получения КЦП на заключительном этапе был необходим рейтинг заявки не ниже 7-го."},
      ],
      foList: [
        {id: 1, text: 'Очная'},
        {id: 2, text: 'Очно-заочная'},
        {id: 3, text: 'Заочная'},
      ],
      bIndicators: [
        {
          title: 'Б11',
          dataFieldName: 'p01',
          about: 'Средний балл ЕГЭ',
          count: '64.8',
          max: 23,
          idUsl: 1,
        },
        {
          title: 'Б12',
          dataFieldName: 'p02',
          about: 'Исполнение КЦП прошлых лет',
          count: '19.4',
          max: 3,
          idUsl: 1,
        },
        {
          title: 'Б13',
          dataFieldName: 'p03',
          about: 'Доля целевого приема',
          count: '3.0',
          max: 4,
          idUsl: 1,
        },
        {
          title: 'Б21',
          dataFieldName: 'p04',
          about: 'Наличие ПОА',
          count: '1.8',
          max: 2,
          idUsl: 1,
        },
        {
          title: 'Б22',
          dataFieldName: 'p05',
          about: 'Соотношение (Маг+Асп)/БС',
          count: '9.0',
          max: 9,
          idUsl: 1,
        },
        {
          title: 'Б23',
          dataFieldName: 'p06',
          about: 'Соотношение (Маг+Асп+Орд)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б24',
          dataFieldName: 'p07',
          about: 'Соотношение (Маг+Асп+Аcc)/БС',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б25',
          dataFieldName: 'p08',
          about: 'Соотношение ДПО/(БС+Маг)',
          count: 'Показатель не рассчитывается',
          max: 0,
          idUsl: 1,
        },
        {
          title: 'Б28',
          dataFieldName: 'p09',
          about: 'Доля целевого обучения',
          count: '9.0',
          max: 9,
          idUsl: 1,
        },
        {
          title: 'Б31',
          dataFieldName: 'p10',
          about: 'Трудоустройство выпускников',
          count: '18.7',
          max: 25,
          idUsl: 1,
        },
        {
          title: 'Б32',
          dataFieldName: 'p11',
          about: 'Сохранность контингента',
          count: '4.7',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б41',
          dataFieldName: 'p12',
          about: 'Публикации на 100 НПР',
          count: '0.4',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б42',
          dataFieldName: 'p13',
          about: 'Доходы от НИОКР на 1 НПР',
          count: '1.3',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б43',
          dataFieldName: 'p14',
          about: 'Доля иностранных студентов',
          count: '2.4',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'Б44',
          dataFieldName: 'p15',
          about: 'Доходы на 1 студента',
          count: '0.9',
          max: 5,
          idUsl: 1,
        },
        {
          title: 'KI',
          dataFieldName: 'ki',
          about: 'корректирующий коэффициент',
          count: '0.9',
          max: 5,
          idUsl: 1,
        },
      ],
    }
  },
  mounted() {
    // if (this.id != null)
    this.getIndicator();
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {authData: 'authData'}),
    indicator() {
      return this.indicators != null ? {data: this.indicators, fo: this.idFO} : {data: null, fo: this.idFO}
    },
    idFO() {
      return this.$route.query.fo != null ? Number.parseInt(this.$route.query.fo) : 1
    },
    foName() {
      return this.foList.find(el => el.id === this.idFO)?.text
    },
    middleValue() {
      return this.$route.query.value != null ? Number.parseFloat(this.$route.query.value) : null
    },
    idUsl() {
      return this.$route.query.usl != null ? Number.parseInt(this.$route.query.usl) : 1
    },
    id() {
      return this.$route.query.id != null ? Number.parseInt(this.$route.query.id) : null
    },
    bIndicatorsCalculated() {
      if (this.indicator.data != null)
        return this.bIndicators
            .filter(el => el.idUsl === 1)
            .map(el => {
              let count = ''
              switch (this.indicator.data['s' + el.dataFieldName]) {
                    case 1:
                      count = this.indicator.data[el.dataFieldName + 'B']
                      break;
                    case 2:
                      count = 'нет данных - определяется по средним'
                      break;
                   case 3:
                     count = 'не рассчитывается для этой УГСН(НПС)'
                     break;
              }
              return {
                ...el,
                count: count,
                max: this.indicator.data[el.dataFieldName + 'Max'],
                centerValuesHide: false
              }
            })
      else return []
    }
  },
  methods: {
    calcColor(val){
      return val >= 0 ? val > 0 ? 'color: #00599B;': 'color: black;' : 'color: #ED393E;'
    },
    calcBackground(value, maxValue = '100') {
      let a = Number.parseFloat(value)
      let b = Number.parseFloat(maxValue)
      let per = a * 100 / b
      return isNaN(per) ? `  background: #f3f3f4; border-color: #CDD1D4` :
          `background: -webkit-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:    -moz-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:     -ms-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:      -o-linear-gradient(left, #eb722e ${per}%,#f9d3be ${per}%);
      background:         linear-gradient(to right, #eb722e ${per}%,#f9d3be ${per}%);`
    },
    async getIndicator() {
      this.empty = false
      this.loading = true
      let indicators = await request({
        endpoint: `/api/zayavka/getCalcRaspredByFoID?sprOrgID=${this.$route.query.org}&foID=${this.$route.query.fo}&sprKCPGroupID=${this.$route.query.kcp}`,
        method: 'GET',
      });
      if (indicators.status === 200) {
        this.indicators = indicators.data;
        this.indicators.kiN = 'KI'
        this.indicators.kiName = 'корректирующий коэффициент'
        this.indicators.kiMax = '1.50'
        this.indicators.kiMin = '1'
        this.indicators.kiB = this.indicators.ki
        this.indicators.ki = null
        this.loading = false
      } else {
        this.loading = false
        this.error = indicators.error
      }
    }
  }
}

</script>


<style lang="sass" scoped>
.indicators-ul-list
  list-style: none
  max-width: 1320px
  li
    text-align: justify

.arrow-up
  width: 0
  height: 0
  border-left: 7px solid transparent
  border-right: 7px solid transparent
  border-bottom: 7px solid #eb722e

  &__darker
    width: 0
    height: 0
    border-left: 7px solid transparent
    border-right: 7px solid transparent
    border-bottom: 7px solid #00599B

.b-indicator-head
  display: flex
  flex-grow: 1
  flex-direction: row

  &__form
    justify-content: center
    margin-right: auto
    max-width: 500px
    flex-basis: 150px
    display: flex
    flex-direction: row

    border-bottom: 1px solid #cdd1d4
    &:nth-child(1)
      justify-content: space-between
      flex-basis: 250px
    &:nth-child(2)
      justify-content: space-between
      flex-basis: 100px
      margin-left: 5px
      align-items: flex-end
    &:nth-child(3)
      flex-basis: 700px
      max-width: 700px
      margin-left: 5px
      justify-content: flex-start
      align-items: flex-end
    &-analyzing
      flex-basis: 614px
      max-width: 614px
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      margin-right: auto
      border-bottom: 1px solid #cdd1d4
      div
        flex-basis: 32px
        &:nth-child(1)
          text-align: center
          margin-right: 6px
        &:nth-child(2)
          flex-basis: 550px
          text-align: center
        &:nth-child(3)
          text-align: center
          margin-left: 6px


.b-indicator-body
  display: flex
  flex-grow: 1
  flex-direction: row
  font-size: 12px
  align-items: stretch

  &.b-indicator-body__opacity
    opacity: 0.5
  &:last-child
    border: 0
  &__form
    justify-content: center
    margin-right: auto
    max-width: 500px
    flex-basis: 150px
    display: flex
    flex-direction: row
    border-bottom: 1px solid #cdd1d4
    padding-bottom: 12px
    &:nth-child(1)
      justify-content: flex-start
      align-items: center
      flex-basis: 250px
      font-size: 14px
    &:nth-child(2)
      justify-content: center
      font-size: 20px
      align-items: center
      margin-left: 5px
    &:nth-child(3)
      flex-basis: 700px
      max-width: 700px
      margin-left: 5px
      justify-content: space-between
      text-align: justify

.general-value-block
  display: flex
  flex-grow: 1
  flex-direction: row
  margin-left: 20px

  &__one
    display: flex
    max-width: 200px
    flex-basis: 150px
    margin-right: auto
    font-size: 12px
    flex-direction: column
    justify-content: center

  &__two
    display: flex
    font-size: 12px
    max-width: 1000px
    flex-basis: 850px
    margin-left: 10px
    margin-right: auto
    justify-content: space-around

    &-first_row
      display: flex
      flex-direction: column
      justify-content: center
      text-align: center

.b-indicator
  display: flex
  //justify-content: space-between;
  flex-grow: 1
  align-items: center

.header-final-value
  font-weight: 500
  color: #455058
  //margin-bottom: 10px
  display: flex
  justify-content: left
  padding: 5px 0px
.border-bottom
  border-bottom: 1px solid #cdd1d4
.border-null
  border: 0
.header-final-value__form
  display: flex
  justify-content: center
  flex-wrap: wrap
  //margin-right: auto
  max-width: 100px
  flex-basis: 100px

  &.bracket
    &:nth-child(1)
      //margin-right: 0
      max-width: 200px
      flex-basis: 200px

    &:nth-child(2)
      max-width: 500px
      flex-basis: 500px

  &-cell
    display: flex
    flex-direction: column

    span
      text-align: center
//.bracket-svg-rotate g
//  transform-origin: center center
//  transform: translate(11px) rotate(-90deg)
.indicators
  //background: #fff
  //border-radius: 10px
  //border: 1px solid #CAD2D8
  //padding: 20px

.main-header__title-list
  font-size: 32px
  line-height: 35.2px
  color: #00599B
  font-family: 'Montserrat'
  font-weight: 700
  max-width: 100%
  margin-bottom: 22px

.header-subtitle-indicators
  font-size: 17px
  color: #eb722e



</style>