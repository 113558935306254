<template>
  <section class="letters">
    <!-- <Заголовок> -->
    <HeaderMain title="Прием конвертов" />
    <!-- <Заголовок> -->
    <div class="cms-panel">
    <div class="cms-panel-search">
        <v-icon :size="25" color="#cdd1d4" class="fr-topbar__icon">mdi-magnify</v-icon>
        <input type="search" class="cms-panel-search__input" v-model="searchLetters">
    </div>
    <BSelect :data="searchFields" v-model="selectedSearchField" class="cms-panel-search__sort" />
    <!-- <Выбор конвертов по статусу> -->
    <BSelect :data="selectedOptions" v-model="selectedOption" class="letters__select-type" />
    <!-- </Выбор конвертов по статусу> -->
    </div>

    <!--<Алерт>-->
    <Alert alertType="primary" v-if="selectedTypeLetters && !selectedTypeLetters.length">
      <template v-slot:icon>
        <v-icon :size="30" color="#084298">mdi-information-outline</v-icon>
      </template>
      <template v-slot:default>Активных конвертов нет</template>
    </Alert>
    <!--</Алерт>-->
    <!--<Список конвертов>-->
    <CardLetter v-for="item in resultSearch.data" :key="item.key" :item="item" @acceptConvert="onOpenAcceptConvert($event)" />
    <!--<Модальное окно для конвертов>-->
    <Modal v-on:input="onCancelAcceptConvert" v-if="modalOpen" title="Принять конверт">
      <AlertError v-if="errorSending">Произошла ошибка, повторите запрос</AlertError>
      <FormAcceptConvert @acceptConvertApp="onSendOkAcceptConvert($event)" @cancel="onCancelAcceptConvert" />
    </Modal>
    <!--</Модальное окно для конвертов>-->

    <p class="paginator-info"> Страница: {{ this.paginatorParams.selectedPage }}, Результатов на странице: {{ this.paginatorParams.itemsPerPage }}, Всего результатов: {{resultSearch.counter}} </p>
    <BPaginator
      v-if="resultSearch"
      :propDataCount="resultSearch.counter"
      v-model="paginatorParams"
    />

    <!--</Список конвертов>-->
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/alerts/Alert';
import AlertError from '@/components/alerts/AlertError';
import Modal from '@/components/modals/Modal';
import request from "@/services/request";
import FormAcceptConvert from "@/components/forms/custom/FormAcceptConvert";
import CardLetter from "@/components/cards/custom/CardLetter";
import BPaginator from "@/components/paginator/BPaginator/BPaginator";
import BSelect from "@/components/controls/BSelect/BSelect";
import HeaderMain from '@/components/headers/HeaderMain';

export default {
  components: {
    CardLetter,
    Modal,
    BSelect,
    Alert,
    HeaderMain,
    FormAcceptConvert,
    AlertError,
    BPaginator,
  },
  data() {
    return {
      letters: [],
      searchLetters: '',
      searchFields: ['Наименование', 'ID организации'],
      selectedSearchField: 'Наименование',
      results: 0,  
      currentSort: 'orgID',
      currentSortDir: 'asc', 
      paginatorParams: {'selectedPage' : 1, 'itemsPerPage' : 10},
      errorSending: null,
      modalOpen: false,
      selectedConvertId: '',
      selectedOptions: ['Отправленные конверты', 'Принятые конверты', 'Все конверты'],
      selectedOption: 'Все конверты',
    }
  },
  mounted() {
    this.getActualLetters();
  },

  computed: {
    ...mapGetters('auth', { authData: 'authData' }),
    selectedField() {
    if (this.selectedSearchField === 'Наименование') {
      return 'orgName'
    } else if (this.selectedSearchField === 'ID организации') {
      return 'orgID'
    } else {
      return 'orgName'
    }
  },
    selectedTypeLetters() {
      if (this.selectedOption === 'Отправленные конверты') {
        return this.letters.converts.filter(el => el.convertStatus === 'SENDED')
      } else if (this.selectedOption === 'Принятые конверты') {
        return this.letters.converts.filter(el => el.convertStatus === 'RECEIVED')
      } else {
        return this.letters.converts;
      }
    },

    resultSearch () {
    let results;
    let array = [];
    if (this.selectedTypeLetters) {
      array = this.selectedTypeLetters;
    }
    array = array.sort((a, b) => {
        let mod = 1
        if (this.currentSortDir === 'desc') mod = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * mod
        if (a[this.currentSort] > b[this.currentSort]) return 1 * mod
        return 0
      });
    let search = this.searchLetters;
    if (!search) {
      results = array.length;
       array = array.filter((row, index) => {
        let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
        let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
        if (index >= start && index < end) return true
      })

    return {'data': array, 'counter': results};
    }


    search = search.trim().toLowerCase()
    array = array.filter((item) => {
      if (String(item.sprOrg[this.selectedField]).toLowerCase().indexOf(search) !== -1) {
        return item
      }
    })
    results = array.length;
    array = array.filter((row, index) => {
        let start = (this.paginatorParams.selectedPage-1)*this.paginatorParams.itemsPerPage
        let end = this.paginatorParams.selectedPage * this.paginatorParams.itemsPerPage
        if (index >= start && index < end) return true
      })
  return {'data': array, 'counter': results};
  },
  },

  methods: {
    async getActualLetters() {
      let letters = await request({
        endpoint: `/api/admin/getAllConverts`,
        method: 'GET',
      });
      letters = letters.data;
      // letters = letters.sort((a, b) => b.sendDate - a.sendDate);
      this.letters = letters;
    },
    onOpenAcceptConvert(event) {
      this.modalOpen = true;
      this.selectedConvertId = event;
    },

    onCancelAcceptConvert() {
      this.modalOpen = false;
      this.selectedConvertId = '';
    },

    async onSendOkAcceptConvert(event) {
      let convert = await request({
        endpoint: `/api/admin/receiveConvert`,
        method: 'POST',
        body: {
          fioRasp: event.fioRasp,
          position: event.position,
          convertID: this.selectedConvertId,
        }
      });
     if (convert.status === 200) {
       this.onCancelAcceptConvert();
       this.errorSending = false;
       this.getActualLetters();
     } else {
       this.errorSending = true;
     }
    }
  }
}
</script>

<style lang="sass">
.letters
        width: 100%
.info-card__button
        margin-top: 10px
        margin-left: 10px   
.info-card__button
        margin-top: 10px
        margin-left: 10px
.info-card__button:hover
        background: #0292e1
.info-card__cell_changed
        background: #fff3cd
        border-radius: 3px
        color: #856404
        padding: 0px 3px
.modal-content
        max-width: 900px
.info-cards
        display: flex
.letter__select-type
        margin-bottom: 20px
        margin-top: 10px
        background-color: #fff
        padding: 5px 10px
        border: 1px solid #00599b
        cursor: pointer
        min-width: 200px
.cms-panel-search
    display: flex
    align-items: center
    background: #fff
    border-radius: 5px
    padding: 0px 10px
    border: 1px solid #cdd1d4
.cms-panel
    margin-bottom: 20px
    display: flex
.cms-panel-search__sort, .letters__select-type
    background-color: #fff
    display: flex
    align-items: center
    border-radius: 5px
    padding: 0px 10px
    border: 1px solid #cdd1d4
    margin-left: 10px
.letters__select-type.select
    margin-left: auto    
.sort-panel
    display: flex
    width: 100%
    justify-content: space-between
.reference-main
  width: 100%
.paginator-info
    text-align: center
    color: #00599b
.sort-panel
    font-weight: 600
    color: #00599b
    margin-bottom: 10px
.card__info_sort
    cursor: pointer
.card__info.card__info__action .button_border
    text-align: center
    max-height: 35px
.cms-panel-search__sort.select
    min-width: 170px
.cms-panel-search__input
    min-width: 500px                
</style>