<template>
<WrapperForm>
    <form class='form form_inline'>
        <Field
                type="text"
                name="orgName"
                placeholder="Введите наименование"
                label="Название"
                v-model="form.orgName"
                :class="{'field_invalid' : !this.validator.orgName}"

        />
        <Field
                type="text"
                name="adress"
                placeholder="Введите адрес"
                label="Адрес"
                v-model="form.adress"
                :class="{'field_invalid' : !this.validator.adress}"
        />
        <Field
                type="text"
                name="kpp"
                placeholder="Введите КПП"
                label="КПП"
                v-model="form.kpp"
                :class="{'field_invalid' : !this.validator.kpp}"
        />

        <v-file-input
                label="Документ"
                v-model="form.file"
                class="field__input_file_dop"
                :class="{'field_invalid' : !this.validator.file}"
            />

        <button type="submit" class="button" @click.prevent="onSubmit">Заявка на добавление</button>


    </form>  
</WrapperForm>  
</template>


<script>
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import WrapperForm from '@/components/wrappers/WrapperForm';
import Field from '@/components/fields/Field/Field';
export default {
    components: {
        Field,
        WrapperForm
    },
    data() {
        return {
            form: {
                orgName: this.$attrs.value.orgName,
                adress: this.$attrs.value.adress,
                kpp: this.$attrs.value.kpp,
                file: this.$attrs.value.file,
            },
            validator: {
                orgName: true,
                adress: true,
                kpp: true,
                file: true,
            },
        }
    },
    validations: {
            form: {
                orgName: {
                    required,
                },
                adress: {
                    required,

                },
                kpp: {
                    required,
                    numeric,
                    minLength: minLength(9),
                    maxLength: maxLength(9),
                },
        },
    },
        methods: {
        onSubmit() {
            this.$v.form.$touch();
            this.validator.orgName = this.$v.form.orgName.$invalid ? false : true;
            this.validator.adress = this.$v.form.adress.$invalid ? false : true;
            this.validator.kpp = this.$v.form.kpp.$invalid ? false : true;
            this.validator.file = this.form.file !== null;

            let check = Object.values(this.validator);
            if (check.every(el => el === true)) {
                this.$emit('input', this.form);
                this.$attrs.value.orgName = '';
                this.$attrs.value.adress = '';
                this.$attrs.value.kpp = '';
            }


            
        },
    }
    
}
</script>
<style lang="sass">
.form_inline
    flex-wrap: nowrap
    align-items: center
    margin-top: 20px
.form_inline .field, .form_inline .button
    flex-basis: 0
    flex-grow: 1
</style>