import { render, staticRenderFns } from "./cardTopDescription.vue?vue&type=template&id=35db40a0&scoped=true&"
import script from "./cardTopDescription.vue?vue&type=script&lang=js&"
export * from "./cardTopDescription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35db40a0",
  null
  
)

export default component.exports