<template>
<ContainerIndicatorsFinalList />
</template>

<script>
import ContainerIndicatorsFinalList from '@/containers/private/indicators/ContainerIndicatorsFinalList';
export default {
    components: {
      ContainerIndicatorsFinalList,
    }
}

</script>


<style lang="sass">
</style>