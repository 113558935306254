<template>
	<div class="cms">
		<LayoutFrontTopbar/>
		<LayoutCMSSidebar/>
		<!-- Контентная часть --> 
		<section class="cms-main">
		<component :is="layout">
		</component>
		</section>
		
	</div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import LayoutFrontTopbar from "@/layout/LayoutFront/LayoutFrontTopbar";
import LayoutCMSSidebar from "@/layout/LayoutCMS/LayoutCMSSidebar";

import LayoutCMSContentDefault from "@/layout/LayoutCMS/layoutCMSContent/LayoutCMSContentDefault";

export default {
	components: {
		Breadcrumbs,
		LayoutFrontTopbar,
		LayoutCMSContentDefault,
		LayoutCMSSidebar
	},
	computed: {
		layout() {
			return this.$route.meta.layout ? this.$route.meta.layout : 'LayoutCMSContentDefault';
		}
	},
}
</script>


<style lang="sass">
.cms
    background: #f8f9fb
    height: 100%
</style>