<template>
    <div class="appeal">
        <div class="appeal__value">
            <div class="appeal__header">
                <div class="appeal__property">Тип: <span class="appeal__span" v-text="typeAppeal"></span></div>
                <div class="appeal__property">Организация: <span class="appeal__span" v-text="item.sprOrg.orgName +' (ID: '+ item.sprOrg.orgID + ' )'"></span></div>
                <div class="appeal__property">ID: <span class="appeal__span" v-text="item.appealID"></span></div>
                <div class="appeal__property">Дата создания апелляции: <span class="appeal__span" v-text="dateCreate"></span></div>
            </div>
        </div>
        <div class="appeal__value">
            <button class="button_border" @click="openAboutAppeal">Подробная информация</button>
        </div>
        <div class="appeal__value">
            Статус: <span class="appeal__span" :class="status.style" v-text="status.text"></span>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
moment.locale('ru');
export default {
    props: {
        item: {
            type: Object,
        }
    },
    computed: {
        typeAppeal() {
            let type;
                switch(this.item.appealType) {
                    case 'SOURCE_DATA_ERROR':
                        type = 'Апелляция на ошибку в исходных данных'
                    break;
                    case 'INDICATORS_VALUE_ERROR':
                        type = 'Апелляция на ошибку в расчете значения показателя'
                    break;
                    case 'NORMALIZED_RATING_ERROR':
                        type = 'Апелляция на ошибку в расчете нормированной оценки'
                    break;
                    case 'SUMMARY_RATING_ERROR':
                        type = 'Апелляция на ошибку в расчете сводной оценки'
                    break;
                }
            return type;
        },
        dateCreate() {
                return moment(this.item.createDate).format('LLL'); 
        },
        status() {
            if (this.item.appealStatus === 'NOT_SET') {
                return {
                    text: 'На рассмотрении',
                    style: 'appeal_not-set'
                }
            } else if (this.item.appealStatus === 'ACCEPTED') {
                return {
                    text: 'Удовлетворена',
                    style: 'appeal_accepted'
                }
            } else if (this.item.appealStatus === 'REJECTED') {
                return {
                    text: 'Отказано',
                    style: 'appeal_rejected'
                }
            } else if (this.item.appealStatus === 'PROTOCOL') {
                return {
                    text: 'Протокол №4',
                    style: 'appeal_not-set'
                }
            } else {
                return 'Ошибка'
            }
        },
    },
    methods: {
        openAboutAppeal() {
            this.$emit('openAboutAppeal', this.item);
        }
    }
}
</script>

<style lang="sass">

</style>