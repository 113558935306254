<template>
    <div>
        <div class="score-about">
            <p><b>п.1.1.3. Приложения 2 Порядка:</b></p>
            <p>Доля принятых на обучение в рамках квоты приема на целевое обучение</p>
        </div>
        <h4 class="score-subtitle">1. Значение показателя</h4>
        <h5 class="score-subsubtitle">
            Исходные данные:
        </h5>
        <div class="calculation__about">
            <p class="calculation__about-text">Форма 5.7.</p>
            <p class="calculation__about-text">Прием на обучение по очной форме на бакалавриат и специалитет за счет всех источников финансирования, человек</p>
        </div>
        <table class="indicators__table">
            <thead class="indicator-thead">
                <tr>
                    <th class="border" rowspan="3">№ п/п</th>
                    <th class="border" rowspan="2">Уровень</th>
                    <th class="border" colspan="2">УГСН </th>
                    <th class="border">Принятых на обучение</th>
                    <th class="border">Из них - на целевое</th>
                </tr>
                <tr>
                    <th class="border">Код</th>
                    <th class="border">Наименование</th>
                    <th class="border">2020 год</th>
                    <th class="border">2020 год</th>
                </tr>
                <tr>
                    <th class="border">1</th>
                    <th class="border">2</th>
                    <th class="border">3</th>
                    <th class="border">4</th>
                    <th class="border">5</th>
                </tr>
            </thead>
            <tbody class="indicator-tbody">
                <tr>
                    <td class="border" colspan="6">{{ rowOfValues.data.sprOrg.orgName }}</td>
                </tr>
                <tr>
                    <td class="border">1.</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.sprUsl.uslName }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.code }}</td>
                    <td class="border">{{ rowOfValues.data.sprKCPGroup.name }}</td>
                    <td class="border text-center" v-html="checkData( rowOfValues.data.p)"></td>
                    <td class="border text-center" v-html="checkData( rowOfValues.data.cp)"></td>
                </tr>
                <tr style="height: 20px">
                    <td colspan="4"></td>
                    <td>
                        <div ><img style="height: 20px; margin: 0; width:185px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png" alt=""/></div>
                    </td>
                    <td>
                        <div ><img style="height: 20px; margin: 0; width:162px; " src="@/assets/img/brackets/bottom-bracket-removebg-preview.png" alt=""/></div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"></td>
                    <td class="text_center"><b>П</b></td>
                    <td class="text_center"><b>ЦП</b></td>
                </tr>
            </tbody>
        </table>

        <!-- formula -->

        <section class="section-score">
            <h5 class="score-subsubtitle">Расчет значения показателя:</h5>
            <div class="formula">
                <div class="formula-full"><span>Б13<span class="font-size-little__bottom">0</span> =</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline">ЦП</div>
                    <div class="formula-half">П</div>
                </div>
                <div class="formula-full"><span class="font-weight-regular">=</span></div>
                <div class="formula-full">
                    <div class="formula-half formula-half_underline">{{ rowOfValues.data.cp }}</div>
                    <div class="formula-half">{{ rowOfValues.data.p }}</div>
                </div>
                <div class="formula-full"><span class="font-weight-regular">=</span></div>
                <div class="formula-full">{{ rowOfValues.data.b13b0 }}</div>
            </div>
            <div class="score-about">
                <p>ЦП - численность студентов, принятых на обучение в рамках квоты приема на целевое обучение по очной форме обучения в отчетном году;</p>
                <p>П - общая численность студентов, принятых на обучение по очной форме обучения в отчетном году.</p>
            </div>
        </section>

    </div>
</template>

<script>
    export default {
        name: "b13",
        props: {
            item: {
                type: Object
            },
            rowOfValues: {
                type: Object,
                default: () => {
                }
            },
        },
        methods: {
            checkData(value) {
                return value;
                // return this.$scripts.TableJs.checkValueWithNullAndReturnND(value.value,'float',value.rnd,false, 'color: #C6C8CB;')
            }
        },
    }
</script>

<style lang="sass" scoped>
    .indicators__table
        //position: relative
        //display: block
        //border-radius: 5px
        width: 100%

    .indicator-thead
        th
            text-align: center
            &.border
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px
            &.min
                min-width: 40px
            &.border_bottom
                border-bottom: 1px solid #546e7a
    .indicator-tbody
        td
            &.border
                background-color: inherit
                border: 1px solid #546e7a
                padding: 5px
            &.min
                min-width: 40px
            img
                height: 260px
                margin-right: 0px
                width: 40px
                position: absolute
            &.text
                &_left
                    font-weight: bold
                    vertical-align: middle
                    text-align: left
                &_right
                    font-weight: bold
                    vertical-align: middle
                    text-align: right
                &_center
                    vertical-align: middle
                    text-align: center

    .grid-container__header
        display: grid
        //grid-template-rows: 1fr 1fr
        div
            border: 1px solid black
            text-align: center
        //grid-auto-rows: 50px
        grid-gap: 3px
        grid-template-columns: 25px 1fr 1fr 1fr 1fr 1.5fr 2fr

        .grid-row-span-2
            grid-column: 1
            grid-row: 1/3

        &_inner
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 2px
</style>