<template>
    <WrapperForm>
		<form @submit.prevent="onSubmit" class='form'>
            <Field
                type="text"
                v-model="form.fioRasp"
                name="fioRasp"
                placeholder="ФИО"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.fioRasp}"
            />
            <Field
                type="text"
                v-model="form.position"
                name="position"
                placeholder="Должность"
                @input="onChange"
                :class="{'field_invalid' : !this.validator.position}"
            />
            <button :disabled="!isOnButtonSave" type="submit" :class="['button', {'button_disabled': !isOnButtonSave}]">Сохранить</button>
			
		</form>
     </WrapperForm>    
</template>    

<script>
import { required } from 'vuelidate/lib/validators'
import Field from '@/components/fields/Field/Field';
import WrapperForm from '@/components/wrappers/WrapperForm';
export default {
    components: {
        Field,
        WrapperForm
    },
    data() {
        return {
           isOnButtonSave: false,
            validator: {
                fioRasp: true,
                position: true,
            },
            form: {
                fioRasp: '',
                position: '',
            }
        }
    },
        validations: {
            form: {
                fioRasp: {
                    required,
                },
                position: {
                    required,
                },

            }
        },

    methods: {
        onSubmit() {
            this.$v.form.$touch();
            this.validator.fioRasp = this.$v.form.fioRasp.$invalid ? false : true;
            this.validator.position = this.$v.form.position.$invalid ? false : true;
            
            let check = Object.values(this.validator);
            if (check.every(el => el === true)) {
                this.$emit('acceptConvertApp', this.form);
            }
        },
        onChange() {
            this.isOnButtonSave = true;
        },
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>

<style lang="sass">
</style>