<template>
	<div class="faq-item">
		<div class="faq-item__question" @click="open = !open">
			<span>{{item.question}}</span>
			<img class="faq-item__icon" src="@/assets/img/arrow-down.svg">
		</div>
		<div class="faq-item__answer" :class="[open ? 'faq-item__answer_open' : 'faq-item__answer_close']" v-html="item.answer"></div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
		},
	},
	data() {
		return {
		open: false,			
		}

	}
}
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.faq-item
	border-top: 1px solid $greyColor
.faq-item__answer
	transition: 0.3s	
.faq-item__answer_close
	max-height: 0px
	overflow: hidden
	transition: 0.3s	
.faq-item__answer_open
	max-height: 100%
	transition: 0.3s	
.faq-item__question 
	padding: 15px 10px
	font-size: 20px
	cursor: pointer
	display: flex
	transition: 0.3s
	justify-content: space-between
.faq-item__icon 
	width: 19px
.faq-item__question:hover
	background: #f0f5f9
	transition: 0.3s
.faq__footer
	display: flex
	justify-content: flex-end
	margin: 30px 0 20px 0
</style>

