<template>
  <div class="modal__wrapper" @click="closeModal">
    <div class="modal-content" @click.stop="">

      <!-- header -->
      <div class="modal-header">
        <span class="modal-title">{{title}}</span>
        <span class="button-close" @click="closeModal">×</span>
      </div>

      <!-- body -->
      <div class="modal-body">
        <slot></slot>
      </div>

    </div>
  </div>

</template>



<script>

export default {
    props: {
        title : {
            type: String,
        },
  },

  methods: {
    closeModal() {
        this.$emit('input');
    },
  },
    mounted() {
    document.body.addEventListener( 'keyup', event => {
      if (event.key === 'Escape') {
         this.closeModal();
      }
      })
  },
}
</script>

<style lang="scss">

.modal__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: opacity .2s ease;
    right: 0;
    z-index: 998;
    background-color: rgba(00,00,00,.48);
  }
  
  .modal-content {
    position: relative;
    max-width: 600px;
    padding: 20px 18px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    transition: all .2s ease;
    border-radius: 8px;
    z-index: 999;
    overflow: hidden;
    max-height: 500px;
    top: 5%;
    overflow-y: scroll;
    @media screen and (min-width: 900px) {
      min-width: 500px;
    }
  }
  .modal-header {
    display: flex;
    align-self: center;
    justify-content: space-between;
    span {
      font-size: 24px;
    }
    .button-close {
      cursor: pointer;
    }
  }
  .modal-body {
    text-align: center;
  }
  
  
</style>
