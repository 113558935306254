<template>
	<DetailArticle :item="faq.data" schemaType="DigitalDocument">
		<p>{{faq.data.question}}</p>
		<p>{{faq.data.answer}}</p>
	</DetailArticle>
</template>
<script>
import request from "@/services/request";
import DetailArticle from "@/components/article/DetailArticle";
import ModelFaqDetail from '@/services/models/ModelFaqDetail';
export default {
	components: {
		DetailArticle
	},
	data() {
		return {
			faq: null,
		}
	},
	mounted() {
		this.getFAQ();
	},
	methods: {
		async getFAQ() {
			this.faq = await request({
				endpoint: `/api/mainPage/getFAQ/?id=${this.$route.params.id}`,
				method: 'GET',
				model: ModelFaqDetail
			})
		},
	}
}
</script>
<style lang="sass">
</style>