<template>
    <div>
        <h1 class="title app-title_main app__title main-header__title-list">Контакты центров ответственности</h1>
        <WrapperPreviewCategory v-for="(item, index) in serverData" :key="index" customClass="section-preview_lk">
            <WrapperCollapse
                    :title="item.orgFounderName"
                    :subTitle=concatUgsList(item.founderUgs)
                    :initialOpen="true"
            >
                <ContainerPersonContact v-for="(contact, i) in item.founderContacts" :key="i"
                        :can-be-deleted="false"
                        :blocked="true"
                        :contact="contact"
                        is-single
                />
            </WrapperCollapse>
        </WrapperPreviewCategory>
    </div>
</template>

<script>
    import ContainerPersonContact from '@/components/contacts/ContainerPersonContact';
    import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
    import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
    import request from "@/services/request";
    import {mapActions} from 'vuex';

    export default {
        name: "ViewContactsCO",
        components: {WrapperPreviewCategory, WrapperCollapse, ContainerPersonContact},
        data() {
            return {
                serverData: [],
                loadingData: false,
                errorData: null
            }
        },
        mounted() {
            this.getServerData();
        },
        methods: {
            ...mapActions('notifications', {addNotification: 'addNotification'}),
            concatUgsList(founderUgs){
                let list = [];
                founderUgs.filter(el => (el.main)).forEach(el => {
                    list.push(el.ugsCode)
                })
                return list.join(', ')
            },
            async getServerData() {
                let hasData = this?.serverData.length > 0;
                if (!hasData) {
                    this.loadingData = true;
                    const res = await request({
                        endpoint: `/api/founderService/getallwithcontacts`,
                        method: 'get',
                    });
                    if (res.status === 200) {
                        this.loadingData = false;
                        this.serverData = res.data.filter(
                            el => (el.founderUgs.length > 0 && el.founderUgs.find(e => (e.main)))
                        );
                    } else {
                        await this.addNotification({
                            notificationStatus: 'error',
                            notificationMessage: 'Ошибка получения данных',
                            timeout: 3000
                        });
                        this.errorData = res.error;
                        this.loadingData = false;
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>