<template>
<div>
	<List direction="column">
		<FAQListItem v-for="item in faq.data" :key="item.key" :item="item"/>
	</List>
</div>	
</template>
<script>
import ModelFaqPreview from '@/services/models/ModelFaqPreview';
import request from "@/services/request";
import List from '@/components/lists/List';
import FAQListItem from '@/components/cards/custom/FAQListItem';
export default {
	components: {
		FAQListItem,
		List,
	},
	data() {
		return {
			faq: [],
		}	
	},
	mounted() {
		this.getAllFAQ();
	},
	methods: {
		async getAllFAQ() {
			this.faq = await request({endpoint: '/api/mainPage/getFAQ', method: 'POST', body: {"pageNumber":-1,"recordCount":0}, path: 'faqs', model: ModelFaqPreview})
		},
	}
}
</script>
<style lang="sass">
</style>