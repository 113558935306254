<template>
	<div class="cms">
		<LayoutFrontTopbar/>
		<!-- <LayoutAuthSidebar/> -->
		<!-- Контентная часть --> 
		<section class="cms-main">
		<component :is="layout">
		</component>
		</section>
		
	</div>
</template>

<script>
import LayoutFrontTopbar from "@/layout/LayoutFront/LayoutFrontTopbar";
import LayoutAuthSidebar from "@/layout/LayoutAuth/LayoutAuthSidebar";

import LayoutAuthContentDefault from "@/layout/LayoutAuth/layoutAuthContent/LayoutAuthContentDefault";

export default {
	components: {
		LayoutFrontTopbar,
		LayoutAuthContentDefault,
		LayoutAuthSidebar
	},
	computed: {
		layout() {
			return this.$route.meta.layout ? this.$route.meta.layout : 'LayoutAuthContentDefault';
		}
	},
}
</script>


<style lang="sass">
.cms
    background: #ffffff
    height: 100%
</style>