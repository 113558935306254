<template>
  <div>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title">{{ `Печать конкурсной заявки` }}</h2>
    </header>
    <button
        class="button_border border_margin_right"
        :disabled="uploadNow"
        @click="print">
      Печать
      <v-progress-circular
          v-if="uploadNow"
          indeterminate
          size="14"
          width="3"
      ></v-progress-circular>
    </button>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      uploadNow: false
    }
  },
  computed: {
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
  },
  methods: {
    async print() {
      console.log("print id", this.get_replace_id_admin)
      this.uploadNow = true;
      await this.getFile()
    },
    async getFile() {
      let responseAxios = await axios.get(`/api/report/run/${this.get_replace_id_admin}`, {
        headers: this.$scripts.Utils.authHeader(),
        responseType: 'blob'})
      this.uploadNow = false;
      this.saveByteArray("ReportFunc.pdf",responseAxios.data)
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/pdf"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    }
  },
}

</script>