<template>
  <label>
  <select class="v-table__selector" v-on:change="paginationChanged" v-bind="$attrs">
    <option v-for="opt in optionsArr"  :value="opt.val" :selected="opt.val === value.val" :key="opt.val">{{opt.name}}</option>
  </select>
<!--    <v-icon class="text&#45;&#45;center">-->
<!--      mdi-chevron-down-->
<!--    </v-icon>-->
  </label>
</template>

<script>
export default {
  name: "Selector",
  props:{
    optionsArr:{
      type:Array,
      default: ()=>[]
    },
    value:{
      type: Object,
      default: ()=> {}
    }
  },
  methods:{
    paginationChanged(value){
      this.$emit('update:option',value.target.value);
    }
  }
}
</script>

<style scoped>


</style>

