<template>
  <ul class="breadcrumbs" itemscope itemtype="https://schema.org/BreadcrumbList">
     <BreadcrumbItem v-for="item in this.data" :key="item.id" :item="item"/>
  </ul>


</template>

<script>
import BreadcrumbItem from '@/components/breadcrumbs/BreadcrumbItem'

	export default {
		props: {
			data: {
				type: Array,
			}
		},
		components: {
			BreadcrumbItem
		}
	}
</script>


<style lang="sass">
@import "@/assets/styles/utils/variables"

.breadcrumbs 
    padding-left: 0
    display: flex

.breadcrumbs 
    font-size: 12px
    padding: 20px 0px

.breadcrumbs__item 
    margin-right: 5px
    color: $firstColor

.breadcrumbs__item:last-of-type a
    color: $secondColor 
.breadcrumbs__item:last-of-type:after
    content: ''

.breadcrumbs__item:after
    color: $firstColor
    content: '|'
    margin-left: 5px     

</style>