import { nanoid } from 'nanoid'
import moment from 'moment'

class ModelDocumentDetail {
    constructor({documentID, annuonce, publicDAte, documentNAme, documentURI}) {
        this.id = documentID;
        this.key = nanoid();
        this.about = annuonce;
        this.createDate = moment(publicDAte).format('LL');
        this.title = documentNAme;
        this.document_url = documentURI;
    }
}
export default ModelDocumentDetail;